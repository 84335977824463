@use '../../../../node_modules/citizen/src/styles/abstracts' as *;
@use '../../../../node_modules/citizen/src/styles/base' as *;

:where(html) {
  --dashboard-margin-block: calc(var(--header-size) + 2rem);
  --dashboard-content-padding-top: 7rem;
  --home-status-badge-height: calc(1.25rem + var(--lh-scale-1)); // Icon size + line-height
}


// GLOBAL
// ————————————————————

.dashboard-container {
  container-type: inline-size;
  container-name: dashboard;
}

.dashboard {
  position: relative;
  display: grid;
  grid: auto / [intro-start] 1fr [intro-end reporting-start] 1fr [reporting-end];
  column-gap: 4rem;
  margin-inline: auto;
  margin-block-start: var(--dashboard-margin-block);
  margin-block-end: 6rem;
  padding-inline: clamp(1rem, calc(-0.2456rem + 5.0713vw), 3rem);
  max-width: 1360px;
  scrollbar-gutter: stable;

  @container dashboard (width < 80ch) {
    grid: [intro-start] auto [intro-end reporting-start] auto [reporting-end] / 1fr;
    column-gap: 0;
    row-gap: 4rem;
  }
}



// INTRO & GREETING
// ————————————————————

.dashboard-intro {
  grid-area: intro;
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 1.5rem;
  padding-block-start: var(--dashboard-content-padding-top);

  @container dashboard (width < 80ch) {
    --dashboard-content-padding-top: 2rem;
  }
}

.sticky {
  position: sticky;
  top: calc(var(--header-size) + 2rem + 7rem);
}

.home-status-badge {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding-inline: 1rem;
  //padding-block: calc(var(--lh-scale-1) / 2);
  height: var(--home-status-badge-height);
  border-radius: calc(var(--home-status-badge-height) / 2);
  background-color: var(--status-badge-bg-color, var(--slate-a4));
  width: fit-content;
}

.home-status-icon {
  --indicator-size: 0.75rem;
  border-radius: 0.25rem;
  height: var(--indicator-size);
  width: var(--indicator-size);
  background-color: var(--indicator-color, var(--text-primary));
  border: 1px solid var(--indicator-border-color, var(--text-primary));
  //box-shadow: 0 0 0 2px var(--status-badge-bg-color, var(--slate-a4));
  position: relative;
}

// STATUSES
// ————————————————————
.home-status-badge[data-candidate-status~="selected"] {
  --status-badge-bg-color: var(--plum-a4);
  --status-badge-text-color: var(--plum-a12);

  @media (prefers-color-scheme: dark) {
    --indicator-color: var(--plum-a12);
    --indicator-border-color: var(--plum-a12);
    color: var(--plum-a12);
  }
}

.home-status-badge[data-candidate-status~="summoned"] {
  --status-badge-bg-color: var(--violet-a4);
  --status-badge-text-color: var(--violet-a12);

  @media (prefers-color-scheme: dark) {
    --indicator-color: var(--violet-a12);
    --indicator-border-color: var(--violet-a12);
    color: var(--violet-a12);
  }
}

.home-status-badge {
  &[data-candidate-status~="confirmed"],
  &[data-candidate-status~="qualified"],
  &[data-candidate-status~="service-complete"] {
    --status-badge-bg-color: var(--jade-a4);
    --status-badge-text-color: var(--jade-a12);

    @media (prefers-color-scheme: dark) {
      --indicator-color: var(--jade-a12);
      --indicator-border-color: var(--jade-a12);
      color: var(--jade-a12);
    }
  }
}

.home-status-badge {
  &[data-candidate-status~="excused"],
  &[data-candidate-status~="excusal-pending"] {
    --status-badge-bg-color: var(--amber-a4);
    --status-badge-text-color: var(--amber-a12);
    --indicator-color: transparent;

    @media (prefers-color-scheme: dark) {
      --indicator-border-color: var(--amber-a12);
      color: var(--amber-a12);
    }
  }
}

.home-status-badge {
  &[data-candidate-status~="disqualified"],
  &[data-candidate-status~="disqualification-pending"],
  &[data-candidate-status~="undeliverable"] {
    --status-badge-bg-color: var(--ruby-a4);
    --status-badge-text-color: var(--ruby-a12);
    --indicator-color: transparent;

    @media (prefers-color-scheme: dark) {
      --indicator-border-color: var(--ruby-a12);
      color: var(--ruby-a12);
    }
  }
}

.home-status-badge {
  &[data-candidate-status~="deferred"],
  &[data-candidate-status~="deferral-pending"],
  &[data-candidate-status~="postponed"] {
    --status-badge-bg-color: var(--orange-a4);
    --status-badge-text-color: var(--orange-a12);
    --indicator-color: transparent;

    @media (prefers-color-scheme: dark) {
      --indicator-border-color: var(--orange-a12);
      color: var(--orange-a12);
    }
  }
}

.home-status-badge[data-candidate-status~="panel"] {
  --status-badge-bg-color: var(--sky-a4);
  --status-badge-text-color: var(--sky-a12);

  @media (prefers-color-scheme: dark) {
    --indicator-color: var(--sky-a12);
    --indicator-border-color: var(--sky-a12);
    color: var(--sky-a12);
  }
}

.home-status-badge[data-candidate-status~="juror"] {
  --status-badge-bg-color: var(--mint-a4);
  --status-badge-text-color: var(--mint-a12);

  @media (prefers-color-scheme: dark) {
    --indicator-color: var(--mint-a12);
    --indicator-border-color: var(--mint-a12);
    color: var(--mint-a12);
  }
}

.home-summary {
  @include display-03;
  margin-block-end: 1.5rem;
}

.dashboard-exemptions {
  display: grid;
  //grid: auto / auto;
  row-gap: 1.5rem;
}



// REPORTING INFO
// ————————————————————

.dashboard-reporting-info-container {
  grid-area: reporting;
  container-type: inline-size;
  container-name: reporting-info;
}

.dashboard-reporting-info {
  --reporting-info-columns: repeat(2, 1fr);
  display: grid;
  grid-template-columns: var(--reporting-info-columns);
  grid-auto-rows: min-content; // For candidate name and ID
  column-gap: 2rem;
  row-gap: 3rem;
  padding-block-start: calc(var(--dashboard-content-padding-top) + var(--home-status-badge-height) + 1.5rem);
  //grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));

  @container dashboard (width < 80ch) {
    padding-block-start: 0;
  }

  @container reporting-info (width < 45ch) {
    --reporting-info-columns: 1fr;
  }
}

.reporting-info-block {
  display: grid;
  row-gap: 2rem;
  padding-block: 1rem;
  border-block-start: 1px solid var(--slate-a8);
  align-content: space-between;
  grid-column: var(--grid-span);

  @container reporting-info (width < 45ch) {

  }
}

.reporting-info-block[data-reporting-info~="full"] {
  --grid-span: 1 / -1;
}

.reporting-info-block[data-reporting-info~="2-col"] {
  grid: auto / 1fr 1fr;
  column-gap: 2rem;
}

.reporting-candidate-info {
  //display: flex;
  //flex-direction: column;
}

.reporting-date-time {
  @include display-05;
  font-weight: 500;
  display: flex;
  column-gap: 0.5em;
  flex-wrap: wrap;
  position: relative;
}

.add-to-calendar {

}

.reporting-upcoming-dates {
  --badge-font-size: var(--scale-1);
  --badge-background-color: var(--slate-a4);
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}

.reporting-contact-info {
  display: grid;
  grid-template-columns: 3.5rem max-content;
  //align-items: center;
  column-gap: 1em;
  font-size: var(--scale0);
  line-height: var(--lh-scale0);
  margin-block-end: calc(var(--lh-scale0) / 2);
}

.em-dash {
  font-weight: 300;
}
