@use '../../../../node_modules/citizen/src/styles/abstracts' as *;
@use '../../../../node_modules/citizen/src/styles/base' as *;
@use 'sass:math';

@mixin shape($width, $height) {
  width: $width;
  height: $height;
  border-radius: $height;
}

@mixin visible-pseudo-element($width, $height) {
  position: absolute;
  display: block;
  content: '';
}

@mixin progress-bar(
  $width: 240px,
  $height: 0.625rem,
  $background-color: var(--slate-a2),
  $bar-color: var(--primary),
  $shadow-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 5%, transparent),
  $highlight-gradient: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 5%, transparent)
) {

  //progress bar container
  position: relative;
  background: $background-color;
  @include shape($width, $height);

  &::before {
    @include shape($width, $height);
    @include visible-pseudo-element($width, $height);
  }

  .progress-bar {
    position: relative;
    background: $bar-color;
    @include shape($width, 0.5rem);

    &,
    &::after {
      transition: $transition width,
      $transition background-color;
    }

    &::after {
      @include shape($width, $height);
      @include visible-pseudo-element($width, $height);

    //  background: $highlight-gradient;
    }

    @for $i from 0 through 100 {
      $percentage: math.div($i, 100);
      &.p-#{$i} {
        width: $width * $percentage;

        &::after {
          width: $width * $percentage;
        }
      }
    }
  }
}

.progress-bar.p-100 {
  background: var(--primary) !important;
  transition: $transition background-color;
}


// CONTAINER
// ————————————————————
.questionnaire-progress {
  display: grid;
  row-gap: 0.25rem;
  position: fixed;
  left: initial;
  bottom: 1.5rem;
  width: 100%;
  z-index: 12;
  max-width: 240px;
}

// Progress bar
.progress-bar-container {
  background-clip: padding-box;
  border: 1px solid var(--slate-a5);
  @include progress-bar;
  //box-shadow: 0px 0px 0px 0px hsl(0 0% 0% / 0),
  //            0px 0px 0px 0px hsl(0 0% 0% / 0),
  //            0px 1px 0px -1px hsl(0 0% 0% / 0.05),
  //            0px 1px 1px -1px hsl(0 0% 0% / 0.05),
  //            0px 1px 2px -1px hsl(0 0% 0% / 0.05),
  //            0px 2px 4px -2px hsl(0 0% 0% / 0.05),
  //            0px 3px 6px -3px hsl(0 0% 0% / 0.05);
}

.questionnaire-progress-data {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;
}

.questionnaire-progress-text {
  @include body-01;
}

.questionnaire-progress-numbers, .mono {
  font-family: var(--font-mono);
  font-feature-settings: "lnum" 1,"pnum" 1,"kern" 1,"liga" 1, "ss04" 1;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  letter-spacing: normal;
  text-align: right;
}

.mono {
  text-align: left;
}
