// TOOLTIP
// ————————————————————

.forge-tooltip {
  --forge-tooltip-theme-background: var(--gray-a12);
  --forge-tooltip-theme-on-background: var(--gray-1);
  --forge-tooltip-font-size: 12px;
  line-height: var(--lh-scale-2);
  font-weight: 450;
  transform: scale(0.95);
  white-space: pre-wrap;
  margin: 4px;
}
