@use '../../../node_modules/citizen/src/styles/abstracts' as *;

.date-dropdown,
.date-dropdown-profile {
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(92px, 1fr) minmax(100px, 1fr);
  gap: 0.5rem;

  @media (width < 380px) {
    grid: auto / 1fr;
  }
}

.date-dropdown-profile {
  @media (width <= 398px) {
    grid: auto / 1fr;
  }
}

:where(.date-dropdown-profile) .select-input[data-input-style~="outlined"] {
  --input-bg-color: inherit;
  --input-bg-color-disabled: inherit;
  --input-border-radius: inherit;
  --input-box-shadow: inherit;
  --input-box-shadow-disabled: inherit;
  --input-height: inherit;
}
