@charset "UTF-8";
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/**
 * @license
 * Copyright 2023 Tyler Technologies, Inc. 
 * License: Apache-2.0
 */
.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  -webkit-transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.forge-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: 0;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
}

.forge-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.forge-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.forge-button:active {
  outline: 0;
}

.forge-button:hover {
  cursor: pointer;
}

.forge-button:disabled {
  cursor: default;
  pointer-events: none;
}

.forge-button .forge-button__icon {
  margin-left: 0;
  margin-right: 8px;
  display: inline-block;
  font-size: 1.125rem;
  height: 1.125rem;
  vertical-align: top;
  width: 1.125rem;
}

.forge-button .forge-button__icon[dir=rtl], [dir=rtl] .forge-button .forge-button__icon {
  margin-left: 8px;
  margin-right: 0;
}

.forge-button__label ~ .forge-button__icon {
  margin-left: 8px;
  margin-right: 0;
}

.forge-button__label ~ .forge-button__icon[dir=rtl], [dir=rtl] .forge-button__label ~ .forge-button__icon {
  margin-left: 0;
  margin-right: 8px;
}

svg.forge-button__icon {
  fill: currentColor;
}

.forge-button--outlined .forge-button__icon, .forge-button--raised .forge-button__icon, .forge-button--unelevated .forge-button__icon {
  margin-left: -4px;
  margin-right: 8px;
}

.forge-button--outlined .forge-button__icon[dir=rtl], .forge-button--raised .forge-button__icon[dir=rtl], .forge-button--unelevated .forge-button__icon[dir=rtl], [dir=rtl] .forge-button--outlined .forge-button__icon, [dir=rtl] .forge-button--raised .forge-button__icon, [dir=rtl] .forge-button--unelevated .forge-button__icon {
  margin-left: 8px;
  margin-right: -4px;
}

.forge-button--outlined .forge-button__label ~ .forge-button__icon, .forge-button--raised .forge-button__label ~ .forge-button__icon, .forge-button--unelevated .forge-button__label ~ .forge-button__icon {
  margin-left: 8px;
  margin-right: -4px;
}

.forge-button--outlined .forge-button__label ~ .forge-button__icon[dir=rtl], .forge-button--raised .forge-button__label ~ .forge-button__icon[dir=rtl], .forge-button--unelevated .forge-button__label ~ .forge-button__icon[dir=rtl], [dir=rtl] .forge-button--outlined .forge-button__label ~ .forge-button__icon, [dir=rtl] .forge-button--raised .forge-button__label ~ .forge-button__icon, [dir=rtl] .forge-button--unelevated .forge-button__label ~ .forge-button__icon {
  margin-left: -4px;
  margin-right: 8px;
}

@-webkit-keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@-webkit-keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@-webkit-keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.forge-button {
  --mdc-ripple-fg-size:0;
  --mdc-ripple-left:0;
  --mdc-ripple-top:0;
  --mdc-ripple-fg-scale:1;
  --mdc-ripple-fg-translate-end:0;
  --mdc-ripple-fg-translate-start:0;
  -webkit-tap-highlight-color: transparent;
  will-change: transform, opacity;
}

.forge-button .forge-button__ripple::after, .forge-button .forge-button__ripple::before {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.forge-button .forge-button__ripple::before {
  -webkit-transition: opacity 15ms linear, background-color 15ms linear;
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}

.forge-button .forge-button__ripple::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}

.forge-button.mdc-ripple-upgraded .forge-button__ripple::before {
  -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.forge-button.mdc-ripple-upgraded .forge-button__ripple::after {
  top: 0;
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.forge-button.mdc-ripple-upgraded--unbounded .forge-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}

.forge-button.mdc-ripple-upgraded--foreground-activation .forge-button__ripple::after {
  -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.forge-button.mdc-ripple-upgraded--foreground-deactivation .forge-button__ripple::after {
  -webkit-animation: mdc-ripple-fg-opacity-out 150ms;
  animation: mdc-ripple-fg-opacity-out 150ms;
  -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.forge-button .forge-button__ripple::after, .forge-button .forge-button__ripple::before {
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.forge-button.mdc-ripple-upgraded .forge-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.forge-button .forge-button__ripple {
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.forge-button:not(.forge-button--outlined) .forge-button__ripple {
  top: 0;
  left: 0;
}

.forge-button--raised {
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.forge-button--raised:focus, .forge-button--raised:hover {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.forge-button--raised:active {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.forge-button--raised:disabled {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
}

.forge-button--outlined {
  border-style: solid;
}

.forge-button {
  height: 36px;
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
  padding: 0 8px 0 8px;
}

.forge-button:not(:disabled) {
  background-color: transparent;
}

.forge-button:disabled {
  background-color: transparent;
}

.forge-button:not(:disabled) {
  color: #3f51b5;
  color: var(--mdc-theme-primary, #3f51b5);
}

.forge-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.forge-button .forge-button__ripple::after, .forge-button .forge-button__ripple::before {
  background-color: #3f51b5;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #3f51b5));
}

.forge-button.mdc-ripple-surface--hover .forge-button__ripple::before, .forge-button:hover .forge-button__ripple::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.forge-button.mdc-ripple-upgraded--background-focused .forge-button__ripple::before, .forge-button:not(.mdc-ripple-upgraded):focus .forge-button__ripple::before {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.forge-button:not(.mdc-ripple-upgraded) .forge-button__ripple::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.forge-button:not(.mdc-ripple-upgraded):active .forge-button__ripple::after {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.forge-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.forge-button .forge-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.forge-button--unelevated {
  padding: 0 16px 0 16px;
  height: 36px;
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.forge-button--unelevated:not(:disabled) {
  background-color: #3f51b5;
  background-color: var(--mdc-theme-primary, #3f51b5);
}

.forge-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.12));
}

.forge-button--unelevated:not(:disabled) {
  color: #fff;
  color: var(--mdc-theme-on-primary, #fff);
}

.forge-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.forge-button--unelevated .forge-button__ripple::after, .forge-button--unelevated .forge-button__ripple::before {
  background-color: #fff;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}

.forge-button--unelevated.mdc-ripple-surface--hover .forge-button__ripple::before, .forge-button--unelevated:hover .forge-button__ripple::before {
  opacity: 0.08;
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.forge-button--unelevated.mdc-ripple-upgraded--background-focused .forge-button__ripple::before, .forge-button--unelevated:not(.mdc-ripple-upgraded):focus .forge-button__ripple::before {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.forge-button--unelevated:not(.mdc-ripple-upgraded) .forge-button__ripple::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.forge-button--unelevated:not(.mdc-ripple-upgraded):active .forge-button__ripple::after {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.forge-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.forge-button--unelevated .forge-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.forge-button--raised {
  padding: 0 16px 0 16px;
  height: 36px;
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.forge-button--raised:not(:disabled) {
  background-color: #3f51b5;
  background-color: var(--mdc-theme-primary, #3f51b5);
}

.forge-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.12));
}

.forge-button--raised:not(:disabled) {
  color: #fff;
  color: var(--mdc-theme-on-primary, #fff);
}

.forge-button--raised:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.forge-button--raised .forge-button__ripple::after, .forge-button--raised .forge-button__ripple::before {
  background-color: #fff;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}

.forge-button--raised.mdc-ripple-surface--hover .forge-button__ripple::before, .forge-button--raised:hover .forge-button__ripple::before {
  opacity: 0.08;
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.forge-button--raised.mdc-ripple-upgraded--background-focused .forge-button__ripple::before, .forge-button--raised:not(.mdc-ripple-upgraded):focus .forge-button__ripple::before {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.forge-button--raised:not(.mdc-ripple-upgraded) .forge-button__ripple::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.forge-button--raised:not(.mdc-ripple-upgraded):active .forge-button__ripple::after {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.24;
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.forge-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.24);
}

.forge-button--raised .forge-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.forge-button--outlined {
  height: 36px;
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
  padding: 0 15px 0 15px;
  border-width: 1px;
}

.forge-button--outlined:not(:disabled) {
  background-color: transparent;
}

.forge-button--outlined:disabled {
  background-color: transparent;
}

.forge-button--outlined:not(:disabled) {
  color: #3f51b5;
  color: var(--mdc-theme-primary, #3f51b5);
}

.forge-button--outlined:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.forge-button--outlined .forge-button__ripple::after, .forge-button--outlined .forge-button__ripple::before {
  background-color: #3f51b5;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #3f51b5));
}

.forge-button--outlined.mdc-ripple-surface--hover .forge-button__ripple::before, .forge-button--outlined:hover .forge-button__ripple::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.forge-button--outlined.mdc-ripple-upgraded--background-focused .forge-button__ripple::before, .forge-button--outlined:not(.mdc-ripple-upgraded):focus .forge-button__ripple::before {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.forge-button--outlined:not(.mdc-ripple-upgraded) .forge-button__ripple::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.forge-button--outlined:not(.mdc-ripple-upgraded):active .forge-button__ripple::after {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.forge-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.forge-button--outlined .forge-button__ripple {
  border-radius: 4px;
  border-radius: var(--mdc-shape-small, 4px);
}

.forge-button--outlined:not(:disabled) {
  border-color: #3f51b5;
  border-color: var(--mdc-theme-primary, #3f51b5);
}

.forge-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
  border-color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.12));
}

.forge-button--outlined .forge-button__ripple {
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
}

.forge-button--dense {
  height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  height: 1.5rem;
}

forge-button {
  display: inline-block;
}

forge-button[hidden] {
  display: none;
}

forge-button[disabled] {
  cursor: not-allowed;
}

a.forge-hyperlink {
  color: #3f51b5;
  color: var(--mdc-theme-primary, #3f51b5);
  text-decoration: underline;
  cursor: pointer;
}

a.forge-hyperlink:visited {
  color: #3f51b5;
  color: var(--mdc-theme-primary, #3f51b5);
}

a.forge-hyperlink[disabled] {
  pointer-events: none;
  cursor: default;
}

button.forge-hyperlink {
  color: #3f51b5;
  color: var(--mdc-theme-primary, #3f51b5);
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: inherit;
  padding: 0;
  outline: 0;
  text-align: left;
}

button.forge-hyperlink[disabled] {
  pointer-events: none;
  cursor: default;
}

button.forge-hyperlink:hover:not([disabled]) {
  text-decoration: underline;
}

/**
 * @license
 * Copyright 2023 Tyler Technologies, Inc. 
 * License: Apache-2.0
 */
forge-tooltip {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transform: translateX(-9999px) translateY(-9999px);
  transform: translateX(-9999px) translateY(-9999px);
}

.forge-tooltip {
  background-color: rgba(97, 97, 97, 0.9);
  background-color: var(--forge-tooltip-theme-background, rgba(97, 97, 97, 0.9));
  color: #fff;
  color: var(--forge-tooltip-theme-on-background, #fff);
  font-size: 10px;
  font-size: var(--forge-tooltip-font-size, 10px);
  max-width: 256px;
  max-width: var(--forge-tooltip-max-width, 256px);
  z-index: 11;
  z-index: var(--forge-z-index-tooltip, 11);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  border-radius: 4px;
  pointer-events: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 8px;
  display: inline-block;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: opacity 120ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms cubic-bezier(0, 0, 0.2, 1), transform 120ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 120ms cubic-bezier(0, 0, 0.2, 1), transform 120ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 120ms cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity transform;
}

.forge-tooltip--open {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.forge-tooltip--top {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}

.forge-tooltip--right {
  -webkit-transform-origin: left;
  transform-origin: left;
}

.forge-tooltip--bottom {
  -webkit-transform-origin: top;
  transform-origin: top;
}

.forge-tooltip--left {
  -webkit-transform-origin: right;
  transform-origin: right;
}

.forge-tooltip-host {
  position: relative;
}

/**
 * @license
 * Copyright 2023 Tyler Technologies, Inc. 
 * License: Apache-2.0
 */
.forge-icon-button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  outline: 0;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.forge-icon-button forge-icon, .forge-icon-button img, .forge-icon-button svg {
  width: 24px;
  height: 24px;
}

.forge-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.forge-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.forge-icon-button__icon {
  display: inline-block;
}

.forge-icon-button__icon.forge-icon-button__icon--on {
  display: none;
}

.forge-icon-button--on .forge-icon-button__icon {
  display: none;
}

.forge-icon-button--on .forge-icon-button__icon.forge-icon-button__icon--on {
  display: inline-block;
}

@-webkit-keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-radius-in {
  from {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@-webkit-keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@-webkit-keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.forge-icon-button {
  --mdc-ripple-fg-size:0;
  --mdc-ripple-left:0;
  --mdc-ripple-top:0;
  --mdc-ripple-fg-scale:1;
  --mdc-ripple-fg-translate-end:0;
  --mdc-ripple-fg-translate-start:0;
  -webkit-tap-highlight-color: transparent;
  will-change: transform, opacity;
}

.forge-icon-button::after, .forge-icon-button::before {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.forge-icon-button::before {
  -webkit-transition: opacity 15ms linear, background-color 15ms linear;
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  z-index: var(--mdc-ripple-z-index, 1);
}

.forge-icon-button::after {
  z-index: 0;
  z-index: var(--mdc-ripple-z-index, 0);
}

.forge-icon-button.mdc-ripple-upgraded::before {
  -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.forge-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  left: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.forge-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  left: var(--mdc-ripple-left, 0);
}

.forge-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.forge-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  -webkit-animation: mdc-ripple-fg-opacity-out 150ms;
  animation: mdc-ripple-fg-opacity-out 150ms;
  -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.forge-icon-button::after, .forge-icon-button::before {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.forge-icon-button.mdc-ripple-upgraded::after, .forge-icon-button.mdc-ripple-upgraded::before {
  top: var(--mdc-ripple-top, 0%);
  left: var(--mdc-ripple-left, 0%);
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.forge-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.forge-icon-button::after, .forge-icon-button::before {
  background-color: #000;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}

.forge-icon-button.mdc-ripple-surface--hover::before, .forge-icon-button:hover::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.forge-icon-button.mdc-ripple-upgraded--background-focused::before, .forge-icon-button:not(.mdc-ripple-upgraded):focus::before {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.forge-icon-button:not(.mdc-ripple-upgraded)::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.forge-icon-button:not(.mdc-ripple-upgraded):active::after {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.forge-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

forge-icon-button {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.forge-icon-button--on {
  color: #3f51b5;
  color: var(--mdc-theme-primary, #3f51b5);
}

.forge-icon-button--on::after, .forge-icon-button--on::before {
  background-color: #3f51b5;
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #3f51b5));
}

.forge-icon-button--on.mdc-ripple-surface--hover::before, .forge-icon-button--on:hover::before {
  opacity: 0.04;
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}

.forge-icon-button--on.mdc-ripple-upgraded--background-focused::before, .forge-icon-button--on:not(.mdc-ripple-upgraded):focus::before {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}

.forge-icon-button--on:not(.mdc-ripple-upgraded)::after {
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.forge-icon-button--on:not(.mdc-ripple-upgraded):active::after {
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  opacity: 0.12;
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.forge-icon-button--on.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity:var(--mdc-ripple-press-opacity, 0.12);
}

.forge-icon-button--on::before {
  background-color: #3f51b5;
  background-color: var(--mdc-theme-primary, #3f51b5);
  opacity: 0.08;
}

.forge-icon-button--dense {
  width: 28px;
  height: 28px;
  padding: 2px;
  padding: 0;
}

.forge-icon-button--dense-1 {
  width: 44px;
  height: 44px;
  padding: 10px;
}

.forge-icon-button--dense-2 {
  width: 40px;
  height: 40px;
  padding: 8px;
}

.forge-icon-button--dense-3 {
  width: 36px;
  height: 36px;
  padding: 6px;
}

.forge-icon-button--dense-4 {
  width: 32px;
  height: 32px;
  padding: 4px;
}

.forge-icon-button--dense-5 {
  width: 28px;
  height: 28px;
  padding: 2px;
}

.forge-icon-button--dense-6 {
  width: 24px;
  height: 24px;
  padding: 0;
}

.forge-icon-button--with-badge forge-badge {
  z-index: 1;
  z-index: var(--forge-z-index-surface, 1);
  pointer-events: none;
  --forge-badge-max-width:32px;
  --forge-badge-border:1px solid transparent;
}

.forge-icon-button--with-badge forge-badge[app-bar-context] {
  --forge-badge-border:2px solid var(--forge-app-bar-theme-background);
}

/* This is an old version. Updated one is located in the jury-manager directory. */
/*
Citizen Design System
Version 0.1a

Table of Contents
-----------------------
00 Structure
01 Cards
02 Lists
03 Forms
04 Buttons & Links
05 Interactive
-> 05A Tooltips
06 Utility
07 Modals

** Supports
-----------------------

$breakpoint-xs: 320px;
$breakpoint-sm: 480px;
$breakpoint-md: 672px;
$breakpoint-lg: 1056px;
$breakpoint-xl: 1312px;
$breakpoint-xxl: 1584px;
$breakpoint-xxxl: 1728px;
*/
:where(html) {
  --white: hsl(0 0% 100%);
  --light-bg: var(--white);
  --dark-bg: hsl(var(--gray-dark-05));
  --color-surface: oklch(100% 0 0 / 85.1%);
  --color-panel-solid: oklch(100% 0 0);
  --color-panel-translucent: oklch(100% 0 0 / 70.2%);
  --color-darken: calc(l - 0.125);
  --gray-bg-weak: hsl(var(--gray-00));
  --gray-bg-ui: var(--slate-a2);
  --gray-bg: hsl(var(--gray-20));
  --gray-fg: hsl(var(--gray-80));
  --gray-hover: var(--slate-a3);
  --gray-active: var(--slate-a4);
  --forge-hover: var(--forge-tertiary-50-a12);
  --forge-active: var(--forge-tertiary-50-a20);
  --red-bg-weak: hsl(var(--red-00));
  --red-bg-ui: hsl(var(--red-05));
  --red-bg: hsl(var(--red-20));
  --red-fg: hsl(var(--red-80));
  --orange-bg-weak: hsl(var(--orange-00));
  --orange-bg-ui: hsl(var(--orange-05));
  --orange-bg: hsl(var(--orange-20));
  --orange-fg: hsl(var(--orange-80));
  --yellow-orange-bg-weak: hsl(var(--yellow-orange-00));
  --yellow-orange-bg-ui: hsl(var(--yellow-orange-05));
  --yellow-orange-bg: hsl(var(--yellow-orange-20));
  --yellow-orange-fg: hsl(var(--yellow-orange-80));
  --yellow-bg-weak: hsl(var(--yellow-00));
  --yellow-bg-ui: hsl(var(--yellow-05));
  --yellow-bg: hsl(var(--yellow-20));
  --yellow-fg: hsl(var(--yellow-80));
  --yellow-green-bg-weak: hsl(var(--yellow-green-00));
  --yellow-green-bg-ui: hsl(var(--yellow-green-05));
  --yellow-green-bg: hsl(var(--yellow-green-20));
  --yellow-green-fg: hsl(var(--yellow-green-80));
  --green-bg-weak: hsl(var(--green-00));
  --green-bg-ui: hsl(var(--green-05));
  --green-bg: hsl(var(--green-20));
  --green-fg: hsl(var(--green-80));
  --blue-green-bg-weak: hsl(var(--blue-green-00));
  --blue-green-bg-ui: hsl(var(--blue-green-05));
  --blue-green-bg: hsl(var(--blue-green-20));
  --blue-green-fg: hsl(var(--blue-green-80));
  --aqua-bg-weak: hsl(var(--aqua-00));
  --aqua-bg-ui: hsl(var(--aqua-05));
  --aqua-bg: hsl(var(--aqua-20));
  --aqua-fg: hsl(var(--aqua-80));
  --blue-bg-weak: hsl(var(--blue-00));
  --blue-bg-ui: hsl(var(--blue-05));
  --blue-bg: hsl(var(--blue-20));
  --blue-fg: hsl(var(--blue-80));
  --indigo-bg-weak: hsl(var(--indigo-00));
  --indigo-bg-ui: hsl(var(--indigo-05));
  --indigo-bg: hsl(var(--indigo-20));
  --indigo-fg: hsl(var(--indigo-80));
  --purple-bg-weak: hsl(var(--purple-00));
  --purple-bg-ui: hsl(var(--purple-05));
  --purple-bg: hsl(var(--purple-20));
  --purple-fg: hsl(var(--purple-80));
  --magenta-bg-weak: hsl(var(--magenta-00));
  --magenta-bg-ui: hsl(var(--magenta-05));
  --magenta-bg: hsl(var(--magenta-20));
  --magenta-fg: hsl(var(--magenta-80));
  --hot-pink-bg-weak: hsl(var(--hot-pink-00));
  --hot-pink-bg-ui: hsl(var(--hot-pink-05));
  --hot-pink-bg: hsl(var(--hot-pink-20));
  --hot-pink-fg: hsl(var(--hot-pink-80));
  --pink-bg-weak: hsl(var(--pink-00));
  --pink-bg-ui: hsl(var(--pink-05));
  --pink-bg: hsl(var(--pink-20));
  --pink-fg: hsl(var(--pink-80));
  --primary-bg-weak: var(--forge-tertiary-00);
  --primary-bg-ui: var(--forge-tertiary-05);
  --primary-bg: var(--forge-tertiary-20);
  --primary-fg: var(--forge-tertiary-80);
  --interactive: var(--forge-tertiary-60);
  --button-primary: var(--forge-tertiary-60);
  --button-primary-hover: var(--forge-tertiary-65);
  --dialog-backdrop: oklch(100% 0 0 / 64%);
  --shadow-1: inset 0 0 0 1px var(--gray-a5),inset 0 1.5px 2px 0 var(--gray-a2),inset 0 1.5px 2px 0 var(--black-a2);
  --shadow-2: 0 0 0 0.5px var(--black-a1),0 1px 1px 0 var(--gray-a2),0 2px 1px -1px var(--black-a1),0 1px 3px 0 var(--black-a1);
  --shadow-3: 0 2px 3px -2px var(--gray-a3),0 3px 12px -4px var(--black-a2),0 4px 16px -8px var(--black-a2);
  --shadow-4: 0 8px 40px var(--black-a1),0 12px 32px -16px var(--gray-a3);
  --shadow-5: 0 12px 60px var(--black-a3),0 12px 32px -16px var(--gray-a5);
  --shadow-6: 0 12px 60px var(--black-a3),0 16px 64px var(--gray-a2),0 16px 36px -20px var(--gray-a7);
}

@supports (color: color-mix(in oklab, white, black)) {
  :where(html) {
    --shadow-1: inset 0 0 0 1px var(--gray-a5),inset 0 1.5px 2px 0 var(--gray-a2),inset 0 1.5px 2px 0 var(--black-a2);
    --shadow-2: 0 0 0 0.5px var(--black-a1),0 1px 1px 0 var(--gray-a2),0 2px 1px -1px var(--black-a1),0 1px 3px 0 var(--black-a1);
    --shadow-3: 0 2px 3px -2px var(--gray-a3),0 3px 12px -4px var(--black-a2),0 4px 16px -8px var(--black-a2);
    --shadow-4: 0 8px 40px var(--black-a1),0 12px 32px -16px var(--gray-a3);
    --shadow-5: 0 12px 60px var(--black-a3),0 12px 32px -16px var(--gray-a5);
    --shadow-6: 0 12px 60px var(--black-a3),0 16px 64px var(--gray-a2),0 16px 36px -20px var(--gray-a7);
  }
}
:where(html) {
  --text-primary: hsl(var(--gray-90));
  --text-secondary: hsl(var(--gray-90) / 75%);
  --text-tertiary: hsl(var(--gray-90) / 61%);
  --text-helper: hsl(var(--gray-90) / 61%);
  --text-placeholder: hsl(var(--gray-90) / 48%);
  --text-on-color: var(--white);
  --text-disabled: hsl(var(--gray-90) / 24%);
  --text-critical: oklch(from var(--ruby-a11) var(--color-darken) c h);
  --text-informational: oklch(from var(--blue-a11) var(--color-darken) c h);
  --text-warning: oklch(from var(--amber-a11) var(--color-darken) c h);
  --link-primary: var(--forge-tertiary-60);
  --link-primary-hover: var(--forge-tertiary-65);
  --primary: var(--forge-tertiary-60);
  --primary-hover: var(--forge-tertiary-65);
}

:where(html) {
  color-scheme: light;
  --ui-bg-00: var(--light-bg);
  --ui-bg-01: hsl(var(--gray-00));
  --ui-bg-02: hsl(var(--gray-05));
  --ui-bg-03: hsl(var(--gray-20));
  --text-01: hsl(var(--gray-90));
  --text-02: hsl(var(--gray-70));
  --text-03: hsl(var(--gray-80));
  --ui-bg-hover: var(--slate-a4);
  --ui-bg-active: var(--slate-a5);
  --dialog-backdrop: oklch(100% 0 0 / 64%);
}

::backdrop {
  color-scheme: light;
  --dialog-backdrop: oklch(100% 0 0 / 64%);
}

@media (prefers-color-scheme: dark) {
  :where(html) {
    --ui-bg-00: var(--dark-bg);
    --ui-bg-01: hsl(var(--gray-dark-00));
    --ui-bg-02: hsl(var(--gray-dark-05));
    --ui-bg-03: hsl(var(--gray-dark-30));
    --text-01: hsl(var(--gray-dark-90));
    --text-02: hsl(var(--gray-dark-70));
    --text-03: hsl(var(--gray-dark-80));
    --ui-bg-hover: hsl(var(--gray-dark-15));
    --ui-bg-active: hsl(var(--gray-dark-20));
    --dialog-backdrop: oklch(0% 0 0 / 32%);
  }
  ::backdrop {
    --dialog-backdrop: oklch(0% 0 0 / 32%);
  }
}
[color-scheme=light] {
  color-scheme: light;
  --ui-bg-00: var(--light-bg);
  --ui-bg-01: hsl(var(--gray-00));
  --ui-bg-02: hsl(var(--gray-05));
  --ui-bg-03: hsl(var(--gray-20));
  --text-01: hsl(var(--gray-90));
  --text-02: hsl(var(--gray-70));
  --text-disabled: hsl(var(--gray-40));
  --ui-bg-hover: hsl(var(--gray-15));
  --ui-bg-active: hsl(var(--gray-20));
  --dialog-backdrop: oklch(100% 0 0 / 64%);
}

[color-scheme=dark] {
  color-scheme: dark;
  --ui-bg-00: var(--dark-bg);
  --ui-bg-01: hsl(var(--gray-dark-00));
  --ui-bg-02: hsl(var(--gray-dark-05));
  --ui-bg-03: hsl(var(--gray-dark-20));
  --text-01: hsl(var(--gray-dark-90));
  --text-02: hsl(var(--gray-dark-70));
  --text-disabled: hsl(var(--gray-dark-40));
  --ui-bg-hover: hsl(var(--gray-dark-15));
  --ui-bg-active: hsl(var(--gray-dark-20));
  --dialog-backdrop: oklch(0% 0 0 / 32%);
}

:where(html) {
  --gray-00: 180 9% 98%;
  --gray-05: 220 13% 95%;
  --gray-10: 225 12% 94%;
  --gray-15: 216 12% 92%;
  --gray-20: 220 11% 89%;
  --gray-25: 225 10% 84%;
  --gray-30: 218 9% 83%;
  --gray-40: 217 6% 72%;
  --gray-50: 214 3% 56%;
  --gray-60: 220 3% 46%;
  --gray-65: 228 3% 37%;
  --gray-70: 225 2% 32%;
  --gray-75: 220 2% 26%;
  --gray-80: 200 3% 20%;
  --gray-85: 240 3% 15%;
  --gray-90: 210 4% 10%;
  --gray-100: 240 3% 6%;
  --gray-dark-00: 210 3% 12%;
  --gray-dark-05: 240 3% 15%;
  --gray-dark-10: 210 2% 16%;
  --gray-dark-15: 220 3% 18%;
  --gray-dark-20: 220 3% 20%;
  --gray-dark-30: 225 3% 24%;
  --gray-dark-40: 225 3% 31%;
  --gray-dark-50: 228 2% 44%;
  --gray-dark-60: 230 3% 53%;
  --gray-dark-65: 231 4% 64%;
  --gray-dark-70: 225 5% 71%;
  --gray-dark-75: 225 8% 79%;
  --gray-dark-80: 231 11% 88%;
  --gray-dark-85: 240 13% 95%;
  --gray-dark-90: 240 1% 98%;
  --gray-dark-100: 0 0% 100%;
  --red-00: 0 100% 98%;
  --red-05: 4 100% 97%;
  --red-10: 2 100% 95%;
  --red-15: 4 100% 94%;
  --red-20: 3 100% 92%;
  --red-30: 2 100% 88%;
  --red-40: 1 100% 80%;
  --red-50: 359 65% 65%;
  --red-60: 0 41% 53%;
  --red-65: 359 37% 43%;
  --red-70: 359 38% 37%;
  --red-75: 359 39% 30%;
  --red-80: 359 41% 24%;
  --red-85: 359 43% 18%;
  --red-90: 0 48% 12%;
  --red-100: 357 56% 8%;
  --critical: 357 76% 49%;
  --orange-00: 22 100% 98%;
  --orange-05: 22 100% 95%;
  --orange-10: 21 100% 94%;
  --orange-15: 21 100% 92%;
  --orange-20: 21 100% 89%;
  --orange-30: 21 100% 83%;
  --orange-40: 21 86% 71%;
  --orange-50: 21 45% 55%;
  --orange-60: 22 37% 45%;
  --orange-65: 21 38% 37%;
  --orange-70: 21 38% 31%;
  --orange-75: 22 40% 26%;
  --orange-80: 21 42% 20%;
  --orange-85: 21 45% 15%;
  --orange-90: 22 51% 10%;
  --orange-100: 19 58% 6%;
  --yellow-orange-00: 39 100% 95%;
  --yellow-orange-05: 40 100% 92%;
  --yellow-orange-10: 40 100% 89%;
  --yellow-orange-15: 40 100% 85%;
  --yellow-orange-20: 40 98% 80%;
  --yellow-orange-30: 41 87% 72%;
  --yellow-orange-40: 41 56% 62%;
  --yellow-orange-50: 40 35% 48%;
  --yellow-orange-60: 41 36% 40%;
  --yellow-orange-65: 40 37% 32%;
  --yellow-orange-70: 40 37% 27%;
  --yellow-orange-75: 40 40% 22%;
  --yellow-orange-80: 41 42% 17%;
  --yellow-orange-85: 39 45% 13%;
  --yellow-orange-90: 40 51% 8%;
  --yellow-orange-100: 38 62% 5%;
  --yellow-00: 52 95% 92%;
  --yellow-05: 52 97% 86%;
  --yellow-10: 52 98% 80%;
  --yellow-15: 51 95% 74%;
  --yellow-20: 52 85% 72%;
  --yellow-30: 51 69% 67%;
  --yellow-40: 52 46% 58%;
  --yellow-50: 52 35% 45%;
  --yellow-60: 52 36% 37%;
  --yellow-65: 52 37% 29%;
  --yellow-70: 51 38% 25%;
  --yellow-75: 51 40% 20%;
  --yellow-80: 53 41% 16%;
  --yellow-85: 51 46% 12%;
  --yellow-90: 51 56% 7%;
  --yellow-100: 48 65% 5%;
  --yellow-green-00: 71 82% 93%;
  --yellow-green-05: 68 80% 86%;
  --yellow-green-10: 68 78% 80%;
  --yellow-green-15: 67 79% 74%;
  --yellow-green-20: 67 72% 71%;
  --yellow-green-30: 67 57% 66%;
  --yellow-green-40: 66 39% 57%;
  --yellow-green-50: 67 31% 44%;
  --yellow-green-60: 66 32% 36%;
  --yellow-green-65: 66 32% 29%;
  --yellow-green-70: 66 33% 25%;
  --yellow-green-75: 65 35% 20%;
  --yellow-green-80: 68 37% 15%;
  --yellow-green-85: 65 40% 11%;
  --yellow-green-90: 64 49% 7%;
  --yellow-green-100: 65 55% 4%;
  --green-00: 113 81% 96%;
  --green-05: 115 80% 92%;
  --green-10: 114 81% 88%;
  --green-15: 115 81% 84%;
  --green-20: 114 79% 79%;
  --green-30: 115 70% 70%;
  --green-40: 115 46% 61%;
  --green-50: 115 30% 47%;
  --green-60: 115 31% 39%;
  --green-65: 115 32% 31%;
  --green-70: 115 33% 26%;
  --green-75: 115 34% 21%;
  --green-80: 114 37% 17%;
  --green-85: 115 40% 12%;
  --green-90: 114 49% 8%;
  --green-100: 115 52% 5%;
  --blue-green-00: 154 80% 96%;
  --blue-green-05: 155 80% 92%;
  --blue-green-10: 156 78% 87%;
  --blue-green-15: 156 79% 83%;
  --blue-green-20: 157 78% 78%;
  --blue-green-30: 158 68% 70%;
  --blue-green-40: 158 45% 60%;
  --blue-green-50: 158 30% 47%;
  --blue-green-60: 159 31% 38%;
  --blue-green-65: 158 32% 31%;
  --blue-green-70: 160 33% 26%;
  --blue-green-75: 158 35% 21%;
  --blue-green-80: 160 36% 16%;
  --blue-green-85: 160 39% 12%;
  --blue-green-90: 160 47% 7%;
  --blue-green-100: 162 57% 5%;
  --aqua-00: 186 100% 96%;
  --aqua-05: 186 100% 91%;
  --aqua-10: 186 100% 87%;
  --aqua-15: 185 98% 82%;
  --aqua-20: 186 95% 76%;
  --aqua-30: 186 73% 71%;
  --aqua-40: 186 48% 62%;
  --aqua-50: 186 30% 48%;
  --aqua-60: 186 31% 39%;
  --aqua-65: 185 32% 32%;
  --aqua-70: 187 33% 27%;
  --aqua-75: 186 34% 22%;
  --aqua-80: 186 36% 17%;
  --aqua-85: 187 38% 13%;
  --aqua-90: 183 45% 8%;
  --aqua-100: 189 54% 5%;
  --blue-00: 205 100% 98%;
  --blue-05: 206 100% 95%;
  --blue-10: 206 100% 93%;
  --blue-15: 206 100% 91%;
  --blue-20: 207 100% 89%;
  --blue-30: 207 100% 83%;
  --blue-40: 206 81% 71%;
  --blue-50: 206 42% 55%;
  --blue-60: 206 34% 45%;
  --blue-65: 205 35% 36%;
  --blue-70: 207 36% 31%;
  --blue-75: 207 37% 26%;
  --blue-80: 206 40% 20%;
  --blue-85: 207 43% 15%;
  --blue-90: 208 48% 10%;
  --blue-100: 208 58% 6%;
  --indigo-00: 227 100% 98%;
  --indigo-05: 225 100% 97%;
  --indigo-10: 224 100% 95%;
  --indigo-15: 223 100% 94%;
  --indigo-20: 224 100% 92%;
  --indigo-30: 225 100% 88%;
  --indigo-40: 226 100% 81%;
  --indigo-50: 227 68% 67%;
  --indigo-60: 228 42% 55%;
  --indigo-65: 228 35% 45%;
  --indigo-70: 228 35% 38%;
  --indigo-75: 228 37% 32%;
  --indigo-80: 229 38% 25%;
  --indigo-85: 228 40% 19%;
  --indigo-90: 229 44% 13%;
  --indigo-100: 229 50% 9%;
  --purple-00: 248 100% 98%;
  --purple-05: 244 100% 97%;
  --purple-10: 243 100% 96%;
  --purple-15: 242 100% 95%;
  --purple-20: 244 100% 94%;
  --purple-30: 245 100% 90%;
  --purple-40: 247 100% 84%;
  --purple-50: 249 70% 70%;
  --purple-60: 249 41% 58%;
  --purple-65: 249 30% 47%;
  --purple-70: 249 31% 40%;
  --purple-75: 250 32% 33%;
  --purple-80: 249 33% 26%;
  --purple-85: 249 34% 20%;
  --purple-90: 249 39% 14%;
  --purple-100: 251 45% 9%;
  --magenta-00: 291 100% 99%;
  --magenta-05: 289 100% 97%;
  --magenta-10: 290 100% 95%;
  --magenta-15: 290 100% 94%;
  --magenta-20: 290 100% 92%;
  --magenta-30: 289 100% 88%;
  --magenta-40: 288 100% 79%;
  --magenta-50: 287 59% 64%;
  --magenta-60: 287 37% 52%;
  --magenta-65: 288 35% 42%;
  --magenta-70: 287 36% 36%;
  --magenta-75: 288 37% 30%;
  --magenta-80: 287 38% 24%;
  --magenta-85: 287 41% 18%;
  --magenta-90: 287 47% 12%;
  --magenta-100: 286 55% 8%;
  --hot-pink-00: 322 100% 98%;
  --hot-pink-05: 326 100% 97%;
  --hot-pink-10: 325 100% 95%;
  --hot-pink-15: 326 100% 94%;
  --hot-pink-20: 326 100% 92%;
  --hot-pink-30: 326 100% 88%;
  --hot-pink-40: 326 100% 79%;
  --hot-pink-50: 324 55% 62%;
  --hot-pink-60: 325 36% 51%;
  --hot-pink-65: 325 35% 41%;
  --hot-pink-70: 324 36% 35%;
  --hot-pink-75: 324 37% 29%;
  --hot-pink-80: 324 38% 23%;
  --hot-pink-85: 323 41% 17%;
  --hot-pink-90: 323 45% 11%;
  --hot-pink-100: 326 54% 8%;
  --pink-00: 345 100% 98%;
  --pink-05: 352 100% 97%;
  --pink-10: 350 100% 95%;
  --pink-15: 348 100% 94%;
  --pink-20: 348 100% 92%;
  --pink-30: 348 100% 88%;
  --pink-40: 347 100% 80%;
  --pink-50: 346 58% 64%;
  --pink-60: 345 37% 52%;
  --pink-65: 346 35% 42%;
  --pink-70: 345 36% 36%;
  --pink-75: 345 37% 30%;
  --pink-80: 346 38% 24%;
  --pink-85: 345 41% 18%;
  --pink-90: 345 47% 12%;
  --pink-100: 348 50% 8%;
  --forge-primary-00: 228 56% 98%;
  --forge-primary-05: 224 52% 96%;
  --forge-primary-10: 224 56% 95%;
  --forge-primary-15: 225 56% 93%;
  --forge-primary-20: 224 54% 91%;
  --forge-primary-30: 224 53% 86%;
  --forge-primary-40: 226 52% 77%;
  --forge-primary-50: 227 49% 64%;
  --forge-primary-60: 228 46% 56%;
  --forge-primary-65: 230 47% 49%;
  --forge-primary-70: 230 49% 42%;
  --forge-primary-75: 231 50% 35%;
  --forge-primary-80: 230 52% 28%;
  --forge-primary-85: 231 54% 21%;
  --forge-primary-90: 230 61% 14%;
  --forge-primary-100: 231 68% 10%;
  --forge-tertiary-00: oklch(97.84% 0.01 267.36);
  --forge-tertiary-05: oklch(96.77% 0.015 267.32);
  --forge-tertiary-10: oklch(94.56% 0.025 268.58);
  --forge-tertiary-15: oklch(93.43% 0.031 268.53);
  --forge-tertiary-20: oklch(91.24% 0.042 268.41);
  --forge-tertiary-30: oklch(86.67% 0.065 269.35);
  --forge-tertiary-40: oklch(77.21% 0.115 270.59);
  --forge-tertiary-50: oklch(65.33% 0.184 270.09);
  --forge-tertiary-60: oklch(57.86% 0.205 270.96);
  --forge-tertiary-65: oklch(49.59% 0.176 270.95);
  --forge-tertiary-70: oklch(43.74% 0.159 270.73);
  --forge-tertiary-75: oklch(38.97% 0.138 270.18);
  --forge-tertiary-80: oklch(33.59% 0.12 270.13);
  --forge-tertiary-85: oklch(28.01% 0.1 270.11);
  --forge-tertiary-90: oklch(22.12% 0.08 270.04);
  --forge-tertiary-100: oklch(17.53% 0.064 270.31);
  --forge-tertiary-50-a12: oklch(65.33% 0.184 270.09 / 12%);
  --forge-tertiary-50-a20: oklch(65.33% 0.184 270.09 / 20%);
  --forge-tertiary-60-a16: oklch(57.86% 0.205 270.96 / 16%);
  --dark-base-00: 0 0% 97%;
  --dark-base-05: 0 0% 95%;
  --dark-base-10: 0 0% 93%;
  --dark-base-15: 0 0% 91%;
  --dark-base-20: 180 2% 88%;
  --dark-base-30: 180 1% 83%;
  --dark-base-40: 180 1% 71%;
  --dark-base-50: 180 1% 55%;
  --dark-base-60: 180 0% 46%;
  --dark-base-65: 180 1% 37%;
  --dark-base-70: 180 1% 31%;
  --dark-base-75: 180 1% 26%;
  --dark-base-80: 180 2% 20%;
  --dark-base-85: 180 3% 15%;
  --dark-base-90: 180 2% 10%;
  --dark-base-100: 180 3% 6%;
}

:where(html) {
  --tomato-1: oklch(99.3% 0.003 17.2);
  --tomato-2: oklch(98.4% 0.008 27.2);
  --tomato-3: oklch(95.4% 0.021 31.8);
  --tomato-4: oklch(92.1% 0.041 34.8);
  --tomato-5: oklch(88.9% 0.059 32.6);
  --tomato-6: oklch(85.3% 0.077 32.6);
  --tomato-7: oklch(80.2% 0.095 32.2);
  --tomato-8: oklch(74.1% 0.118 32.3);
  --tomato-9: oklch(62.7% 0.194 33.3);
  --tomato-10: oklch(60.3% 0.195 33.2);
  --tomato-11: oklch(56.6% 0.198 32.7);
  --tomato-12: oklch(34.6% 0.08 30.4);
  --tomato-a1: oklch(62.8% 0.258 29.2 / 1.18%);
  --tomato-a2: oklch(63.6% 0.25 30.7 / 3.14%);
  --tomato-a3: oklch(62.4% 0.237 31.9 / 9.41%);
  --tomato-a4: oklch(64.8% 0.24 32.9 / 17.25%);
  --tomato-a5: oklch(64.3% 0.244 32 / 23.92%);
  --tomato-a6: oklch(63.2% 0.239 32.1 / 31.37%);
  --tomato-a7: oklch(59.6% 0.226 31.9 / 40.39%);
  --tomato-a8: oklch(57.3% 0.218 31.9 / 51.76%);
  --tomato-a9: oklch(58.1% 0.221 31.9 / 81.96%);
  --tomato-a10: oklch(56.5% 0.215 31.9 / 85.49%);
  --tomato-a11: oklch(54.5% 0.207 31.9 / 91.76%);
  --tomato-a12: oklch(25.7% 0.093 33.8 / 87.84%);
  --red-1: oklch(99.3% 0.003 17.2);
  --red-2: oklch(98.2% 0.009 17.3);
  --red-3: oklch(95.5% 0.021 13.9);
  --red-4: oklch(92.2% 0.04 16);
  --red-5: oklch(89.2% 0.057 16.9);
  --red-6: oklch(85.7% 0.074 17.7);
  --red-7: oklch(80.7% 0.089 18.3);
  --red-8: oklch(74.4% 0.113 18.8);
  --red-9: oklch(62.6% 0.193 23);
  --red-10: oklch(59.9% 0.195 24);
  --red-11: oklch(55.7% 0.197 25.2);
  --red-12: oklch(33.9% 0.109 16.6);
  --red-a1: oklch(62.8% 0.258 29.2 / 1.18%);
  --red-a2: oklch(62.8% 0.258 29.2 / 3.14%);
  --red-a3: oklch(60.6% 0.248 28.4 / 7.84%);
  --red-a4: oklch(62.8% 0.257 28.8 / 14.12%);
  --red-a5: oklch(62.8% 0.257 28.9 / 19.61%);
  --red-a6: oklch(61.5% 0.252 29 / 25.88%);
  --red-a7: oklch(56.8% 0.233 29 / 33.73%);
  --red-a8: oklch(54.3% 0.222 28.8 / 44.31%);
  --red-a9: oklch(56% 0.229 28.7 / 71.76%);
  --red-a10: oklch(54.1% 0.222 28.8 / 75.69%);
  --red-a11: oklch(51.5% 0.211 28.6 / 82.75%);
  --red-a12: oklch(28.4% 0.114 21.9 / 90.98%);
  --ruby-1: oklch(99.4% 0.003 354.7);
  --ruby-2: oklch(98.3% 0.009 8.5);
  --ruby-3: oklch(95.4% 0.022 7.2);
  --ruby-4: oklch(92.5% 0.039 8.1);
  --ruby-5: oklch(89.6% 0.056 7.3);
  --ruby-6: oklch(85.8% 0.066 8);
  --ruby-7: oklch(81.1% 0.08 7.2);
  --ruby-8: oklch(74.9% 0.102 6.5);
  --ruby-9: oklch(62.8% 0.195 13.2);
  --ruby-10: oklch(60.1% 0.197 13.5);
  --ruby-11: oklch(54.9% 0.199 13.9);
  --ruby-12: oklch(34.1% 0.11 10);
  --ruby-a1: oklch(63.5% 0.254 15.5 / 1.18%);
  --ruby-a2: oklch(62.9% 0.256 26.7 / 3.14%);
  --ruby-a3: oklch(60.7% 0.246 25.6 / 8.24%);
  --ruby-a4: oklch(62.9% 0.256 26 / 13.73%);
  --ruby-a5: oklch(63% 0.255 25.2 / 19.22%);
  --ruby-a6: oklch(57.9% 0.235 25.3 / 25.1%);
  --ruby-a7: oklch(53.7% 0.217 24.1 / 32.55%);
  --ruby-a8: oklch(51.6% 0.208 22.7 / 42.75%);
  --ruby-a9: oklch(56.2% 0.227 23.2 / 72.55%);
  --ruby-a10: oklch(54.5% 0.22 22.7 / 76.86%);
  --ruby-a11: oklch(51.3% 0.206 20.2 / 85.88%);
  --ruby-a12: oklch(28.6% 0.114 15.8 / 90.98%);
  --crimson-1: oklch(99.4% 0.003 354.7);
  --crimson-2: oklch(98.2% 0.008 357.8);
  --crimson-3: oklch(95.4% 0.026 356.3);
  --crimson-4: oklch(92.6% 0.04 356.4);
  --crimson-5: oklch(89.3% 0.053 355.8);
  --crimson-6: oklch(85.4% 0.065 355.2);
  --crimson-7: oklch(80.9% 0.078 354.9);
  --crimson-8: oklch(74.9% 0.1 354);
  --crimson-9: oklch(63.4% 0.213 1.3);
  --crimson-10: oklch(60.7% 0.211 2.2);
  --crimson-11: oklch(55.2% 0.207 4.5);
  --crimson-12: oklch(34.1% 0.113 356.9);
  --crimson-a1: oklch(63.5% 0.254 15.5 / 1.18%);
  --crimson-a2: oklch(57.4% 0.23 18.4 / 3.14%);
  --crimson-a3: oklch(63.5% 0.254 16.3 / 8.63%);
  --crimson-a4: oklch(62.2% 0.249 15.9 / 13.73%);
  --crimson-a5: oklch(58.6% 0.234 14.3 / 19.22%);
  --crimson-a6: oklch(54.6% 0.218 12.9 / 25.49%);
  --crimson-a7: oklch(51.3% 0.205 11.8 / 32.55%);
  --crimson-a8: oklch(49.7% 0.199 9.1 / 42.35%);
  --crimson-a9: oklch(58.3% 0.233 10 / 76.08%);
  --crimson-a10: oklch(56.2% 0.225 10.1 / 79.61%);
  --crimson-a11: oklch(52.5% 0.21 9.6 / 88.63%);
  --crimson-a12: oklch(28.6% 0.115 1.8 / 91.37%);
  --pink-1: oklch(99.4% 0.004 337.3);
  --pink-2: oklch(98.3% 0.009 341.8);
  --pink-3: oklch(95.4% 0.028 342.3);
  --pink-4: oklch(92.6% 0.042 340.6);
  --pink-5: oklch(89.3% 0.055 340.4);
  --pink-6: oklch(85.6% 0.067 340.7);
  --pink-7: oklch(81% 0.083 341.9);
  --pink-8: oklch(75.1% 0.107 341.5);
  --pink-9: oklch(61.7% 0.208 346);
  --pink-10: oklch(59.6% 0.207 346.7);
  --pink-11: oklch(55.8% 0.207 347.3);
  --pink-12: oklch(35% 0.129 345.4);
  --pink-a1: oklch(66% 0.276 349.7 / 1.18%);
  --pink-a2: oklch(59% 0.241 356.7 / 3.14%);
  --pink-a3: oklch(63% 0.257 356.7 / 8.63%);
  --pink-a4: oklch(59.8% 0.247 353.4 / 13.73%);
  --pink-a5: oklch(56.5% 0.234 352.4 / 19.22%);
  --pink-a6: oklch(53% 0.219 352.4 / 25.1%);
  --pink-a7: oklch(50.8% 0.21 353.4 / 32.55%);
  --pink-a8: oklch(49.6% 0.206 351.5 / 42.35%);
  --pink-a9: oklch(54.7% 0.227 351.7 / 74.9%);
  --pink-a10: oklch(53.4% 0.221 352.1 / 78.04%);
  --pink-a11: oklch(51% 0.212 351.3 / 83.92%);
  --pink-a12: oklch(30.7% 0.129 347.8 / 92.94%);
  --plum-1: oklch(99.3% 0.004 314.8);
  --plum-2: oklch(98.2% 0.01 325.7);
  --plum-3: oklch(95.7% 0.027 325.8);
  --plum-4: oklch(92.9% 0.044 324.8);
  --plum-5: oklch(89.9% 0.058 325.1);
  --plum-6: oklch(86.1% 0.071 323.9);
  --plum-7: oklch(80.9% 0.092 323.3);
  --plum-8: oklch(74.1% 0.12 322.2);
  --plum-9: oklch(57.9% 0.188 322.1);
  --plum-10: oklch(55.2% 0.181 322.2);
  --plum-11: oklch(52.2% 0.173 321.9);
  --plum-12: oklch(33.8% 0.125 321.4);
  --plum-a1: oklch(58.1% 0.299 307 / 1.18%);
  --plum-a2: oklch(56.7% 0.261 328.4 / 3.14%);
  --plum-a3: oklch(59.3% 0.273 328.4 / 7.84%);
  --plum-a4: oklch(57.6% 0.267 326.5 / 12.94%);
  --plum-a5: oklch(55.1% 0.255 326.6 / 18.04%);
  --plum-a6: oklch(51.2% 0.24 324.6 / 23.92%);
  --plum-a7: oklch(48.9% 0.231 323.4 / 32.16%);
  --plum-a8: oklch(47.3% 0.226 321.2 / 43.14%);
  --plum-a9: oklch(45.7% 0.218 320.9 / 70.98%);
  --plum-a10: oklch(43.2% 0.206 321.1 / 73.33%);
  --plum-a11: oklch(40.4% 0.193 320.5 / 75.69%);
  --plum-a12: oklch(26.9% 0.129 320.8 / 90.2%);
  --purple-1: oklch(99.3% 0.003 325.6);
  --purple-2: oklch(98.1% 0.01 311.2);
  --purple-3: oklch(95.9% 0.025 311.7);
  --purple-4: oklch(93.3% 0.039 312.7);
  --purple-5: oklch(90.1% 0.053 311.2);
  --purple-6: oklch(85.9% 0.072 311.1);
  --purple-7: oklch(80.4% 0.092 309.7);
  --purple-8: oklch(73.3% 0.123 308);
  --purple-9: oklch(55.6% 0.183 305.9);
  --purple-10: oklch(52.5% 0.175 305.4);
  --purple-11: oklch(51.7% 0.173 305.9);
  --purple-12: oklch(32.2% 0.11 303.8);
  --purple-a1: oklch(51.8% 0.238 328.4 / 1.18%);
  --purple-a2: oklch(50.03% 0.267 299.88 / 3.14%);
  --purple-a3: oklch(53.3% 0.283 301.2 / 7.06%);
  --purple-a4: oklch(52.1% 0.273 303.3 / 11.37%);
  --purple-a5: oklch(49.6% 0.263 301 / 16.47%);
  --purple-a6: oklch(47.8% 0.253 301.2 / 23.14%);
  --purple-a7: oklch(44.9% 0.241 299.2 / 31.37%);
  --purple-a8: oklch(43.7% 0.237 297 / 42.35%);
  --purple-a9: oklch(40.5% 0.22 297.3 / 69.41%);
  --purple-a10: oklch(37.9% 0.205 297.1 / 72.16%);
  --purple-a11: oklch(37.3% 0.202 297.7 / 72.94%);
  --purple-a12: oklch(22.2% 0.119 299 / 87.45%);
  --violet-1: oklch(99.2% 0.003 308.4);
  --violet-2: oklch(98.3% 0.009 299.2);
  --violet-3: oklch(96.2% 0.019 299.1);
  --violet-4: oklch(93.2% 0.037 297.5);
  --violet-5: oklch(90.4% 0.052 295);
  --violet-6: oklch(86.4% 0.072 293.7);
  --violet-7: oklch(80.6% 0.09 293.6);
  --violet-8: oklch(73% 0.119 292.6);
  --violet-9: oklch(54.2% 0.179 288);
  --violet-10: oklch(51.1% 0.177 287.7);
  --violet-11: oklch(50.8% 0.159 288.6);
  --violet-12: oklch(31.3% 0.097 286.6);
  --violet-a1: oklch(39.4% 0.216 294.8 / 1.18%);
  --violet-a2: oklch(48% 0.298 276.3 / 2.75%);
  --violet-a3: oklch(45.6% 0.283 276.5 / 5.88%);
  --violet-a4: oklch(47.6% 0.3 274.7 / 10.59%);
  --violet-a5: oklch(46.8% 0.303 271.4 / 14.9%);
  --violet-a6: oklch(46% 0.301 270.4 / 20.78%);
  --violet-a7: oklch(42% 0.272 271.2 / 29.02%);
  --violet-a8: oklch(40.2% 0.26 271.6 / 40%);
  --violet-a9: oklch(36.5% 0.236 271.4 / 66.27%);
  --violet-a10: oklch(34.8% 0.224 272.1 / 69.8%);
  --violet-a11: oklch(32.1% 0.206 272.4 / 68.63%);
  --violet-a12: oklch(18.3% 0.115 275.2 / 85.1%);
  --iris-1: oklch(99.5% 0.003 286.4);
  --iris-2: oklch(98.1% 0.009 286.2);
  --iris-3: oklch(96.1% 0.017 282.5);
  --iris-4: oklch(93.4% 0.032 283.9);
  --iris-5: oklch(90.4% 0.048 282.8);
  --iris-6: oklch(86.3% 0.069 283);
  --iris-7: oklch(80.9% 0.087 282.8);
  --iris-8: oklch(72.9% 0.118 281.4);
  --iris-9: oklch(54% 0.184 278.3);
  --iris-10: oklch(50.9% 0.187 277.4);
  --iris-11: oklch(51.1% 0.174 279.8);
  --iris-12: oklch(31.4% 0.099 277.6);
  --iris-a1: oklch(45.2% 0.313 264.1 / 0.78%);
  --iris-a2: oklch(45.2% 0.313 264.1 / 2.75%);
  --iris-a3: oklch(43.6% 0.293 264.1 / 5.88%);
  --iris-a4: oklch(45.6% 0.311 264.1 / 9.8%);
  --iris-a5: oklch(45.7% 0.31 264.1 / 14.51%);
  --iris-a6: oklch(45.5% 0.311 264.1 / 20.39%);
  --iris-a7: oklch(42.1% 0.288 264.1 / 27.84%);
  --iris-a8: oklch(40.3% 0.275 264.1 / 39.22%);
  --iris-a9: oklch(36.5% 0.253 264.1 / 64.31%);
  --iris-a10: oklch(35.1% 0.243 264.1 / 68.24%);
  --iris-a11: oklch(33.7% 0.231 265 / 67.45%);
  --iris-a12: oklch(18.2% 0.121 264.2 / 84.71%);
  --indigo-1: oklch(99.4% 0.001 286.4);
  --indigo-2: oklch(98.2% 0.008 271.3);
  --indigo-3: oklch(96.1% 0.017 267.8);
  --indigo-4: oklch(93.5% 0.031 269.8);
  --indigo-5: oklch(90.2% 0.047 269.6);
  --indigo-6: oklch(86.2% 0.068 271.1);
  --indigo-7: oklch(80.6% 0.088 271.4);
  --indigo-8: oklch(73.1% 0.112 270.4);
  --indigo-9: oklch(54.4% 0.191 267);
  --indigo-10: oklch(51.1% 0.195 266.6);
  --indigo-11: oklch(50.9% 0.172 267.2);
  --indigo-12: oklch(31.3% 0.086 268.6);
  --indigo-a1: oklch(27.1% 0.188 264.1 / 0.78%);
  --indigo-a2: oklch(50.1% 0.28 263.8 / 3.14%);
  --indigo-a3: oklch(49.6% 0.258 263.3 / 7.06%);
  --indigo-a4: oklch(50.7% 0.277 263.7 / 11.76%);
  --indigo-a5: oklch(50.7% 0.277 263.7 / 17.65%);
  --indigo-a6: oklch(49.8% 0.282 263.9 / 24.31%);
  --indigo-a7: oklch(46.9% 0.269 263.9 / 32.94%);
  --indigo-a8: oklch(44.6% 0.252 263.9 / 44.71%);
  --indigo-a9: oklch(43% 0.244 263.9 / 75.69%);
  --indigo-a10: oklch(41.6% 0.236 263.9 / 80%);
  --indigo-a11: oklch(39% 0.219 263.8 / 77.25%);
  --indigo-a12: oklch(20.8% 0.104 262.9 / 87.84%);
  --blue-1: oklch(99.3% 0.003 247.9);
  --blue-2: oklch(98.2% 0.009 242.8);
  --blue-3: oklch(96% 0.02 238.7);
  --blue-4: oklch(93.8% 0.035 234.8);
  --blue-5: oklch(90.5% 0.051 240.3);
  --blue-6: oklch(86.3% 0.068 243.3);
  --blue-7: oklch(81% 0.089 243.1);
  --blue-8: oklch(73.4% 0.121 243.1);
  --blue-9: oklch(64.9% 0.193 251.8);
  --blue-10: oklch(62.2% 0.183 251.7);
  --blue-11: oklch(55.6% 0.162 252.2);
  --blue-12: oklch(32.4% 0.096 258.8);
  --blue-a1: oklch(61.5% 0.211 256.1 / 1.57%);
  --blue-a2: oklch(64.1% 0.197 253 / 4.31%);
  --blue-a3: oklch(64.1% 0.182 250.2 / 9.8%);
  --blue-a4: oklch(68% 0.179 247 / 16.47%);
  --blue-a5: oklch(65.6% 0.19 250.8 / 23.92%);
  --blue-a6: oklch(62.6% 0.191 252.6 / 32.55%);
  --blue-a7: oklch(60.8% 0.183 252.2 / 44.31%);
  --blue-a8: oklch(60.7% 0.176 250.8 / 63.14%);
  --blue-a9: oklch(64.9% 0.193 251.8);
  --blue-a10: oklch(61.8% 0.186 252.2 / 98.04%);
  --blue-a11: oklch(53.7% 0.168 253.4 / 94.9%);
  --blue-a12: oklch(27.2% 0.105 259 / 93.33%);
  --cyan-1: oklch(99.2% 0.003 219.5);
  --cyan-2: oklch(97.9% 0.009 205.9);
  --cyan-3: oklch(95.9% 0.026 202.6);
  --cyan-4: oklch(93.2% 0.041 206);
  --cyan-5: oklch(90% 0.054 206.4);
  --cyan-6: oklch(85.8% 0.066 208.1);
  --cyan-7: oklch(80.4% 0.082 209.7);
  --cyan-8: oklch(72.8% 0.11 211.9);
  --cyan-9: oklch(66% 0.122 221.7);
  --cyan-10: oklch(62.7% 0.114 221.5);
  --cyan-11: oklch(54.7% 0.097 220.8);
  --cyan-12: oklch(33.1% 0.053 218.8);
  --cyan-a1: oklch(64.1% 0.129 231.1 / 1.96%);
  --cyan-a2: oklch(63.8% 0.111 210.6 / 5.1%);
  --cyan-a3: oklch(74.3% 0.127 204.6 / 12.94%);
  --cyan-a4: oklch(72.9% 0.126 210.8 / 20.78%);
  --cyan-a5: oklch(70.6% 0.123 211.5 / 29.02%);
  --cyan-a6: oklch(67% 0.118 214 / 38.43%);
  --cyan-a7: oklch(64.7% 0.115 216.2 / 50.98%);
  --cyan-a8: oklch(65.9% 0.117 216.4 / 76.08%);
  --cyan-a9: oklch(66% 0.122 221.7);
  --cyan-a10: oklch(61.8% 0.115 222.4 / 97.25%);
  --cyan-a11: oklch(51.9% 0.097 223.1 / 93.73%);
  --cyan-a12: oklch(29.3% 0.053 219.2 / 94.9%);
  --teal-1: oklch(99.4% 0.004 179.7);
  --teal-2: oklch(98.2% 0.009 179.6);
  --teal-3: oklch(96% 0.026 181.9);
  --teal-4: oklch(93.4% 0.042 179.8);
  --teal-5: oklch(90% 0.053 181.5);
  --teal-6: oklch(85.6% 0.064 181.2);
  --teal-7: oklch(79.6% 0.076 183);
  --teal-8: oklch(72.1% 0.097 183.3);
  --teal-9: oklch(64.9% 0.114 182);
  --teal-10: oklch(61.9% 0.109 181.3);
  --teal-11: oklch(55.2% 0.101 178.8);
  --teal-12: oklch(32.7% 0.051 185);
  --teal-a1: oklch(75% 0.154 166.9 / 1.96%);
  --teal-a2: oklch(65.5% 0.134 167.4 / 4.71%);
  --teal-a3: oklch(73.6% 0.144 170.9 / 12.16%);
  --teal-a4: oklch(72.6% 0.146 168.7 / 20%);
  --teal-a5: oklch(68.6% 0.133 171.7 / 27.84%);
  --teal-a6: oklch(64.6% 0.125 171.9 / 36.86%);
  --teal-a7: oklch(61% 0.114 175.6 / 48.63%);
  --teal-a8: oklch(60.6% 0.111 178.9 / 67.45%);
  --teal-a9: oklch(62.7% 0.113 181 / 92.94%);
  --teal-a10: oklch(60.3% 0.109 180.3 / 94.9%);
  --teal-a11: oklch(55.2% 0.101 178.8);
  --teal-a12: oklch(28.8% 0.051 182.5 / 94.9%);
  --jade-1: oklch(99.4% 0.003 174.5);
  --jade-2: oklch(98.2% 0.009 161.4);
  --jade-3: oklch(96.1% 0.022 160.2);
  --jade-4: oklch(93.5% 0.034 163.2);
  --jade-5: oklch(90.2% 0.047 165.1);
  --jade-6: oklch(86% 0.06 166.2);
  --jade-7: oklch(80% 0.076 169.9);
  --jade-8: oklch(72.1% 0.103 173.1);
  --jade-9: oklch(64.2% 0.115 170.7);
  --jade-10: oklch(61.3% 0.11 170.9);
  --jade-11: oklch(54.7% 0.098 170);
  --jade-12: oklch(32.5% 0.041 170.1);
  --jade-a1: oklch(71.3% 0.16 160.5 / 1.57%);
  --jade-a2: oklch(62.5% 0.176 149.1 / 4.31%);
  --jade-a3: oklch(65.5% 0.187 148.5 / 9.80%);
  --jade-a4: oklch(64% 0.172 150.9 / 16.08%);
  --jade-a5: oklch(62.4% 0.161 152.8 / 23.53%);
  --jade-a6: oklch(60.3% 0.15 154.8 / 32.55%);
  --jade-a7: oklch(58.8% 0.134 159.3 / 45.49%);
  --jade-a8: oklch(60% 0.125 165.6 / 66.27%);
  --jade-a9: oklch(58.2% 0.12 166.4 / 83.92%);
  --jade-a10: oklch(55.3% 0.114 166.8 / 85.10%);
  --jade-a11: oklch(48.6% 0.101 166 / 87.45%);
  --jade-a12: oklch(22.4% 0.046 167.4 / 88.63%);
  --green-1: oklch(99.4% 0.004 157.2);
  --green-2: oklch(98.1% 0.01 155.1);
  --green-3: oklch(95.8% 0.022 155.9);
  --green-4: oklch(93.4% 0.037 156.4);
  --green-5: oklch(89.9% 0.049 157.2);
  --green-6: oklch(85.6% 0.064 158.2);
  --green-7: oklch(79.8% 0.083 159.1);
  --green-8: oklch(71.6% 0.113 160.3);
  --green-9: oklch(64.1% 0.133 157.7);
  --green-10: oklch(61.1% 0.127 158.2);
  --green-11: oklch(54.3% 0.112 159.5);
  --green-12: oklch(32.2% 0.048 164.5);
  --green-a1: oklch(70.3% 0.214 146.2 / 1.57%);
  --green-a2: oklch(62.2% 0.193 145.4 / 4.31%);
  --green-a3: oklch(62.5% 0.192 145.9 / 9.80%);
  --green-a4: oklch(63.7% 0.193 146.3 / 16.08%);
  --green-a5: oklch(60.4% 0.178 147.2 / 23.14%);
  --green-a6: oklch(58.7% 0.169 148.3 / 32.16%);
  --green-a7: oklch(57.4% 0.159 149.7 / 44.31%);
  --green-a8: oklch(57.8% 0.151 152.5 / 64.31%);
  --green-a9: oklch(57.0% 0.148 152.7 / 81.18%);
  --green-a10: oklch(54.4% 0.139 153.4 / 83.14%);
  --green-a11: oklch(48.2% 0.119 155.1 / 87.06%);
  --green-a12: oklch(23.7% 0.053 160.8 / 90.20%);
  --grass-1: oklch(99.4% 0.005 145.5);
  --grass-2: oklch(98.2% 0.01 145.5);
  --grass-3: oklch(96.0% 0.022 145.4);
  --grass-4: oklch(93.5% 0.038 146.5);
  --grass-5: oklch(90.0% 0.052 146);
  --grass-6: oklch(85.6% 0.071 146.8);
  --grass-7: oklch(79.8% 0.094 147.3);
  --grass-8: oklch(71.7% 0.131 148.1);
  --grass-9: oklch(65.1% 0.147 147.4);
  --grass-10: oklch(61.5% 0.142 147.2);
  --grass-11: oklch(52.6% 0.129 147.2);
  --grass-12: oklch(32.7% 0.053 148.6);
  --grass-a1: oklch(70.0% 0.238 142.5 / 1.57%);
  --grass-a2: oklch(59.2% 0.201 142.5 / 3.92%);
  --grass-a3: oklch(58.6% 0.199 142.5 / 8.63%);
  --grass-a4: oklch(60.9% 0.206 142.7 / 14.51%);
  --grass-a5: oklch(57.5% 0.194 142.7 / 21.18%);
  --grass-a6: oklch(56.4% 0.189 142.9 / 30.20%);
  --grass-a7: oklch(55.2% 0.184 143.1 / 41.96%);
  --grass-a8: oklch(55.8% 0.182 143.8 / 60.39%);
  --grass-a9: oklch(53.8% 0.174 144 / 72.55%);
  --grass-a10: oklch(50.6% 0.164 144.1 / 75.69%);
  --grass-a11: oklch(44.0% 0.141 144.5 / 83.53%);
  --grass-a12: oklch(21.2% 0.062 147.4 / 87.45%);
  --orange-1: oklch(99.2% 0.003 48.7);
  --orange-2: oklch(98.0% 0.016 73.7);
  --orange-3: oklch(95.8% 0.037 79.1);
  --orange-4: oklch(92.0% 0.065 74.4);
  --orange-5: oklch(88.8% 0.088 71.3);
  --orange-6: oklch(85.4% 0.107 66);
  --orange-7: oklch(80.6% 0.112 60);
  --orange-8: oklch(74.5% 0.132 54.7);
  --orange-9: oklch(69.1% 0.191 45);
  --orange-10: oklch(66.2% 0.195 43.5);
  --orange-11: oklch(58.5% 0.174 42.7);
  --orange-12: oklch(35.0% 0.069 40.8);
  --orange-a1: oklch(54.9% 0.174 39.5 / 1.57%);
  --orange-a2: oklch(75.4% 0.178 59.2 / 7.06%);
  --orange-a3: oklch(77.7% 0.173 66.1 / 16.08%);
  --orange-a4: oklch(75.9% 0.177 60.6 / 29.02%);
  --orange-a5: oklch(74.9% 0.18 57.8 / 39.61%);
  --orange-a6: oklch(73.3% 0.185 53.4 / 49.02%);
  --orange-a7: oklch(67.7% 0.183 48.5 / 54.90%);
  --orange-a8: oklch(64.4% 0.183 45.3 / 66.67%);
  --orange-a9: oklch(67.2% 0.202 42.2 / 91.76%);
  --orange-a10: oklch(66.2% 0.195 43.5);
  --orange-a11: oklch(58.5% 0.174 42.7);
  --orange-a12: oklch(26.2% 0.081 40.7 / 88.63%);
  --brown-1: oklch(99.5% 0.002 67.8);
  --brown-2: oklch(98.4% 0.005 67.8);
  --brown-3: oklch(95.3% 0.013 63.9);
  --brown-4: oklch(92.5% 0.02 65.1);
  --brown-5: oklch(89.8% 0.029 65.7);
  --brown-6: oklch(86.2% 0.039 66);
  --brown-7: oklch(81.5% 0.054 64.2);
  --brown-8: oklch(74.6% 0.072 62.1);
  --brown-9: oklch(63.3% 0.078 61);
  --brown-10: oklch(59.7% 0.072 59.1);
  --brown-11: oklch(51.2% 0.058 55.5);
  --brown-12: oklch(33.1% 0.018 45.9);
  --brown-a1: oklch(54.3% 0.136 54.1 / 1.18%);
  --brown-a2: oklch(54.3% 0.136 54.1 / 3.53%);
  --brown-a3: oklch(51.2% 0.133 51.2 / 9.41%);
  --brown-a4: oklch(50.3% 0.129 52.2 / 14.90%);
  --brown-a5: oklch(51.4% 0.131 52.8 / 20.78%);
  --brown-a6: oklch(51.7% 0.131 53.1 / 28.24%);
  --brown-a7: oklch(52.1% 0.134 52.1 / 37.65%);
  --brown-a8: oklch(50.9% 0.133 50.9 / 50.59%);
  --brown-a9: oklch(44.1% 0.114 51.4 / 65.49%);
  --brown-a10: oklch(40.0% 0.104 50.8 / 67.45%);
  --brown-a11: oklch(31.4% 0.084 48.9 / 72.55%);
  --brown-a12: oklch(14.3% 0.034 58.1 / 81.96%);
  --sky-1: oklch(99.4% 0.005 211);
  --sky-2: oklch(97.9% 0.01 219.6);
  --sky-3: oklch(96.0% 0.024 219.7);
  --sky-4: oklch(93.6% 0.035 219.2);
  --sky-5: oklch(90.3% 0.047 220.6);
  --sky-6: oklch(86.0% 0.057 223.6);
  --sky-7: oklch(80.6% 0.072 225.5);
  --sky-8: oklch(72.8% 0.096 228.4);
  --sky-9: oklch(86.1% 0.103 217.8);
  --sky-10: oklch(83.8% 0.104 219.4);
  --sky-11: oklch(52.5% 0.108 232.5);
  --sky-12: oklch(35.1% 0.057 242.4);
  --sky-a1: oklch(80.7% 0.146 218.9 / 2.35%);
  --sky-a2: oklch(67.4% 0.137 231.4 / 5.49%);
  --sky-a3: oklch(71.9% 0.145 231.1 / 11.76%);
  --sky-a4: oklch(69.8% 0.14 230.7 / 18.04%);
  --sky-a5: oklch(66.6% 0.136 231.8 / 25.49%);
  --sky-a6: oklch(62.3% 0.132 234.7 / 33.73%);
  --sky-a7: oklch(59.6% 0.129 236.2 / 44.71%);
  --sky-a8: oklch(58.5% 0.13 237.9 / 62.35%);
  --sky-a9: oklch(77.3% 0.149 226.7 / 51.37%);
  --sky-a10: oklch(74.3% 0.145 228.1 / 54.51%);
  --sky-a11: oklch(52.5% 0.108 232.5);
  --sky-a12: oklch(25.6% 0.065 245.5 / 88.63%);
  --mint-1: oklch(99.3% 0.005 183);
  --mint-2: oklch(98.1% 0.01 181.4);
  --mint-3: oklch(96.0% 0.031 178.9);
  --mint-4: oklch(93.3% 0.048 178.3);
  --mint-5: oklch(90.0% 0.061 178.1);
  --mint-6: oklch(85.7% 0.072 178.2);
  --mint-7: oklch(79.7% 0.084 178.2);
  --mint-8: oklch(72.2% 0.106 177.8);
  --mint-9: oklch(87.0% 0.1 178);
  --mint-10: oklch(84.0% 0.099 178.4);
  --mint-11: oklch(51.2% 0.096 175.6);
  --mint-12: oklch(35.0% 0.051 181.3);
  --mint-a1: oklch(77.7% 0.152 171.3 / 2.35%);
  --mint-a2: oklch(67.6% 0.134 169.8 / 5.10%);
  --mint-a3: oklch(76.7% 0.157 167.1 / 13.33%);
  --mint-a4: oklch(75.0% 0.154 166.9 / 21.57%);
  --mint-a5: oklch(71.7% 0.146 167.5 / 29.80%);
  --mint-a6: oklch(67.3% 0.136 168.1 / 38.82%);
  --mint-a7: oklch(63.0% 0.125 169.6 / 50.59%);
  --mint-a8: oklch(62.3% 0.12 172.3 / 70.20%);
  --mint-a9: oklch(77.1% 0.153 169.8 / 47.45%);
  --mint-a10: oklch(72.7% 0.144 170.1 / 50.98%);
  --mint-a11: oklch(50.8% 0.095 175.5 / 99.22%);
  --mint-a12: oklch(28.1% 0.051 180.2 / 91.37%);
  --lime-1: oklch(99.2% 0.004 121.6);
  --lime-2: oklch(98.2% 0.009 119.6);
  --lime-3: oklch(95.9% 0.043 118.6);
  --lime-4: oklch(93.2% 0.068 120.2);
  --lime-5: oklch(89.7% 0.088 122.1);
  --lime-6: oklch(85.3% 0.099 123.3);
  --lime-7: oklch(79.5% 0.112 125.4);
  --lime-8: oklch(72.5% 0.135 128.2);
  --lime-9: oklch(88.7% 0.175 126.1);
  --lime-10: oklch(85.9% 0.188 126.7);
  --lime-11: oklch(54.4% 0.111 128.6);
  --lime-12: oklch(35.4% 0.057 121);
  --lime-a1: oklch(62.3% 0.166 129.4 / 1.96%);
  --lime-a2: oklch(61.6% 0.159 127 / 4.71%);
  --lime-a3: oklch(76.9% 0.195 125.6 / 16.08%);
  --lime-a4: oklch(76.0% 0.196 127 / 25.88%);
  --lime-a5: oklch(72.4% 0.191 128.5 / 34.90%);
  --lime-a6: oklch(67.3% 0.179 129.3 / 43.14%);
  --lime-a7: oklch(62.0% 0.169 130.7 / 52.94%);
  --lime-a8: oklch(59.5% 0.167 132.7 / 67.06%);
  --lime-a9: oklch(83.4% 0.227 130.7 / 61.18%);
  --lime-a10: oklch(81.3% 0.22 130.5 / 70.20%);
  --lime-a11: oklch(43.9% 0.122 132.1 / 81.57%);
  --lime-a12: oklch(26.1% 0.065 123.7 / 89.02%);
  --yellow-1: oklch(99.3% 0.005 106.5);
  --yellow-2: oklch(98.8% 0.025 101.9);
  --yellow-3: oklch(97.4% 0.084 104.2);
  --yellow-4: oklch(95.3% 0.117 102.1);
  --yellow-5: oklch(92.5% 0.141 98.1);
  --yellow-6: oklch(88.1% 0.134 95.3);
  --yellow-7: oklch(83.5% 0.12 92.8);
  --yellow-8: oklch(76.6% 0.137 89.7);
  --yellow-9: oklch(91.8% 0.184 100.9);
  --yellow-10: oklch(89.7% 0.185 97.4);
  --yellow-11: oklch(56.9% 0.119 76.8);
  --yellow-12: oklch(35.8% 0.046 86.9);
  --yellow-a1: oklch(71.4% 0.156 109.8 / 2.35%);
  --yellow-a2: oklch(88.9% 0.186 101.8 / 8.63%);
  --yellow-a3: oklch(93.3% 0.197 104.3 / 27.84%);
  --yellow-a4: oklch(91.1% 0.189 100.2 / 41.96%);
  --yellow-a5: oklch(88.3% 0.181 94.5 / 56.08%);
  --yellow-a6: oklch(81.4% 0.166 90.5 / 59.22%);
  --yellow-a7: oklch(73.4% 0.15 86.6 / 59.61%);
  --yellow-a8: oklch(70.5% 0.144 84.8 / 77.65%);
  --yellow-a9: oklch(90.7% 0.188 99.5 / 83.92%);
  --yellow-a10: oklch(89.7% 0.185 97.4);
  --yellow-a11: oklch(56.9% 0.119 76.8);
  --yellow-a12: oklch(25.4% 0.052 84.7 / 87.84%);
  --amber-1: oklch(99.4% 0.003 84.6);
  --amber-2: oklch(98.6% 0.024 99.1);
  --amber-3: oklch(97.0% 0.068 100.4);
  --amber-4: oklch(94.5% 0.103 97.9);
  --amber-5: oklch(91.8% 0.133 98.2);
  --amber-6: oklch(88.0% 0.123 93.4);
  --amber-7: oklch(82.7% 0.122 86.7);
  --amber-8: oklch(75.8% 0.14 76.7);
  --amber-9: oklch(85.4% 0.157 84.1);
  --amber-10: oklch(83.1% 0.168 80.8);
  --amber-11: oklch(57.1% 0.129 63.9);
  --amber-12: oklch(35.2% 0.049 54.2);
  --amber-a1: oklch(65.0% 0.138 74 / 1.57%);
  --amber-a2: oklch(86.4% 0.178 96.8 / 8.63%);
  --amber-a3: oklch(90.1% 0.186 98.3 / 23.92%);
  --amber-a4: oklch(88.1% 0.181 94 / 38.82%);
  --amber-a5: oklch(86.4% 0.177 94.4 / 53.33%);
  --amber-a6: oklch(79.9% 0.163 87.3 / 54.90%);
  --amber-a7: oklch(73.4% 0.153 78.6 / 61.57%);
  --amber-a8: oklch(70.0% 0.152 69.2 / 78.82%);
  --amber-a9: oklch(81.8% 0.171 77.9 / 76.08%);
  --amber-a10: oklch(81.8% 0.171 77.9 / 90.59%);
  --amber-a11: oklch(57.1% 0.129 63.9);
  --amber-a12: oklch(23.8% 0.06 53.3 / 86.67%);
  --gold-1: oklch(99.4% 0.001 106.4);
  --gold-2: oklch(98.1% 0.009 100);
  --gold-3: oklch(95.4% 0.012 96.4);
  --gold-4: oklch(92.5% 0.015 90.2);
  --gold-5: oklch(89.5% 0.018 89.4);
  --gold-6: oklch(85.9% 0.025 85.8);
  --gold-7: oklch(81.1% 0.033 84.6);
  --gold-8: oklch(73.9% 0.042 79.4);
  --gold-9: oklch(62% 0.049 77.7);
  --gold-10: oklch(58.9% 0.046 78.6);
  --gold-11: oklch(50.4% 0.039 78.3);
  --gold-12: oklch(33.2% 0.019 80.5);
  --gold-a1: oklch(43.5% 0.095 109.8 / 1.18%);
  --gold-a2: oklch(63.1% 0.131 99.5 / 5.10%);
  --gold-a3: oklch(49.5% 0.102 94 / 9.41%);
  --gold-a4: oklch(44.3% 0.091 83.8 / 14.12%);
  --gold-a5: oklch(41.1% 0.084 84.3 / 18.82%);
  --gold-a6: oklch(41.2% 0.086 78.2 / 25.10%);
  --gold-a7: oklch(40.6% 0.085 76.6 / 33.33%);
  --gold-a8: oklch(39.6% 0.085 70.4 / 44.71%);
  --gold-a9: oklch(34.9% 0.076 69.9 / 60.39%);
  --gold-a10: oklch(32.4% 0.069 72.5 / 63.14%);
  --gold-a11: oklch(26.8% 0.057 74.3 / 70.59%);
  --gold-a12: oklch(15.9% 0.033 87.5 / 83.14%);
  --bronze-1: oklch(99.2% 0.001 17.2);
  --bronze-2: oklch(98% 0.007 39.5);
  --bronze-3: oklch(95.2% 0.011 39.4);
  --bronze-4: oklch(92.6% 0.014 46.2);
  --bronze-5: oklch(89.5% 0.018 44.8);
  --bronze-6: oklch(86.1% 0.023 45.6);
  --bronze-7: oklch(81.2% 0.029 42.6);
  --bronze-8: oklch(74.2% 0.039 41.2);
  --bronze-9: oklch(62.7% 0.046 44.2);
  --bronze-10: oklch(58.8% 0.046 40.9);
  --bronze-11: oklch(51.1% 0.044 38.6);
  --bronze-12: oklch(32.9% 0.029 35.2);
  --bronze-a1: oklch(28.2% 0.116 29.2 / 01.18%);
  --bronze-a2: oklch(55.6% 0.195 34.9 / 03.92%);
  --bronze-a3: oklch(43.7% 0.15 35.9 / 08.24%);
  --bronze-a4: oklch(40.8% 0.129 39.6 / 12.55%);
  --bronze-a5: oklch(37.9% 0.121 39.4 / 17.25%);
  --bronze-a6: oklch(37.9% 0.119 40.1 / 22.75%);
  --bronze-a7: oklch(35.9% 0.115 38.9 / 29.80%);
  --bronze-a8: oklch(34.6% 0.113 38.2 / 40.00%);
  --bronze-a9: oklch(30.9% 0.094 41.3 / 55.29%);
  --bronze-a10: oklch(28.4% 0.089 40.2 / 59.22%);
  --bronze-a11: oklch(24.5% 0.077 40.1 / 67.06%);
  --bronze-a12: oklch(16.2% 0.046 45.2 / 83.14%);
  --gray-1: oklch(99.11% 0 0);
  --gray-2: oklch(98.21% 0 0);
  --gray-3: oklch(95.51% 0 0);
  --gray-4: oklch(93.1% 0 0);
  --gray-5: oklch(90.67% 0 0);
  --gray-6: oklch(88.53% 0 0);
  --gray-7: oklch(85.14% 0 0);
  --gray-8: oklch(79.21% 0 0);
  --gray-9: oklch(64.34% 0 0);
  --gray-10: oklch(61% 0 0);
  --gray-11: oklch(50.32% 0 0);
  --gray-12: oklch(24.35% 0 0);
  --gray-a1: oklch(0% 0 0 / 1.18%);
  --gray-a2: oklch(0% 0 0 / 2.35%);
  --gray-a3: oklch(0% 0 0 / 5.88%);
  --gray-a4: oklch(0% 0 0 / 9.02%);
  --gray-a5: oklch(0% 0 0 / 12.16%);
  --gray-a6: oklch(0% 0 0 / 14.9%);
  --gray-a7: oklch(0% 0 0 / 19.22%);
  --gray-a8: oklch(0% 0 0 / 26.67%);
  --gray-a9: oklch(0% 0 0 / 44.71%);
  --gray-a10: oklch(0% 0 0 / 48.63%);
  --gray-a11: oklch(0% 0 0 / 60.78%);
  --gray-a12: oklch(0% 0 0 / 87.45%);
  --mauve-1: oklch(99.2% 0.002 325.6);
  --mauve-2: oklch(98.3% 0.003 308.4);
  --mauve-3: oklch(95.6% 0.006 317.8);
  --mauve-4: oklch(93.2% 0.007 312.3);
  --mauve-5: oklch(90.9% 0.01 311.2);
  --mauve-6: oklch(88.7% 0.011 303.1);
  --mauve-7: oklch(85.4% 0.014 299.8);
  --mauve-8: oklch(79.5% 0.018 293);
  --mauve-9: oklch(64.6% 0.019 292.9);
  --mauve-10: oklch(61.2% 0.018 293.5);
  --mauve-11: oklch(50.5% 0.016 295);
  --mauve-12: oklch(24.5% 0.013 298.5);
  --mauve-a1: oklch(31.5% 0.145 328.4 / 1.18%);
  --mauve-a2: oklch(24.5% 0.132 298.1 / 2.35%);
  --mauve-a3: oklch(23.1% 0.114 315.3 / 6.27%);
  --mauve-a4: oklch(19.3% 0.099 306.4 / 9.41%);
  --mauve-a5: oklch(19.5% 0.101 304.9 / 12.55%);
  --mauve-a6: oklch(17.3% 0.096 292.1 / 15.29%);
  --mauve-a7: oklch(16.4% 0.094 288.1 / 19.61%);
  --mauve-a8: oklch(15.2% 0.093 278.2 / 27.06%);
  --mauve-a9: oklch(11.6% 0.068 284.2 / 45.10%);
  --mauve-a10: oklch(10.9% 0.063 288 / 49.02%);
  --mauve-a11: oklch(9.4% 0.052 294 / 61.18%);
  --mauve-a12: oklch(7.3% 0.039 296.8 / 87.84%);
  --slate-1: oklch(99.1% 0.001 286.4);
  --slate-2: oklch(98.3% 0.003 286.4);
  --slate-3: oklch(95.6% 0.004 286.3);
  --slate-4: oklch(93.2% 0.005 286.3);
  --slate-5: oklch(91.0% 0.007 277.2);
  --slate-6: oklch(88.7% 0.01 286.2);
  --slate-7: oklch(85.3% 0.011 280.4);
  --slate-8: oklch(79.4% 0.016 277.8);
  --slate-9: oklch(64.5% 0.016 277.7);
  --slate-10: oklch(61.1% 0.015 272.6);
  --slate-11: oklch(50.2% 0.014 264.4);
  --slate-12: oklch(24.1% 0.01 248.2);
  --slate-a1: oklch(20.3% 0.141 264.1 / 1.18%);
  --slate-a2: oklch(20.3% 0.141 264.1 / 2.35%);
  --slate-a3: oklch(14.5% 0.101 264.1 / 5.88%);
  --slate-a4: oklch(13.4% 0.093 264.1 / 9.02%);
  --slate-a5: oklch(16.8% 0.083 262.8 / 12.16%);
  --slate-a6: oklch(13.8% 0.096 264.1 / 14.90%);
  --slate-a7: oklch(15.5% 0.082 263.4 / 19.61%);
  --slate-a8: oklch(16.3% 0.082 262.9 / 27.45%);
  --slate-a9: oklch(12.9% 0.056 261.3 / 45.49%);
  --slate-a10: oklch(13.4% 0.048 257.5 / 49.80%);
  --slate-a11: oklch(12.7% 0.036 249.9 / 62.35%);
  --slate-a12: oklch(10.7% 0.021 228.6 / 89.02%);
  --sage-1: oklch(99.2% 0.002 165.1);
  --sage-2: oklch(98.0% 0.002 165.1);
  --sage-3: oklch(95.6% 0.003 174.5);
  --sage-4: oklch(93.1% 0.003 174.5);
  --sage-5: oklch(91.0% 0.004 157.2);
  --sage-6: oklch(88.6% 0.004 174.5);
  --sage-7: oklch(85.1% 0.005 165);
  --sage-8: oklch(79.1% 0.005 165);
  --sage-9: oklch(63.9% 0.01 171.6);
  --sage-10: oklch(60.6% 0.01 171.6);
  --sage-11: oklch(50.1% 0.008 174.1);
  --sage-12: oklch(24.0% 0.012 167.6);
  --sage-a1: oklch(52.5% 0.138 152.2 / 1.57%);
  --sage-a2: oklch(32.6% 0.082 154.2 / 3.14%);
  --sage-a3: oklch(26.3% 0.055 165.1 / 6.67%);
  --sage-a4: oklch(21.3% 0.043 168 / 9.80%);
  --sage-a5: oklch(18.3% 0.047 153.5 / 12.55%);
  --sage-a6: oklch(17.0% 0.034 169.6 / 15.69%);
  --sage-a7: oklch(16.9% 0.037 161.8 / 20.39%);
  --sage-a8: oklch(14.9% 0.031 165.8 / 27.84%);
  --sage-a9: oklch(15.8% 0.031 170.9 / 47.45%);
  --sage-a10: oklch(15.4% 0.03 170.2 / 51.37%);
  --sage-a11: oklch(12.9% 0.024 176.9 / 62.75%);
  --sage-a12: oklch(12.0% 0.023 172.5 / 89.80%);
  --olive-1: oklch(99.3% 0.002 145.6);
  --olive-2: oklch(98.3% 0.003 145.5);
  --olive-3: oklch(95.6% 0.003 145.5);
  --olive-4: oklch(93.2% 0.003 145.5);
  --olive-5: oklch(91.0% 0.005 145.5);
  --olive-6: oklch(88.5% 0.005 145.5);
  --olive-7: oklch(85.1% 0.005 145.5);
  --olive-8: oklch(79.2% 0.006 137.8);
  --olive-9: oklch(64.0% 0.012 136.6);
  --olive-10: oklch(60.7% 0.012 136.6);
  --olive-11: oklch(50.0% 0.011 140.5);
  --olive-12: oklch(24.2% 0.011 139.4);
  --olive-a1: oklch(38.9% 0.133 142.5 / 1.18%);
  --olive-a2: oklch(35.1% 0.12 142.5 / 2.75%);
  --olive-a3: oklch(21.1% 0.072 142.5 / 6.27%);
  --olive-a4: oklch(17.3% 0.059 142.5 / 9.41%);
  --olive-a5: oklch(18.1% 0.062 142.5 / 12.55%);
  --olive-a6: oklch(16.6% 0.056 142.5 / 15.69%);
  --olive-a7: oklch(14.6% 0.05 142.5 / 20.00%);
  --olive-a8: oklch(15.1% 0.044 134.7 / 27.84%);
  --olive-a9: oklch(15.2% 0.043 132.6 / 47.06%);
  --olive-a10: oklch(14.7% 0.042 134 / 50.98%);
  --olive-a11: oklch(12.9% 0.039 136.4 / 62.75%);
  --olive-a12: oklch(10.8% 0.033 137.4 / 89.02%);
  --sand-1: oklch(99.4% 0.001 106.4);
  --sand-2: oklch(98.2% 0.001 106.4);
  --sand-3: oklch(95.6% 0.002 67.8);
  --sand-4: oklch(93.1% 0.003 84.6);
  --sand-5: oklch(91.0% 0.004 91.4);
  --sand-6: oklch(88.5% 0.004 91.5);
  --sand-7: oklch(85.1% 0.006 95.1);
  --sand-8: oklch(79.1% 0.008 98.9);
  --sand-9: oklch(64.1% 0.01 106.7);
  --sand-10: oklch(60.5% 0.009 106.7);
  --sand-11: oklch(49.8% 0.008 106.7);
  --sand-12: oklch(24.3% 0.008 95.4);
  --sand-a1: oklch(43.5% 0.095 109.8 / 1.18%);
  --sand-a2: oklch(25.6% 0.056 109.8 / 2.75%);
  --sand-a3: oklch(19.2% 0.042 68.9 / 6.27%);
  --sand-a4: oklch(20.3% 0.042 86.2 / 9.80%);
  --sand-a5: oklch(21.1% 0.043 94.5 / 12.94%);
  --sand-a6: oklch(18.9% 0.039 94.7 / 16.08%);
  --sand-a7: oklch(19.2% 0.04 97.7 / 20.78%);
  --sand-a8: oklch(19.5% 0.038 99.7 / 29.02%);
  --sand-a9: oklch(16.3% 0.036 109.8 / 47.45%);
  --sand-a10: oklch(14.9% 0.033 109.8 / 51.37%);
  --sand-a11: oklch(13.0% 0.028 109.8 / 63.14%);
  --sand-a12: oklch(11.3% 0.024 103.4 / 89.02%);
  --gray-surface: oklch(100% 0 0 / 80%);
  --mauve-surface: oklch(100% 0 0 / 80%);
  --slate-surface: oklch(100% 0 0 / 80%);
  --sage-surface: oklch(100% 0 0 / 80%);
  --olive-surface: oklch(100% 0 0 / 80%);
  --sand-surface: oklch(100% 0 0 / 80%);
  --tomato-surface: oklch(98.0% 0.01 25.1 / 80%);
  --red-surface: oklch(97.8% 0.011 17.3 / 80%);
  --ruby-surface: oklch(97.8% 0.011 10.3 / 80%);
  --crimson-surface: oklch(97.8% 0.01 354.9 / 80%);
  --pink-surface: oklch(97.9% 0.012 342.6 / 80%);
  --plum-surface: oklch(97.9% 0.014 325.7 / 80%);
  --purple-surface: oklch(97.7% 0.013 310.5 / 80%);
  --violet-surface: oklch(97.8% 0.012 301.3 / 80%);
  --iris-surface: oklch(97.6% 0.012 286.2 / 80%);
  --indigo-surface: oklch(97.9% 0.01 267.4 / 80%);
  --blue-surface: oklch(97.8% 0.012 239.9 / 80%);
  --cyan-surface: oklch(97.5% 0.011 204.1 / 80%);
  --teal-surface: oklch(97.7% 0.011 182.9 / 80%);
  --jade-surface: oklch(97.7% 0.012 162.1 / 80%);
  --green-surface: oklch(97.7% 0.012 157 / 80%);
  --grass-surface: oklch(97.8% 0.012 145.5 / 80%);
  --brown-surface: oklch(98.0% 0.006 75.4 / 80%);
  --bronze-surface: oklch(97.6% 0.009 34.3 / 80%);
  --gold-surface: oklch(97.7% 0.012 101.5 / 80%);
  --sky-surface: oklch(97.5% 0.013 221.4 / 80%);
  --mint-surface: oklch(97.6% 0.012 184.1 / 80%);
  --lime-surface: oklch(97.7% 0.012 121.7 / 80%);
  --yellow-surface: oklch(98.4% 0.03 100.9 / 80%);
  --amber-surface: oklch(98.2% 0.029 98.5 / 80%);
  --orange-surface: oklch(97.5% 0.019 72.6 / 80%);
  --black-a1: oklch(0% 0 0 / 5%);
  --black-a2: oklch(0% 0 0 / 10%);
  --black-a3: oklch(0% 0 0 / 15%);
  --black-a4: oklch(0% 0 0 / 20%);
  --black-a5: oklch(0% 0 0 / 30%);
  --black-a6: oklch(0% 0 0 / 40%);
  --black-a7: oklch(0% 0 0 / 50%);
  --black-a8: oklch(0% 0 0 / 60%);
  --black-a9: oklch(0% 0 0 / 70%);
  --black-a10: oklch(0% 0 0 / 80%);
  --black-a11: oklch(0% 0 0 / 90%);
  --black-a12: oklch(0% 0 0 / 95%);
  --white-a1: oklch(100% 0 0 / 5%);
  --white-a2: oklch(100% 0 0 / 10%);
  --white-a3: oklch(100% 0 0 / 15%);
  --white-a4: oklch(100% 0 0 / 20%);
  --white-a5: oklch(100% 0 0 / 30%);
  --white-a6: oklch(100% 0 0 / 40%);
  --white-a7: oklch(100% 0 0 / 50%);
  --white-a8: oklch(100% 0 0 / 60%);
  --white-a9: oklch(100% 0 0 / 70%);
  --white-a10: oklch(100% 0 0 / 80%);
  --white-a11: oklch(100% 0 0 / 90%);
  --white-a12: oklch(100% 0 0 / 95%);
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :where(html) {
      --tomato-1:color(display-p3 0.998 0.989 0.988);
      --tomato-2: color(display-p3 0.994 0.974 0.969);
      --tomato-3: color(display-p3 0.985 0.924 0.909);
      --tomato-4: color(display-p3 0.996 0.868 0.835);
      --tomato-5: color(display-p3 0.98 0.812 0.77);
      --tomato-6: color(display-p3 0.953 0.75 0.698);
      --tomato-7: color(display-p3 0.917 0.673 0.611);
      --tomato-8: color(display-p3 0.875 0.575 0.502);
      --tomato-9: color(display-p3 0.831 0.345 0.231);
      --tomato-10: color(display-p3 0.802 0.313 0.2);
      --tomato-11: color(display-p3 0.755 0.259 0.152);
      --tomato-12: color(display-p3 0.335 0.165 0.132);
      --tomato-a1: color(display-p3 0.675 0.024 0.024/0.012);
      --tomato-a2: color(display-p3 0.757 0.145 0.02/0.032);
      --tomato-a3: color(display-p3 0.831 0.184 0.012/0.091);
      --tomato-a4: color(display-p3 0.976 0.192 0.004/0.165);
      --tomato-a5: color(display-p3 0.918 0.192 0.004/0.232);
      --tomato-a6: color(display-p3 0.847 0.173 0.004/0.302);
      --tomato-a7: color(display-p3 0.788 0.165 0.004/0.389);
      --tomato-a8: color(display-p3 0.749 0.153 0.004/0.499);
      --tomato-a9: color(display-p3 0.78 0.149 0/0.769);
      --tomato-a10: color(display-p3 0.757 0.141 0/0.8);
      --tomato-a11: color(display-p3 0.755 0.259 0.152);
      --tomato-a12: color(display-p3 0.335 0.165 0.132);
      --red-1: color(display-p3 0.998 0.989 0.988);
      --red-2: color(display-p3 0.995 0.971 0.971);
      --red-3: color(display-p3 0.985 0.925 0.925);
      --red-4: color(display-p3 0.999 0.866 0.866);
      --red-5: color(display-p3 0.984 0.812 0.811);
      --red-6: color(display-p3 0.955 0.751 0.749);
      --red-7: color(display-p3 0.915 0.675 0.672);
      --red-8: color(display-p3 0.872 0.575 0.572);
      --red-9: color(display-p3 0.83 0.329 0.324);
      --red-10: color(display-p3 0.798 0.294 0.285);
      --red-11: color(display-p3 0.744 0.234 0.222);
      --red-12: color(display-p3 0.36 0.115 0.143);
      --red-a1: color(display-p3 0.675 0.024 0.024/0.012);
      --red-a2: color(display-p3 0.863 0.024 0.024/0.028);
      --red-a3: color(display-p3 0.792 0.008 0.008/0.075);
      --red-a4: color(display-p3 1 0.008 0.008/0.134);
      --red-a5: color(display-p3 0.918 0.008 0.008/0.189);
      --red-a6: color(display-p3 0.831 0.02 0.004/0.251);
      --red-a7: color(display-p3 0.741 0.016 0.004/0.33);
      --red-a8: color(display-p3 0.698 0.012 0.004/0.428);
      --red-a9: color(display-p3 0.749 0.008 0/0.675);
      --red-a10: color(display-p3 0.714 0.012 0/0.714);
      --red-a11: color(display-p3 0.744 0.234 0.222);
      --red-a12: color(display-p3 0.36 0.115 0.143);
      --ruby-1: color(display-p3 0.998 0.989 0.992);
      --ruby-2: color(display-p3 0.995 0.971 0.974);
      --ruby-3: color(display-p3 0.983 0.92 0.928);
      --ruby-4: color(display-p3 0.987 0.869 0.885);
      --ruby-5: color(display-p3 0.968 0.817 0.839);
      --ruby-6: color(display-p3 0.937 0.758 0.786);
      --ruby-7: color(display-p3 0.897 0.685 0.721);
      --ruby-8: color(display-p3 0.851 0.588 0.639);
      --ruby-9: color(display-p3 0.83 0.323 0.408);
      --ruby-10: color(display-p3 0.795 0.286 0.375);
      --ruby-11: color(display-p3 0.728 0.211 0.311);
      --ruby-12: color(display-p3 0.36 0.115 0.171);
      --ruby-a1: color(display-p3 0.675 0.024 0.349/0.012);
      --ruby-a2: color(display-p3 0.863 0.024 0.024/0.028);
      --ruby-a3: color(display-p3 0.804 0.008 0.11/0.079);
      --ruby-a4: color(display-p3 0.91 0.008 0.125/0.13);
      --ruby-a5: color(display-p3 0.831 0.004 0.133/0.185);
      --ruby-a6: color(display-p3 0.745 0.004 0.118/0.244);
      --ruby-a7: color(display-p3 0.678 0.004 0.114/0.314);
      --ruby-a8: color(display-p3 0.639 0.004 0.125/0.412);
      --ruby-a9: color(display-p3 0.753 0 0.129/0.679);
      --ruby-a10: color(display-p3 0.714 0 0.125/0.714);
      --ruby-a11: color(display-p3 0.728 0.211 0.311);
      --ruby-a12: color(display-p3 0.36 0.115 0.171);
      --crimson-1: color(display-p3 0.998 0.989 0.992);
      --crimson-2: color(display-p3 0.991 0.969 0.976);
      --crimson-3: color(display-p3 0.987 0.917 0.941);
      --crimson-4: color(display-p3 0.975 0.866 0.904);
      --crimson-5: color(display-p3 0.953 0.813 0.864);
      --crimson-6: color(display-p3 0.921 0.755 0.817);
      --crimson-7: color(display-p3 0.88 0.683 0.761);
      --crimson-8: color(display-p3 0.834 0.592 0.694);
      --crimson-9: color(display-p3 0.843 0.298 0.507);
      --crimson-10: color(display-p3 0.807 0.266 0.468);
      --crimson-11: color(display-p3 0.731 0.195 0.388);
      --crimson-12: color(display-p3 0.352 0.111 0.221);
      --crimson-a1: color(display-p3 0.675 0.024 0.349/0.012);
      --crimson-a2: color(display-p3 0.757 0.02 0.267/0.032);
      --crimson-a3: color(display-p3 0.859 0.008 0.294/0.083);
      --crimson-a4: color(display-p3 0.827 0.008 0.298/0.134);
      --crimson-a5: color(display-p3 0.753 0.008 0.275/0.189);
      --crimson-a6: color(display-p3 0.682 0.004 0.247/0.244);
      --crimson-a7: color(display-p3 0.62 0.004 0.251/0.318);
      --crimson-a8: color(display-p3 0.6 0.004 0.251/0.408);
      --crimson-a9: color(display-p3 0.776 0 0.298/0.702);
      --crimson-a10: color(display-p3 0.737 0 0.275/0.734);
      --crimson-a11: color(display-p3 0.731 0.195 0.388);
      --crimson-a12: color(display-p3 0.352 0.111 0.221);
      --pink-1: color(display-p3 0.998 0.989 0.996);
      --pink-2: color(display-p3 0.992 0.97 0.985);
      --pink-3: color(display-p3 0.981 0.917 0.96);
      --pink-4: color(display-p3 0.963 0.867 0.932);
      --pink-5: color(display-p3 0.939 0.815 0.899);
      --pink-6: color(display-p3 0.907 0.756 0.859);
      --pink-7: color(display-p3 0.869 0.683 0.81);
      --pink-8: color(display-p3 0.825 0.59 0.751);
      --pink-9: color(display-p3 0.775 0.297 0.61);
      --pink-10: color(display-p3 0.748 0.27 0.581);
      --pink-11: color(display-p3 0.698 0.219 0.528);
      --pink-12: color(display-p3 0.363 0.101 0.279);
      --pink-a1: color(display-p3 0.675 0.024 0.675/0.012);
      --pink-a2: color(display-p3 0.757 0.02 0.51/0.032);
      --pink-a3: color(display-p3 0.765 0.008 0.529/0.083);
      --pink-a4: color(display-p3 0.737 0.008 0.506/0.134);
      --pink-a5: color(display-p3 0.663 0.004 0.451/0.185);
      --pink-a6: color(display-p3 0.616 0.004 0.424/0.244);
      --pink-a7: color(display-p3 0.596 0.004 0.412/0.318);
      --pink-a8: color(display-p3 0.573 0.004 0.404/0.412);
      --pink-a9: color(display-p3 0.682 0 0.447/0.702);
      --pink-a10: color(display-p3 0.655 0 0.424/0.73);
      --pink-a11: color(display-p3 0.698 0.219 0.528);
      --pink-a12: color(display-p3 0.363 0.101 0.279);
      --plum-1: color(display-p3 0.995 0.988 0.999);
      --plum-2: color(display-p3 0.988 0.971 0.99);
      --plum-3: color(display-p3 0.973 0.923 0.98);
      --plum-4: color(display-p3 0.953 0.875 0.966);
      --plum-5: color(display-p3 0.926 0.825 0.945);
      --plum-6: color(display-p3 0.89 0.765 0.916);
      --plum-7: color(display-p3 0.84 0.686 0.877);
      --plum-8: color(display-p3 0.775 0.58 0.832);
      --plum-9: color(display-p3 0.624 0.313 0.708);
      --plum-10: color(display-p3 0.587 0.29 0.667);
      --plum-11: color(display-p3 0.543 0.263 0.619);
      --plum-12: color(display-p3 0.299 0.114 0.352);
      --plum-a1: color(display-p3 0.675 0.024 1/0.012);
      --plum-a2: color(display-p3 0.58 0.024 0.58/0.028);
      --plum-a3: color(display-p3 0.655 0.008 0.753/0.079);
      --plum-a4: color(display-p3 0.627 0.008 0.722/0.126);
      --plum-a5: color(display-p3 0.58 0.004 0.69/0.177);
      --plum-a6: color(display-p3 0.537 0.004 0.655/0.236);
      --plum-a7: color(display-p3 0.49 0.004 0.616/0.314);
      --plum-a8: color(display-p3 0.471 0.004 0.6/0.42);
      --plum-a9: color(display-p3 0.451 0 0.576/0.687);
      --plum-a10: color(display-p3 0.42 0 0.529/0.71);
      --plum-a11: color(display-p3 0.543 0.263 0.619);
      --plum-a12: color(display-p3 0.299 0.114 0.352);
      --purple-1: color(display-p3 0.995 0.988 0.996);
      --purple-2: color(display-p3 0.983 0.971 0.993);
      --purple-3: color(display-p3 0.963 0.931 0.989);
      --purple-4: color(display-p3 0.937 0.888 0.981);
      --purple-5: color(display-p3 0.904 0.837 0.966);
      --purple-6: color(display-p3 0.86 0.774 0.942);
      --purple-7: color(display-p3 0.799 0.69 0.91);
      --purple-8: color(display-p3 0.719 0.583 0.874);
      --purple-9: color(display-p3 0.523 0.318 0.751);
      --purple-10: color(display-p3 0.483 0.289 0.7);
      --purple-11: color(display-p3 0.473 0.281 0.687);
      --purple-12: color(display-p3 0.234 0.132 0.363);
      --purple-a1: color(display-p3 0.675 0.024 0.675/0.012);
      --purple-a2: color(display-p3 0.443 0.024 0.722/0.028);
      --purple-a3: color(display-p3 0.506 0.008 0.835/0.071);
      --purple-a4: color(display-p3 0.451 0.004 0.831/0.114);
      --purple-a5: color(display-p3 0.431 0.004 0.788/0.165);
      --purple-a6: color(display-p3 0.384 0.004 0.745/0.228);
      --purple-a7: color(display-p3 0.357 0.004 0.71/0.31);
      --purple-a8: color(display-p3 0.322 0.004 0.702/0.416);
      --purple-a9: color(display-p3 0.298 0 0.639/0.683);
      --purple-a10: color(display-p3 0.271 0 0.58/0.71);
      --purple-a11: color(display-p3 0.473 0.281 0.687);
      --purple-a12: color(display-p3 0.234 0.132 0.363);
      --violet-1: color(display-p3 0.991 0.988 0.995);
      --violet-2: color(display-p3 0.978 0.974 0.998);
      --violet-3: color(display-p3 0.953 0.943 0.993);
      --violet-4: color(display-p3 0.916 0.897 1);
      --violet-5: color(display-p3 0.876 0.851 1);
      --violet-6: color(display-p3 0.825 0.793 0.981);
      --violet-7: color(display-p3 0.752 0.712 0.943);
      --violet-8: color(display-p3 0.654 0.602 0.902);
      --violet-9: color(display-p3 0.417 0.341 0.784);
      --violet-10: color(display-p3 0.381 0.306 0.741);
      --violet-11: color(display-p3 0.383 0.317 0.702);
      --violet-12: color(display-p3 0.179 0.15 0.359);
      --violet-a1: color(display-p3 0.349 0.024 0.675/0.012);
      --violet-a2: color(display-p3 0.161 0.024 0.863/0.028);
      --violet-a3: color(display-p3 0.204 0.004 0.871/0.059);
      --violet-a4: color(display-p3 0.196 0.004 1/0.102);
      --violet-a5: color(display-p3 0.165 0.008 1/0.15);
      --violet-a6: color(display-p3 0.153 0.004 0.906/0.208);
      --violet-a7: color(display-p3 0.141 0.004 0.796/0.287);
      --violet-a8: color(display-p3 0.133 0.004 0.753/0.397);
      --violet-a9: color(display-p3 0.114 0 0.675/0.659);
      --violet-a10: color(display-p3 0.11 0 0.627/0.695);
      --violet-a11: color(display-p3 0.383 0.317 0.702);
      --violet-a12: color(display-p3 0.179 0.15 0.359);
      --iris-1: color(display-p3 0.992 0.992 0.999);
      --iris-2: color(display-p3 0.972 0.973 0.998);
      --iris-3: color(display-p3 0.943 0.945 0.992);
      --iris-4: color(display-p3 0.902 0.906 1);
      --iris-5: color(display-p3 0.857 0.861 1);
      --iris-6: color(display-p3 0.799 0.805 0.987);
      --iris-7: color(display-p3 0.721 0.727 0.955);
      --iris-8: color(display-p3 0.61 0.619 0.918);
      --iris-9: color(display-p3 0.357 0.357 0.81);
      --iris-10: color(display-p3 0.318 0.318 0.774);
      --iris-11: color(display-p3 0.337 0.326 0.748);
      --iris-12: color(display-p3 0.154 0.161 0.371);
      --iris-a1: color(display-p3 0.02 0.02 1/0.008);
      --iris-a2: color(display-p3 0.024 0.024 0.863/0.028);
      --iris-a3: color(display-p3 0.004 0.071 0.871/0.059);
      --iris-a4: color(display-p3 0.012 0.051 1/0.099);
      --iris-a5: color(display-p3 0.008 0.035 1/0.142);
      --iris-a6: color(display-p3 0 0.02 0.941/0.2);
      --iris-a7: color(display-p3 0.004 0.02 0.847/0.279);
      --iris-a8: color(display-p3 0.004 0.024 0.788/0.389);
      --iris-a9: color(display-p3 0 0 0.706/0.644);
      --iris-a10: color(display-p3 0 0 0.667/0.683);
      --iris-a11: color(display-p3 0.337 0.326 0.748);
      --iris-a12: color(display-p3 0.154 0.161 0.371);
      --indigo-1: color(display-p3 0.992 0.992 0.996);
      --indigo-2: color(display-p3 0.971 0.977 0.998);
      --indigo-3: color(display-p3 0.933 0.948 0.992);
      --indigo-4: color(display-p3 0.885 0.914 1);
      --indigo-5: color(display-p3 0.831 0.87 1);
      --indigo-6: color(display-p3 0.767 0.814 0.995);
      --indigo-7: color(display-p3 0.685 0.74 0.957);
      --indigo-8: color(display-p3 0.569 0.639 0.916);
      --indigo-9: color(display-p3 0.276 0.384 0.837);
      --indigo-10: color(display-p3 0.234 0.343 0.801);
      --indigo-11: color(display-p3 0.256 0.354 0.755);
      --indigo-12: color(display-p3 0.133 0.175 0.348);
      --indigo-a1: color(display-p3 0.02 0.02 0.51/0.008);
      --indigo-a2: color(display-p3 0.024 0.161 0.863/0.028);
      --indigo-a3: color(display-p3 0.008 0.239 0.886/0.067);
      --indigo-a4: color(display-p3 0.004 0.247 1/0.114);
      --indigo-a5: color(display-p3 0.004 0.235 1/0.169);
      --indigo-a6: color(display-p3 0.004 0.208 0.984/0.232);
      --indigo-a7: color(display-p3 0.004 0.176 0.863/0.314);
      --indigo-a8: color(display-p3 0.004 0.165 0.812/0.432);
      --indigo-a9: color(display-p3 0 0.153 0.773/0.726);
      --indigo-a10: color(display-p3 0 0.137 0.737/0.765);
      --indigo-a11: color(display-p3 0.256 0.354 0.755);
      --indigo-a12: color(display-p3 0.133 0.175 0.348);
      --blue-1: color(display-p3 0.986 0.992 0.999);
      --blue-2: color(display-p3 0.96 0.979 0.998);
      --blue-3: color(display-p3 0.912 0.956 0.991);
      --blue-4: color(display-p3 0.853 0.932 1);
      --blue-5: color(display-p3 0.788 0.894 0.998);
      --blue-6: color(display-p3 0.709 0.843 0.976);
      --blue-7: color(display-p3 0.606 0.777 0.947);
      --blue-8: color(display-p3 0.451 0.688 0.917);
      --blue-9: color(display-p3 0.247 0.556 0.969);
      --blue-10: color(display-p3 0.234 0.523 0.912);
      --blue-11: color(display-p3 0.15 0.44 0.84);
      --blue-12: color(display-p3 0.102 0.193 0.379);
      --blue-a1: color(display-p3 0.024 0.514 1/0.016);
      --blue-a2: color(display-p3 0.024 0.514 0.906/0.04);
      --blue-a3: color(display-p3 0.012 0.506 0.914/0.087);
      --blue-a4: color(display-p3 0.008 0.545 1/0.146);
      --blue-a5: color(display-p3 0.004 0.502 0.984/0.212);
      --blue-a6: color(display-p3 0.004 0.463 0.922/0.291);
      --blue-a7: color(display-p3 0.004 0.431 0.863/0.393);
      --blue-a8: color(display-p3 0 0.427 0.851/0.55);
      --blue-a9: color(display-p3 0 0.412 0.961/0.753);
      --blue-a10: color(display-p3 0 0.376 0.886/0.765);
      --blue-a11: color(display-p3 0.15 0.44 0.84);
      --blue-a12: color(display-p3 0.102 0.193 0.379);
      --cyan-1: color(display-p3 0.982 0.992 0.996);
      --cyan-2: color(display-p3 0.955 0.981 0.984);
      --cyan-3: color(display-p3 0.888 0.965 0.975);
      --cyan-4: color(display-p3 0.821 0.941 0.959);
      --cyan-5: color(display-p3 0.751 0.907 0.935);
      --cyan-6: color(display-p3 0.671 0.862 0.9);
      --cyan-7: color(display-p3 0.564 0.8 0.854);
      --cyan-8: color(display-p3 0.388 0.715 0.798);
      --cyan-9: color(display-p3 0.282 0.627 0.765);
      --cyan-10: color(display-p3 0.264 0.583 0.71);
      --cyan-11: color(display-p3 0.08 0.48 0.63);
      --cyan-12: color(display-p3 0.108 0.232 0.277);
      --cyan-a1: color(display-p3 0.02 0.608 0.804/0.02);
      --cyan-a2: color(display-p3 0.02 0.557 0.647/0.044);
      --cyan-a3: color(display-p3 0.004 0.694 0.796/0.114);
      --cyan-a4: color(display-p3 0.004 0.678 0.784/0.181);
      --cyan-a5: color(display-p3 0.004 0.624 0.733/0.248);
      --cyan-a6: color(display-p3 0.004 0.584 0.706/0.33);
      --cyan-a7: color(display-p3 0.004 0.541 0.667/0.436);
      --cyan-a8: color(display-p3 0 0.533 0.667/0.612);
      --cyan-a9: color(display-p3 0 0.482 0.675/0.718);
      --cyan-a10: color(display-p3 0 0.435 0.608/0.738);
      --cyan-a11: color(display-p3 0.08 0.48 0.63);
      --cyan-a12: color(display-p3 0.108 0.232 0.277);
      --teal-1: color(display-p3 0.983 0.996 0.992);
      --teal-2: color(display-p3 0.958 0.983 0.976);
      --teal-3: color(display-p3 0.895 0.971 0.952);
      --teal-4: color(display-p3 0.831 0.949 0.92);
      --teal-5: color(display-p3 0.761 0.914 0.878);
      --teal-6: color(display-p3 0.682 0.864 0.825);
      --teal-7: color(display-p3 0.581 0.798 0.756);
      --teal-8: color(display-p3 0.433 0.716 0.671);
      --teal-9: color(display-p3 0.297 0.637 0.581);
      --teal-10: color(display-p3 0.275 0.599 0.542);
      --teal-11: color(display-p3 0.08 0.5 0.43);
      --teal-12: color(display-p3 0.11 0.235 0.219);
      --teal-a1: color(display-p3 0.024 0.757 0.514/0.016);
      --teal-a2: color(display-p3 0.02 0.647 0.467/0.044);
      --teal-a3: color(display-p3 0.004 0.741 0.557/0.106);
      --teal-a4: color(display-p3 0.004 0.702 0.537/0.169);
      --teal-a5: color(display-p3 0.004 0.643 0.494/0.24);
      --teal-a6: color(display-p3 0.004 0.569 0.447/0.318);
      --teal-a7: color(display-p3 0.004 0.518 0.424/0.42);
      --teal-a8: color(display-p3 0 0.506 0.424/0.569);
      --teal-a9: color(display-p3 0 0.482 0.404/0.702);
      --teal-a10: color(display-p3 0 0.451 0.369/0.726);
      --teal-a11: color(display-p3 0.08 0.5 0.43);
      --teal-a12: color(display-p3 0.11 0.235 0.219);
      --jade-1: color(display-p3 0.986 0.996 0.992);
      --jade-2: color(display-p3 0.962 0.983 0.969);
      --jade-3: color(display-p3 0.912 0.965 0.932);
      --jade-4: color(display-p3 0.858 0.941 0.893);
      --jade-5: color(display-p3 0.795 0.909 0.847);
      --jade-6: color(display-p3 0.715 0.864 0.791);
      --jade-7: color(display-p3 0.603 0.802 0.718);
      --jade-8: color(display-p3 0.44 0.72 0.629);
      --jade-9: color(display-p3 0.319 0.63 0.521);
      --jade-10: color(display-p3 0.299 0.592 0.488);
      --jade-11: color(display-p3 0.15 0.5 0.37);
      --jade-12: color(display-p3 0.142 0.229 0.194);
      --jade-a1: color(display-p3 0.024 0.757 0.514/0.016);
      --jade-a2: color(display-p3 0.024 0.612 0.22/0.04);
      --jade-a3: color(display-p3 0.012 0.596 0.235/0.087);
      --jade-a4: color(display-p3 0.008 0.588 0.255/0.142);
      --jade-a5: color(display-p3 0.004 0.561 0.251/0.204);
      --jade-a6: color(display-p3 0.004 0.525 0.278/0.287);
      --jade-a7: color(display-p3 0.004 0.506 0.29/0.397);
      --jade-a8: color(display-p3 0 0.506 0.337/0.561);
      --jade-a9: color(display-p3 0 0.459 0.298/0.683);
      --jade-a10: color(display-p3 0 0.42 0.271/0.702);
      --jade-a11: color(display-p3 0.15 0.5 0.37);
      --jade-a12: color(display-p3 0.142 0.229 0.194);
      --green-1: color(display-p3 0.986 0.996 0.989);
      --green-2: color(display-p3 0.963 0.983 0.967);
      --green-3: color(display-p3 0.913 0.964 0.925);
      --green-4: color(display-p3 0.859 0.94 0.879);
      --green-5: color(display-p3 0.796 0.907 0.826);
      --green-6: color(display-p3 0.718 0.863 0.761);
      --green-7: color(display-p3 0.61 0.801 0.675);
      --green-8: color(display-p3 0.451 0.715 0.559);
      --green-9: color(display-p3 0.332 0.634 0.442);
      --green-10: color(display-p3 0.308 0.595 0.417);
      --green-11: color(display-p3 0.19 0.5 0.32);
      --green-12: color(display-p3 0.132 0.228 0.18);
      --green-a1: color(display-p3 0.024 0.757 0.267/0.016);
      --green-a2: color(display-p3 0.024 0.565 0.129/0.036);
      --green-a3: color(display-p3 0.012 0.596 0.145/0.087);
      --green-a4: color(display-p3 0.008 0.588 0.145/0.142);
      --green-a5: color(display-p3 0.004 0.541 0.157/0.204);
      --green-a6: color(display-p3 0.004 0.518 0.157/0.283);
      --green-a7: color(display-p3 0.004 0.486 0.165/0.389);
      --green-a8: color(display-p3 0 0.478 0.2/0.55);
      --green-a9: color(display-p3 0 0.455 0.165/0.667);
      --green-a10: color(display-p3 0 0.416 0.153/0.691);
      --green-a11: color(display-p3 0.19 0.5 0.32);
      --green-a12: color(display-p3 0.132 0.228 0.18);
      --grass-1: color(display-p3 0.986 0.996 0.985);
      --grass-2: color(display-p3 0.966 0.983 0.964);
      --grass-3: color(display-p3 0.923 0.965 0.917);
      --grass-4: color(display-p3 0.872 0.94 0.865);
      --grass-5: color(display-p3 0.811 0.908 0.802);
      --grass-6: color(display-p3 0.733 0.864 0.724);
      --grass-7: color(display-p3 0.628 0.803 0.622);
      --grass-8: color(display-p3 0.477 0.72 0.482);
      --grass-9: color(display-p3 0.38 0.647 0.378);
      --grass-10: color(display-p3 0.344 0.598 0.342);
      --grass-11: color(display-p3 0.263 0.488 0.261);
      --grass-12: color(display-p3 0.151 0.233 0.153);
      --grass-a1: color(display-p3 0.024 0.757 0.024/0.016);
      --grass-a2: color(display-p3 0.024 0.565 0.024/0.036);
      --grass-a3: color(display-p3 0.059 0.576 0.008/0.083);
      --grass-a4: color(display-p3 0.035 0.565 0.008/0.134);
      --grass-a5: color(display-p3 0.047 0.545 0.008/0.197);
      --grass-a6: color(display-p3 0.031 0.502 0.004/0.275);
      --grass-a7: color(display-p3 0.012 0.482 0.004/0.377);
      --grass-a8: color(display-p3 0 0.467 0.008/0.522);
      --grass-a9: color(display-p3 0.008 0.435 0/0.624);
      --grass-a10: color(display-p3 0.008 0.388 0/0.659);
      --grass-a11: color(display-p3 0.263 0.488 0.261);
      --grass-a12: color(display-p3 0.151 0.233 0.153);
      --orange-1: color(display-p3 0.995 0.988 0.985);
      --orange-2: color(display-p3 0.994 0.968 0.934);
      --orange-3: color(display-p3 0.989 0.938 0.85);
      --orange-4: color(display-p3 1 0.874 0.687);
      --orange-5: color(display-p3 1 0.821 0.583);
      --orange-6: color(display-p3 0.975 0.767 0.545);
      --orange-7: color(display-p3 0.919 0.693 0.486);
      --orange-8: color(display-p3 0.877 0.597 0.379);
      --orange-9: color(display-p3 0.9 0.45 0.2);
      --orange-10: color(display-p3 0.87 0.409 0.164);
      --orange-11: color(display-p3 0.76 0.34 0);
      --orange-12: color(display-p3 0.323 0.185 0.127);
      --orange-a1: color(display-p3 0.757 0.267 0.024/0.016);
      --orange-a2: color(display-p3 0.886 0.533 0.008/0.067);
      --orange-a3: color(display-p3 0.922 0.584 0.008/0.15);
      --orange-a4: color(display-p3 1 0.604 0.004/0.314);
      --orange-a5: color(display-p3 1 0.569 0.004/0.416);
      --orange-a6: color(display-p3 0.949 0.494 0.004/0.455);
      --orange-a7: color(display-p3 0.839 0.408 0/0.514);
      --orange-a8: color(display-p3 0.804 0.349 0/0.62);
      --orange-a9: color(display-p3 0.878 0.314 0/0.8);
      --orange-a10: color(display-p3 0.843 0.29 0/0.836);
      --orange-a11: color(display-p3 0.76 0.34 0);
      --orange-a12: color(display-p3 0.323 0.185 0.127);
      --brown-1: color(display-p3 0.995 0.992 0.989);
      --brown-2: color(display-p3 0.987 0.976 0.964);
      --brown-3: color(display-p3 0.959 0.936 0.909);
      --brown-4: color(display-p3 0.934 0.897 0.855);
      --brown-5: color(display-p3 0.909 0.856 0.798);
      --brown-6: color(display-p3 0.88 0.808 0.73);
      --brown-7: color(display-p3 0.841 0.742 0.639);
      --brown-8: color(display-p3 0.782 0.647 0.514);
      --brown-9: color(display-p3 0.651 0.505 0.368);
      --brown-10: color(display-p3 0.601 0.465 0.344);
      --brown-11: color(display-p3 0.485 0.374 0.288);
      --brown-12: color(display-p3 0.236 0.202 0.183);
      --brown-a1: color(display-p3 0.675 0.349 0.024/0.012);
      --brown-a2: color(display-p3 0.675 0.349 0.024/0.036);
      --brown-a3: color(display-p3 0.573 0.314 0.012/0.091);
      --brown-a4: color(display-p3 0.545 0.302 0.008/0.146);
      --brown-a5: color(display-p3 0.561 0.29 0.004/0.204);
      --brown-a6: color(display-p3 0.553 0.294 0.004/0.271);
      --brown-a7: color(display-p3 0.557 0.286 0.004/0.361);
      --brown-a8: color(display-p3 0.549 0.275 0.004/0.487);
      --brown-a9: color(display-p3 0.447 0.22 0/0.632);
      --brown-a10: color(display-p3 0.388 0.188 0/0.655);
      --brown-a11: color(display-p3 0.485 0.374 0.288);
      --brown-a12: color(display-p3 0.236 0.202 0.183);
      --sky-1: color(display-p3 0.98 0.995 0.999);
      --sky-2: color(display-p3 0.953 0.98 0.99);
      --sky-3: color(display-p3 0.899 0.963 0.989);
      --sky-4: color(display-p3 0.842 0.937 0.977);
      --sky-5: color(display-p3 0.777 0.9 0.954);
      --sky-6: color(display-p3 0.701 0.851 0.921);
      --sky-7: color(display-p3 0.604 0.785 0.879);
      --sky-8: color(display-p3 0.457 0.696 0.829);
      --sky-9: color(display-p3 0.585 0.877 0.983);
      --sky-10: color(display-p3 0.555 0.845 0.959);
      --sky-11: color(display-p3 0.193 0.448 0.605);
      --sky-12: color(display-p3 0.145 0.241 0.329);
      --sky-a1: color(display-p3 0.02 0.804 1/0.02);
      --sky-a2: color(display-p3 0.024 0.592 0.757/0.048);
      --sky-a3: color(display-p3 0.004 0.655 0.886/0.102);
      --sky-a4: color(display-p3 0.004 0.604 0.851/0.157);
      --sky-a5: color(display-p3 0.004 0.565 0.792/0.224);
      --sky-a6: color(display-p3 0.004 0.502 0.737/0.299);
      --sky-a7: color(display-p3 0.004 0.459 0.694/0.397);
      --sky-a8: color(display-p3 0 0.435 0.682/0.542);
      --sky-a9: color(display-p3 0.004 0.71 0.965/0.416);
      --sky-a10: color(display-p3 0.004 0.647 0.914/0.444);
      --sky-a11: color(display-p3 0.193 0.448 0.605);
      --sky-a12: color(display-p3 0.145 0.241 0.329);
      --mint-1: color(display-p3 0.98 0.995 0.992);
      --mint-2: color(display-p3 0.957 0.985 0.977);
      --mint-3: color(display-p3 0.888 0.972 0.95);
      --mint-4: color(display-p3 0.819 0.951 0.916);
      --mint-5: color(display-p3 0.747 0.918 0.873);
      --mint-6: color(display-p3 0.668 0.87 0.818);
      --mint-7: color(display-p3 0.567 0.805 0.744);
      --mint-8: color(display-p3 0.42 0.724 0.649);
      --mint-9: color(display-p3 0.62 0.908 0.834);
      --mint-10: color(display-p3 0.585 0.871 0.797);
      --mint-11: color(display-p3 0.203 0.463 0.397);
      --mint-12: color(display-p3 0.136 0.259 0.236);
      --mint-a1: color(display-p3 0.02 0.804 0.608/0.02);
      --mint-a2: color(display-p3 0.02 0.647 0.467/0.044);
      --mint-a3: color(display-p3 0.004 0.761 0.553/0.114);
      --mint-a4: color(display-p3 0.004 0.741 0.545/0.181);
      --mint-a5: color(display-p3 0.004 0.678 0.51/0.255);
      --mint-a6: color(display-p3 0.004 0.616 0.463/0.334);
      --mint-a7: color(display-p3 0.004 0.549 0.412/0.432);
      --mint-a8: color(display-p3 0 0.529 0.392/0.581);
      --mint-a9: color(display-p3 0.004 0.765 0.569/0.381);
      --mint-a10: color(display-p3 0.004 0.69 0.51/0.416);
      --mint-a11: color(display-p3 0.203 0.463 0.397);
      --mint-a12: color(display-p3 0.136 0.259 0.236);
      --lime-1: color(display-p3 0.989 0.992 0.981);
      --lime-2: color(display-p3 0.975 0.98 0.954);
      --lime-3: color(display-p3 0.939 0.965 0.851);
      --lime-4: color(display-p3 0.896 0.94 0.76);
      --lime-5: color(display-p3 0.843 0.903 0.678);
      --lime-6: color(display-p3 0.778 0.852 0.599);
      --lime-7: color(display-p3 0.694 0.784 0.508);
      --lime-8: color(display-p3 0.585 0.707 0.378);
      --lime-9: color(display-p3 0.78 0.928 0.466);
      --lime-10: color(display-p3 0.734 0.896 0.397);
      --lime-11: color(display-p3 0.386 0.482 0.227);
      --lime-12: color(display-p3 0.222 0.25 0.128);
      --lime-a1: color(display-p3 0.412 0.608 0.02/0.02);
      --lime-a2: color(display-p3 0.514 0.592 0.024/0.048);
      --lime-a3: color(display-p3 0.584 0.765 0.008/0.15);
      --lime-a4: color(display-p3 0.561 0.757 0.004/0.24);
      --lime-a5: color(display-p3 0.514 0.698 0.004/0.322);
      --lime-a6: color(display-p3 0.443 0.627 0/0.4);
      --lime-a7: color(display-p3 0.376 0.561 0.004/0.491);
      --lime-a8: color(display-p3 0.333 0.529 0/0.624);
      --lime-a9: color(display-p3 0.588 0.867 0/0.534);
      --lime-a10: color(display-p3 0.561 0.827 0/0.604);
      --lime-a11: color(display-p3 0.386 0.482 0.227);
      --lime-a12: color(display-p3 0.222 0.25 0.128);
      --yellow-1: color(display-p3 0.992 0.992 0.978);
      --yellow-2: color(display-p3 0.995 0.99 0.922);
      --yellow-3: color(display-p3 0.997 0.982 0.749);
      --yellow-4: color(display-p3 0.992 0.953 0.627);
      --yellow-5: color(display-p3 0.984 0.91 0.51);
      --yellow-6: color(display-p3 0.934 0.847 0.474);
      --yellow-7: color(display-p3 0.876 0.785 0.46);
      --yellow-8: color(display-p3 0.811 0.689 0.313);
      --yellow-9: color(display-p3 1 0.92 0.22);
      --yellow-10: color(display-p3 0.977 0.868 0.291);
      --yellow-11: color(display-p3 0.6 0.44 0);
      --yellow-12: color(display-p3 0.271 0.233 0.137);
      --yellow-a1: color(display-p3 0.675 0.675 0.024/0.024);
      --yellow-a2: color(display-p3 0.953 0.855 0.008/0.079);
      --yellow-a3: color(display-p3 0.988 0.925 0.004/0.251);
      --yellow-a4: color(display-p3 0.98 0.875 0.004/0.373);
      --yellow-a5: color(display-p3 0.969 0.816 0.004/0.491);
      --yellow-a6: color(display-p3 0.875 0.71 0/0.526);
      --yellow-a7: color(display-p3 0.769 0.604 0/0.542);
      --yellow-a8: color(display-p3 0.725 0.549 0/0.687);
      --yellow-a9: color(display-p3 1 0.898 0/0.781);
      --yellow-a10: color(display-p3 0.969 0.812 0/0.71);
      --yellow-a11: color(display-p3 0.6 0.44 0);
      --yellow-a12: color(display-p3 0.271 0.233 0.137);
      --amber-1: color(display-p3 0.995 0.992 0.985);
      --amber-2: color(display-p3 0.994 0.986 0.921);
      --amber-3: color(display-p3 0.994 0.969 0.782);
      --amber-4: color(display-p3 0.989 0.937 0.65);
      --amber-5: color(display-p3 0.97 0.902 0.527);
      --amber-6: color(display-p3 0.936 0.844 0.506);
      --amber-7: color(display-p3 0.89 0.762 0.443);
      --amber-8: color(display-p3 0.85 0.65 0.3);
      --amber-9: color(display-p3 1 0.77 0.26);
      --amber-10: color(display-p3 0.959 0.741 0.274);
      --amber-11: color(display-p3 0.64 0.4 0);
      --amber-12: color(display-p3 0.294 0.208 0.145);
      --amber-a1: color(display-p3 0.757 0.514 0.024/0.016);
      --amber-a2: color(display-p3 0.902 0.804 0.008/0.079);
      --amber-a3: color(display-p3 0.965 0.859 0.004/0.22);
      --amber-a4: color(display-p3 0.969 0.82 0.004/0.35);
      --amber-a5: color(display-p3 0.933 0.796 0.004/0.475);
      --amber-a6: color(display-p3 0.875 0.682 0.004/0.495);
      --amber-a7: color(display-p3 0.804 0.573 0/0.557);
      --amber-a8: color(display-p3 0.788 0.502 0/0.699);
      --amber-a9: color(display-p3 1 0.686 0/0.742);
      --amber-a10: color(display-p3 0.945 0.643 0/0.726);
      --amber-a11: color(display-p3 0.64 0.4 0);
      --amber-a12: color(display-p3 0.294 0.208 0.145);
      --gold-1: color(display-p3 0.992 0.992 0.989);
      --gold-2: color(display-p3 0.98 0.976 0.953);
      --gold-3: color(display-p3 0.947 0.94 0.909);
      --gold-4: color(display-p3 0.914 0.904 0.865);
      --gold-5: color(display-p3 0.88 0.865 0.816);
      --gold-6: color(display-p3 0.84 0.818 0.756);
      --gold-7: color(display-p3 0.788 0.753 0.677);
      --gold-8: color(display-p3 0.715 0.66 0.565);
      --gold-9: color(display-p3 0.579 0.517 0.41);
      --gold-10: color(display-p3 0.538 0.479 0.38);
      --gold-11: color(display-p3 0.433 0.386 0.305);
      --gold-12: color(display-p3 0.227 0.209 0.173);
      --gold-a1: color(display-p3 0.349 0.349 0.024/0.012);
      --gold-a2: color(display-p3 0.592 0.514 0.024/0.048);
      --gold-a3: color(display-p3 0.4 0.357 0.012/0.091);
      --gold-a4: color(display-p3 0.357 0.298 0.008/0.134);
      --gold-a5: color(display-p3 0.345 0.282 0.004/0.185);
      --gold-a6: color(display-p3 0.341 0.263 0.004/0.244);
      --gold-a7: color(display-p3 0.345 0.235 0.004/0.322);
      --gold-a8: color(display-p3 0.345 0.22 0.004/0.436);
      --gold-a9: color(display-p3 0.286 0.18 0/0.589);
      --gold-a10: color(display-p3 0.255 0.161 0/0.62);
      --gold-a11: color(display-p3 0.433 0.386 0.305);
      --gold-a12: color(display-p3 0.227 0.209 0.173);
      --bronze-1: color(display-p3 0.991 0.988 0.988);
      --bronze-2: color(display-p3 0.989 0.97 0.961);
      --bronze-3: color(display-p3 0.958 0.932 0.919);
      --bronze-4: color(display-p3 0.929 0.894 0.877);
      --bronze-5: color(display-p3 0.898 0.853 0.832);
      --bronze-6: color(display-p3 0.861 0.805 0.778);
      --bronze-7: color(display-p3 0.812 0.739 0.706);
      --bronze-8: color(display-p3 0.741 0.647 0.606);
      --bronze-9: color(display-p3 0.611 0.507 0.455);
      --bronze-10: color(display-p3 0.563 0.461 0.414);
      --bronze-11: color(display-p3 0.471 0.373 0.336);
      --bronze-12: color(display-p3 0.251 0.191 0.172);
      --bronze-a1: color(display-p3 0.349 0.024 0.024/0.012);
      --bronze-a2: color(display-p3 0.71 0.22 0.024/0.04);
      --bronze-a3: color(display-p3 0.482 0.2 0.008/0.083);
      --bronze-a4: color(display-p3 0.424 0.133 0.004/0.122);
      --bronze-a5: color(display-p3 0.4 0.145 0.004/0.169);
      --bronze-a6: color(display-p3 0.388 0.125 0.004/0.224);
      --bronze-a7: color(display-p3 0.365 0.11 0.004/0.295);
      --bronze-a8: color(display-p3 0.341 0.102 0.004/0.393);
      --bronze-a9: color(display-p3 0.29 0.094 0/0.546);
      --bronze-a10: color(display-p3 0.255 0.082 0/0.585);
      --bronze-a11: color(display-p3 0.471 0.373 0.336);
      --bronze-a12: color(display-p3 0.251 0.191 0.172);
      --gray-1: color(display-p3 0.988 0.988 0.988);
      --gray-2: color(display-p3 0.975 0.975 0.975);
      --gray-3: color(display-p3 0.939 0.939 0.939);
      --gray-4: color(display-p3 0.908 0.908 0.908);
      --gray-5: color(display-p3 0.88 0.88 0.88);
      --gray-6: color(display-p3 0.849 0.849 0.849);
      --gray-7: color(display-p3 0.807 0.807 0.807);
      --gray-8: color(display-p3 0.732 0.732 0.732);
      --gray-9: color(display-p3 0.553 0.553 0.553);
      --gray-10: color(display-p3 0.512 0.512 0.512);
      --gray-11: color(display-p3 0.392 0.392 0.392);
      --gray-12: color(display-p3 0.125 0.125 0.125);
      --gray-a1: color(display-p3 0 0 0/0.012);
      --gray-a2: color(display-p3 0 0 0/0.024);
      --gray-a3: color(display-p3 0 0 0/0.063);
      --gray-a4: color(display-p3 0 0 0/0.09);
      --gray-a5: color(display-p3 0 0 0/0.122);
      --gray-a6: color(display-p3 0 0 0/0.153);
      --gray-a7: color(display-p3 0 0 0/0.192);
      --gray-a8: color(display-p3 0 0 0/0.267);
      --gray-a9: color(display-p3 0 0 0/0.447);
      --gray-a10: color(display-p3 0 0 0/0.486);
      --gray-a11: color(display-p3 0 0 0/0.608);
      --gray-a12: color(display-p3 0 0 0/0.875);
      --mauve-1: color(display-p3 0.991 0.988 0.992);
      --mauve-2: color(display-p3 0.98 0.976 0.984);
      --mauve-3: color(display-p3 0.946 0.938 0.952);
      --mauve-4: color(display-p3 0.915 0.906 0.925);
      --mauve-5: color(display-p3 0.886 0.876 0.901);
      --mauve-6: color(display-p3 0.856 0.846 0.875);
      --mauve-7: color(display-p3 0.814 0.804 0.84);
      --mauve-8: color(display-p3 0.735 0.728 0.777);
      --mauve-9: color(display-p3 0.555 0.549 0.596);
      --mauve-10: color(display-p3 0.514 0.508 0.552);
      --mauve-11: color(display-p3 0.395 0.388 0.424);
      --mauve-12: color(display-p3 0.128 0.122 0.147);
      --mauve-a1: color(display-p3 0.349 0.024 0.349/0.012);
      --mauve-a2: color(display-p3 0.184 0.024 0.349/0.024);
      --mauve-a3: color(display-p3 0.129 0.008 0.255/0.063);
      --mauve-a4: color(display-p3 0.094 0.012 0.216/0.095);
      --mauve-a5: color(display-p3 0.098 0.008 0.224/0.126);
      --mauve-a6: color(display-p3 0.055 0.004 0.18/0.153);
      --mauve-a7: color(display-p3 0.067 0.008 0.184/0.197);
      --mauve-a8: color(display-p3 0.02 0.004 0.176/0.271);
      --mauve-a9: color(display-p3 0.02 0.004 0.106/0.451);
      --mauve-a10: color(display-p3 0.012 0.004 0.09/0.491);
      --mauve-a11: color(display-p3 0.016 0 0.059/0.612);
      --mauve-a12: color(display-p3 0.008 0 0.027/0.879);
      --slate-1: color(display-p3 0.988 0.988 0.992);
      --slate-2: color(display-p3 0.976 0.976 0.984);
      --slate-3: color(display-p3 0.94 0.941 0.953);
      --slate-4: color(display-p3 0.908 0.909 0.925);
      --slate-5: color(display-p3 0.88 0.881 0.901);
      --slate-6: color(display-p3 0.85 0.852 0.876);
      --slate-7: color(display-p3 0.805 0.808 0.838);
      --slate-8: color(display-p3 0.727 0.733 0.773);
      --slate-9: color(display-p3 0.547 0.553 0.592);
      --slate-10: color(display-p3 0.503 0.512 0.549);
      --slate-11: color(display-p3 0.379 0.392 0.421);
      --slate-12: color(display-p3 0.113 0.125 0.14);
      --slate-a1: color(display-p3 0.024 0.024 0.349/0.012);
      --slate-a2: color(display-p3 0.024 0.024 0.349/0.024);
      --slate-a3: color(display-p3 0.004 0.004 0.204/0.059);
      --slate-a4: color(display-p3 0.012 0.012 0.184/0.091);
      --slate-a5: color(display-p3 0.004 0.039 0.2/0.122);
      --slate-a6: color(display-p3 0.008 0.008 0.165/0.15);
      --slate-a7: color(display-p3 0.008 0.027 0.184/0.197);
      --slate-a8: color(display-p3 0.004 0.031 0.176/0.275);
      --slate-a9: color(display-p3 0.004 0.02 0.106/0.455);
      --slate-a10: color(display-p3 0.004 0.027 0.098/0.499);
      --slate-a11: color(display-p3 0 0.02 0.063/0.62);
      --slate-a12: color(display-p3 0 0.012 0.031/0.887);
      --sage-1: color(display-p3 0.986 0.992 0.988);
      --sage-2: color(display-p3 0.97 0.977 0.974);
      --sage-3: color(display-p3 0.935 0.944 0.94);
      --sage-4: color(display-p3 0.904 0.913 0.909);
      --sage-5: color(display-p3 0.875 0.885 0.88);
      --sage-6: color(display-p3 0.844 0.854 0.849);
      --sage-7: color(display-p3 0.8 0.811 0.806);
      --sage-8: color(display-p3 0.725 0.738 0.732);
      --sage-9: color(display-p3 0.531 0.556 0.546);
      --sage-10: color(display-p3 0.492 0.515 0.506);
      --sage-11: color(display-p3 0.377 0.395 0.389);
      --sage-12: color(display-p3 0.107 0.129 0.118);
      --sage-a1: color(display-p3 0.024 0.514 0.267/0.016);
      --sage-a2: color(display-p3 0.02 0.267 0.145/0.032);
      --sage-a3: color(display-p3 0.008 0.184 0.125/0.067);
      --sage-a4: color(display-p3 0.012 0.094 0.051/0.095);
      --sage-a5: color(display-p3 0.008 0.098 0.035/0.126);
      --sage-a6: color(display-p3 0.004 0.078 0.027/0.157);
      --sage-a7: color(display-p3 0 0.059 0.039/0.2);
      --sage-a8: color(display-p3 0.004 0.047 0.031/0.275);
      --sage-a9: color(display-p3 0.004 0.059 0.035/0.471);
      --sage-a10: color(display-p3 0 0.047 0.031/0.51);
      --sage-a11: color(display-p3 0 0.031 0.02/0.624);
      --sage-a12: color(display-p3 0 0.027 0.012/0.895);
      --olive-1: color(display-p3 0.989 0.992 0.989);
      --olive-2: color(display-p3 0.974 0.98 0.973);
      --olive-3: color(display-p3 0.939 0.945 0.937);
      --olive-4: color(display-p3 0.907 0.914 0.905);
      --olive-5: color(display-p3 0.878 0.885 0.875);
      --olive-6: color(display-p3 0.846 0.855 0.843);
      --olive-7: color(display-p3 0.803 0.812 0.8);
      --olive-8: color(display-p3 0.727 0.738 0.723);
      --olive-9: color(display-p3 0.541 0.556 0.532);
      --olive-10: color(display-p3 0.5 0.515 0.491);
      --olive-11: color(display-p3 0.38 0.395 0.374);
      --olive-12: color(display-p3 0.117 0.129 0.111);
      --olive-a1: color(display-p3 0.024 0.349 0.024/0.012);
      --olive-a2: color(display-p3 0.024 0.302 0.024/0.028);
      --olive-a3: color(display-p3 0.008 0.129 0.008/0.063);
      --olive-a4: color(display-p3 0.012 0.094 0.012/0.095);
      --olive-a5: color(display-p3 0.035 0.098 0.008/0.126);
      --olive-a6: color(display-p3 0.027 0.078 0.004/0.157);
      --olive-a7: color(display-p3 0.02 0.059 0/0.2);
      --olive-a8: color(display-p3 0.02 0.059 0.004/0.279);
      --olive-a9: color(display-p3 0.02 0.051 0.004/0.467);
      --olive-a10: color(display-p3 0.024 0.047 0/0.51);
      --olive-a11: color(display-p3 0.012 0.039 0/0.628);
      --olive-a12: color(display-p3 0.008 0.024 0/0.891);
      --sand-1: color(display-p3 0.992 0.992 0.989);
      --sand-2: color(display-p3 0.977 0.977 0.973);
      --sand-3: color(display-p3 0.943 0.942 0.936);
      --sand-4: color(display-p3 0.913 0.912 0.903);
      --sand-5: color(display-p3 0.885 0.883 0.873);
      --sand-6: color(display-p3 0.854 0.852 0.839);
      --sand-7: color(display-p3 0.813 0.81 0.794);
      --sand-8: color(display-p3 0.738 0.734 0.713);
      --sand-9: color(display-p3 0.553 0.553 0.528);
      --sand-10: color(display-p3 0.511 0.511 0.488);
      --sand-11: color(display-p3 0.388 0.388 0.37);
      --sand-12: color(display-p3 0.129 0.126 0.111);
      --sand-a1: color(display-p3 0.349 0.349 0.024/0.012);
      --sand-a2: color(display-p3 0.161 0.161 0.024/0.028);
      --sand-a3: color(display-p3 0.067 0.067 0.008/0.063);
      --sand-a4: color(display-p3 0.129 0.129 0.012/0.099);
      --sand-a5: color(display-p3 0.098 0.067 0.008/0.126);
      --sand-a6: color(display-p3 0.102 0.075 0.004/0.161);
      --sand-a7: color(display-p3 0.098 0.098 0.004/0.208);
      --sand-a8: color(display-p3 0.086 0.075 0.004/0.287);
      --sand-a9: color(display-p3 0.051 0.051 0.004/0.471);
      --sand-a10: color(display-p3 0.047 0.047 0/0.514);
      --sand-a11: color(display-p3 0.031 0.031 0/0.632);
      --sand-a12: color(display-p3 0.024 0.02 0/0.891);
      --gray-surface: color(display-p3 1 1 1/0.8);
      --mauve-surface: color(display-p3 1 1 1/0.8);
      --slate-surface: color(display-p3 1 1 1/0.8);
      --sage-surface: color(display-p3 1 1 1/0.8);
      --olive-surface: color(display-p3 1 1 1/0.8);
      --sand-surface: color(display-p3 1 1 1/0.8);
      --tomato-surface: color(display-p3 0.9922 0.9647 0.9608/0.8);
      --red-surface: color(display-p3 0.9961 0.9647 0.9647/0.8);
      --ruby-surface: color(display-p3 0.9961 0.9647 0.9647/0.8);
      --crimson-surface: color(display-p3 0.9922 0.9608 0.9725/0.8);
      --pink-surface: color(display-p3 0.9922 0.9608 0.9804/0.8);
      --plum-surface: color(display-p3 0.9843 0.9647 0.9843/0.8);
      --purple-surface: color(display-p3 0.9804 0.9647 0.9922/0.8);
      --violet-surface: color(display-p3 0.9725 0.9647 0.9961/0.8);
      --iris-surface: color(display-p3 0.9647 0.9647 0.9961/0.8);
      --indigo-surface: color(display-p3 0.9647 0.9725 0.9961/0.8);
      --blue-surface: color(display-p3 0.9529 0.9765 0.9961/0.8);
      --cyan-surface: color(display-p3 0.9412 0.9765 0.9804/0.8);
      --teal-surface: color(display-p3 0.9451 0.9804 0.9725/0.8);
      --jade-surface: color(display-p3 0.9529 0.9804 0.9608/0.8);
      --green-surface: color(display-p3 0.9569 0.9804 0.9608/0.8);
      --grass-surface: color(display-p3 0.9569 0.9804 0.9569/0.8);
      --brown-surface: color(display-p3 0.9843 0.9725 0.9569/0.8);
      --bronze-surface: color(display-p3 0.9843 0.9608 0.9529/0.8);
      --gold-surface: color(display-p3 0.9765 0.9725 0.9412/0.8);
      --sky-surface: color(display-p3 0.9412 0.9765 0.9843/0.8);
      --mint-surface: color(display-p3 0.9451 0.9804 0.9725/0.8);
      --lime-surface: color(display-p3 0.9725 0.9765 0.9412/0.8);
      --yellow-surface: color(display-p3 0.9961 0.9922 0.902/0.8);
      --amber-surface: color(display-p3 0.9922 0.9843 0.902/0.8);
      --orange-surface: color(display-p3 0.9961 0.9608 0.9176/0.8);
      --black-a1: color(display-p3 0 0 0/0.05);
      --black-a2: color(display-p3 0 0 0/0.1);
      --black-a3: color(display-p3 0 0 0/0.15);
      --black-a4: color(display-p3 0 0 0/0.2);
      --black-a5: color(display-p3 0 0 0/0.3);
      --black-a6: color(display-p3 0 0 0/0.4);
      --black-a7: color(display-p3 0 0 0/0.5);
      --black-a8: color(display-p3 0 0 0/0.6);
      --black-a9: color(display-p3 0 0 0/0.7);
      --black-a10: color(display-p3 0 0 0/0.8);
      --black-a11: color(display-p3 0 0 0/0.9);
      --black-a12: color(display-p3 0 0 0/0.95);
      --white-a1: color(display-p3 1 1 1/0.05);
      --white-a2: color(display-p3 1 1 1/0.1);
      --white-a3: color(display-p3 1 1 1/0.15);
      --white-a4: color(display-p3 1 1 1/0.2);
      --white-a5: color(display-p3 1 1 1/0.3);
      --white-a6: color(display-p3 1 1 1/0.4);
      --white-a7: color(display-p3 1 1 1/0.5);
      --white-a8: color(display-p3 1 1 1/0.6);
      --white-a9: color(display-p3 1 1 1/0.7);
      --white-a10: color(display-p3 1 1 1/0.8);
      --white-a11: color(display-p3 1 1 1/0.9);
      --white-a12: color(display-p3 1 1 1/0.95);
    }
  }
}
/*
@media (prefers-color-scheme: dark) {
  :where(html) {
    --tomato-1: oklch(18.7% 0.012 18.3);
    --tomato-2: oklch(20.8% 0.017 31.4);
    --tomato-3: oklch(25.5% 0.055 26.8);
    --tomato-4: oklch(29.0% 0.087 27.8);
    --tomato-5: oklch(33.1% 0.098 28.6);
    --tomato-6: oklch(38.0% 0.1 29.9);
    --tomato-7: oklch(44.6% 0.106 31.6);
    --tomato-8: oklch(53.8% 0.129 33.4);
    --tomato-9: oklch(62.7% 0.194 33.3);
    --tomato-10: oklch(66.4% 0.179 34.1);
    --tomato-11: oklch(77.9% 0.131 34.9);
    --tomato-12: oklch(89.9% 0.046 31.2);

    --tomato-a1: oklch(60.6% 0.243 28.7 / 3.14%);
    --tomato-a2: oklch(67.8% 0.212 33.2 / 5.88%);
    --tomato-a3: oklch(64.9% 0.237 29.9 / 16.86%);
    --tomato-a4: oklch(63.3% 0.248 29.7 / 25.88%);
    --tomato-a5: oklch(64.6% 0.238 29.9 / 32.55%);
    --tomato-a6: oklch(67.2% 0.216 30.8 / 39.22%);
    --tomato-a7: oklch(69.5% 0.192 31.9 / 49.02%);
    --tomato-a8: oklch(70.8% 0.184 33.7 / 65.49%);
    --tomato-a9: oklch(67.5% 0.212 33.4 / 89.41%);
    --tomato-a10: oklch(70.2% 0.191 33.9 / 92.16%);
    --tomato-a11: oklch(77.9% 0.131 34.9);
    --tomato-a12: oklch(90.9% 0.047 30.9 / 98.43%);

    --red-1: oklch(18.8% 0.013 18.4);
    --red-2: oklch(20.5% 0.022 14.1);
    --red-3: oklch(25.1% 0.065 12.7);
    --red-4: oklch(28.9% 0.095 14.3);
    --red-5: oklch(33.2% 0.107 15.5);
    --red-6: oklch(38.1% 0.111 16.8);
    --red-7: oklch(45.0% 0.121 18.8);
    --red-8: oklch(54.4% 0.146 21.8);
    --red-9: oklch(62.6% 0.193 23);
    --red-10: oklch(66.3% 0.177 22.9);
    --red-11: oklch(78.0% 0.128 22.1);
    --red-12: oklch(90.2% 0.053 6.5);

    --red-a1: oklch(61.2% 0.245 28.7 / 3.53%);
    --red-a2: oklch(62.5% 0.227 23.6 / 6.67%);
    --red-a3: oklch(63.7% 0.249 21.9 / 17.65%);
    --red-a4: oklch(63.1% 0.252 22.2 / 26.67%);
    --red-a5: oklch(64.1% 0.245 20.6 / 33.73%);
    --red-a6: oklch(66.1% 0.226 19.7 / 40.78%);
    --red-a7: oklch(68.2% 0.207 20.1 / 51.76%);
    --red-a8: oklch(69.3% 0.197 22.5 / 69.02%);
    --red-a9: oklch(67.3% 0.212 23.1 / 89.41%);
    --red-a10: oklch(70.2% 0.189 23 / 92.16%);
    --red-a11: oklch(78.0% 0.128 22.1);
    --red-a12: oklch(90.2% 0.053 6.5);

    --ruby-1: oklch(18.9% 0.014 1.9);
    --ruby-2: oklch(20.8% 0.015 3.6);
    --ruby-3: oklch(25.4% 0.061 6);
    --ruby-4: oklch(29.3% 0.089 6.1);
    --ruby-5: oklch(33.4% 0.1 6.5);
    --ruby-6: oklch(38.2% 0.106 7.1);
    --ruby-7: oklch(44.8% 0.116 9.1);
    --ruby-8: oklch(54.3% 0.145 11.3);
    --ruby-9: oklch(62.8% 0.195 13.2);
    --ruby-10: oklch(66.4% 0.18 13.6);
    --ruby-11: oklch(78.1% 0.129 15.1);
    --ruby-12: oklch(90.6% 0.053 355.7);

    --ruby-a1: oklch(61.7% 0.242 18.1 / 3.53%);
    --ruby-a2: oklch(69.4% 0.2 10.4 / 5.49%);
    --ruby-a3: oklch(64.6% 0.244 14.7 / 17.25%);
    --ruby-a4: oklch(63.9% 0.247 13.5 / 25.88%);
    --ruby-a5: oklch(65.2% 0.239 11 / 32.55%);
    --ruby-a6: oklch(67.3% 0.223 10.1 / 39.61%);
    --ruby-a7: oklch(69.2% 0.204 10.7 / 50.20%);
    --ruby-a8: oklch(69.7% 0.198 12.2 / 68.24%);
    --ruby-a9: oklch(67.7% 0.213 13.4 / 89.41%);
    --ruby-a10: oklch(70.3% 0.192 13.7 / 92.16%);
    --ruby-a11: oklch(78.1% 0.129 15.1);
    --ruby-a12: oklch(90.9% 0.053 355.7);

    --crimson-1: oklch(18.9% 0.014 354.2);
    --crimson-2: oklch(20.6% 0.023 354.5);
    --crimson-3: oklch(25.5% 0.06 353.5);
    --crimson-4: oklch(29.3% 0.093 354.3);
    --crimson-5: oklch(33.2% 0.105 354.5);
    --crimson-6: oklch(38.3% 0.108 355.9);
    --crimson-7: oklch(45.0% 0.121 357.1);
    --crimson-8: oklch(54.3% 0.148 358.7);
    --crimson-9: oklch(63.4% 0.213 1.3);
    --crimson-10: oklch(66.3% 0.197 1.8);
    --crimson-11: oklch(78.2% 0.134 4.7);
    --crimson-12: oklch(90.9% 0.054 346.6);

    --crimson-a1: oklch(62.3% 0.244 9.2 / 3.53%);
    --crimson-a2: oklch(63.7% 0.23 4.4 / 6.67%);
    --crimson-a3: oklch(65.9% 0.247 0.3 / 16.47%);
    --crimson-a4: oklch(64.8% 0.256 0.4 / 25.49%);
    --crimson-a5: oklch(65.7% 0.25 358.6 / 31.76%);
    --crimson-a6: oklch(68.1% 0.228 358.4 / 38.82%);
    --crimson-a7: oklch(69.5% 0.211 358.6 / 49.80%);
    --crimson-a8: oklch(70.3% 0.205 359.6 / 67.06%);
    --crimson-a9: oklch(67.5% 0.229 1.5 / 90.98%);
    --crimson-a10: oklch(69.7% 0.209 2.1 / 92.94%);
    --crimson-a11: oklch(78.2% 0.134 4.7);
    --crimson-a12: oklch(91.5% 0.054 346.6 / 99.22%);

    --pink-1: oklch(19.1% 0.017 335.3);
    --pink-2: oklch(20.8% 0.032 337);
    --pink-3: oklch(26.2% 0.063 337.6);
    --pink-4: oklch(29.9% 0.098 339.4);
    --pink-5: oklch(33.8% 0.105 341.2);
    --pink-6: oklch(38.8% 0.107 341.4);
    --pink-7: oklch(45.8% 0.12 342.9);
    --pink-8: oklch(54.6% 0.145 344);
    --pink-9: oklch(61.7% 0.208 346);
    --pink-10: oklch(64.9% 0.197 346);
    --pink-11: oklch(78.5% 0.155 347);
    --pink-12: oklch(90.5% 0.059 343.2);

    --pink-a1: oklch(65.2% 0.275 342.4 / 3.53%);
    --pink-a2: oklch(65.6% 0.268 342.9 / 7.06%);
    --pink-a3: oklch(69.1% 0.267 340.7 / 16.08%);
    --pink-a4: oklch(67.2% 0.278 342.1 / 24.71%);
    --pink-a5: oklch(68.4% 0.263 343.3 / 30.59%);
    --pink-a6: oklch(70.7% 0.237 342.9 / 37.25%);
    --pink-a7: oklch(72.5% 0.216 343.5 / 48.24%);
    --pink-a8: oklch(73.4% 0.209 344.4 / 63.53%);
    --pink-a9: oklch(69.7% 0.241 346.2 / 83.14%);
    --pink-a10: oklch(71.8% 0.221 346.3 / 86.27%);
    --pink-a11: oklch(78.5% 0.155 347);
    --pink-a12: oklch(91.2% 0.059 343.2 / 99.22%);

    --plum-1: oklch(19.0% 0.018 326.2);
    --plum-2: oklch(21.0% 0.032 326.6);
    --plum-3: oklch(26.7% 0.061 327.1);
    --plum-4: oklch(30.7% 0.087 325.6);
    --plum-5: oklch(34.4% 0.097 325);
    --plum-6: oklch(38.9% 0.097 325);
    --plum-7: oklch(45.6% 0.107 323.2);
    --plum-8: oklch(54.5% 0.128 321.9);
    --plum-9: oklch(57.9% 0.188 322.1);
    --plum-10: oklch(61.6% 0.182 322.3);
    --plum-11: oklch(78.6% 0.154 322.2);
    --plum-12: oklch(90.6% 0.055 326);

    --plum-a1: oklch(67.5% 0.305 328.3 / 3.14%);
    --plum-a2: oklch(68.8% 0.292 328.2 / 6.67%);
    --plum-a3: oklch(72.9% 0.279 328 / 15.29%);
    --plum-a4: oklch(71.6% 0.283 325.9 / 22.75%);
    --plum-a5: oklch(72.5% 0.267 325.1 / 28.24%);
    --plum-a6: oklch(75.3% 0.237 325.2 / 33.73%);
    --plum-a7: oklch(76.3% 0.21 323.5 / 43.92%);
    --plum-a8: oklch(77.3% 0.199 321.9 / 58.43%);
    --plum-a9: oklch(72.3% 0.246 322.1 / 71.37%);
    --plum-a10: oklch(74.5% 0.228 322.5 / 75.29%);
    --plum-a11: oklch(81.1% 0.16 322.1 / 95.29%);
    --plum-a12: oklch(93.5% 0.056 326 / 95.69%);

    --purple-1: oklch(19.1% 0.022 315.6);
    --purple-2: oklch(21.4% 0.03 313.1);
    --purple-3: oklch(26.8% 0.061 312.6);
    --purple-4: oklch(30.9% 0.082 310.8);
    --purple-5: oklch(34.5% 0.092 310.5);
    --purple-6: oklch(38.9% 0.096 309.5);
    --purple-7: oklch(44.9% 0.108 308.6);
    --purple-8: oklch(54.1% 0.133 307.6);
    --purple-9: oklch(55.6% 0.183 305.9);
    --purple-10: oklch(59.6% 0.177 306.5);
    --purple-11: oklch(78.1% 0.145 307.7);
    --purple-12: oklch(91.1% 0.049 311);

    --purple-a1: oklch(59.3% 0.292 310.9 / 4.31%);
    --purple-a2: oklch(62.6% 0.256 310.3 / 7.84%);
    --purple-a3: oklch(65.6% 0.253 310.9 / 17.65%);
    --purple-a4: oklch(65.0% 0.246 309.2 / 25.88%);
    --purple-a5: oklch(66.4% 0.235 309.4 / 31.76%);
    --purple-a6: oklch(68.9% 0.213 308.8 / 38.04%);
    --purple-a7: oklch(70.7% 0.197 308.2 / 47.84%);
    --purple-a8: oklch(71.8% 0.191 307.3 / 64.31%);
    --purple-a9: oklch(66.3% 0.229 305.8 / 76.08%);
    --purple-a10: oklch(68.8% 0.211 306.3 / 80.39%);
    --purple-a11: oklch(78.1% 0.145 307.7);
    --purple-a12: oklch(92.4% 0.05 311.5 / 98.04%);

    --violet-1: oklch(19.1% 0.026 290.8);
    --violet-2: oklch(21.1% 0.032 300.9);
    --violet-3: oklch(27.1% 0.065 294.4);
    --violet-4: oklch(31.2% 0.093 292.1);
    --violet-5: oklch(34.9% 0.099 291.3);
    --violet-6: oklch(38.9% 0.102 292.1);
    --violet-7: oklch(44.5% 0.11 292);
    --violet-8: oklch(51.8% 0.131 290.3);
    --violet-9: oklch(54.2% 0.179 288);
    --violet-10: oklch(58.9% 0.169 289.4);
    --violet-11: oklch(77.8% 0.125 293.2);
    --violet-12: oklch(91.2% 0.045 292.4);

    --violet-a1: oklch(49.1% 0.289 274.4 / 5.88%);
    --violet-a2: oklch(56.6% 0.255 293.7 / 8.63%);
    --violet-a3: oklch(59.3% 0.238 289.9 / 21.18%);
    --violet-a4: oklch(58.3% 0.24 288.3 / 31.37%);
    --violet-a5: oklch(60.9% 0.223 288.7 / 37.25%);
    --violet-a6: oklch(63.8% 0.207 290.5 / 42.75%);
    --violet-a7: oklch(66.8% 0.191 291 / 51.37%);
    --violet-a8: oklch(67.3% 0.184 289.8 / 65.88%);
    --violet-a9: oklch(62.6% 0.214 287.7 / 80.00%);
    --violet-a10: oklch(65.7% 0.194 289.1 / 84.31%);
    --violet-a11: oklch(77.8% 0.125 293.2);
    --violet-a12: oklch(91.5% 0.045 292.4);

    --iris-1: oklch(19.2% 0.022 284.1);
    --iris-2: oklch(20.9% 0.029 286.6);
    --iris-3: oklch(27.2% 0.069 278.5);
    --iris-4: oklch(31.8% 0.102 276);
    --iris-5: oklch(35.7% 0.11 277.3);
    --iris-6: oklch(40.0% 0.112 279.5);
    --iris-7: oklch(44.8% 0.12 280.4);
    --iris-8: oklch(50.7% 0.138 280.8);
    --iris-9: oklch(54.0% 0.184 278.3);
    --iris-10: oklch(58.7% 0.172 281.3);
    --iris-11: oklch(77.4% 0.122 287.5);
    --iris-12: oklch(91.4% 0.042 287);

    --iris-a1: oklch(50.1% 0.279 270.4 / 5.49%);
    --iris-a2: oklch(54.1% 0.247 277 / 8.63%);
    --iris-a3: oklch(56.8% 0.238 273.5 / 23.14%);
    --iris-a4: oklch(56.0% 0.243 272.6 / 35.29%);
    --iris-a5: oklch(58.3% 0.226 274.9 / 41.96%);
    --iris-a6: oklch(61.7% 0.206 277.9 / 47.84%);
    --iris-a7: oklch(63.8% 0.196 279.3 / 55.69%);
    --iris-a8: oklch(64.6% 0.191 280 / 67.45%);
    --iris-a9: oklch(60.7% 0.214 277.9 / 83.14%);
    --iris-a10: oklch(64.7% 0.193 280.9 / 86.27%);
    --iris-a11: oklch(77.4% 0.122 287.5);
    --iris-a12: oklch(91.7% 0.042 287);

    --indigo-1: oklch(19.1% 0.025 276.5);
    --indigo-2: oklch(20.9% 0.03 274.8);
    --indigo-3: oklch(27.2% 0.071 268);
    --indigo-4: oklch(31.8% 0.095 267.2);
    --indigo-5: oklch(36.2% 0.104 267);
    --indigo-6: oklch(40.3% 0.111 268.8);
    --indigo-7: oklch(44.9% 0.12 268.9);
    --indigo-8: oklch(50.2% 0.137 268.3);
    --indigo-9: oklch(54.4% 0.191 267);
    --indigo-10: oklch(58.9% 0.176 269.3);
    --indigo-11: oklch(77.6% 0.114 273);
    --indigo-12: oklch(91.1% 0.043 269.6);

    --indigo-a1: oklch(48.7% 0.289 265.1 / 5.88%);
    --indigo-a2: oklch(53.7% 0.247 267.6 / 9.02%);
    --indigo-a3: oklch(56.4% 0.239 265.3 / 23.53%);
    --indigo-a4: oklch(57.3% 0.233 265.6 / 34.12%);
    --indigo-a5: oklch(59.7% 0.215 265.8 / 41.96%);
    --indigo-a6: oklch(61.7% 0.203 267.7 / 48.63%);
    --indigo-a7: oklch(63.6% 0.194 268.3 / 56.47%);
    --indigo-a8: oklch(64.0% 0.19 268 / 67.45%);
    --indigo-a9: oklch(60.0% 0.216 266.9 / 85.88%);
    --indigo-a10: oklch(63.5% 0.194 269.1 / 89.02%);
    --indigo-a11: oklch(77.6% 0.114 273);
    --indigo-a12: oklch(91.1% 0.043 269.6);

    --blue-1: oklch(19.4% 0.025 256.5);
    --blue-2: oklch(21.3% 0.03 261.3);
    --blue-3: oklch(27.4% 0.066 253.9);
    --blue-4: oklch(32.0% 0.097 252.3);
    --blue-5: oklch(36.7% 0.106 250.7);
    --blue-6: oklch(41.6% 0.113 252);
    --blue-7: oklch(47.4% 0.122 253.1);
    --blue-8: oklch(54.1% 0.14 253.2);
    --blue-9: oklch(64.9% 0.193 251.8);
    --blue-10: oklch(68.8% 0.169 251.4);
    --blue-11: oklch(76.4% 0.126 249.5);
    --blue-12: oklch(90.7% 0.051 238.4);

    --blue-a1: oklch(50.7% 0.253 262.9 / 6.67%);
    --blue-a2: oklch(56.2% 0.235 261.4 / 9.41%);
    --blue-a3: oklch(59.7% 0.221 258 / 22.75%);
    --blue-a4: oklch(59.3% 0.223 258.4 / 34.12%);
    --blue-a5: oklch(61.6% 0.207 255.6 / 41.96%);
    --blue-a6: oklch(63.4% 0.197 254.1 / 49.80%);
    --blue-a7: oklch(65.6% 0.186 253.8 / 59.61%);
    --blue-a8: oklch(66.3% 0.181 253.5 / 72.55%);
    --blue-a9: oklch(64.9% 0.193 251.8);
    --blue-a10: oklch(68.8% 0.169 251.4);
    --blue-a11: oklch(76.4% 0.126 249.5);
    --blue-a12: oklch(90.7% 0.051 238.4);

    --cyan-1: oklch(19.2% 0.018 222.8);
    --cyan-2: oklch(21.4% 0.019 227.9);
    --cyan-3: oklch(27.3% 0.043 220.4);
    --cyan-4: oklch(31.6% 0.059 223.7);
    --cyan-5: oklch(36.3% 0.068 223.7);
    --cyan-6: oklch(41.4% 0.075 221.5);
    --cyan-7: oklch(47.8% 0.083 221.6);
    --cyan-8: oklch(55.7% 0.099 221.1);
    --cyan-9: oklch(66.0% 0.122 221.7);
    --cyan-10: oklch(69.9% 0.119 218.9);
    --cyan-11: oklch(78.5% 0.116 213.8);
    --cyan-12: oklch(91.0% 0.057 211.5);

    --cyan-a1: oklch(64.6% 0.182 249.9 / 03.92%);
    --cyan-a2: oklch(69.3% 0.157 239.3 / 06.67%);
    --cyan-a3: oklch(75.2% 0.152 231.4 / 15.69%);
    --cyan-a4: oklch(74.4% 0.156 234.3 / 23.14%);
    --cyan-a5: oklch(75.2% 0.152 231.4 / 30.20%);
    --cyan-a6: oklch(77.3% 0.148 226.3 / 36.86%);
    --cyan-a7: oklch(78.9% 0.146 224.1 / 45.88%);
    --cyan-a8: oklch(79.3% 0.146 222.8 / 58.43%);
    --cyan-a9: oklch(79.3% 0.147 222.5 / 76.47%);
    --cyan-a10: oklch(81.2% 0.141 219.3 / 80.39%);
    --cyan-a11: oklch(84.5% 0.126 213.9 / 89.80%);
    --cyan-a12: oklch(92.9% 0.059 211 / 96.86%);

    --teal-1: oklch(18.7% 0.012 186.7);
    --teal-2: oklch(21.6% 0.016 189);
    --teal-3: oklch(27.3% 0.038 186.8);
    --teal-4: oklch(31.8% 0.054 187.3);
    --teal-5: oklch(36.3% 0.06 186.6);
    --teal-6: oklch(41.4% 0.066 184.7);
    --teal-7: oklch(47.3% 0.074 185);
    --teal-8: oklch(53.7% 0.086 183.5);
    --teal-9: oklch(64.9% 0.114 182);
    --teal-10: oklch(68.8% 0.123 180.3);
    --teal-11: oklch(78.9% 0.147 175.7);
    --teal-12: oklch(90.5% 0.072 175.1);

    --teal-a1: oklch(80.0% 0.161 168.6 / 1.96%);
    --teal-a2: oklch(88.9% 0.156 184.1 / 4.71%);
    --teal-a3: oklch(89.8% 0.16 182.4 / 11.76%);
    --teal-a4: oklch(89.8% 0.159 183.8 / 17.65%);
    --teal-a5: oklch(89.9% 0.159 184.3 / 23.14%);
    --teal-a6: oklch(89.9% 0.157 183.2 / 29.41%);
    --teal-a7: oklch(89.6% 0.152 184 / 37.25%);
    --teal-a8: oklch(90.1% 0.154 182.5 / 45.88%);
    --teal-a9: oklch(89.8% 0.16 181.4 / 62.35%);
    --teal-a10: oklch(89.6% 0.162 179.6 / 68.24%);
    --teal-a11: oklch(89.1% 0.167 175.2 / 83.92%);
    --teal-a12: oklch(94.8% 0.075 175.2 / 93.73%);

    --jade-1: oklch(18.6% 0.014 169.8);
    --jade-2: oklch(21.5% 0.017 168.2);
    --jade-3: oklch(27.4% 0.043 165.2);
    --jade-4: oklch(31.6% 0.057 167.6);
    --jade-5: oklch(36.1% 0.064 168.2);
    --jade-6: oklch(41.3% 0.069 169.6);
    --jade-7: oklch(46.8% 0.076 170.3);
    --jade-8: oklch(53.7% 0.087 172.2);
    --jade-9: oklch(64.2% 0.115 170.7);
    --jade-10: oklch(67.8% 0.126 169.6);
    --jade-11: oklch(78.5% 0.156 167.1);
    --jade-12: oklch(90.3% 0.078 166.9);

    --jade-a1: oklch(78.3% 0.242 145.6 / 1.96%);
    --jade-a2: oklch(87.4% 0.194 159.1 / 4.71%);
    --jade-a3: oklch(86.4% 0.206 156.8 / 12.55%);
    --jade-a4: oklch(88.2% 0.199 160.1 / 17.65%);
    --jade-a5: oklch(88.6% 0.189 163.5 / 23.14%);
    --jade-a6: oklch(89.2% 0.174 166.6 / 29.41%);
    --jade-a7: oklch(89.1% 0.164 168.4 / 36.86%);
    --jade-a8: oklch(89.8% 0.16 170.9 / 45.88%);
    --jade-a9: oklch(89.2% 0.166 169.8 / 61.57%);
    --jade-a10: oklch(89.0% 0.17 168.9 / 67.06%);
    --jade-a11: oklch(88.7% 0.178 166.7 / 83.92%);
    --jade-a12: oklch(94.5% 0.081 166.6 / 93.73%);

    --green-1: oklch(18.7% 0.012 167);
    --green-2: oklch(21.2% 0.015 165.9);
    --green-3: oklch(27.2% 0.04 162.2);
    --green-4: oklch(31.7% 0.057 161.8);
    --green-5: oklch(36.5% 0.066 161.2);
    --green-6: oklch(41.2% 0.072 160.9);
    --green-7: oklch(46.7% 0.082 160.2);
    --green-8: oklch(52.8% 0.096 159.4);
    --green-9: oklch(64.1% 0.133 157.7);
    --green-10: oklch(67.5% 0.141 157.7);
    --green-11: oklch(77.9% 0.165 157.3);
    --green-12: oklch(90.5% 0.083 158.2);

    --green-a1: oklch(78.3% 0.242 145.6 / 1.96%);
    --green-a2: oklch(86.7% 0.199 157 / 4.31%);
    --green-a3: oklch(88.1% 0.211 155.4 / 11.76%);
    --green-a4: oklch(88.0% 0.212 155.8 / 17.65%);
    --green-a5: oklch(88.4% 0.202 157.3 / 23.53%);
    --green-a6: oklch(88.9% 0.189 158.3 / 29.41%);
    --green-a7: oklch(88.7% 0.181 158.5 / 36.86%);
    --green-a8: oklch(89.3% 0.181 158.1 / 45.10%);
    --green-a9: oklch(88.8% 0.194 156.7 / 61.96%);
    --green-a10: oklch(88.5% 0.193 156.9 / 67.06%);
    --green-a11: oklch(88.6% 0.191 157 / 83.14%);
    --green-a12: oklch(94.4% 0.087 158.4 / 94.12%);

    --grass-1: oklch(18.7% 0.013 159.7);
    --grass-2: oklch(21.0% 0.013 149.9);
    --grass-3: oklch(26.7% 0.03 150.2);
    --grass-4: oklch(31.9% 0.053 150.3);
    --grass-5: oklch(36.7% 0.062 149.9);
    --grass-6: oklch(41.6% 0.072 149.3);
    --grass-7: oklch(46.8% 0.083 149);
    --grass-8: oklch(52.3% 0.097 148.3);
    --grass-9: oklch(65.1% 0.147 147.4);
    --grass-10: oklch(68.9% 0.145 147.8);
    --grass-11: oklch(78.0% 0.142 148.5);
    --grass-12: oklch(91.1% 0.078 144.9);

    --grass-a1: oklch(78.1% 0.263 142.8 / 1.96%);
    --grass-a2: oklch(86.7% 0.213 146.5 / 3.92%);
    --grass-a3: oklch(89.2% 0.198 147.8 / 10.59%);
    --grass-a4: oklch(88.6% 0.219 148 / 17.25%);
    --grass-a5: oklch(89.2% 0.203 148.4 / 23.14%);
    --grass-a6: oklch(89.6% 0.196 148.2 / 29.41%);
    --grass-a7: oklch(89.3% 0.188 148.1 / 36.47%);
    --grass-a8: oklch(89.3% 0.189 147.7 / 43.92%);
    --grass-a9: oklch(89.0% 0.212 147.1 / 63.14%);
    --grass-a10: oklch(89.6% 0.197 147.7 / 68.24%);
    --grass-a11: oklch(90.7% 0.169 148.3 / 80.39%);
    --grass-a12: oklch(95.3% 0.082 144.9 / 93.73%);

    --orange-1: oklch(18.7% 0.011 60.7);
    --orange-2: oklch(20.8% 0.019 62.7);
    --orange-3: oklch(25.8% 0.045 60.8);
    --orange-4: oklch(29.4% 0.071 56.7);
    --orange-5: oklch(33.4% 0.083 54.4);
    --orange-6: oklch(38.5% 0.087 55);
    --orange-7: oklch(45.2% 0.095 52.7);
    --orange-8: oklch(54.1% 0.116 50);
    --orange-9: oklch(69.1% 0.191 45);
    --orange-10: oklch(73.3% 0.182 50.9);
    --orange-11: oklch(78.9% 0.143 56.2);
    --orange-12: oklch(92.5% 0.052 66.2);

    --orange-a1: oklch(61.5% 0.223 33.8 / 2.75%);
    --orange-a2: oklch(70.3% 0.198 45.9 / 5.49%);
    --orange-a3: oklch(69.5% 0.197 45.4 / 14.51%);
    --orange-a4: oklch(68.0% 0.214 39.8 / 22.35%);
    --orange-a5: oklch(69.0% 0.208 42 / 29.02%);
    --orange-a6: oklch(71.3% 0.191 48.8 / 36.08%);
    --orange-a7: oklch(73.8% 0.177 50.4 / 45.88%);
    --orange-a8: oklch(73.9% 0.171 48.9 / 61.57%);
    --orange-a9: oklch(70.4% 0.196 44.7 / 96.86%);
    --orange-a10: oklch(73.3% 0.182 50.9);
    --orange-a11: oklch(78.9% 0.143 56.2);
    --orange-a12: oklch(92.5% 0.052 66.2);

    --brown-1: oklch(17.8% 0.004 84.6);
    --brown-2: oklch(21.3% 0.007 48.4);
    --brown-3: oklch(25.4% 0.013 51.6);
    --brown-4: oklch(28.9% 0.018 59.9);
    --brown-5: oklch(32.5% 0.024 56.4);
    --brown-6: oklch(37.1% 0.032 59);
    --brown-7: oklch(42.9% 0.041 57.8);
    --brown-8: oklch(50.9% 0.053 62);
    --brown-9: oklch(63.3% 0.078 61);
    --brown-10: oklch(67.3% 0.074 61.1);
    --brown-11: oklch(79.8% 0.063 62.7);
    --brown-12: oklch(91.7% 0.036 75.5);

    --brown-a1: oklch(42.0% 0.162 31.2 / 0.78%);
    --brown-a2: oklch(80.0% 0.116 46.9 / 4.71%);
    --brown-a3: oklch(83.0% 0.099 50.9 / 9.80%);
    --brown-a4: oklch(84.0% 0.098 58.4 / 14.12%);
    --brown-a5: oklch(83.5% 0.1 55.5 / 19.22%);
    --brown-a6: oklch(84.0% 0.102 58.1 / 25.49%);
    --brown-a7: oklch(84.4% 0.103 57.7 / 33.73%);
    --brown-a8: oklch(84.9% 0.103 61 / 45.10%);
    --brown-a9: oklch(83.5% 0.111 60.6 / 65.88%);
    --brown-a10: oklch(85.5% 0.099 61.4 / 70.20%);
    --brown-a11: oklch(89.0% 0.072 62.6 / 85.10%);
    --brown-a12: oklch(95.1% 0.037 75.2 / 94.90%);

    --sky-1: oklch(19.0% 0.025 259.2);
    --sky-2: oklch(21.6% 0.029 257.5);
    --sky-3: oklch(27.1% 0.054 251.3);
    --sky-4: oklch(32.1% 0.07 248.7);
    --sky-5: oklch(37.3% 0.078 244.9);
    --sky-6: oklch(42.6% 0.088 243.9);
    --sky-7: oklch(48.8% 0.098 240.8);
    --sky-8: oklch(55.7% 0.115 237.4);
    --sky-9: oklch(86.1% 0.103 217.8);
    --sky-10: oklch(90.8% 0.073 214.5);
    --sky-11: oklch(79.3% 0.099 231.6);
    --sky-12: oklch(93.4% 0.053 214.3);

    --sky-a1: oklch(50.7% 0.277 263.7 / 5.88%);
    --sky-a2: oklch(58.3% 0.222 259.5 / 9.41%);
    --sky-a3: oklch(62.3% 0.201 255.4 / 20.00%);
    --sky-a4: oklch(64.8% 0.193 252.8 / 28.63%);
    --sky-a5: oklch(67.9% 0.175 248.1 / 36.47%);
    --sky-a6: oklch(70.0% 0.167 246.2 / 44.71%);
    --sky-a7: oklch(71.8% 0.159 242.5 / 54.51%);
    --sky-a8: oklch(72.7% 0.158 239 / 66.27%);
    --sky-a9: oklch(86.4% 0.103 217.6);
    --sky-a10: oklch(90.8% 0.073 214.5);
    --sky-a11: oklch(82.8% 0.104 231.9 / 93.73%);
    --sky-a12: oklch(93.4% 0.053 214.3);

    --mint-1: oklch(18.9% 0.011 196.3);
    --mint-2: oklch(21.1% 0.017 195.9);
    --mint-3: oklch(26.8% 0.039 192.4);
    --mint-4: oklch(31.5% 0.054 191);
    --mint-5: oklch(35.9% 0.062 190);
    --mint-6: oklch(41.1% 0.066 186.2);
    --mint-7: oklch(47.0% 0.073 183.5);
    --mint-8: oklch(54.1% 0.085 179.2);
    --mint-9: oklch(87.0% 0.1 178);
    --mint-10: oklch(91.6% 0.079 180);
    --mint-11: oklch(79.5% 0.118 176.5);
    --mint-12: oklch(93.1% 0.057 168.3);

    --mint-a1: oklch(81.5% 0.139 194.8 / 1.96%);
    --mint-a2: oklch(88.9% 0.152 194.8 / 4.31%);
    --mint-a3: oklch(90.2% 0.156 190.2 / 11.37%);
    --mint-a4: oklch(90.2% 0.156 189.2 / 17.25%);
    --mint-a5: oklch(90.1% 0.157 188.2 / 22.75%);
    --mint-a6: oklch(89.9% 0.158 184.8 / 29.02%);
    --mint-a7: oklch(89.6% 0.152 182.4 / 36.86%);
    --mint-a8: oklch(90.1% 0.153 178.3 / 46.27%);
    --mint-a9: oklch(92.8% 0.107 177.9 / 91.37%);
    --mint-a10: oklch(94.1% 0.082 179.7 / 96.08%);
    --mint-a11: oklch(91.0% 0.138 176.2 / 82.35%);
    --mint-a12: oklch(95.6% 0.059 168.1 / 96.08%);

    --lime-1: oklch(18.2% 0.014 120.8);
    --lime-2: oklch(20.9% 0.02 129.6);
    --lime-3: oklch(26.6% 0.035 132);
    --lime-4: oklch(31.6% 0.047 131.7);
    --lime-5: oklch(36.3% 0.057 131);
    --lime-6: oklch(41.0% 0.068 131.5);
    --lime-7: oklch(46.3% 0.079 131.1);
    --lime-8: oklch(52.4% 0.094 130.6);
    --lime-9: oklch(88.7% 0.175 126.1);
    --lime-10: oklch(94.2% 0.176 123.8);
    --lime-11: oklch(86.8% 0.155 124.7);
    --lime-12: oklch(94.6% 0.082 122.7);

    --lime-a1: oklch(68.7% 0.232 142.2 / 1.18%);
    --lime-a2: oklch(86.8% 0.26 136.3 / 3.92%);
    --lime-a3: oklch(90.1% 0.226 134.2 / 10.20%);
    --lime-a4: oklch(91.0% 0.211 133.2 / 16.08%);
    --lime-a5: oklch(91.6% 0.2 132.2 / 21.57%);
    --lime-a6: oklch(91.8% 0.192 132.3 / 27.45%);
    --lime-a7: oklch(92.3% 0.19 131.7 / 34.12%);
    --lime-a8: oklch(91.8% 0.189 131.2 / 42.35%);
    --lime-a9: oklch(93.4% 0.186 126.2 / 92.94%);
    --lime-a10: oklch(94.2% 0.176 123.8);
    --lime-a11: oklch(93.8% 0.17 124.9 / 89.41%);
    --lime-a12: oklch(96.6% 0.084 122.9 / 96.86%);

    --yellow-1: oklch(18.2% 0.014 94);
    --yellow-2: oklch(20.9% 0.017 91.8);
    --yellow-3: oklch(26.1% 0.047 90.3);
    --yellow-4: oklch(29.3% 0.06 93.8);
    --yellow-5: oklch(33.5% 0.068 92.5);
    --yellow-6: oklch(38.5% 0.078 92.9);
    --yellow-7: oklch(45.2% 0.081 92);
    --yellow-8: oklch(53.5% 0.095 89.4);
    --yellow-9: oklch(91.8% 0.184 100.9);
    --yellow-10: oklch(97.1% 0.182 109.4);
    --yellow-11: oklch(90.0% 0.166 101.7);
    --yellow-12: oklch(94.2% 0.075 101.1);

    --yellow-a1: oklch(59.7% 0.177 43.1 / 1.57%);
    --yellow-a2: oklch(81.3% 0.168 80.7 / 4.31%);
    --yellow-a3: oklch(80.2% 0.171 73.3 / 11.76%);
    --yellow-a4: oklch(82.3% 0.17 80.8 / 15.69%);
    --yellow-a5: oklch(83.2% 0.171 82.4 / 21.18%);
    --yellow-a6: oklch(84.9% 0.174 86.9 / 27.45%);
    --yellow-a7: oklch(86.2% 0.17 89.9 / 36.08%);
    --yellow-a8: oklch(86.1% 0.164 88.5 / 48.24%);
    --yellow-a9: oklch(91.8% 0.184 100.9);
    --yellow-a10: oklch(97.1% 0.182 109.4);
    --yellow-a11: oklch(92.4% 0.171 101.6 / 96.08%);
    --yellow-a12: oklch(96.5% 0.077 101.3 / 96.47%);

    --amber-1: oklch(18.5% 0.013 77.8);
    --amber-2: oklch(21.2% 0.018 82);
    --amber-3: oklch(25.8% 0.044 74.5);
    --amber-4: oklch(29.6% 0.063 73.9);
    --amber-5: oklch(33.6% 0.072 72.9);
    --amber-6: oklch(38.6% 0.078 75.3);
    --amber-7: oklch(45.4% 0.082 75);
    --amber-8: oklch(53.6% 0.096 73.4);
    --amber-9: oklch(85.4% 0.157 84.1);
    --amber-10: oklch(88.5% 0.181 94.8);
    --amber-11: oklch(86.2% 0.173 89);
    --amber-12: oklch(93.5% 0.072 86.1);

    --amber-a1: oklch(60.9% 0.213 35.1 / 2.35%);
    --amber-a2: oklch(77.3% 0.172 66.2 / 5.10%);
    --amber-a3: oklch(72.8% 0.18 55 / 13.33%);
    --amber-a4: oklch(73.1% 0.182 54.5 / 19.61%);
    --amber-a5: oklch(74.6% 0.178 58.4 / 25.49%);
    --amber-a6: oklch(77.3% 0.172 66.2 / 31.76%);
    --amber-a7: oklch(80.4% 0.164 71.9 / 40.39%);
    --amber-a8: oklch(81.0% 0.158 71.9 / 52.94%);
    --amber-a9: oklch(85.4% 0.157 84.1);
    --amber-a10: oklch(88.5% 0.181 94.8);
    --amber-a11: oklch(86.2% 0.173 89);
    --amber-a12: oklch(93.5% 0.072 86.1);

    --gold-1: oklch(18.2% 0.002 106.6);
    --gold-2: oklch(21.8% 0.006 91.6);
    --gold-3: oklch(25.6% 0.007 95.4);
    --gold-4: oklch(28.9% 0.009 88.8);
    --gold-5: oklch(33.0% 0.013 87.6);
    --gold-6: oklch(37.3% 0.013 81.7);
    --gold-7: oklch(43.0% 0.016 82.4);
    --gold-8: oklch(49.9% 0.021 81.3);
    --gold-9: oklch(62.0% 0.049 77.7);
    --gold-10: oklch(66.3% 0.047 78.3);
    --gold-11: oklch(79.4% 0.041 77.1);
    --gold-12: oklch(91.5% 0.014 78.3);

    --gold-a1: oklch(63.6% 0.134 109.7 / 0.78%);
    --gold-a2: oklch(91.6% 0.091 91.8 / 4.31%);
    --gold-a3: oklch(94.1% 0.065 95.9 / 08.24%);
    --gold-a4: oklch(95.2% 0.057 88.6 / 11.76%);
    --gold-a5: oklch(94.7% 0.058 87.6 / 16.47%);
    --gold-a6: oklch(94.7% 0.047 80.8 / 21.57%);
    --gold-a7: oklch(95.2% 0.046 82 / 28.24%);
    --gold-a8: oklch(94.4% 0.047 80.8 / 37.25%);
    --gold-a9: oklch(90.9% 0.079 77.1 / 56.47%);
    --gold-a10: oklch(91.8% 0.07 78 / 61.57%);
    --gold-a11: oklch(93.8% 0.05 76.9 / 78.43%);
    --gold-a12: oklch(97.9% 0.015 77.1 / 90.59%);

    --bronze-1: oklch(18.1% 0.005 39.3);
    --bronze-2: oklch(21.6% 0.006 56);
    --bronze-3: oklch(25.6% 0.007 48.4);
    --bronze-4: oklch(29.0% 0.01 48.3);
    --bronze-5: oklch(32.9% 0.013 41.7);
    --bronze-6: oklch(37.4% 0.017 41);
    --bronze-7: oklch(42.9% 0.021 40.6);
    --bronze-8: oklch(49.9% 0.024 45);
    --bronze-9: oklch(62.7% 0.046 44.2);
    --bronze-10: oklch(66.8% 0.046 43.5);
    --bronze-11: oklch(79.2% 0.043 44.3);
    --bronze-12: oklch(91.5% 0.017 50.4);

    --bronze-a1: oklch(54.5% 0.218 30.1 / 1.57%);
    --bronze-a2: oklch(84.3% 0.092 55.4 / 4.71%);
    --bronze-a3: oklch(88.4% 0.058 48 / 9.02%);
    --bronze-a4: oklch(88.1% 0.06 48.6 / 13.33%);
    --bronze-a5: oklch(89.8% 0.056 41.7 / 17.65%);
    --bronze-a6: oklch(89.6% 0.057 41.3 / 23.53%);
    --bronze-a7: oklch(89.2% 0.056 40.5 / 30.98%);
    --bronze-a8: oklch(90.6% 0.052 43.8 / 39.61%);
    --bronze-a9: oklch(87.3% 0.07 43.8 / 60.78%);
    --bronze-a10: oklch(87.9% 0.066 43.1 / 66.27%);
    --bronze-a11: oklch(90.9% 0.051 44.3 / 81.96%);
    --bronze-a12: oklch(96.7% 0.019 52 / 92.55%);

    --gray-1: oklch(17.76% 0 0);
    --gray-2: oklch(21.34% 0 0);
    --gray-3: oklch(25.2% 0 0);
    --gray-4: oklch(28.5% 0 0);
    --gray-5: oklch(31.3% 0 0);
    --gray-6: oklch(34.8% 0 0);
    --gray-7: oklch(40.2% 0 0);
    --gray-8: oklch(48.9% 0 0);
    --gray-9: oklch(53.8% 0 0);
    --gray-10: oklch(58.3% 0 0);
    --gray-11: oklch(77.0% 0 0);
    --gray-12: oklch(94.9% 0 0);

    --gray-a1: oklch(0% 0 0 / 0);
    --gray-a2: oklch(100% 0 0 / 3.53%);
    --gray-a3: oklch(100% 0 0 / 7.06%);
    --gray-a4: oklch(100% 0 0 / 10.59%);
    --gray-a5: oklch(100% 0 0 / 13.33%);
    --gray-a6: oklch(100% 0 0 / 17.25%);
    --gray-a7: oklch(100% 0 0 / 23.14%);
    --gray-a8: oklch(100% 0 0 / 33.33%);
    --gray-a9: oklch(100% 0 0 / 39.22%);
    --gray-a10: oklch(100% 0 0 / 44.71%);
    --gray-a11: oklch(100% 0 0 / 68.63%);
    --gray-a12: oklch(100% 0 0 / 92.94%);

    --mauve-1: oklch(18.0% 0.004 308.2);
    --mauve-2: oklch(21.5% 0.004 308.2);
    --mauve-3: oklch(25.4% 0.006 301.1);
    --mauve-4: oklch(28.5% 0.008 308.2);
    --mauve-5: oklch(31.3% 0.009 303.9);
    --mauve-6: oklch(35.0% 0.011 308.1);
    --mauve-7: oklch(40.2% 0.012 298.9);
    --mauve-8: oklch(49.2% 0.016 299.5);
    --mauve-9: oklch(54.0% 0.017 294.2);
    --mauve-10: oklch(58.5% 0.017 294.2);
    --mauve-11: oklch(76.9% 0.014 299.7);
    --mauve-12: oklch(95.0% 0.003 286.3);

    --mauve-a1: oklch(0% 0 0 / 0%);
    --mauve-a2: oklch(96.8% 0.003 308.4 / 3.53%);
    --mauve-a3: oklch(94.2% 0.019 289.3 / 7.84%);
    --mauve-a4: oklch(93.5% 0.027 307.1 / 11.37%);
    --mauve-a5: oklch(93.9% 0.033 302.8 / 14.51%);
    --mauve-a6: oklch(94.0% 0.033 307.2 / 18.82%);
    --mauve-a7: oklch(94.4% 0.03 296.2 / 25.10%);
    --mauve-a8: oklch(94.1% 0.033 299.1 / 36.47%);
    --mauve-a9: oklch(93.5% 0.031 293.8 / 43.14%);
    --mauve-a10: oklch(94.2% 0.027 292.8 / 48.63%);
    --mauve-a11: oklch(96.5% 0.019 299.1 / 71.76%);
    --mauve-a12: oklch(99.5% 0.003 286.4 / 93.73%);

    --slate-1: oklch(17.9% 0.004 286);
    --slate-2: oklch(21.3% 0.004 264.5);
    --slate-3: oklch(25.2% 0.006 271.2);
    --slate-4: oklch(28.3% 0.007 248.1);
    --slate-5: oklch(31.2% 0.008 255.6);
    --slate-6: oklch(34.7% 0.01 254);
    --slate-7: oklch(39.9% 0.012 252.9);
    --slate-8: oklch(48.9% 0.016 251.7);
    --slate-9: oklch(53.7% 0.015 262.3);
    --slate-10: oklch(58.3% 0.015 266.6);
    --slate-11: oklch(76.9% 0.01 258.3);
    --slate-12: oklch(94.9% 0.003 264.5);

    --slate-a1: oklch(0% 0 0 / 0%);
    --slate-a2: oklch(94.8% 0.029 201.9 / 3.53%);
    --slate-a3: oklch(93.1% 0.024 250.1 / 7.84%);
    --slate-a4: oklch(93.0% 0.031 225.1 / 11.37%);
    --slate-a5: oklch(93.6% 0.031 243.7 / 14.51%);
    --slate-a6: oklch(93.0% 0.033 244 / 18.82%);
    --slate-a7: oklch(93.7% 0.032 245.3 / 25.10%);
    --slate-a8: oklch(93.7% 0.032 245.3 / 36.47%);
    --slate-a9: oklch(93.6% 0.028 258.4 / 42.75%);
    --slate-a10: oklch(94.5% 0.023 264.5 / 48.24%);
    --slate-a11: oklch(97.4% 0.011 252.1 / 70.98%);
    --slate-a12: oklch(99.4% 0.003 264.5 / 93.73%);

    --sage-1: oklch(18.0% 0.004 164.6);
    --sage-2: oklch(21.1% 0.004 164.7);
    --sage-3: oklch(24.9% 0.003 164.8);
    --sage-4: oklch(28.2% 0.005 174.1);
    --sage-5: oklch(31.0% 0.005 174.2);
    --sage-6: oklch(34.8% 0.006 164.7);
    --sage-7: oklch(40.0% 0.007 170.1);
    --sage-8: oklch(48.9% 0.01 168.4);
    --sage-9: oklch(53.3% 0.017 170.5);
    --sage-10: oklch(57.8% 0.015 173.8);
    --sage-11: oklch(76.6% 0.01 171.7);
    --sage-12: oklch(94.7%0.003 165.1);

    --sage-a1: oklch(0% 0 0 / 0%);
    --sage-a2: oklch(95.9% 0.003 165.1 / 3.14%);
    --sage-a3: oklch(96.8% 0.002 165.1 / 7.06%);
    --sage-a4: oklch(98.8% 0.013 191 / 10.20%);
    --sage-a5: oklch(98.0% 0.011 189.8 / 13.33%);
    --sage-a6: oklch(97.6% 0.017 164.7 / 17.65%);
    --sage-a7: oklch(97.9% 0.017 174.1 / 23.53%);
    --sage-a8: oklch(97.9% 0.021 170.6 / 34.12%);
    --sage-a9: oklch(96.9% 0.034 171.5 / 40.00%);
    --sage-a10: oklch(97.5% 0.027 176.4 / 45.49%);
    --sage-a11: oklch(98.9% 0.011 176.3 / 69.02%);
    --sage-a12: oklch(99.8% 0.002 165.1 / 92.94%);

    --olive-1: oklch(18.0% 0.004 128.7);
    --olive-2: oklch(21.2% 0.004 128.7);
    --olive-3: oklch(25.0% 0.004 128.6);
    --olive-4: oklch(28.2% 0.006 134.9);
    --olive-5: oklch(31.0% 0.006 134.9);
    --olive-6: oklch(34.5% 0.007 128.7);
    --olive-7: oklch(39.7% 0.009 132.5);
    --olive-8: oklch(48.9% 0.013 141.2);
    --olive-9: oklch(53.5% 0.018 139.4);
    --olive-10: oklch(58.1% 0.016 138.7);
    --olive-11: oklch(76.6% 0.013 137.8);
    --olive-12: oklch(94.7% 0.003 145.5);

    --olive-a1: oklch(0% 0 0 / 0%);
    --olive-a2: oklch(96.0% 0.003 128.5 / 3.14%);
    --olive-a3: oklch(96.9% 0.003 128.5 / 7.06%);
    --olive-a4: oklch(98.6% 0.02 143 / 10.20%);
    --olive-a5: oklch(97.9% 0.016 142.6 / 13.33%);
    --olive-a6: oklch(97.7% 0.018 127.1 / 17.25%);
    --olive-a7: oklch(98.0% 0.022 134.9 / 23.14%);
    --olive-a8: oklch(97.7% 0.029 142.2 / 34.12%);
    --olive-a9: oklch(97.5% 0.035 140 / 40.00%);
    --olive-a10: oklch(98.0% 0.026 138.3 / 45.49%);
    --olive-a11: oklch(98.8% 0.016 139.4 / 69.02%);
    --olive-a12: oklch(99.8% 0.003 145.6 / 92.94%);

    --sand-1: oklch(17.7% 0.002 106.6);
    --sand-2: oklch(21.3% 0.002 106.6);
    --sand-3: oklch(25.2% 0.002 106.5);
    --sand-4: oklch(28.4% 0.004 106.6);
    --sand-5: oklch(31.2% 0.005 106.7);
    --sand-6: oklch(34.8% 0.005 91.5);
    --sand-7: oklch(40.1% 0.007 95.2);
    --sand-8: oklch(48.9% 0.008 88.7);
    --sand-9: oklch(53.4% 0.011 93.7);
    --sand-10: oklch(58.2% 0.011 100.1);
    --sand-11: oklch(76.7% 0.009 91.5);
    --sand-12: oklch(94.9% 0.003 106.5);

    --sand-a1: oklch(0% 0 0 / 0%);
    --sand-a2: oklch(96.7% 0.001 106.4 / 3.53%);
    --sand-a3: oklch(97.3% 0.001 106.4 / 7.45%);
    --sand-a4: oklch(99.4% 0.014 106.7 / 10.59%);
    --sand-a5: oklch(98.4% 0.021 106.8 / 13.73%);
    --sand-a6: oklch(98.5% 0.018 89.4 / 17.65%);
    --sand-a7: oklch(98.7% 0.019 93.7 / 23.53%);
    --sand-a8: oklch(98.3% 0.019 87.5 / 34.12%);
    --sand-a9: oklch(98.4% 0.023 93.3 / 39.61%);
    --sand-a10: oklch(99.2% 0.02 100.6 / 45.10%);
    --sand-a11: oklch(99.1% 0.011 89.7 / 69.02%);
    --sand-a12: oklch(99.9% 0.003 106.4 / 92.94%);

    --gray-2-translucent: oklch(23.08% 0 0 / 70.2%);
    --mauve-2-translucent: oklch(23.23% 0.002 325.65 / 70.2%);
    --slate-2-translucent: oklch(22.9% 0.004 228.96 / 70.2%);
    --sage-2-translucent: oklch(22.39% 0.004 164.72 / 70.2%);
    --olive-2-translucent: oklch(22.46% 0.004 128.67 / 70.2%);
    --sand-2-translucent: oklch(23% 0.004 106.69 / 70.2%);

    --gray-surface: oklch(24.78% 0 0 / 50.2%);
    --mauve-surface: oklch(25.0% 0.004 308.3 / 50.2%);
    --slate-surface: oklch(24.6% 0.005 248 / 50.2%);
    --sage-surface: oklch(24.1% 0.004 164.8 / 50.2%);
    --olive-surface: oklch(24.2% 0.004 128.6 / 50.2%);
    --sand-surface: oklch(24.7% 0.002 106.5 / 50.2%);
    --tomato-surface: oklch(23.9% 0.033 31.9 / 50.2%);
    --red-surface: oklch(23.4% 0.042 16 / 50.2%);
    --ruby-surface: oklch(23.9% 0.03 5 / 50.2%);
    --crimson-surface: oklch(23.7% 0.045 356.6 / 50.2%);
    --pink-surface: oklch(24.1% 0.059 338.5 / 50.2%);
    --plum-surface: oklch(24.5% 0.06 327.2 / 50.2%);
    --purple-surface: oklch(24.7% 0.06 313.5 / 50.2%);
    --violet-surface: oklch(24.6% 0.06 299.2 / 50.2%);
    --iris-surface: oklch(24.2% 0.056 284.4 / 50.2%);
    --indigo-surface: oklch(24.3% 0.058 272.9 / 50.2%);
    --blue-surface: oklch(25.0% 0.058 260.8 / 50.2%);
    --cyan-surface: oklch(25.2% 0.03 225.9 / 50.2%);
    --teal-surface: oklch(25.5% 0.026 187.5 / 50.2%);
    --jade-surface: oklch(25.3% 0.03 166.5 / 50.2%);
    --green-surface: oklch(24.7% 0.027 161.9 / 50.2%);
    --grass-surface: oklch(24.4% 0.021 150.6 / 50.2%);
    --brown-surface: oklch(24.7% 0.015 48.1 / 50.2%);
    --bronze-surface: oklch(25.3% 0.012 55.8 / 50.2%);
    --gold-surface: oklch(25.6% 0.011 91.7 / 50.2%);
    --sky-surface: oklch(25.5% 0.051 258.3 / 50.2%);
    --mint-surface: oklch(25.7% 0.024 195.8 / 50.2%);
    --lime-surface: oklch(23.8% 0.024 129.7 / 50.2%);
    --yellow-surface: oklch(24.0% 0.022 91.8 / 50.2%);
    --amber-surface: oklch(24.5% 0.024 77.3 / 50.2%);
    --orange-surface: oklch(24.0% 0.024 66.3 / 50.2%);
  }
}
*/
/*
@supports (color: color(display-p3 1 1 1)) {
  @media (prefers-color-scheme: dark) and (color-gamut:p3) {
    :where(html) {
      --tomato-1:color(display-p3 0.09 0.068 0.067);
      --tomato-2: color(display-p3 0.115 0.084 0.076);
      --tomato-3: color(display-p3 0.205 0.097 0.083);
      --tomato-4: color(display-p3 0.282 0.099 0.077);
      --tomato-5: color(display-p3 0.339 0.129 0.101);
      --tomato-6: color(display-p3 0.398 0.179 0.141);
      --tomato-7: color(display-p3 0.487 0.245 0.194);
      --tomato-8: color(display-p3 0.629 0.322 0.248);
      --tomato-9: color(display-p3 0.831 0.345 0.231);
      --tomato-10: color(display-p3 0.862 0.415 0.298);
      --tomato-11: color(display-p3 1 0.585 0.455);
      --tomato-12: color(display-p3 0.959 0.833 0.802);
      --tomato-a1: color(display-p3 0.973 0.071 0.071/0.026);
      --tomato-a2: color(display-p3 0.992 0.376 0.224/0.051);
      --tomato-a3: color(display-p3 0.996 0.282 0.176/0.148);
      --tomato-a4: color(display-p3 1 0.204 0.118/0.232);
      --tomato-a5: color(display-p3 1 0.286 0.192/0.29);
      --tomato-a6: color(display-p3 1 0.392 0.278/0.353);
      --tomato-a7: color(display-p3 1 0.459 0.349/0.45);
      --tomato-a8: color(display-p3 1 0.49 0.369/0.601);
      --tomato-a9: color(display-p3 1 0.408 0.267/0.82);
      --tomato-a10: color(display-p3 1 0.478 0.341/0.853);
      --tomato-a11: color(display-p3 1 0.585 0.455);
      --tomato-a12: color(display-p3 0.959 0.833 0.802);

      --red-1: color(display-p3 0.093 0.068 0.067);
      --red-2: color(display-p3 0.118 0.077 0.079);
      --red-3: color(display-p3 0.211 0.081 0.099);
      --red-4: color(display-p3 0.287 0.079 0.113);
      --red-5: color(display-p3 0.348 0.11 0.142);
      --red-6: color(display-p3 0.414 0.16 0.183);
      --red-7: color(display-p3 0.508 0.224 0.236);
      --red-8: color(display-p3 0.659 0.298 0.297);
      --red-9: color(display-p3 0.83 0.329 0.324);
      --red-10: color(display-p3 0.861 0.403 0.387);
      --red-11: color(display-p3 1 0.57 0.55);
      --red-12: color(display-p3 0.971 0.826 0.852);
      --red-a1: color(display-p3 0.984 0.071 0.071/0.03);
      --red-a2: color(display-p3 0.996 0.282 0.282/0.055);
      --red-a3: color(display-p3 1 0.169 0.271/0.156);
      --red-a4: color(display-p3 1 0.118 0.267/0.236);
      --red-a5: color(display-p3 1 0.212 0.314/0.303);
      --red-a6: color(display-p3 1 0.318 0.38/0.374);
      --red-a7: color(display-p3 1 0.4 0.424/0.475);
      --red-a8: color(display-p3 1 0.431 0.431/0.635);
      --red-a9: color(display-p3 1 0.388 0.384/0.82);
      --red-a10: color(display-p3 1 0.463 0.447/0.853);
      --red-a11: color(display-p3 1 0.57 0.55);
      --red-a12: color(display-p3 0.971 0.826 0.852);

      --ruby-1: color(display-p3 0.093 0.068 0.074);
      --ruby-2: color(display-p3 0.113 0.083 0.089);
      --ruby-3: color(display-p3 0.208 0.088 0.117);
      --ruby-4: color(display-p3 0.279 0.092 0.147);
      --ruby-5: color(display-p3 0.337 0.12 0.18);
      --ruby-6: color(display-p3 0.401 0.166 0.223);
      --ruby-7: color(display-p3 0.495 0.224 0.281);
      --ruby-8: color(display-p3 0.652 0.295 0.359);
      --ruby-9: color(display-p3 0.83 0.323 0.408);
      --ruby-10: color(display-p3 0.857 0.392 0.455);
      --ruby-11: color(display-p3 1 0.57 0.59);
      --ruby-12: color(display-p3 0.968 0.83 0.88);
      --ruby-a1: color(display-p3 0.984 0.071 0.329/0.03);
      --ruby-a2: color(display-p3 0.992 0.376 0.529/0.051);
      --ruby-a3: color(display-p3 0.996 0.196 0.404/0.152);
      --ruby-a4: color(display-p3 1 0.173 0.416/0.227);
      --ruby-a5: color(display-p3 1 0.259 0.459/0.29);
      --ruby-a6: color(display-p3 1 0.341 0.506/0.358);
      --ruby-a7: color(display-p3 1 0.412 0.541/0.458);
      --ruby-a8: color(display-p3 1 0.431 0.537/0.627);
      --ruby-a9: color(display-p3 1 0.376 0.482/0.82);
      --ruby-a10: color(display-p3 1 0.447 0.522/0.849);
      --ruby-a11: color(display-p3 1 0.57 0.59);
      --ruby-a12: color(display-p3 0.968 0.83 0.88);

      --crimson-1: color(display-p3 0.093 0.068 0.078);
      --crimson-2: color(display-p3 0.117 0.078 0.095);
      --crimson-3: color(display-p3 0.203 0.091 0.143);
      --crimson-4: color(display-p3 0.277 0.087 0.182);
      --crimson-5: color(display-p3 0.332 0.115 0.22);
      --crimson-6: color(display-p3 0.394 0.162 0.268);
      --crimson-7: color(display-p3 0.489 0.222 0.336);
      --crimson-8: color(display-p3 0.638 0.289 0.429);
      --crimson-9: color(display-p3 0.843 0.298 0.507);
      --crimson-10: color(display-p3 0.864 0.364 0.539);
      --crimson-11: color(display-p3 1 0.56 0.66);
      --crimson-12: color(display-p3 0.966 0.834 0.906);
      --crimson-a1: color(display-p3 0.984 0.071 0.463/0.03);
      --crimson-a2: color(display-p3 0.996 0.282 0.569/0.055);
      --crimson-a3: color(display-p3 0.996 0.227 0.573/0.148);
      --crimson-a4: color(display-p3 1 0.157 0.569/0.227);
      --crimson-a5: color(display-p3 1 0.231 0.604/0.286);
      --crimson-a6: color(display-p3 1 0.337 0.643/0.349);
      --crimson-a7: color(display-p3 1 0.416 0.663/0.454);
      --crimson-a8: color(display-p3 0.996 0.427 0.651/0.614);
      --crimson-a9: color(display-p3 1 0.345 0.596/0.832);
      --crimson-a10: color(display-p3 1 0.42 0.62/0.853);
      --crimson-a11: color(display-p3 1 0.56 0.66);
      --crimson-a12: color(display-p3 0.966 0.834 0.906);

      --pink-1: color(display-p3 0.093 0.068 0.089);
      --pink-2: color(display-p3 0.121 0.073 0.11);
      --pink-3: color(display-p3 0.198 0.098 0.179);
      --pink-4: color(display-p3 0.271 0.095 0.231);
      --pink-5: color(display-p3 0.32 0.127 0.273);
      --pink-6: color(display-p3 0.382 0.177 0.326);
      --pink-7: color(display-p3 0.477 0.238 0.405);
      --pink-8: color(display-p3 0.612 0.304 0.51);
      --pink-9: color(display-p3 0.775 0.297 0.61);
      --pink-10: color(display-p3 0.808 0.356 0.645);
      --pink-11: color(display-p3 1 0.535 0.78);
      --pink-12: color(display-p3 0.964 0.826 0.912);
      --pink-a1: color(display-p3 0.984 0.071 0.855/0.03);
      --pink-a2: color(display-p3 1 0.2 0.8/0.059);
      --pink-a3: color(display-p3 1 0.294 0.886/0.139);
      --pink-a4: color(display-p3 1 0.192 0.82/0.219);
      --pink-a5: color(display-p3 1 0.282 0.827/0.274);
      --pink-a6: color(display-p3 1 0.396 0.835/0.337);
      --pink-a7: color(display-p3 1 0.459 0.831/0.442);
      --pink-a8: color(display-p3 1 0.478 0.827/0.585);
      --pink-a9: color(display-p3 1 0.373 0.784/0.761);
      --pink-a10: color(display-p3 1 0.435 0.792/0.795);
      --pink-a11: color(display-p3 1 0.535 0.78);
      --pink-a12: color(display-p3 0.964 0.826 0.912);

      --plum-1: color(display-p3 0.09 0.068 0.092);
      --plum-2: color(display-p3 0.118 0.077 0.121);
      --plum-3: color(display-p3 0.192 0.105 0.202);
      --plum-4: color(display-p3 0.25 0.121 0.271);
      --plum-5: color(display-p3 0.293 0.152 0.319);
      --plum-6: color(display-p3 0.343 0.198 0.372);
      --plum-7: color(display-p3 0.424 0.262 0.461);
      --plum-8: color(display-p3 0.54 0.341 0.595);
      --plum-9: color(display-p3 0.624 0.313 0.708);
      --plum-10: color(display-p3 0.666 0.365 0.748);
      --plum-11: color(display-p3 0.86 0.602 0.933);
      --plum-12: color(display-p3 0.936 0.836 0.949);
      --plum-a1: color(display-p3 0.973 0.071 0.973/0.026);
      --plum-a2: color(display-p3 0.933 0.267 1/0.059);
      --plum-a3: color(display-p3 0.918 0.333 0.996/0.148);
      --plum-a4: color(display-p3 0.91 0.318 1/0.219);
      --plum-a5: color(display-p3 0.914 0.388 1/0.269);
      --plum-a6: color(display-p3 0.906 0.463 1/0.328);
      --plum-a7: color(display-p3 0.906 0.529 1/0.425);
      --plum-a8: color(display-p3 0.906 0.553 1/0.568);
      --plum-a9: color(display-p3 0.875 0.427 1/0.69);
      --plum-a10: color(display-p3 0.886 0.471 0.996/0.732);
      --plum-a11: color(display-p3 0.86 0.602 0.933);
      --plum-a12: color(display-p3 0.936 0.836 0.949);

      --purple-1: color(display-p3 0.09 0.068 0.103);
      --purple-2: color(display-p3 0.113 0.082 0.134);
      --purple-3: color(display-p3 0.175 0.112 0.224);
      --purple-4: color(display-p3 0.224 0.137 0.297);
      --purple-5: color(display-p3 0.264 0.167 0.349);
      --purple-6: color(display-p3 0.311 0.208 0.406);
      --purple-7: color(display-p3 0.381 0.266 0.496);
      --purple-8: color(display-p3 0.49 0.349 0.649);
      --purple-9: color(display-p3 0.523 0.318 0.751);
      --purple-10: color(display-p3 0.57 0.373 0.791);
      --purple-11: color(display-p3 0.8 0.62 1);
      --purple-12: color(display-p3 0.913 0.854 0.971);
      --purple-a1: color(display-p3 0.686 0.071 0.996/0.038);
      --purple-a2: color(display-p3 0.722 0.286 0.996/0.072);
      --purple-a3: color(display-p3 0.718 0.349 0.996/0.169);
      --purple-a4: color(display-p3 0.702 0.353 1/0.248);
      --purple-a5: color(display-p3 0.718 0.404 1/0.303);
      --purple-a6: color(display-p3 0.733 0.455 1/0.366);
      --purple-a7: color(display-p3 0.753 0.506 1/0.458);
      --purple-a8: color(display-p3 0.749 0.522 1/0.622);
      --purple-a9: color(display-p3 0.686 0.408 1/0.736);
      --purple-a10: color(display-p3 0.71 0.459 1/0.778);
      --purple-a11: color(display-p3 0.8 0.62 1);
      --purple-a12: color(display-p3 0.913 0.854 0.971);

      --violet-1: color(display-p3 0.077 0.071 0.118);
      --violet-2: color(display-p3 0.101 0.084 0.141);
      --violet-3: color(display-p3 0.154 0.123 0.256);
      --violet-4: color(display-p3 0.191 0.148 0.345);
      --violet-5: color(display-p3 0.226 0.182 0.396);
      --violet-6: color(display-p3 0.269 0.223 0.449);
      --violet-7: color(display-p3 0.326 0.277 0.53);
      --violet-8: color(display-p3 0.399 0.346 0.656);
      --violet-9: color(display-p3 0.417 0.341 0.784);
      --violet-10: color(display-p3 0.477 0.402 0.823);
      --violet-11: color(display-p3 0.72 0.65 1);
      --violet-12: color(display-p3 0.883 0.867 0.986);
      --violet-a1: color(display-p3 0.282 0.141 0.996/0.055);
      --violet-a2: color(display-p3 0.51 0.263 1/0.08);
      --violet-a3: color(display-p3 0.494 0.337 0.996/0.202);
      --violet-a4: color(display-p3 0.49 0.345 1/0.299);
      --violet-a5: color(display-p3 0.525 0.392 1/0.353);
      --violet-a6: color(display-p3 0.569 0.455 1/0.408);
      --violet-a7: color(display-p3 0.588 0.494 1/0.496);
      --violet-a8: color(display-p3 0.596 0.51 1/0.631);
      --violet-a9: color(display-p3 0.522 0.424 1/0.769);
      --violet-a10: color(display-p3 0.576 0.482 1/0.811);
      --violet-a11: color(display-p3 0.72 0.65 1);
      --violet-a12: color(display-p3 0.883 0.867 0.986);

      --iris-1: color(display-p3 0.075 0.075 0.114);
      --iris-2: color(display-p3 0.089 0.086 0.14);
      --iris-3: color(display-p3 0.128 0.134 0.272);
      --iris-4: color(display-p3 0.153 0.165 0.382);
      --iris-5: color(display-p3 0.192 0.201 0.44);
      --iris-6: color(display-p3 0.239 0.241 0.491);
      --iris-7: color(display-p3 0.291 0.289 0.565);
      --iris-8: color(display-p3 0.35 0.345 0.673);
      --iris-9: color(display-p3 0.357 0.357 0.81);
      --iris-10: color(display-p3 0.428 0.416 0.843);
      --iris-11: color(display-p3 0.685 0.662 1);
      --iris-12: color(display-p3 0.878 0.875 0.986);
      --iris-a1: color(display-p3 0.224 0.224 0.992/0.051);
      --iris-a2: color(display-p3 0.361 0.314 1/0.08);
      --iris-a3: color(display-p3 0.357 0.373 1/0.219);
      --iris-a4: color(display-p3 0.325 0.361 1/0.337);
      --iris-a5: color(display-p3 0.38 0.4 1/0.4);
      --iris-a6: color(display-p3 0.447 0.447 1/0.454);
      --iris-a7: color(display-p3 0.486 0.486 1/0.534);
      --iris-a8: color(display-p3 0.502 0.494 1/0.652);
      --iris-a9: color(display-p3 0.431 0.431 1/0.799);
      --iris-a10: color(display-p3 0.502 0.486 1/0.832);
      --iris-a11: color(display-p3 0.685 0.662 1);
      --iris-a12: color(display-p3 0.878 0.875 0.986);

      --indigo-1: color(display-p3 0.068 0.074 0.118);
      --indigo-2: color(display-p3 0.081 0.089 0.144);
      --indigo-3: color(display-p3 0.105 0.141 0.275);
      --indigo-4: color(display-p3 0.129 0.18 0.369);
      --indigo-5: color(display-p3 0.163 0.22 0.439);
      --indigo-6: color(display-p3 0.203 0.262 0.5);
      --indigo-7: color(display-p3 0.245 0.309 0.575);
      --indigo-8: color(display-p3 0.285 0.362 0.674);
      --indigo-9: color(display-p3 0.276 0.384 0.837);
      --indigo-10: color(display-p3 0.354 0.445 0.866);
      --indigo-11: color(display-p3 0.63 0.69 1);
      --indigo-12: color(display-p3 0.848 0.881 0.99);
      --indigo-a1: color(display-p3 0.071 0.212 0.996/0.055);
      --indigo-a2: color(display-p3 0.251 0.345 0.988/0.085);
      --indigo-a3: color(display-p3 0.243 0.404 1/0.223);
      --indigo-a4: color(display-p3 0.263 0.42 1/0.324);
      --indigo-a5: color(display-p3 0.314 0.451 1/0.4);
      --indigo-a6: color(display-p3 0.361 0.49 1/0.467);
      --indigo-a7: color(display-p3 0.388 0.51 1/0.547);
      --indigo-a8: color(display-p3 0.404 0.518 1/0.652);
      --indigo-a9: color(display-p3 0.318 0.451 1/0.824);
      --indigo-a10: color(display-p3 0.404 0.506 1/0.858);
      --indigo-a11: color(display-p3 0.63 0.69 1);
      --indigo-a12: color(display-p3 0.848 0.881 0.99);

      --blue-1: color(display-p3 0.057 0.081 0.122);
      --blue-2: color(display-p3 0.072 0.098 0.147);
      --blue-3: color(display-p3 0.078 0.154 0.27);
      --blue-4: color(display-p3 0.033 0.197 0.37);
      --blue-5: color(display-p3 0.08 0.245 0.441);
      --blue-6: color(display-p3 0.14 0.298 0.511);
      --blue-7: color(display-p3 0.195 0.361 0.6);
      --blue-8: color(display-p3 0.239 0.434 0.72);
      --blue-9: color(display-p3 0.247 0.556 0.969);
      --blue-10: color(display-p3 0.344 0.612 0.973);
      --blue-11: color(display-p3 0.49 0.72 1);
      --blue-12: color(display-p3 0.788 0.898 0.99);
      --blue-a1: color(display-p3 0 0.333 1/0.059);
      --blue-a2: color(display-p3 0.114 0.435 0.988/0.085);
      --blue-a3: color(display-p3 0.122 0.463 1/0.219);
      --blue-a4: color(display-p3 0 0.467 1/0.324);
      --blue-a5: color(display-p3 0.098 0.51 1/0.4);
      --blue-a6: color(display-p3 0.224 0.557 1/0.475);
      --blue-a7: color(display-p3 0.294 0.584 1/0.572);
      --blue-a8: color(display-p3 0.314 0.592 1/0.702);
      --blue-a9: color(display-p3 0.251 0.573 0.996/0.967);
      --blue-a10: color(display-p3 0.357 0.631 1/0.971);
      --blue-a11: color(display-p3 0.49 0.72 1);
      --blue-a12: color(display-p3 0.788 0.898 0.99);

      --cyan-1: color(display-p3 0.053 0.085 0.098);
      --cyan-2: color(display-p3 0.072 0.105 0.122);
      --cyan-3: color(display-p3 0.073 0.168 0.209);
      --cyan-4: color(display-p3 0.063 0.216 0.277);
      --cyan-5: color(display-p3 0.091 0.267 0.336);
      --cyan-6: color(display-p3 0.137 0.324 0.4);
      --cyan-7: color(display-p3 0.186 0.398 0.484);
      --cyan-8: color(display-p3 0.23 0.496 0.6);
      --cyan-9: color(display-p3 0.282 0.627 0.765);
      --cyan-10: color(display-p3 0.331 0.675 0.801);
      --cyan-11: color(display-p3 0.446 0.79 0.887);
      --cyan-12: color(display-p3 0.757 0.919 0.962);
      --cyan-a1: color(display-p3 0 0.647 0.992/0.034);
      --cyan-a2: color(display-p3 0.133 0.733 1/0.059);
      --cyan-a3: color(display-p3 0.122 0.741 0.996/0.152);
      --cyan-a4: color(display-p3 0.051 0.725 1/0.227);
      --cyan-a5: color(display-p3 0.149 0.757 1/0.29);
      --cyan-a6: color(display-p3 0.267 0.792 1/0.358);
      --cyan-a7: color(display-p3 0.333 0.808 1/0.446);
      --cyan-a8: color(display-p3 0.357 0.816 1/0.572);
      --cyan-a9: color(display-p3 0.357 0.82 1/0.748);
      --cyan-a10: color(display-p3 0.4 0.839 1/0.786);
      --cyan-a11: color(display-p3 0.446 0.79 0.887);
      --cyan-a12: color(display-p3 0.757 0.919 0.962);

      --teal-1: color(display-p3 0.059 0.083 0.079);
      --teal-2: color(display-p3 0.075 0.11 0.107);
      --teal-3: color(display-p3 0.087 0.175 0.165);
      --teal-4: color(display-p3 0.087 0.227 0.214);
      --teal-5: color(display-p3 0.12 0.277 0.261);
      --teal-6: color(display-p3 0.162 0.335 0.314);
      --teal-7: color(display-p3 0.205 0.406 0.379);
      --teal-8: color(display-p3 0.245 0.489 0.453);
      --teal-9: color(display-p3 0.297 0.637 0.581);
      --teal-10: color(display-p3 0.319 0.69 0.62);
      --teal-11: color(display-p3 0.388 0.835 0.719);
      --teal-12: color(display-p3 0.734 0.934 0.87);
      --teal-a1: color(display-p3 0 0.992 0.761/0.017);
      --teal-a2: color(display-p3 0.235 0.988 0.902/0.047);
      --teal-a3: color(display-p3 0.235 1 0.898/0.118);
      --teal-a4: color(display-p3 0.18 0.996 0.929/0.173);
      --teal-a5: color(display-p3 0.31 1 0.933/0.227);
      --teal-a6: color(display-p3 0.396 1 0.933/0.286);
      --teal-a7: color(display-p3 0.443 1 0.925/0.366);
      --teal-a8: color(display-p3 0.459 1 0.925/0.454);
      --teal-a9: color(display-p3 0.443 0.996 0.906/0.61);
      --teal-a10: color(display-p3 0.439 0.996 0.89/0.669);
      --teal-a11: color(display-p3 0.388 0.835 0.719);
      --teal-a12: color(display-p3 0.734 0.934 0.87);
      --jade-1: color(display-p3 0.059 0.083 0.071);

      --jade-2: color(display-p3 0.078 0.11 0.094);
      --jade-3: color(display-p3 0.091 0.176 0.138);
      --jade-4: color(display-p3 0.102 0.228 0.177);
      --jade-5: color(display-p3 0.133 0.279 0.221);
      --jade-6: color(display-p3 0.174 0.334 0.273);
      --jade-7: color(display-p3 0.219 0.402 0.335);
      --jade-8: color(display-p3 0.263 0.488 0.411);
      --jade-9: color(display-p3 0.319 0.63 0.521);
      --jade-10: color(display-p3 0.338 0.68 0.555);
      --jade-11: color(display-p3 0.4 0.835 0.656);
      --jade-12: color(display-p3 0.734 0.934 0.838);
      --jade-a1: color(display-p3 0 0.992 0.298/0.017);
      --jade-a2: color(display-p3 0.318 0.988 0.651/0.047);
      --jade-a3: color(display-p3 0.267 1 0.667/0.118);
      --jade-a4: color(display-p3 0.275 0.996 0.702/0.173);
      --jade-a5: color(display-p3 0.361 1 0.741/0.227);
      --jade-a6: color(display-p3 0.439 1 0.796/0.286);
      --jade-a7: color(display-p3 0.49 1 0.804/0.362);
      --jade-a8: color(display-p3 0.506 1 0.835/0.45);
      --jade-a9: color(display-p3 0.478 0.996 0.816/0.606);
      --jade-a10: color(display-p3 0.478 1 0.816/0.656);
      --jade-a11: color(display-p3 0.4 0.835 0.656);
      --jade-a12: color(display-p3 0.734 0.934 0.838);

      --green-1: color(display-p3 0.062 0.083 0.071);
      --green-2: color(display-p3 0.079 0.106 0.09);
      --green-3: color(display-p3 0.1 0.173 0.133);
      --green-4: color(display-p3 0.115 0.229 0.166);
      --green-5: color(display-p3 0.147 0.282 0.206);
      --green-6: color(display-p3 0.185 0.338 0.25);
      --green-7: color(display-p3 0.227 0.403 0.298);
      --green-8: color(display-p3 0.27 0.479 0.351);
      --green-9: color(display-p3 0.332 0.634 0.442);
      --green-10: color(display-p3 0.357 0.682 0.474);
      --green-11: color(display-p3 0.434 0.828 0.573);
      --green-12: color(display-p3 0.747 0.938 0.807);
      --green-a1: color(display-p3 0 0.992 0.298/0.017);
      --green-a2: color(display-p3 0.341 0.98 0.616/0.043);
      --green-a3: color(display-p3 0.376 0.996 0.655/0.114);
      --green-a4: color(display-p3 0.341 0.996 0.635/0.173);
      --green-a5: color(display-p3 0.408 1 0.678/0.232);
      --green-a6: color(display-p3 0.475 1 0.706/0.29);
      --green-a7: color(display-p3 0.514 1 0.706/0.362);
      --green-a8: color(display-p3 0.529 1 0.718/0.442);
      --green-a9: color(display-p3 0.502 0.996 0.682/0.61);
      --green-a10: color(display-p3 0.506 1 0.682/0.66);
      --green-a11: color(display-p3 0.434 0.828 0.573);
      --green-a12: color(display-p3 0.747 0.938 0.807);

      --grass-1: color(display-p3 0.062 0.083 0.067);
      --grass-2: color(display-p3 0.083 0.103 0.085);
      --grass-3: color(display-p3 0.118 0.163 0.122);
      --grass-4: color(display-p3 0.142 0.225 0.15);
      --grass-5: color(display-p3 0.178 0.279 0.186);
      --grass-6: color(display-p3 0.217 0.337 0.224);
      --grass-7: color(display-p3 0.258 0.4 0.264);
      --grass-8: color(display-p3 0.302 0.47 0.305);
      --grass-9: color(display-p3 0.38 0.647 0.378);
      --grass-10: color(display-p3 0.426 0.694 0.426);
      --grass-11: color(display-p3 0.535 0.807 0.542);
      --grass-12: color(display-p3 0.797 0.936 0.776);
      --grass-a1: color(display-p3 0 0.992 0.071/0.017);
      --grass-a2: color(display-p3 0.482 0.996 0.584/0.038);
      --grass-a3: color(display-p3 0.549 0.992 0.588/0.106);
      --grass-a4: color(display-p3 0.51 0.996 0.557/0.169);
      --grass-a5: color(display-p3 0.553 1 0.588/0.227);
      --grass-a6: color(display-p3 0.584 1 0.608/0.29);
      --grass-a7: color(display-p3 0.604 1 0.616/0.358);
      --grass-a8: color(display-p3 0.608 1 0.62/0.433);
      --grass-a9: color(display-p3 0.573 1 0.569/0.622);
      --grass-a10: color(display-p3 0.6 0.996 0.6/0.673);
      --grass-a11: color(display-p3 0.535 0.807 0.542);
      --grass-a12: color(display-p3 0.797 0.936 0.776);

      --orange-1: color(display-p3 0.088 0.07 0.057);
      --orange-2: color(display-p3 0.113 0.089 0.061);
      --orange-3: color(display-p3 0.189 0.12 0.056);
      --orange-4: color(display-p3 0.262 0.132 0);
      --orange-5: color(display-p3 0.315 0.168 0.016);
      --orange-6: color(display-p3 0.376 0.219 0.088);
      --orange-7: color(display-p3 0.465 0.283 0.147);
      --orange-8: color(display-p3 0.601 0.359 0.201);
      --orange-9: color(display-p3 0.9 0.45 0.2);
      --orange-10: color(display-p3 0.98 0.51 0.23);
      --orange-11: color(display-p3 1 0.63 0.38);
      --orange-12: color(display-p3 0.98 0.883 0.775);
      --orange-a1: color(display-p3 0.961 0.247 0/0.022);
      --orange-a2: color(display-p3 0.992 0.529 0/0.051);
      --orange-a3: color(display-p3 0.996 0.486 0/0.131);
      --orange-a4: color(display-p3 0.996 0.384 0/0.211);
      --orange-a5: color(display-p3 1 0.455 0/0.265);
      --orange-a6: color(display-p3 1 0.529 0.129/0.332);
      --orange-a7: color(display-p3 1 0.569 0.251/0.429);
      --orange-a8: color(display-p3 1 0.584 0.302/0.572);
      --orange-a9: color(display-p3 1 0.494 0.216/0.895);
      --orange-a10: color(display-p3 1 0.522 0.235/0.979);
      --orange-a11: color(display-p3 1 0.63 0.38);
      --orange-a12: color(display-p3 0.98 0.883 0.775);

      --brown-1: color(display-p3 0.071 0.067 0.059);
      --brown-2: color(display-p3 0.107 0.095 0.087);
      --brown-3: color(display-p3 0.151 0.13 0.115);
      --brown-4: color(display-p3 0.191 0.161 0.138);
      --brown-5: color(display-p3 0.235 0.194 0.162);
      --brown-6: color(display-p3 0.291 0.237 0.192);
      --brown-7: color(display-p3 0.365 0.295 0.232);
      --brown-8: color(display-p3 0.469 0.377 0.287);
      --brown-9: color(display-p3 0.651 0.505 0.368);
      --brown-10: color(display-p3 0.697 0.557 0.423);
      --brown-11: color(display-p3 0.835 0.715 0.597);
      --brown-12: color(display-p3 0.938 0.885 0.802);
      --brown-a1: color(display-p3 0.855 0.071 0/0.005);
      --brown-a2: color(display-p3 0.98 0.706 0.525/0.043);
      --brown-a3: color(display-p3 0.996 0.745 0.576/0.093);
      --brown-a4: color(display-p3 1 0.765 0.592/0.135);
      --brown-a5: color(display-p3 1 0.761 0.588/0.181);
      --brown-a6: color(display-p3 1 0.773 0.592/0.24);
      --brown-a7: color(display-p3 0.996 0.776 0.58/0.32);
      --brown-a8: color(display-p3 1 0.78 0.573/0.433);
      --brown-a9: color(display-p3 1 0.769 0.549/0.627);
      --brown-a10: color(display-p3 1 0.792 0.596/0.677);
      --brown-a11: color(display-p3 0.835 0.715 0.597);
      --brown-a12: color(display-p3 0.938 0.885 0.802);

      --sky-1: color(display-p3 0.056 0.078 0.116);
      --sky-2: color(display-p3 0.075 0.101 0.149);
      --sky-3: color(display-p3 0.089 0.154 0.244);
      --sky-4: color(display-p3 0.106 0.207 0.323);
      --sky-5: color(display-p3 0.135 0.261 0.394);
      --sky-6: color(display-p3 0.17 0.322 0.469);
      --sky-7: color(display-p3 0.205 0.394 0.557);
      --sky-8: color(display-p3 0.232 0.48 0.665);
      --sky-9: color(display-p3 0.585 0.877 0.983);
      --sky-10: color(display-p3 0.718 0.925 0.991);
      --sky-11: color(display-p3 0.536 0.772 0.924);
      --sky-12: color(display-p3 0.799 0.947 0.993);
      --sky-a1: color(display-p3 0 0.282 0.996/0.055);
      --sky-a2: color(display-p3 0.157 0.467 0.992/0.089);
      --sky-a3: color(display-p3 0.192 0.522 0.996/0.19);
      --sky-a4: color(display-p3 0.212 0.584 1/0.274);
      --sky-a5: color(display-p3 0.259 0.631 1/0.349);
      --sky-a6: color(display-p3 0.302 0.655 1/0.433);
      --sky-a7: color(display-p3 0.329 0.686 1/0.526);
      --sky-a8: color(display-p3 0.325 0.71 1/0.643);
      --sky-a9: color(display-p3 0.592 0.894 1/0.984);
      --sky-a10: color(display-p3 0.722 0.933 1/0.992);
      --sky-a11: color(display-p3 0.536 0.772 0.924);
      --sky-a12: color(display-p3 0.799 0.947 0.993);

      --mint-1: color(display-p3 0.059 0.082 0.081);
      --mint-2: color(display-p3 0.068 0.104 0.105);
      --mint-3: color(display-p3 0.077 0.17 0.168);
      --mint-4: color(display-p3 0.068 0.224 0.22);
      --mint-5: color(display-p3 0.104 0.275 0.264);
      --mint-6: color(display-p3 0.154 0.332 0.313);
      --mint-7: color(display-p3 0.207 0.403 0.373);
      --mint-8: color(display-p3 0.258 0.49 0.441);
      --mint-9: color(display-p3 0.62 0.908 0.834);
      --mint-10: color(display-p3 0.725 0.954 0.898);
      --mint-11: color(display-p3 0.482 0.825 0.733);
      --mint-12: color(display-p3 0.807 0.955 0.887);
      --mint-a1: color(display-p3 0 0.992 0.992/0.017);
      --mint-a2: color(display-p3 0.071 0.98 0.98/0.043);
      --mint-a3: color(display-p3 0.176 0.996 0.996/0.11);
      --mint-a4: color(display-p3 0.071 0.996 0.973/0.169);
      --mint-a5: color(display-p3 0.243 1 0.949/0.223);
      --mint-a6: color(display-p3 0.369 1 0.933/0.286);
      --mint-a7: color(display-p3 0.459 1 0.914/0.362);
      --mint-a8: color(display-p3 0.49 1 0.89/0.454);
      --mint-a9: color(display-p3 0.678 0.996 0.914/0.904);
      --mint-a10: color(display-p3 0.761 1 0.941/0.95);
      --mint-a11: color(display-p3 0.482 0.825 0.733);
      --mint-a12: color(display-p3 0.807 0.955 0.887);

      --lime-1: color(display-p3 0.067 0.073 0.048);
      --lime-2: color(display-p3 0.086 0.1 0.067);
      --lime-3: color(display-p3 0.13 0.16 0.099);
      --lime-4: color(display-p3 0.172 0.214 0.126);
      --lime-5: color(display-p3 0.213 0.266 0.153);
      --lime-6: color(display-p3 0.257 0.321 0.182);
      --lime-7: color(display-p3 0.307 0.383 0.215);
      --lime-8: color(display-p3 0.365 0.456 0.25);
      --lime-9: color(display-p3 0.78 0.928 0.466);
      --lime-10: color(display-p3 0.865 0.995 0.519);
      --lime-11: color(display-p3 0.771 0.893 0.485);
      --lime-12: color(display-p3 0.905 0.966 0.753);
      --lime-a1: color(display-p3 0.067 0.941 0/0.009);
      --lime-a2: color(display-p3 0.584 0.996 0.071/0.038);
      --lime-a3: color(display-p3 0.69 1 0.38/0.101);
      --lime-a4: color(display-p3 0.729 1 0.435/0.16);
      --lime-a5: color(display-p3 0.745 1 0.471/0.215);
      --lime-a6: color(display-p3 0.769 1 0.482/0.274);
      --lime-a7: color(display-p3 0.769 1 0.506/0.341);
      --lime-a8: color(display-p3 0.784 1 0.51/0.416);
      --lime-a9: color(display-p3 0.839 1 0.502/0.925);
      --lime-a10: color(display-p3 0.871 1 0.522/0.996);
      --lime-a11: color(display-p3 0.771 0.893 0.485);
      --lime-a12: color(display-p3 0.905 0.966 0.753);

      --yellow-1: color(display-p3 0.078 0.069 0.047);
      --yellow-2: color(display-p3 0.103 0.094 0.063);
      --yellow-3: color(display-p3 0.168 0.137 0.039);
      --yellow-4: color(display-p3 0.209 0.169 0);
      --yellow-5: color(display-p3 0.255 0.209 0);
      --yellow-6: color(display-p3 0.31 0.261 0.07);
      --yellow-7: color(display-p3 0.389 0.331 0.135);
      --yellow-8: color(display-p3 0.497 0.42 0.182);
      --yellow-9: color(display-p3 1 0.92 0.22);
      --yellow-10: color(display-p3 1 1 0.456);
      --yellow-11: color(display-p3 0.948 0.885 0.392);
      --yellow-12: color(display-p3 0.959 0.934 0.731);
      --yellow-a1: color(display-p3 0.973 0.369 0/0.013);
      --yellow-a2: color(display-p3 0.996 0.792 0/0.038);
      --yellow-a3: color(display-p3 0.996 0.71 0/0.11);
      --yellow-a4: color(display-p3 0.996 0.741 0/0.152);
      --yellow-a5: color(display-p3 0.996 0.765 0/0.202);
      --yellow-a6: color(display-p3 0.996 0.816 0.082/0.261);
      --yellow-a7: color(display-p3 1 0.831 0.263/0.345);
      --yellow-a8: color(display-p3 1 0.831 0.314/0.463);
      --yellow-a9: color(display-p3 1 0.922 0.22);
      --yellow-a10: color(display-p3 1 1 0.455);
      --yellow-a11: color(display-p3 0.948 0.885 0.392);
      --yellow-a12: color(display-p3 0.959 0.934 0.731);

      --amber-1: color(display-p3 0.082 0.07 0.05);
      --amber-2: color(display-p3 0.111 0.094 0.064);
      --amber-3: color(display-p3 0.178 0.128 0.049);
      --amber-4: color(display-p3 0.239 0.156 0);
      --amber-5: color(display-p3 0.29 0.193 0);
      --amber-6: color(display-p3 0.344 0.245 0.076);
      --amber-7: color(display-p3 0.422 0.314 0.141);
      --amber-8: color(display-p3 0.535 0.399 0.189);
      --amber-9: color(display-p3 1 0.77 0.26);
      --amber-10: color(display-p3 1 0.87 0.15);
      --amber-11: color(display-p3 1 0.8 0.29);
      --amber-12: color(display-p3 0.984 0.909 0.726);
      --amber-a1: color(display-p3 0.992 0.298 0/0.017);
      --amber-a2: color(display-p3 0.988 0.651 0/0.047);
      --amber-a3: color(display-p3 1 0.6 0/0.118);
      --amber-a4: color(display-p3 1 0.557 0/0.185);
      --amber-a5: color(display-p3 1 0.592 0/0.24);
      --amber-a6: color(display-p3 1 0.659 0.094/0.299);
      --amber-a7: color(display-p3 1 0.714 0.263/0.383);
      --amber-a8: color(display-p3 0.996 0.729 0.306/0.5);
      --amber-a9: color(display-p3 1 0.769 0.259);
      --amber-a10: color(display-p3 1 0.871 0.149);
      --amber-a11: color(display-p3 1 0.8 0.29);
      --amber-a12: color(display-p3 0.984 0.909 0.726);

      --gold-1: color(display-p3 0.071 0.071 0.067);
      --gold-2: color(display-p3 0.104 0.101 0.09);
      --gold-3: color(display-p3 0.141 0.136 0.122);
      --gold-4: color(display-p3 0.177 0.17 0.152);
      --gold-5: color(display-p3 0.217 0.207 0.185);
      --gold-6: color(display-p3 0.265 0.252 0.225);
      --gold-7: color(display-p3 0.327 0.31 0.277);
      --gold-8: color(display-p3 0.407 0.384 0.342);
      --gold-9: color(display-p3 0.579 0.517 0.41);
      --gold-10: color(display-p3 0.628 0.566 0.463);
      --gold-11: color(display-p3 0.784 0.728 0.635);
      --gold-12: color(display-p3 0.906 0.887 0.855);
      --gold-a1: color(display-p3 0.855 0.855 0.071/0.005);
      --gold-a2: color(display-p3 0.98 0.89 0.616/0.043);
      --gold-a3: color(display-p3 1 0.949 0.753/0.08);
      --gold-a4: color(display-p3 1 0.933 0.8/0.118);
      --gold-a5: color(display-p3 1 0.949 0.804/0.16);
      --gold-a6: color(display-p3 1 0.925 0.8/0.215);
      --gold-a7: color(display-p3 1 0.945 0.831/0.278);
      --gold-a8: color(display-p3 1 0.937 0.82/0.366);
      --gold-a9: color(display-p3 0.996 0.882 0.69/0.551);
      --gold-a10: color(display-p3 1 0.894 0.725/0.601);
      --gold-a11: color(display-p3 0.784 0.728 0.635);
      --gold-a12: color(display-p3 0.906 0.887 0.855);

      --bronze-1: color(display-p3 0.076 0.067 0.063);
      --bronze-2: color(display-p3 0.106 0.097 0.093);
      --bronze-3: color(display-p3 0.147 0.132 0.125);
      --bronze-4: color(display-p3 0.185 0.166 0.156);
      --bronze-5: color(display-p3 0.227 0.202 0.19);
      --bronze-6: color(display-p3 0.278 0.246 0.23);
      --bronze-7: color(display-p3 0.343 0.302 0.281);
      --bronze-8: color(display-p3 0.426 0.374 0.347);
      --bronze-9: color(display-p3 0.611 0.507 0.455);
      --bronze-10: color(display-p3 0.66 0.556 0.504);
      --bronze-11: color(display-p3 0.81 0.707 0.655);
      --bronze-12: color(display-p3 0.921 0.88 0.854);
      --bronze-a1: color(display-p3 0.941 0.067 0/0.009);
      --bronze-a2: color(display-p3 0.98 0.8 0.706/0.043);
      --bronze-a3: color(display-p3 0.988 0.851 0.761/0.085);
      --bronze-a4: color(display-p3 0.996 0.839 0.78/0.127);
      --bronze-a5: color(display-p3 0.996 0.863 0.773/0.173);
      --bronze-a6: color(display-p3 1 0.863 0.796/0.227);
      --bronze-a7: color(display-p3 1 0.867 0.8/0.295);
      --bronze-a8: color(display-p3 1 0.859 0.788/0.387);
      --bronze-a9: color(display-p3 1 0.82 0.733/0.585);
      --bronze-a10: color(display-p3 1 0.839 0.761/0.635);
      --bronze-a11: color(display-p3 0.81 0.707 0.655);
      --bronze-a12: color(display-p3 0.921 0.88 0.854);

      --gray-1: color(display-p3 0.067 0.067 0.067);
      --gray-2: color(display-p3 0.098 0.098 0.098);
      --gray-3: color(display-p3 0.135 0.135 0.135);
      --gray-4: color(display-p3 0.163 0.163 0.163);
      --gray-5: color(display-p3 0.192 0.192 0.192);
      --gray-6: color(display-p3 0.228 0.228 0.228);
      --gray-7: color(display-p3 0.283 0.283 0.283);
      --gray-8: color(display-p3 0.375 0.375 0.375);
      --gray-9: color(display-p3 0.431 0.431 0.431);
      --gray-10: color(display-p3 0.484 0.484 0.484);
      --gray-11: color(display-p3 0.706 0.706 0.706);
      --gray-12: color(display-p3 0.933 0.933 0.933);
      --gray-a1: color(display-p3 0 0 0/0);
      --gray-a2: color(display-p3 1 1 1/0.034);
      --gray-a3: color(display-p3 1 1 1/0.071);
      --gray-a4: color(display-p3 1 1 1/0.105);
      --gray-a5: color(display-p3 1 1 1/0.134);
      --gray-a6: color(display-p3 1 1 1/0.172);
      --gray-a7: color(display-p3 1 1 1/0.231);
      --gray-a8: color(display-p3 1 1 1/0.332);
      --gray-a9: color(display-p3 1 1 1/0.391);
      --gray-a10: color(display-p3 1 1 1/0.445);
      --gray-a11: color(display-p3 1 1 1/0.685);
      --gray-a12: color(display-p3 1 1 1/0.929);

      --mauve-1: color(display-p3 0.07 0.067 0.074);
      --mauve-2: color(display-p3 0.101 0.098 0.105);
      --mauve-3: color(display-p3 0.138 0.134 0.144);
      --mauve-4: color(display-p3 0.167 0.161 0.175);
      --mauve-5: color(display-p3 0.196 0.189 0.206);
      --mauve-6: color(display-p3 0.232 0.225 0.245);
      --mauve-7: color(display-p3 0.286 0.277 0.302);
      --mauve-8: color(display-p3 0.383 0.373 0.408);
      --mauve-9: color(display-p3 0.434 0.428 0.467);
      --mauve-10: color(display-p3 0.487 0.48 0.519);
      --mauve-11: color(display-p3 0.707 0.7 0.735);
      --mauve-12: color(display-p3 0.933 0.933 0.94);
      --mauve-a1: color(display-p3 0 0 0/0);
      --mauve-a2: color(display-p3 0.996 0.992 1/0.034);
      --mauve-a3: color(display-p3 0.937 0.933 0.992/0.077);
      --mauve-a4: color(display-p3 0.957 0.918 0.996/0.111);
      --mauve-a5: color(display-p3 0.937 0.906 0.996/0.145);
      --mauve-a6: color(display-p3 0.953 0.925 0.996/0.183);
      --mauve-a7: color(display-p3 0.945 0.929 1/0.246);
      --mauve-a8: color(display-p3 0.937 0.918 1/0.361);
      --mauve-a9: color(display-p3 0.933 0.918 1/0.424);
      --mauve-a10: color(display-p3 0.941 0.925 1/0.479);
      --mauve-a11: color(display-p3 0.965 0.961 1/0.712);
      --mauve-a12: color(display-p3 0.992 0.992 1/0.937);

      --slate-1: color(display-p3 0.067 0.067 0.074);
      --slate-2: color(display-p3 0.095 0.098 0.105);
      --slate-3: color(display-p3 0.13 0.135 0.145);
      --slate-4: color(display-p3 0.156 0.163 0.176);
      --slate-5: color(display-p3 0.183 0.191 0.206);
      --slate-6: color(display-p3 0.215 0.226 0.244);
      --slate-7: color(display-p3 0.265 0.28 0.302);
      --slate-8: color(display-p3 0.357 0.381 0.409);
      --slate-9: color(display-p3 0.415 0.431 0.463);
      --slate-10: color(display-p3 0.469 0.483 0.514);
      --slate-11: color(display-p3 0.692 0.704 0.728);
      --slate-12: color(display-p3 0.93 0.933 0.94);
      --slate-a1: color(display-p3 0 0 0/0);
      --slate-a2: color(display-p3 0.875 0.992 1/0.034);
      --slate-a3: color(display-p3 0.882 0.933 0.992/0.077);
      --slate-a4: color(display-p3 0.882 0.953 0.996/0.111);
      --slate-a5: color(display-p3 0.878 0.929 0.996/0.145);
      --slate-a6: color(display-p3 0.882 0.949 0.996/0.183);
      --slate-a7: color(display-p3 0.882 0.929 1/0.246);
      --slate-a8: color(display-p3 0.871 0.937 1/0.361);
      --slate-a9: color(display-p3 0.898 0.937 1/0.42);
      --slate-a10: color(display-p3 0.918 0.945 1/0.475);
      --slate-a11: color(display-p3 0.949 0.969 0.996/0.708);
      --slate-a12: color(display-p3 0.988 0.992 1/0.937);

      --sage-1: color(display-p3 0.064 0.07 0.067);
      --sage-2: color(display-p3 0.092 0.098 0.094);
      --sage-3: color(display-p3 0.128 0.135 0.131);
      --sage-4: color(display-p3 0.155 0.164 0.159);
      --sage-5: color(display-p3 0.183 0.193 0.188);
      --sage-6: color(display-p3 0.218 0.23 0.224);
      --sage-7: color(display-p3 0.269 0.285 0.277);
      --sage-8: color(display-p3 0.362 0.382 0.373);
      --sage-9: color(display-p3 0.398 0.438 0.421);
      --sage-10: color(display-p3 0.453 0.49 0.474);
      --sage-11: color(display-p3 0.685 0.709 0.697);
      --sage-12: color(display-p3 0.927 0.933 0.93);
      --sage-a1: color(display-p3 0 0 0/0);
      --sage-a2: color(display-p3 0.976 0.988 0.984/0.03);
      --sage-a3: color(display-p3 0.992 0.945 0.941/0.072);
      --sage-a4: color(display-p3 0.988 0.996 0.992/0.102);
      --sage-a5: color(display-p3 0.992 1 0.996/0.131);
      --sage-a6: color(display-p3 0.973 1 0.976/0.173);
      --sage-a7: color(display-p3 0.957 1 0.976/0.233);
      --sage-a8: color(display-p3 0.957 1 0.984/0.334);
      --sage-a9: color(display-p3 0.902 1 0.957/0.397);
      --sage-a10: color(display-p3 0.929 1 0.973/0.452);
      --sage-a11: color(display-p3 0.969 1 0.988/0.688);
      --sage-a12: color(display-p3 0.992 1 0.996/0.929);

      --olive-1: color(display-p3 0.067 0.07 0.063);
      --olive-2: color(display-p3 0.095 0.098 0.091);
      --olive-3: color(display-p3 0.131 0.135 0.126);
      --olive-4: color(display-p3 0.158 0.163 0.153);
      --olive-5: color(display-p3 0.186 0.192 0.18);
      --olive-6: color(display-p3 0.221 0.229 0.215);
      --olive-7: color(display-p3 0.273 0.284 0.266);
      --olive-8: color(display-p3 0.365 0.382 0.359);
      --olive-9: color(display-p3 0.414 0.438 0.404);
      --olive-10: color(display-p3 0.467 0.49 0.458);
      --olive-11: color(display-p3 0.69 0.709 0.682);
      --olive-12: color(display-p3 0.927 0.933 0.926);
      --olive-a1: color(display-p3 0 0 0/0);
      --olive-a2: color(display-p3 0.984 0.988 0.976/0.03);
      --olive-a3: color(display-p3 0.992 0.996 0.988/0.068);
      --olive-a4: color(display-p3 0.953 0.996 0.949/0.102);
      --olive-a5: color(display-p3 0.969 1 0.965/0.131);
      --olive-a6: color(display-p3 0.973 1 0.969/0.169);
      --olive-a7: color(display-p3 0.98 1 0.961/0.228);
      --olive-a8: color(display-p3 0.961 1 0.957/0.334);
      --olive-a9: color(display-p3 0.949 1 0.922/0.397);
      --olive-a10: color(display-p3 0.953 1 0.941/0.452);
      --olive-a11: color(display-p3 0.976 1 0.965/0.688);
      --olive-a12: color(display-p3 0.992 1 0.992/0.929);

      --sand-1: color(display-p3 0.067 0.067 0.063);
      --sand-2: color(display-p3 0.098 0.098 0.094);
      --sand-3: color(display-p3 0.135 0.135 0.129);
      --sand-4: color(display-p3 0.164 0.163 0.156);
      --sand-5: color(display-p3 0.193 0.192 0.183);
      --sand-6: color(display-p3 0.23 0.229 0.217);
      --sand-7: color(display-p3 0.285 0.282 0.267);
      --sand-8: color(display-p3 0.384 0.378 0.357);
      --sand-9: color(display-p3 0.434 0.428 0.403);
      --sand-10: color(display-p3 0.487 0.481 0.456);
      --sand-11: color(display-p3 0.707 0.703 0.68);
      --sand-12: color(display-p3 0.933 0.933 0.926);
      --sand-a1: color(display-p3 0 0 0/0);
      --sand-a2: color(display-p3 0.992 0.992 0.988/0.034);
      --sand-a3: color(display-p3 0.996 0.996 0.992/0.072);
      --sand-a4: color(display-p3 0.992 0.992 0.953/0.106);
      --sand-a5: color(display-p3 1 1 0.965/0.135);
      --sand-a6: color(display-p3 1 0.976 0.929/0.177);
      --sand-a7: color(display-p3 1 0.984 0.929/0.236);
      --sand-a8: color(display-p3 1 0.976 0.925/0.341);
      --sand-a9: color(display-p3 1 0.98 0.925/0.395);
      --sand-a10: color(display-p3 1 0.992 0.933/0.45);
      --sand-a11: color(display-p3 1 0.996 0.961/0.685);
      --sand-a12: color(display-p3 1 1 0.992/0.929);

      --gray-2-translucent: color(display-p3 0.1137 0.1137 0.1137/0.7);
      --mauve-2-translucent: color(display-p3 0.1176 0.1137 0.1176/0.7);
      --slate-2-translucent: color(display-p3 0.1059 0.1137 0.1176/0.7);
      --sage-2-translucent: color(display-p3 0.102 0.1137 0.1059/0.7);
      --olive-2-translucent: color(display-p3 0.1059 0.1137 0.102/0.7);
      --sand-2-translucent: color(display-p3 0.1137 0.1137 0.1059/0.7);

      --gray-surface: color(display-p3 0.1255 0.1255 0.1255/0.5);
      --mauve-surface: color(display-p3 0.1333 0.1255 0.1333/0.5);
      --slate-surface: color(display-p3 0.1176 0.1255 0.1333/0.5);
      --sage-surface: color(display-p3 0.1176 0.1255 0.1176/0.5);
      --olive-surface: color(display-p3 0.1176 0.1255 0.1176/0.5);
      --sand-surface: color(display-p3 0.1255 0.1255 0.1255/0.5);
      --tomato-surface: color(display-p3 0.1569 0.0941 0.0784/0.5);
      --red-surface: color(display-p3 0.1647 0.0863 0.0863/0.5);
      --ruby-surface: color(display-p3 0.1569 0.0941 0.1098/0.5);
      --crimson-surface: color(display-p3 0.1647 0.0863 0.1176/0.5);
      --pink-surface: color(display-p3 0.1725 0.0784 0.149/0.5);
      --plum-surface: color(display-p3 0.1647 0.0863 0.1725/0.5);
      --purple-surface: color(display-p3 0.149 0.0941 0.1961/0.5);
      --violet-surface: color(display-p3 0.1333 0.102 0.2118/0.5);
      --iris-surface: color(display-p3 0.1098 0.102 0.2118/0.5);
      --indigo-surface: color(display-p3 0.0941 0.1098 0.2196/0.5);
      --blue-surface: color(display-p3 0.0706 0.1255 0.2196/0.5);
      --cyan-surface: color(display-p3 0.0784 0.1412 0.1725/0.5);
      --teal-surface: color(display-p3 0.0863 0.149 0.1412/0.5);
      --jade-surface: color(display-p3 0.0863 0.149 0.1176/0.5);
      --green-surface: color(display-p3 0.0941 0.1412 0.1098/0.5);
      --grass-surface: color(display-p3 0.102 0.1333 0.102/0.5);
      --brown-surface: color(display-p3 0.1412 0.1176 0.102/0.5);
      --bronze-surface: color(display-p3 0.1412 0.1255 0.1176/0.5);
      --gold-surface: color(display-p3 0.1412 0.1333 0.1098/0.5);
      --sky-surface: color(display-p3 0.0863 0.1333 0.2196/0.5);
      --mint-surface: color(display-p3 0.0941 0.149 0.1412/0.5);
      --lime-surface: color(display-p3 0.1098 0.1255 0.0784/0.5);
      --yellow-surface: color(display-p3 0.1333 0.1176 0.0706/0.5);
      --amber-surface: color(display-p3 0.1412 0.1176 0.0784/0.5);
      --orange-surface: color(display-p3 0.1412 0.1098 0.0706/0.5)
    }
  }
}
*/
:where(html) {
  --black-a1: oklch(0% 0 0 / 5%);
  --black-a2: oklch(0% 0 0 / 10%);
  --black-a3: oklch(0% 0 0 / 15%);
  --black-a4: oklch(0% 0 0 / 20%);
  --black-a5: oklch(0% 0 0 / 30%);
  --black-a6: oklch(0% 0 0 / 40%);
  --black-a7: oklch(0% 0 0 / 50%);
  --black-a8: oklch(0% 0 0 / 60%);
  --black-a9: oklch(0% 0 0 / 70%);
  --black-a10: oklch(0% 0 0 / 80%);
  --black-a11: oklch(0% 0 0 / 90%);
  --black-a12: oklch(0% 0 0 / 95%);
  --white-a1: oklch(100% 0 0 / 5%);
  --white-a2: oklch(100% 0 0 / 10%);
  --white-a3: oklch(100% 0 0 / 15%);
  --white-a4: oklch(100% 0 0 / 20%);
  --white-a5: oklch(100% 0 0 / 30%);
  --white-a6: oklch(100% 0 0 / 40%);
  --white-a7: oklch(100% 0 0 / 50%);
  --white-a8: oklch(100% 0 0 / 60%);
  --white-a9: oklch(100% 0 0 / 70%);
  --white-a10: oklch(100% 0 0 / 80%);
  --white-a11: oklch(100% 0 0 / 90%);
  --white-a12: oklch(100% 0 0 / 95%);
  --tomato-9-contrast: oklch(100% 0 0);
  --red-9-contrast: oklch(100% 0 0);
  --ruby-9-contrast: oklch(100% 0 0);
  --crimson-9-contrast: oklch(100% 0 0);
  --pink-9-contrast: oklch(100% 0 0);
  --plum-9-contrast: oklch(100% 0 0);
  --purple-9-contrast: oklch(100% 0 0);
  --violet-9-contrast: oklch(100% 0 0);
  --iris-9-contrast: oklch(100% 0 0);
  --indigo-9-contrast: oklch(100% 0 0);
  --blue-9-contrast: oklch(100% 0 0);
  --cyan-9-contrast: oklch(100% 0 0);
  --teal-9-contrast: oklch(100% 0 0);
  --jade-9-contrast: oklch(100% 0 0);
  --green-9-contrast: oklch(100% 0 0);
  --grass-9-contrast: oklch(100% 0 0);
  --orange-9-contrast: oklch(100% 0 0);
  --brown-9-contrast: oklch(100% 0 0);
  --sky-9-contrast: oklch(24.11% 0.01 248.23);
  --mint-9-contrast: oklch(23.96% 0.012 167.56);
  --lime-9-contrast: oklch(24.17% 0.011 139.43);
  --yellow-9-contrast: oklch(24.31% 0.008 95.37);
  --amber-9-contrast: oklch(24.31% 0.008 95.37);
  --gold-9-contrast: oklch(100% 0 0);
  --bronze-9-contrast: oklch(100% 0 0);
  --gray-9-contrast: oklch(100% 0 0);
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :where(html) {
      --black-a1:color(display-p3 0 0 0/0.05);
      --black-a2: color(display-p3 0 0 0/0.1);
      --black-a3: color(display-p3 0 0 0/0.15);
      --black-a4: color(display-p3 0 0 0/0.2);
      --black-a5: color(display-p3 0 0 0/0.3);
      --black-a6: color(display-p3 0 0 0/0.4);
      --black-a7: color(display-p3 0 0 0/0.5);
      --black-a8: color(display-p3 0 0 0/0.6);
      --black-a9: color(display-p3 0 0 0/0.7);
      --black-a10: color(display-p3 0 0 0/0.8);
      --black-a11: color(display-p3 0 0 0/0.9);
      --black-a12: color(display-p3 0 0 0/0.95);
      --white-a1: color(display-p3 1 1 1/0.05);
      --white-a2: color(display-p3 1 1 1/0.1);
      --white-a3: color(display-p3 1 1 1/0.15);
      --white-a4: color(display-p3 1 1 1/0.2);
      --white-a5: color(display-p3 1 1 1/0.3);
      --white-a6: color(display-p3 1 1 1/0.4);
      --white-a7: color(display-p3 1 1 1/0.5);
      --white-a8: color(display-p3 1 1 1/0.6);
      --white-a9: color(display-p3 1 1 1/0.7);
      --white-a10: color(display-p3 1 1 1/0.8);
      --white-a11: color(display-p3 1 1 1/0.9);
      --white-a12: color(display-p3 1 1 1/0.95) ;
    }
  }
}
/* This is a copy file from citizen directory (Juror Access). Updated with new variables. */
:where(html) {
  --size-2: calc(2rem / var(--rem-base));
  --size-4: calc(4rem / var(--rem-base));
  --size-6: calc(6rem / var(--rem-base));
  --size-8: calc(8rem / var(--rem-base));
  --size-12: calc(12rem / var(--rem-base));
  --size-16: calc(16rem / var(--rem-base));
  --size-18: calc(18rem / var(--rem-base));
  --size-20: calc(20rem / var(--rem-base));
  --size-24: calc(24rem / var(--rem-base));
  --size-32: calc(32rem / var(--rem-base));
  --size-40: calc(40rem / var(--rem-base));
  --size-48: calc(48rem / var(--rem-base));
  --size-56: calc(56rem / var(--rem-base));
  --size-64: calc(64rem / var(--rem-base));
}

:where(html) {
  --font-sans: "InterVariable", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --font-mono: "IBM Plex Mono", ui-monospace, SF Mono, monospace;
  --font-icons: "Material Symbols Outlined";
  --rem-base: 16;
  --scale-4: calc(8rem / var(--rem-base));
  --scale-3: calc(10rem / var(--rem-base));
  --scale-2: calc(12rem / var(--rem-base));
  --scale-1: calc(14rem / var(--rem-base));
  --scale0: calc(16rem / var(--rem-base));
  --scale1: calc(18rem / var(--rem-base));
  --scale2: calc(20rem / var(--rem-base));
  --scale3: calc(24rem / var(--rem-base));
  --scale4: calc(28rem / var(--rem-base));
  --scale5: calc(32rem / var(--rem-base));
  --scale6: calc(36rem / var(--rem-base));
  --scale7: calc(40rem / var(--rem-base));
  --scale8: calc(48rem / var(--rem-base));
  --lh-scale-2: calc(16rem / var(--rem-base));
  --lh-scale-1: calc(18rem / var(--rem-base));
  --lh-scale0: calc(20rem / var(--rem-base));
  --lh-scale1: calc(22rem / var(--rem-base));
  --lh-scale2: calc(24rem / var(--rem-base));
  --lh-scale3: calc(28rem / var(--rem-base));
  --lh-scale4: calc(32rem / var(--rem-base));
  --lh-scale5: calc(36rem / var(--rem-base));
  --lh-scale6: calc(40rem / var(--rem-base));
  --lh-scale7: calc(42rem / var(--rem-base));
  --lh-scale8: calc(50rem / var(--rem-base));
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --small-text: character-variant(lc-l-with-tail);
  --mdc-typography-font-family: var(--font-sans);
  --mdc-typography-body2-font-family: var(--font-sans);
  --mdc-typography-body2-font-size: var(--scale-1);
  --mdc-typography-body2-line-height: var(--lh-scale-1);
  --mdc-typography-body2-letter-spacing: normal;
}

/* This is a copy file from citizen directory (Juror Access). Updated with new variables. */
/* Badges */
/* UI Backgrounds */
/* Core UI Colours */
@media (prefers-color-scheme: dark) {
  /* UI Backgrounds */
  /* Core UI Colours */
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list], ul,
ol[role=list] ol,
li,
menu {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  min-height: 100dvh;
  overflow: hidden;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  font-weight: normal;
  color: var(--gray-70);
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

fieldset, legend {
  padding: 0;
  border: none;
}

address {
  font-style: normal;
}

/* Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "InterVariable";
  font-style: normal;
  font-weight: 100 900;
  font-display: block;
  src: url("../../fonts/InterVariable.woff2?v=4.1") format("woff2");
}
@font-face {
  font-family: "InterVariable";
  font-style: italic;
  font-weight: 100 900;
  font-display: block;
  src: url("../../fonts/InterVariable-Italic.woff2?v=4.1") format("woff2");
}
@font-feature-values InterVariable {
  @character-variant {
    cv01: 1;
    cv02: 2;
    cv03: 3;
    cv04: 4;
    cv05: 5;
    cv06: 6;
    cv07: 7;
    cv08: 8;
    cv09: 9;
    cv10: 10;
    cv11: 11;
    cv12: 12;
    cv13: 13;
    alt-1: 1; /* Alternate one */
    alt-3: 9; /* Flat-top three */
    open-4: 2; /* Open four */
    open-6: 3; /* Open six */
    open-9: 4; /* Open nine */
    lc-l-with-tail: 5; /* Lower-case L with tail */
    simplified-u: 6; /* Simplified u */
    alt-double-s: 7; /* Alternate German double s */
    uc-i-with-serif: 8; /* Upper-case i with serif */
    uc-g-with-spur: 10; /* Capital G with spur */
    single-story-a: 11; /* Single-story a */
    compact-lc-f: 12; /* Compact f */
    compact-lc-t: 13; /* Compact t */
  }
  @styleset {
    ss01: 1;
    ss02: 2;
    ss03: 3;
    ss04: 4;
    ss05: 5;
    ss06: 6;
    ss07: 7;
    ss08: 8;
    open-digits: 1; /* Open digits */
    disambiguation: 2; /* Disambiguation (with zero) */
    disambiguation-except-zero: 4; /* Disambiguation (no zero) */
    round-quotes-and-commas: 3; /* Round quotes &amp; commas */
    square-punctuation: 7; /* Square punctuation */
    square-quotes: 8; /* Square quotes */
    circled-characters: 5; /* Circled characters */
    squared-characters: 6; /* Squared characters */
  }
}
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url("../../fonts/MaterialSymbolsOutlined.woff2") format("woff2");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/IBMPlexMono-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/IBMPlexMono-Italic.woff2") format("woff2");
}
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/IBMPlexMono-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Libre Barcode 39";
  font-style: normal;
  font-weight: 400;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url("../../fonts/LibreBarcode39-Regular.ttf") format("truetype");
}
/* This is an old version. Updated one is located in the jury-manager directory. */
/*
Citizen Design System
Version 0.1a

Table of Contents
-----------------------
00 Structure
01 Cards
02 Lists
03 Forms
04 Buttons & Links
05 Interactive
-> 05A Tooltips
06 Utility
07 Modals

** Supports
-----------------------

$breakpoint-xs: 320px;
$breakpoint-sm: 480px;
$breakpoint-md: 672px;
$breakpoint-lg: 1056px;
$breakpoint-xl: 1312px;
$breakpoint-xxl: 1584px;
$breakpoint-xxxl: 1728px;
*/
/* This is a copy file from citizen directory (Juror Access). Updated with new variables. */
:root {
  font-family: var(--font-sans);
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: var(--font-sans);
  }
}
html, body {
  font-family: var(--font-sans);
  font-size: var(--scale0);
  line-height: var(--lh-scale0);
  color: var(--text-primary);
  -webkit-text-size-adjust: 100%;
}

@media (-webkit-min-device-pixel-ratio: 2) {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
p {
  font-weight: normal;
  margin-bottom: 0;
}

em {
  font-style: italic;
}

code,
kbd,
samp {
  font-family: var(--font-mono);
  letter-spacing: normal;
}

strong {
  font-weight: 600;
}

a, .btn-link {
  color: var(--primary);
  text-decoration: none;
  transition: color 150ms ease-out;
}
a:hover, .btn-link:hover {
  color: var(--primary-hover);
}

.label-01 {
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  color: var(--text-secondary);
  font-variant-alternates: var(--small-text);
}
.label-01:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-2) / 2);
}
.label-01:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-2);
}
.label-01:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-2) * 1.5);
}

.label-02 {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  color: var(--text-secondary);
  font-variant-alternates: var(--small-text);
}
.label-02:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-1) / 2);
}
.label-02:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-1);
}
.label-02:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-1) * 1.5);
}

.helper-text-01 {
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  color: var(--text-helper);
  font-variant-alternates: var(--small-text);
}
.helper-text-01:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-2) / 2);
}
.helper-text-01:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-2);
}
.helper-text-01:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-2) * 1.5);
}

.helper-text-02 {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  color: var(--text-helper);
  font-variant-alternates: var(--small-text);
}
.helper-text-02:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-1) / 2);
}
.helper-text-02:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-1);
}
.helper-text-02:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-1) * 1.5);
}

.body-01 {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}
.body-01:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-1) / 2);
}
.body-01:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-1);
}
.body-01:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-1) * 1.5);
}

.body-02 {
  font-size: var(--scale0);
  line-height: var(--lh-scale0);
  font-variant-alternates: var(--small-text);
}
.body-02:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale0) / 2);
}
.body-02:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale0);
}
.body-02:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale0) * 1.5);
}

.body-03 {
  font-size: var(--scale1);
  line-height: var(--lh-scale1);
  font-variant-alternates: var(--small-text);
}
.body-03:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale1) / 2);
}
.body-03:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale1);
}
.body-03:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale1) * 1.5);
}

.body-04 {
  font-size: var(--scale2);
  line-height: var(--lh-scale2);
}
.body-04:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale2) / 2);
}
.body-04:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale2);
}
.body-04:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale2) * 1.5);
}

:where(html) {
  --heading-weight: 550;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: var(--heading-weight);
  letter-spacing: normal;
}

.heading-01 {
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  font-weight: var(--heading-weight);
  font-variant-alternates: var(--small-text);
}
.heading-01:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-2) / 2);
}
.heading-01:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-2);
}
.heading-01:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-2) * 1.5);
}

.heading-02, h6 {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-weight: var(--heading-weight);
  font-variant-alternates: var(--small-text);
}
.heading-02:where(.margin-bottom), h6:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale-1) / 2);
}
.heading-02:where(.margin-bottom-1), h6:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale-1);
}
.heading-02:where(.margin-bottom-2), h6:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale-1) * 1.5);
}

.heading-03, h5 {
  font-size: var(--scale0);
  line-height: var(--lh-scale0);
  font-weight: var(--heading-weight);
  font-variant-alternates: var(--small-text);
}
.heading-03:where(.margin-bottom), h5:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale0) / 2);
}
.heading-03:where(.margin-bottom-1), h5:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale0);
}
.heading-03:where(.margin-bottom-2), h5:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale0) * 1.5);
}

.heading-04, h4 {
  font-size: var(--scale1);
  line-height: var(--lh-scale1);
  font-weight: var(--heading-weight);
  font-variant-alternates: var(--small-text);
}
.heading-04:where(.margin-bottom), h4:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale1) / 2);
}
.heading-04:where(.margin-bottom-1), h4:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale1);
}
.heading-04:where(.margin-bottom-2), h4:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale1) * 1.5);
}
.heading-04:where(.border-bottom), h4:where(.border-bottom) {
  padding-block-end: calc(var(--lh-scale1) / 2);
  border-bottom: 1px solid var(--slate-a6);
}
.heading-04:where(.border-bottom):has(.btn-icon-only), h4:where(.border-bottom):has(.btn-icon-only) {
  padding-block-end: 0.6875rem;
}

.heading-05, h3 {
  font-size: var(--scale2);
  line-height: var(--lh-scale2);
  font-weight: var(--heading-weight);
}
.heading-05:where(.margin-bottom), h3:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale2) / 2);
}
.heading-05:where(.margin-bottom-1), h3:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale2);
}
.heading-05:where(.margin-bottom-2), h3:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale2) * 1.5);
}
.heading-05:where(.border-bottom), h3:where(.border-bottom) {
  padding-block-end: calc(var(--lh-scale2) / 2);
  border-bottom: 1px solid var(--slate-a6);
}

.heading-06, h2 {
  font-size: var(--scale3);
  line-height: var(--lh-scale3);
  font-weight: var(--heading-weight);
}
.heading-06:where(.margin-bottom), h2:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale3) / 2);
}
.heading-06:where(.margin-bottom-1), h2:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale3);
}
.heading-06:where(.margin-bottom-2), h2:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale3) * 1.5);
}
.heading-06:where(.border-bottom), h2:where(.border-bottom) {
  padding-block-end: calc(var(--lh-scale3) / 2);
  border-bottom: 1px solid var(--slate-a6);
}

.heading-07, .history-dashboard-header h2, .history-dashboard-instruction h2, h1 {
  font-size: var(--scale5);
  line-height: var(--lh-scale5);
  font-weight: var(--heading-weight);
}
.heading-07:where(.margin-bottom), .history-dashboard-header h2:where(.margin-bottom), .history-dashboard-instruction h2:where(.margin-bottom), h1:where(.margin-bottom) {
  margin-block-end: calc(var(--lh-scale5) / 2);
}
.heading-07:where(.margin-bottom-1), .history-dashboard-header h2:where(.margin-bottom-1), .history-dashboard-instruction h2:where(.margin-bottom-1), h1:where(.margin-bottom-1) {
  margin-block-end: var(--lh-scale5);
}
.heading-07:where(.margin-bottom-2), .history-dashboard-header h2:where(.margin-bottom-2), .history-dashboard-instruction h2:where(.margin-bottom-2), h1:where(.margin-bottom-2) {
  margin-block-end: calc(var(--lh-scale5) * 1.5);
}

.display-01 {
  font-size: var(--scale3);
  line-height: var(--lh-scale3);
}

.display-02 {
  font-size: var(--scale4);
  line-height: var(--lh-scale4);
}

.display-03 {
  font-size: var(--scale5);
  line-height: var(--lh-scale5);
}

.display-04 {
  font-size: var(--scale6);
  line-height: var(--lh-scale6);
}

.display-05 {
  font-size: var(--scale7);
  line-height: var(--lh-scale7);
}

.display-06 {
  font-size: var(--scale8);
  line-height: var(--lh-scale8);
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-tertiary {
  color: var(--text-tertiary);
}

.material-icons,
.material-icons-outlined,
.material-symbols-outlined {
  font-family: var(--font-icons);
  font-size: var(--scale1);
  font-style: normal;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
}

[class*=icon--] {
  font-family: var(--font-icons);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
}

.icon--8 {
  font-size: var(--scale-4);
}
.icon--8-filled {
  font-size: var(--scale-4);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--10 {
  font-size: var(--scale-3);
}
.icon--10-filled {
  font-size: var(--scale-3);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--12 {
  font-size: var(--scale-2);
}
.icon--12-filled {
  font-size: var(--scale-2);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--14 {
  font-size: var(--scale-1);
}
.icon--14-filled {
  font-size: var(--scale-1);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--16 {
  font-size: var(--scale0);
}
.icon--16-filled {
  font-size: var(--scale0);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--18 {
  font-size: var(--scale1);
}
.icon--18-filled {
  font-size: var(--scale1);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--20 {
  font-size: var(--scale2);
}
.icon--20-filled {
  font-size: var(--scale2);
  font-variation-settings: "FILL" 1, "GRAD" 0, "opsz" 20;
}
.icon--24 {
  font-size: var(--scale3);
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.icon--24-filled {
  font-size: var(--scale3);
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
.icon--48 {
  font-size: var(--scale8);
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.fw-light {
  font-weight: var(--font-weight-light);
}

.fw-regular {
  font-weight: var(--font-weight-regular);
}

.fw-medium {
  font-weight: var(--font-weight-medium);
}

.fw-semibold {
  font-weight: var(--font-weight-semibold);
}

.fw-bold {
  font-weight: var(--font-weight-bold);
}

.numbers {
  font-variant-numeric: tabular-nums;
}

.line-length-30em {
  width: 48em;
}

.text-center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.uppercase-sm {
  font-family: var(--font-sans);
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.highlight {
  color: #323DA8;
}

.pipe {
  margin: 0 8px;
  font-size: 10px;
  opacity: 0.5;
}

.card {
  position: relative;
  background-clip: padding-box;
  background-color: var(--white);
  border: 1px solid hsl(var(--gray-90)/8%);
  border-radius: 0.5rem;
  padding: 24px;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 1px 0px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 1px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 2px -1px hsla(0, 0%, 0%, 0.05), 0px 2px 4px -2px hsla(0, 0%, 0%, 0.05), 0px 3px 6px -3px hsla(0, 0%, 0%, 0.05);
  transition: 600ms cubic-bezier(0.165, 0.84, 0.44, 1) all;
}
.card--field-group {
  background: #F4F5F5;
  width: calc(50% - 36px);
  font-size: 2.2rem;
  margin: 0 32px 32px 0;
  display: inline-block;
  border-radius: 12px;
  padding: 24px;
}
.card-with-header {
  padding: 0;
  border-radius: 8px;
}
.card.is-invalid {
  border: 1px solid #D34242;
  transition: 0.4s ease all;
  box-shadow: 0 0 16px -2px rgba(211, 66, 66, 0.2);
}

.list {
  list-style: none;
  padding: 0;
  position: relative;
}

.form-icon-control {
  position: relative;
}

.btn .animate-slide--right,
.btn .animate-slide--left, .btn-link .animate-slide--right,
.btn-link .animate-slide--left, .btn-icon-link .animate-slide--right,
.btn-icon-link .animate-slide--left {
  transition: 600ms cubic-bezier(0.165, 0.84, 0.44, 1) transform;
}
.btn:hover .animate-slide--right, .btn-link:hover .animate-slide--right, .btn-icon-link:hover .animate-slide--right {
  transform: translateX(6px);
}
.btn:hover .animate-slide--left, .btn-link:hover .animate-slide--left, .btn-icon-link:hover .animate-slide--left {
  transform: translateX(-6px);
}

.link-breadcrumb {
  font-size: inherit;
}

body.noscroll {
  overflow: hidden;
  position: fixed;
}
body:before {
  display: none;
  content: "initial";
}
@media only screen and (min-width: 320px) {
  body:before {
    content: "xs";
  }
}
@media only screen and (min-width: 480px) {
  body:before {
    content: "sm";
  }
}
@media only screen and (min-width: 672px) {
  body:before {
    content: "md";
  }
}
@media only screen and (min-width: 1056px) {
  body:before {
    content: "lg";
  }
}
@media only screen and (min-width: 1312px) {
  body:before {
    content: "xl";
  }
}
@media only screen and (min-width: 1584px) {
  body:before {
    content: "xxl";
  }
}
@media only screen and (min-width: 1728px) {
  body:before {
    content: "xxxl";
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.table th {
  background: var(--slate-a3);
  text-align: left;
  font-weight: 600;
  display: none;
}
@media only screen and (min-width: 672px) {
  .table th {
    display: table-cell;
  }
}
.table th, .table td {
  padding: 16px;
  height: 48px;
  box-sizing: border-box;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
}
.table td {
  display: flex;
  align-items: center;
  padding: 0;
  height: auto;
  border-bottom: 1px solid var(--slate-a6);
}
.table td:first-child {
  border-top: 1px solid var(--slate-a6);
}
@media only screen and (min-width: 672px) {
  .table td:first-child {
    border: 0;
  }
}
.table td:last-child {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 672px) {
  .table td:last-child {
    display: table-cell;
    justify-content: initial;
  }
}
.table td:last-child .table-action {
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}
.table td:last-child .table-action button {
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 672px) {
  .table td:last-child .table-action {
    height: initial;
    display: inline-block;
  }
}
@media only screen and (min-width: 672px) {
  .table td {
    display: table-cell;
    padding: 16px;
    height: 48px;
  }
}
.table tr {
  background: var(--color-panel-solid);
  display: block;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 672px) {
  .table tr {
    margin-bottom: 0;
    display: table-row;
    border-bottom: 1px solid var(--slate-a6);
  }
}

.table-header--mobile {
  display: inline-block;
  font-weight: 600;
  width: 110px;
  height: 40px;
  box-sizing: border-box;
  padding: 12px;
  background: var(--slate-a3);
}
@media only screen and (min-width: 672px) {
  .table-header--mobile {
    display: none;
  }
}

.table-data-content {
  width: calc(100% - 110px);
  box-sizing: border-box;
  padding: 0 12px;
}
@media only screen and (min-width: 672px) {
  .table-data-content {
    width: auto;
    padding: 0;
  }
}

.table-action {
  text-align: right;
}
.table-action button {
  appearance: none;
  border: 0;
  background: none;
  outline: 0;
}

.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row > * {
  flex: 1;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-align--center {
  align-items: center;
}
.flex-justify-center {
  justify-content: center;
}
.flex-align--start {
  align-items: flex-start;
}
.flex-align--end {
  align-items: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}

.flex-align-self--center {
  align-self: center;
}
.flex-align-self--start {
  align-self: flex-start;
}
.flex-align-self--end {
  align-self: flex-end;
}

[class*=grid-] {
  grid-column: 1/-1;
}

.grid {
  display: grid;
}
.grid-col-full {
  grid-column: 1/-1;
}
.grid-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(80px, 100%), 1fr));
  row-gap: 1.5rem;
  column-gap: 2rem;
  align-items: start;
}
.grid-auto-fit--table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(680px, 100%), 1fr));
  gap: 24px 32px;
}
.grid-auto-fit-dense {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(64px, 100%), 1fr));
  gap: 24px 16px;
}
.grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(298px, 100%), 1fr));
}
.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px 32px;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 32px;
}
@media screen and (max-width: 1200px) {
  .grid-2 {
    grid-template-columns: 1fr;
  }
}
.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px 32px;
}
@media screen and (max-width: 1200px) {
  .grid-3 {
    grid-template-columns: 1fr;
  }
}
.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px 32px;
}
@media screen and (max-width: 1200px) {
  .grid-4 {
    grid-template-columns: 1fr;
  }
}

.grid-center {
  display: grid;
  place-items: center;
}

.grid-auto-fit-dense:has(.is-invalid) {
  align-items: start;
}

.gap-2 {
  gap: var(--size-2);
}
.gap-4 {
  gap: var(--size-4);
}
.gap-8 {
  gap: var(--size-8);
}
.gap-12 {
  gap: var(--size-12);
}
.gap-16 {
  gap: var(--size-16);
}
.gap-24 {
  gap: var(--size-24);
}
.gap-32 {
  gap: var(--size-32);
}
.gap-40 {
  gap: var(--size-40);
}
.gap-48 {
  gap: var(--size-48);
}

.row-gap-2 {
  row-gap: var(--size-2);
}
.row-gap-4 {
  row-gap: var(--size-4);
}
.row-gap-8 {
  row-gap: var(--size-8);
}
.row-gap-12 {
  row-gap: var(--size-12);
}
.row-gap-16 {
  row-gap: var(--size-16);
}
.row-gap-24 {
  row-gap: var(--size-24);
}
.row-gap-32 {
  row-gap: var(--size-32);
}
.row-gap-40 {
  row-gap: var(--size-40);
}
.row-gap-48 {
  row-gap: var(--size-48);
}
.row-gap-64 {
  row-gap: var(--size-64);
}

.column-gap-2 {
  column-gap: var(--size-2);
}
.column-gap-4 {
  column-gap: var(--size-4);
}
.column-gap-8 {
  column-gap: var(--size-8);
}
.column-gap-12 {
  column-gap: var(--size-12);
}
.column-gap-16 {
  column-gap: var(--size-16);
}
.column-gap-24 {
  column-gap: var(--size-24);
}
.column-gap-32 {
  column-gap: var(--size-32);
}
.column-gap-40 {
  column-gap: var(--size-40);
}
.column-gap-48 {
  column-gap: var(--size-48);
}

.group-gap {
  gap: 24px 32px;
}

.margin-4 {
  margin: var(--size-4);
}
.margin-8 {
  margin: var(--size-8);
}
.margin-12 {
  margin: var(--size-12);
}
.margin-16 {
  margin: var(--size-16);
}
.margin-24 {
  margin: var(--size-24);
}
.margin-32 {
  margin: var(--size-32);
}
.margin-none {
  margin: 0;
}

.margin-inline-4 {
  margin-inline: var(--size-4);
}
.margin-inline-8 {
  margin-inline: var(--size-8);
}
.margin-inline-12 {
  margin-inline: var(--size-12);
}
.margin-inline-16 {
  margin-inline: var(--size-16);
}
.margin-inline-24 {
  margin-inline: var(--size-24);
}
.margin-inline-32 {
  margin-inline: var(--size-32);
}
.margin-inline-none {
  margin-inline: 0;
}
.margin-inline-auto {
  margin-inline: auto;
}

.margin-block-4 {
  margin-block: var(--size-4);
}
.margin-block-8 {
  margin-block: var(--size-8);
}
.margin-block-12 {
  margin-block: var(--size-12);
}
.margin-block-16 {
  margin-block: var(--size-16);
}
.margin-block-24 {
  margin-block: var(--size-24);
}
.margin-block-32 {
  margin-block: var(--size-32);
}
.margin-block-none {
  margin-block: 0;
}
.margin-block-auto {
  margin-block: auto;
}

.margin-top-4 {
  margin-block-start: var(--size-4);
}
.margin-top-8 {
  margin-block-start: var(--size-8);
}
.margin-top-12 {
  margin-block-start: var(--size-12);
}
.margin-top-16 {
  margin-block-start: var(--size-16);
}
.margin-top-24 {
  margin-block-start: var(--size-24);
}
.margin-top-32 {
  margin-block-start: var(--size-32);
}
.margin-top-none {
  margin-block-start: 0;
}
.margin-top-auto {
  margin-block-start: auto;
}

.margin-right-4 {
  margin-inline-end: var(--size-4);
}
.margin-right-8 {
  margin-inline-end: var(--size-8);
}
.margin-right-12 {
  margin-inline-end: var(--size-12);
}
.margin-right-16 {
  margin-inline-end: var(--size-16);
}
.margin-right-24 {
  margin-inline-end: var(--size-24);
}
.margin-right-32 {
  margin-inline-end: var(--size-32);
}
.margin-right-none {
  margin-inline-end: 0;
}
.margin-right-auto {
  margin-inline-end: auto;
}

.margin-bottom-2 {
  margin-block-end: var(--size-2);
}
.margin-bottom-4 {
  margin-block-end: var(--size-4);
}
.margin-bottom-8 {
  margin-block-end: var(--size-8);
}
.margin-bottom-12 {
  margin-block-end: var(--size-12);
}
.margin-bottom-16 {
  margin-block-end: var(--size-16);
}
.margin-bottom-24 {
  margin-block-end: var(--size-24);
}
.margin-bottom-32 {
  margin-block-end: var(--size-32);
}
.margin-bottom-none {
  margin-block-end: 0;
}
.margin-bottom-auto {
  margin-block-end: auto;
}

.margin-left-4 {
  margin-inline-start: var(--size-4);
}
.margin-left-8 {
  margin-inline-start: var(--size-8);
}
.margin-left-12 {
  margin-inline-start: var(--size-12);
}
.margin-left-16 {
  margin-inline-start: var(--size-16);
}
.margin-left-24 {
  margin-inline-start: var(--size-24);
}
.margin-left-32 {
  margin-inline-start: var(--size-32);
}
.margin-left-none {
  margin-inline-start: 0;
}
.margin-left-auto {
  margin-inline-start: auto;
}

.padding-4 {
  padding: var(--size-4);
}
.padding-8 {
  padding: var(--size-8);
}
.padding-12 {
  padding: var(--size-12);
}
.padding-16 {
  padding: var(--size-16);
}
.padding-24 {
  padding: var(--size-24);
}
.padding-32 {
  padding: var(--size-32);
}
.padding-none {
  padding: 0;
}

.padding-inline-4 {
  padding-inline: var(--size-4);
}
.padding-inline-8 {
  padding-inline: var(--size-8);
}
.padding-inline-12 {
  padding-inline: var(--size-12);
}
.padding-inline-16 {
  padding-inline: var(--size-16);
}
.padding-inline-24 {
  padding-inline: var(--size-24);
}
.padding-inline-32 {
  padding-inline: var(--size-32);
}
.padding-inline-none {
  padding-inline: 0;
}

.padding-block-4 {
  padding-block: var(--size-4);
}
.padding-block-8 {
  padding-block: var(--size-8);
}
.padding-block-12 {
  padding-block: var(--size-12);
}
.padding-block-16 {
  padding-block: var(--size-16);
}
.padding-block-24 {
  padding-block: var(--size-24);
}
.padding-block-32 {
  padding-block: var(--size-32);
}
.padding-block-none {
  padding-block: 0;
}

.padding-top-8 {
  padding-block-start: var(--size-8);
}
.padding-top-4 {
  padding-block-start: var(--size-4);
}
.padding-top-12 {
  padding-block-start: var(--size-12);
}
.padding-top-16 {
  padding-block-start: var(--size-16);
}
.padding-top-24 {
  padding-block-start: var(--size-24);
}
.padding-top-32 {
  padding-block-start: var(--size-32);
}
.padding-top-none {
  padding-block-start: 0;
}

.padding-right-4 {
  padding-inline-end: var(--size-4);
}
.padding-right-8 {
  padding-inline-end: var(--size-8);
}
.padding-right-12 {
  padding-inline-end: var(--size-12);
}
.padding-right-16 {
  padding-inline-end: var(--size-16);
}
.padding-right-24 {
  padding-inline-end: var(--size-24);
}
.padding-right-32 {
  padding-inline-end: var(--size-32);
}
.padding-right-none {
  padding-inline-end: 0;
}

.padding-bottom-4 {
  padding-block-end: var(--size-4);
}
.padding-bottom-8 {
  padding-block-end: var(--size-8);
}
.padding-bottom-12 {
  padding-block-end: var(--size-12);
}
.padding-bottom-16 {
  padding-block-end: var(--size-16);
}
.padding-bottom-24 {
  padding-block-end: var(--size-24);
}
.padding-bottom-32 {
  padding-block-end: var(--size-32);
}
.padding-bottom-none {
  padding-block-end: 0;
}

.padding-left-4 {
  padding-inline-start: var(--size-4);
}
.padding-left-8 {
  padding-inline-start: var(--size-8);
}
.padding-left-12 {
  padding-inline-start: var(--size-12);
}
.padding-left-16 {
  padding-inline-start: var(--size-16);
}
.padding-left-24 {
  padding-inline-start: var(--size-24);
}
.padding-left-32 {
  padding-inline-start: var(--size-32);
}
.padding-left-none {
  padding-inline-start: 0;
}

:where(html) {
  --sidebar-width: 240px;
  --top-bar-height: 3rem;
  --spacing: 0.5rem;
}

:where([data-layout~=xs]) {
  --layout-width: 20rem;
}

:where([data-layout~=sm]) {
  --layout-width: 30rem;
}

:where([data-layout~=md]) {
  --layout-width: 40rem;
}

:where([data-layout~=lg]) {
  --layout-width: 50rem;
}

:where([data-layout~=xl]) {
  --layout-width: 60rem;
}

:where([data-layout~=centered]) {
  --layout-alignment: auto;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-primary--wrapper {
  display: flex;
  flex: 1;
  min-height: 0;
}

.main {
  display: flex;
  flex: 1 1 auto;
  width: 0;
  overflow-y: auto;
}

.router-view {
  position: relative;
  display: flex;
  flex: auto;
  z-index: 0;
  overflow-x: hidden;
  scroll-padding-top: 24px;
}

.router-view > :first-child {
  flex: auto;
}

.layout-wrapper {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  row-gap: var(--layout-row-gap, 2rem);
  margin-inline: var(--layout-alignment);
  width: min(100%, var(--layout-width));
  padding-inline: var(--layout-padding-inline);
  padding-block: var(--layout-padding-block);
  height: max-content;
}
.layout-wrapper[data-layout~=xs] {
  --layout-width: 25rem;
}
.layout-wrapper[data-layout~=sm] {
  --layout-width: 30rem;
}
.layout-wrapper[data-layout~=md] {
  --layout-width: 40rem;
}
.layout-wrapper[data-layout~=lg] {
  --layout-width: 50rem;
}
.layout-wrapper[data-layout~=xl] {
  --layout-width: 60rem;
}
.layout-wrapper[data-layout~=xxl] {
  --layout-width: 70rem;
}
.layout-wrapper[data-layout~=x-wide] {
  --layout-width: 80rem;
}
.layout-wrapper[data-layout~=centered] {
  --layout-alignment: auto;
}
.layout-wrapper[data-layout~=block-padding] {
  --layout-padding-block: 2rem 6rem;
}
.layout-wrapper[data-layout~=block-padding-equal] {
  --layout-padding-block: 2rem;
}
.layout-wrapper[data-layout~=inline-padding] {
  --layout-padding-inline: 2rem;
}
.layout-wrapper[data-layout~=padded] {
  --layout-padding-block: 2rem 6rem;
  --layout-padding-inline: 2rem;
}
.layout-wrapper[data-layout~=padded-32] {
  --layout-padding-block: 2rem;
  --layout-padding-inline: 2rem;
}
.layout-wrapper[data-layout~=no-padding] {
  --layout-padding-block: 0;
  --layout-padding-inline: 0;
}
.layout-wrapper[data-layout~=xs]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=xs]:where([data-layout~=padded]), .layout-wrapper[data-layout~=xs]:where([data-layout~=padded-32]) {
  --layout-width: calc(25rem + (var(--layout-padding-inline) * 2));
}
.layout-wrapper[data-layout~=sm]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=sm]:where([data-layout~=padded]), .layout-wrapper[data-layout~=sm]:where([data-layout~=padded-32]) {
  --layout-width: calc(30rem + (var(--layout-padding-inline) * 2));
}
.layout-wrapper[data-layout~=md]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=md]:where([data-layout~=padded]), .layout-wrapper[data-layout~=md]:where([data-layout~=padded-32]) {
  --layout-width: calc(40rem + (var(--layout-padding-inline) * 2));
}
.layout-wrapper[data-layout~=lg]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=lg]:where([data-layout~=padded]), .layout-wrapper[data-layout~=lg]:where([data-layout~=padded-32]) {
  --layout-width: calc(50rem + (var(--layout-padding-inline) * 2));
}
.layout-wrapper[data-layout~=xl]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=xl]:where([data-layout~=padded]), .layout-wrapper[data-layout~=xl]:where([data-layout~=padded-32]) {
  --layout-width: calc(60rem + (var(--layout-padding-inline) * 2));
}
.layout-wrapper[data-layout~=xxl]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=xxl]:where([data-layout~=padded]), .layout-wrapper[data-layout~=xxl]:where([data-layout~=padded-32]) {
  --layout-width: calc(70rem + (var(--layout-padding-inline) * 2));
}
.layout-wrapper[data-layout~=x-wide]:where([data-layout~=inline-padding]), .layout-wrapper[data-layout~=x-wide]:where([data-layout~=padded]), .layout-wrapper[data-layout~=x-wide]:where([data-layout~=padded-32]) {
  --layout-width: calc(80rem + (var(--layout-padding-inline) * 2));
}

.notification-layout-wrapper {
  --layout-width: 40rem;
  --layout-alignment: auto;
  margin-inline: var(--layout-alignment);
  max-width: var(--layout-width);
}

.form-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  padding: 2rem;
  height: max-content;
}

.form-layout-with-table {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 2rem;
  padding: 2rem;
  height: max-content;
  overflow-x: hidden;
}

.form-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(298px, 100%), 1fr));
  grid-column: 1/-1;
  column-gap: 2rem;
  row-gap: 1.5rem;
  align-items: start;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hide {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

:where(.wrapper-sm) .grid-item-auto {
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.overflow-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-auto {
  overflow: auto;
  height: 100%;
  padding-bottom: 64px;
}

.settings-wrapper {
  margin-inline: var(--layout-alignment);
  max-width: var(--layout-width, 40rem);
}

.settings-section-wrapper {
  width: min(100%, var(--layout-width));
  margin-block-end: 2rem;
}
.settings-section-wrapper:only-of-type {
  margin-block-end: 0;
}
.settings-section-wrapper[data-settings-section-wrapper~=no-margin] {
  margin-block-end: 0;
}

.settings-section-wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;
  padding-block-end: calc(var(--lh-scale1) / 2);
  border-bottom: 1px solid var(--slate-a6);
  margin-block-end: 1.5rem;
}
.settings-section-wrapper-header:has(button) {
  padding-block-end: calc(var(--lh-scale1) / 2 - 1px);
  margin-block-start: -1px;
}

:where(.settings-section-wrapper:not(:first-of-type)) .settings-section-wrapper-header {
  margin-block-start: 0;
}

.settings-section {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  row-gap: var(--settings-section-row-gap, 1.5rem);
  padding-block: 1.5rem;
}
.settings-section[data-settings-section~=border-bottom] {
  border-bottom: 1px solid var(--slate-a7);
}
.settings-section[data-settings-section~=border-top] {
  border-top: 1px solid var(--slate-a7);
}
.settings-section[data-settings-section~=padding-bottom] {
  padding-block: 0 1.5rem;
}
.settings-section[data-settings-section~=gap-4] {
  --settings-section-row-gap: 0.25rem;
}
.settings-section[data-settings-section~=gap-8] {
  --settings-section-row-gap: 0.5rem;
}
.settings-section[data-settings-section~=gap-12] {
  --settings-section-row-gap: 0.75rem;
}
.settings-section[data-settings-section~=gap-16] {
  --settings-section-row-gap: 1rem;
}
.settings-section[data-settings-section~=gap-32] {
  --settings-section-row-gap: 2rem;
}
.settings-section[data-settings-section~=filled] {
  padding: 1rem;
  background-color: var(--slate-a2);
  border: 1px solid var(--slate-a6);
  border-radius: 0.25rem;
}

.settings-row-wrapper {
  display: grid;
  row-gap: 0.5rem;
}

.settings-row {
  display: flex;
  column-gap: 1rem;
  row-gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.settings-row[data-settings-row~=filled] {
  padding: 1rem;
  background-color: var(--slate-a2);
  border: 1px solid var(--slate-a6);
  border-radius: 0.25rem;
}

.settings-row:has(.is-invalid),
.input-group-inline:has(.is-invalid) {
  align-items: flex-start;
}

.settings-row--description {
  display: grid;
  row-gap: var(--size-4);
  flex: 1 1 12rem;
}

.pay-option--mileage {
  padding: 1rem;
  align-items: center;
  background-color: var(--slate-a2);
  border: 1px solid var(--slate-a6);
  border-radius: 0.25rem;
}

.wd-8 {
  width: 0.5rem;
}
.wd-16 {
  width: 1rem;
}
.wd-24 {
  width: 1.5rem;
}
.wd-half {
  width: 50%;
}
.wd-full {
  width: 100%;
}
.wd-fit-content {
  width: fit-content;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
}

.heading-border-bottom {
  padding-block-end: 1rem;
  border-bottom: 1px solid var(--slate-a6);
}
.heading-border-bottom:has(.btn-icon-only) {
  padding-block-end: 0.6875rem;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}
.cursor-grab:active {
  cursor: grabbing;
}

.cursor-pointer {
  cursor: pointer;
}

.helper-message {
  margin-top: -4px;
  display: block;
}

.divider {
  height: 1px;
  background-color: hsl(var(--gray-40));
  margin-inline: 1rem;
  align-self: center;
  width: 40px;
}

.divider-md {
  height: 1px;
  background-color: hsl(var(--gray-40));
  margin-inline: 1rem;
  align-self: center;
  flex: auto;
}

.pointer-event-initial {
  pointer-events: initial !important;
}

.info-icon {
  display: grid;
  place-items: center;
  block-size: 1.5rem;
  inline-size: 1.5rem;
  transition: color 100ms ease-out;
}
.info-icon:hover {
  color: var(--text-tertiary);
}

.badge-xs, .badge-sm, .badge-md, .badge-lg {
  display: flex;
  align-items: center;
  column-gap: var(--badge-gap, 0.25rem);
  height: var(--badge-height, var(--size-24));
  width: fit-content;
  padding-inline: var(--badge-padding, 0.5rem);
  border-radius: var(--badge-border-radius, 0.5rem);
  background-color: var(--badge-background-color, var(--slate-a3));
  color: var(--badge-text-color, var(--slate-a12));
  font-size: var(--badge-font-size, var(--scale-2));
  line-height: var(--badge-line-height, var(--lh-scale-2));
  font-weight: var(--badge-font-weight, 450);
  font-variant-alternates: character-variant(l-tail);
}
.badge-xs:has([class*=icon--]), .badge-sm:has([class*=icon--]), .badge-md:has([class*=icon--]), .badge-lg:has([class*=icon--]) {
  --badge-padding: 0.25rem 0.5rem;
}

.badge-sm {
  --badge-height: var(--size-20);
  --badge-border-radius: 0.375rem;
}

.badge-lg {
  --badge-height: var(--size-32);
  --badge-border-radius: var(--size-12);
  --badge-font-size: var(--scale0);
  --badge-line-height: var(--lh-scale0);
  --badge-font-weight: var(--font-weight-regular);
}

.badge-group-inline {
  display: flex;
  column-gap: var(--size-8);
}
:where(.badge) {
  --color-darken: calc(l - 0.125);
}

.badge {
  display: flex;
  align-items: center;
  column-gap: var(--badge-gap, var(--size-8));
  height: var(--badge-height, var(--size-24));
  width: fit-content;
  padding-inline: var(--badge-padding, var(--size-8));
  border-radius: var(--badge-border-radius, var(--size-8));
  background-color: var(--badge-background-color, var(--slate-a3));
  color: var(--badge-text-color, var(--slate-a11));
  font-size: var(--badge-font-size, var(--scale-2));
  line-height: var(--badge-line-height, var(--lh-scale-2));
  font-weight: var(--badge-font-weight, 450);
  font-variant-alternates: character-variant(l-tail);
}
.badge:has(svg) {
  --badge-padding: var(--size-6) var(--size-8);
  --badge-gap: var(--size-6);
}

.badge[data-badge~=xs] {
  --badge-height: var(--size-18);
  --badge-border-radius: var(--size-6);
}

.badge[data-badge~=sm] {
  --badge-height: var(--size-20);
  --badge-border-radius: var(--size-6);
}

.badge[data-badge~=lg] {
  --badge-height: var(--size-32);
  --badge-border-radius: var(--size-12);
}

.badge[data-badge~=informational] {
  --badge-foreground: oklch(from var(--blue-a11) var(--color-darken) c h);
  --badge-background-color: var(--blue-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=informational] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=success] {
  --badge-foreground: oklch(from var(--jade-a11) var(--color-darken) c h);
  --badge-background-color: var(--jade-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=success] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=attention] {
  --badge-foreground: oklch(from var(--yellow-a11) var(--color-darken) c h);
  --badge-background-color: var(--yellow-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=attention] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=warning] {
  --badge-foreground: oklch(from var(--orange-a11) var(--color-darken) c h);
  --badge-background-color: var(--orange-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=warning] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=critical] {
  --badge-foreground: oklch(from var(--ruby-a11) var(--color-darken) c h);
  --badge-background-color: var(--ruby-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=critical] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=selected],
.badge[data-badge~=q-pool-selected] {
  --badge-foreground: oklch(from var(--plum-a11) var(--color-darken) c h);
  --badge-background-color: var(--plum-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=selected] path,
.badge[data-badge~=q-pool-selected] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=summoned] {
  --badge-foreground: oklch(from var(--violet-a11) var(--color-darken) c h);
  --badge-background-color: var(--violet-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=summoned] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=confirmed],
.badge[data-badge~=completed] {
  --badge-foreground: oklch(from var(--jade-a11) var(--color-darken) c h);
  --badge-background-color: var(--jade-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=confirmed] path,
.badge[data-badge~=completed] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=excused],
.badge[data-badge~=qpoolexcused] {
  --badge-foreground: oklch(from var(--yellow-a11) var(--color-darken) c h);
  --badge-background-color: var(--yellow-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=excused] path,
.badge[data-badge~=qpoolexcused] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=disqualified],
.badge[data-badge~=qpooldisqualified] {
  --badge-foreground: oklch(from var(--ruby-a11) var(--color-darken) c h);
  --badge-background-color: var(--ruby-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=disqualified] path,
.badge[data-badge~=qpooldisqualified] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=deferred],
.badge[data-badge~=postponed] {
  --badge-foreground: oklch(from var(--amber-a11) var(--color-darken) c h);
  --badge-background-color: var(--amber-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=deferred] path,
.badge[data-badge~=postponed] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=panel] {
  --badge-foreground: oklch(from var(--sky-a11) var(--color-darken) c h);
  --badge-background-color: var(--sky-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=panel] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=juror] {
  --badge-foreground: oklch(from var(--jade-a11) var(--color-darken) c h);
  --badge-background-color: var(--jade-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=juror] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=undeliverable],
.badge[data-badge~=qpoolundeliverable] {
  --badge-foreground: oklch(from var(--crimson-a11) var(--color-darken) c h);
  --badge-background-color: var(--crimson-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=undeliverable] path,
.badge[data-badge~=qpoolundeliverable] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=pending-request] {
  --badge-foreground: oklch(from var(--amber-a11) var(--color-darken) c h);
  --badge-background-color: var(--amber-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=pending-request] path {
  fill: var(--badge-foreground);
}

.badge[data-badge~=not-scheduled] {
  --badge-foreground: oklch(from var(--crimson-a11) var(--color-darken) c h);
  --badge-background-color: var(--crimson-a3);
  --badge-text-color: var(--badge-foreground);
}
.badge[data-badge~=not-scheduled] path {
  fill: var(--badge-foreground);
}

.case-panel-badge {
  margin: 0;
  position: absolute;
  border-radius: 6px;
  right: 16px;
  overflow: initial;
  bottom: 8px;
  min-width: 24px;
  box-sizing: border-box;
  text-align: center;
}

.color--warning,
[class*=badge-md].color--warning,
[class*=badge-sm].color--warning,
[class*=tag-md].color--warning,
[class*=tag-sm].color--warning {
  background-color: var(--orange-a3);
  color: var(--orange-a11);
}
.color--success,
[class*=badge-md].color--success,
[class*=badge-sm].color--success,
[class*=tag-md].color--success,
[class*=tag-sm].color--success {
  background-color: var(--jade-a3);
  color: var(--jade-a11);
}
.color--informational,
[class*=badge-md].color--informational,
[class*=badge-sm].color--informational,
[class*=tag-md].color--informational,
[class*=tag-sm].color--informational {
  background-color: var(--blue-a4);
  color: var(--blue-a12);
}
.color--active,
[class*=badge-md].color--active,
[class*=badge-sm].color--active,
[class*=tag-md].color--active,
[class*=tag-sm].color--active {
  background-color: var(--jade-a4);
  color: var(--jade-a12);
}
.color--inactive,
[class*=badge-md].color--inactive,
[class*=badge-sm].color--inactive,
[class*=tag-md].color--inactive,
[class*=tag-sm].color--inactive {
  background-color: var(--amber-4);
  color: var(--amber-a12);
}
.color--yes-no,
[class*=badge-md].color--yes-no,
[class*=badge-sm].color--yes-no,
[class*=tag-md].color--yes-no,
[class*=tag-sm].color--yes-no {
  background-color: var(--jade-a4);
  color: var(--jade-a12);
}
.color--multiple-choice,
[class*=badge-md].color--multiple-choice,
[class*=badge-sm].color--multiple-choice,
[class*=tag-md].color--multiple-choice,
[class*=tag-sm].color--multiple-choice {
  background-color: var(--teal-a4);
  color: var(--teal-a12);
}
.color--text,
[class*=badge-md].color--text,
[class*=badge-sm].color--text,
[class*=tag-md].color--text,
[class*=tag-sm].color--text {
  background-color: var(--blue-a4);
  color: var(--blue-a12);
}
.color--date,
[class*=badge-md].color--date,
[class*=badge-sm].color--date,
[class*=tag-md].color--date,
[class*=tag-sm].color--date {
  background-color: var(--violet-a4);
  color: var(--violet-a12);
}
.color--candidate,
[class*=badge-md].color--candidate,
[class*=badge-sm].color--candidate,
[class*=tag-md].color--candidate,
[class*=tag-sm].color--candidate {
  background-color: var(--pink-a4);
  color: var(--pink-a12);
}
.color--disqualification,
[class*=badge-md].color--disqualification,
[class*=badge-sm].color--disqualification,
[class*=tag-md].color--disqualification,
[class*=tag-sm].color--disqualification {
  background-color: var(--crimson-a4);
  color: var(--crimson-a12);
}
.color--excusal,
[class*=badge-md].color--excusal,
[class*=badge-sm].color--excusal,
[class*=tag-md].color--excusal,
[class*=tag-sm].color--excusal {
  background-color: var(--amber-a4);
  color: var(--amber-a12);
}
.color--nested,
[class*=badge-md].color--nested,
[class*=badge-sm].color--nested,
[class*=tag-md].color--nested,
[class*=tag-sm].color--nested {
  background-color: var(--slate-a4);
  color: var(--slate-a12);
}
.color--new,
[class*=badge-md].color--new,
[class*=badge-sm].color--new,
[class*=tag-md].color--new,
[class*=tag-sm].color--new {
  background-color: var(--primary-bg);
  color: var(--primary-fg);
}
.color--not-scheduled, .color--error,
[class*=badge-md].color--not-scheduled,
[class*=badge-md].color--error,
[class*=badge-sm].color--not-scheduled,
[class*=badge-sm].color--error,
[class*=tag-md].color--not-scheduled,
[class*=tag-md].color--error,
[class*=tag-sm].color--not-scheduled,
[class*=tag-sm].color--error {
  background-color: var(--ruby-a4);
  color: var(--ruby-a12);
}
.color--not-scheduled path, .color--error path,
[class*=badge-md].color--not-scheduled path,
[class*=badge-md].color--error path,
[class*=badge-sm].color--not-scheduled path,
[class*=badge-sm].color--error path,
[class*=tag-md].color--not-scheduled path,
[class*=tag-md].color--error path,
[class*=tag-sm].color--not-scheduled path,
[class*=tag-sm].color--error path {
  fill: var(--ruby-a12);
}
.color--selected,
[class*=badge-md].color--selected,
[class*=badge-sm].color--selected,
[class*=tag-md].color--selected,
[class*=tag-sm].color--selected {
  background-color: var(--plum-a4);
  color: var(--plum-a12);
}
.color--summoned,
[class*=badge-md].color--summoned,
[class*=badge-sm].color--summoned,
[class*=tag-md].color--summoned,
[class*=tag-sm].color--summoned {
  background-color: var(--violet-a4);
  color: var(--violet-a12);
}
.color--confirmed,
[class*=badge-md].color--confirmed,
[class*=badge-sm].color--confirmed,
[class*=tag-md].color--confirmed,
[class*=tag-sm].color--confirmed {
  background-color: var(--jade-a4);
  color: var(--jade-a12);
}
.color--confirmed path,
[class*=badge-md].color--confirmed path,
[class*=badge-sm].color--confirmed path,
[class*=tag-md].color--confirmed path,
[class*=tag-sm].color--confirmed path {
  fill: var(--jade-a12);
}
.color--panel,
[class*=badge-md].color--panel,
[class*=badge-sm].color--panel,
[class*=tag-md].color--panel,
[class*=tag-sm].color--panel {
  background-color: var(--sky-a4);
  color: var(--sky-a12);
}
.color--pending-request,
[class*=badge-md].color--pending-request,
[class*=badge-sm].color--pending-request,
[class*=tag-md].color--pending-request,
[class*=tag-sm].color--pending-request {
  background-color: var(--orange-a4);
  color: var(--orange-a12);
}
.color--pending-request path,
[class*=badge-md].color--pending-request path,
[class*=badge-sm].color--pending-request path,
[class*=tag-md].color--pending-request path,
[class*=tag-sm].color--pending-request path {
  fill: var(--orange-a12);
}
.color--pending,
[class*=badge-md].color--pending,
[class*=badge-sm].color--pending,
[class*=tag-md].color--pending,
[class*=tag-sm].color--pending {
  background-color: var(--amber-a4);
  color: var(--amber-a12);
}
.color--approved, .color--panel-sent, .color--empaneled, .color--jury-empaneled, .color--juror, .color--time-in, .color--time-in-out,
[class*=badge-md].color--approved,
[class*=badge-md].color--panel-sent,
[class*=badge-md].color--empaneled,
[class*=badge-md].color--jury-empaneled,
[class*=badge-md].color--juror,
[class*=badge-md].color--time-in,
[class*=badge-md].color--time-in-out,
[class*=badge-sm].color--approved,
[class*=badge-sm].color--panel-sent,
[class*=badge-sm].color--empaneled,
[class*=badge-sm].color--jury-empaneled,
[class*=badge-sm].color--juror,
[class*=badge-sm].color--time-in,
[class*=badge-sm].color--time-in-out,
[class*=tag-md].color--approved,
[class*=tag-md].color--panel-sent,
[class*=tag-md].color--empaneled,
[class*=tag-md].color--jury-empaneled,
[class*=tag-md].color--juror,
[class*=tag-md].color--time-in,
[class*=tag-md].color--time-in-out,
[class*=tag-sm].color--approved,
[class*=tag-sm].color--panel-sent,
[class*=tag-sm].color--empaneled,
[class*=tag-sm].color--jury-empaneled,
[class*=tag-sm].color--juror,
[class*=tag-sm].color--time-in,
[class*=tag-sm].color--time-in-out {
  background-color: var(--jade-a4);
  color: var(--jade-a12);
}
.color--excused,
[class*=badge-md].color--excused,
[class*=badge-sm].color--excused,
[class*=tag-md].color--excused,
[class*=tag-sm].color--excused {
  background-color: var(--yellow-a4);
  color: var(--yellow-a12);
}
.color--excused path,
[class*=badge-md].color--excused path,
[class*=badge-sm].color--excused path,
[class*=tag-md].color--excused path,
[class*=tag-sm].color--excused path {
  fill: var(--yellow-a12);
}
.color--deferred, .color--postponed,
[class*=badge-md].color--deferred,
[class*=badge-md].color--postponed,
[class*=badge-sm].color--deferred,
[class*=badge-sm].color--postponed,
[class*=tag-md].color--deferred,
[class*=tag-md].color--postponed,
[class*=tag-sm].color--deferred,
[class*=tag-sm].color--postponed {
  background-color: var(--orange-a4);
  color: var(--orange-a12);
}
.color--deferred path, .color--postponed path,
[class*=badge-md].color--deferred path,
[class*=badge-md].color--postponed path,
[class*=badge-sm].color--deferred path,
[class*=badge-sm].color--postponed path,
[class*=tag-md].color--deferred path,
[class*=tag-md].color--postponed path,
[class*=tag-sm].color--deferred path,
[class*=tag-sm].color--postponed path {
  fill: var(--orange-a12);
}
.color--denied,
[class*=badge-md].color--denied,
[class*=badge-sm].color--denied,
[class*=tag-md].color--denied,
[class*=tag-sm].color--denied {
  background-color: var(--red-a4);
  color: var(--red-a12);
}
.color--completed, .color--pay-first-days,
[class*=badge-md].color--completed,
[class*=badge-md].color--pay-first-days,
[class*=badge-sm].color--completed,
[class*=badge-sm].color--pay-first-days,
[class*=tag-md].color--completed,
[class*=tag-md].color--pay-first-days,
[class*=tag-sm].color--completed,
[class*=tag-sm].color--pay-first-days {
  background-color: var(--jade-a4);
  color: var(--jade-a12);
}
.color--completed path, .color--pay-first-days path,
[class*=badge-md].color--completed path,
[class*=badge-md].color--pay-first-days path,
[class*=badge-sm].color--completed path,
[class*=badge-sm].color--pay-first-days path,
[class*=tag-md].color--completed path,
[class*=tag-md].color--pay-first-days path,
[class*=tag-sm].color--completed path,
[class*=tag-sm].color--pay-first-days path {
  fill: var(--jade-a12);
}
.color--undeliverable, .color--q-pool-undeliverable,
[class*=badge-md].color--undeliverable,
[class*=badge-md].color--q-pool-undeliverable,
[class*=badge-sm].color--undeliverable,
[class*=badge-sm].color--q-pool-undeliverable,
[class*=tag-md].color--undeliverable,
[class*=tag-md].color--q-pool-undeliverable,
[class*=tag-sm].color--undeliverable,
[class*=tag-sm].color--q-pool-undeliverable {
  background-color: var(--crimson-a4);
  color: var(--crimson-a12);
}
.color--text-success,
[class*=badge-md].color--text-success,
[class*=badge-sm].color--text-success,
[class*=tag-md].color--text-success,
[class*=tag-sm].color--text-success {
  color: var(--jade-a11);
}
.color--text-warning,
[class*=badge-md].color--text-warning,
[class*=badge-sm].color--text-warning,
[class*=tag-md].color--text-warning,
[class*=tag-sm].color--text-warning {
  color: var(--orange-a11);
}

body:where(.site--dev) .app-bar--page-title::before,
body:where(.site--dev) .top-bar--title::before,
body:where(.site--dev) .branding::after {
  content: "Dev";
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

body:where(.site--dev-test) .app-bar--page-title::before,
body:where(.site--dev-test) .top-bar--title::before,
body:where(.site--dev-test) .branding::after {
  content: "Dev Test";
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

body:where(.site--dev-stage) .app-bar--page-title::before,
body:where(.site--dev-stage) .top-bar--title::before,
body:where(.site--dev-stage) .branding::after {
  content: "Dev Stage";
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

body:where(.site--client-test) .app-bar--page-title::before,
body:where(.site--client-test) .top-bar--title::before,
body:where(.site--client-test) .branding::after {
  content: "Test Site";
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.disbursements-account-number--input {
  max-width: 18rem;
}

.disbursements-account-number--badge {
  --badge-text-color: var(--jade-a12);
  --badge-background-color: var(--jade-a3);
  font-variant-numeric: tabular-nums;
}
.disbursements-account-number--badge i {
  color: var(--jade-a11);
}

.utility-group {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.donation-wrapper {
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
}

.donation-type-row {
  display: grid;
  grid-template-columns: [donation-type-start] minmax(5rem, 13.25rem) [donation-type-end];
  gap: 1.5rem 1rem;
  max-width: 27.5rem;
}
.donation-type-row:has(.donation-amount) {
  grid-template-columns: [donation-type-start] minmax(5rem, 13.25rem) [donation-type-end donation-amount-start] minmax(5rem, 13.25rem) [donation-amount-end];
}

.donation-allocation {
  display: grid;
  padding-block-start: var(--size-8);
  row-gap: var(--size-16);
  container-type: inline-size;
}

.donation-allocation--row {
  display: grid;
  grid-template-columns: [charity-start] minmax(5rem, 27.5rem) [charity-end utility-start] 2.5rem [utility-end];
  column-gap: var(--size-16);
}
.donation-allocation--row:has(.donation-percent) {
  grid-template-columns: [charity-start] minmax(5rem, 27.5rem) [charity-end percent-start] 5rem [percent-end utility-start] 2.5rem [utility-end];
}

.donation-split {
  grid-area: split;
}

.donation-remove {
  margin-block-start: 1.5rem;
}

.donation-allocation--action-row {
  display: grid;
  grid-template-columns: [split-start] auto [split-end];
  align-items: center;
  column-gap: var(--size-16);
}
.donation-allocation--action-row:has(.allocation-total--text):has(.allocation-total--value) {
  grid-template-columns: [split-start] minmax(2.5rem, 13.25rem) [split-end total-text-start] minmax(2.5rem, 13.25rem) [total-text-end total-value-start] 5rem [total-value-end empty-start] 2.5rem [empty-end];
}
@container ( width < 31.25rem ) {
  .donation-allocation--action-row:has(.allocation-total--text):has(.allocation-total--value) {
    grid-template-columns: [split-start total-text-start] minmax(2.5rem, 27.5rem) [split-end total-text-end total-value-start] 5rem [total-value-end empty-start] 2.5rem [empty-end];
    grid-template-rows: [split-start] auto [split-end total-text-start total-value-start] auto [total-text-end total-value-end];
    row-gap: var(--size-16);
  }
}

.allocation-total--text {
  grid-area: total-text;
  justify-self: end;
}

.allocation-total--value {
  display: flex;
  align-items: center;
  column-gap: var(--size-4);
  grid-area: total-value;
}
.allocation-total--value > .badge-md {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  transition: background-color 80ms ease-out, color 80ms ease-out;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  font-size: var(--scale-1);
  transition: all 150ms ease-out;
}
button:disabled {
  color: hsl(var(--gray-40));
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  flex-shrink: 0;
  padding-inline: 1rem;
  border-radius: 0.25rem;
  font-size: var(--scale-1, 0.875rem);
  line-height: var(--lh-scale-1, 1.28572);
  letter-spacing: normal;
  font-weight: 450;
  font-feature-settings: "cv05";
  text-decoration: none;
  text-transform: initial;
  min-height: 3rem;
  min-width: 3.5rem;
  width: fit-content;
  transition: background-color 150ms ease-out, outline 150ms ease-out, color 150ms ease-out, box-shadow 150ms ease-out;
}

.btn-primary {
  --mdc-theme-primary: var(--button-primary);
  --mdc-theme-on-primary: var(--text-on-color);
}
.btn-primary:hover {
  --mdc-theme-primary: var(--button-primary-hover);
}

.btn-primary--alt {
  --mdc-theme-primary: var(--slate-a12);
  --mdc-theme-on-primary: var(--slate-1);
  background-color: var(--slate-a12);
  color: var(--slate-1);
}
.btn-primary--alt:hover {
  background-color: var(--slate-a11);
}
.btn-secondary {
  --mdc-theme-primary: var(--slate-4);
  --mdc-theme-on-primary: var(--slate-a12);
  background-color: var(--slate-4);
  color: var(--slate-a12);
}
.btn-secondary:hover {
  --mdc-theme-primary: var(--slate-5);
  background-color: var(--slate-5);
}

.btn-secondary--alt {
  background-color: var(--slate-4);
  color: var(--slate-a12);
}
.btn-secondary--alt:hover {
  background-color: var(--slate-5);
  color: inherit;
}

.btn-tertiary {
  --mdc-theme-primary: var(--slate-a9);
  --mdc-theme-on-primary: var(--text-secondary);
  color: var(--text-secondary) !important;
}
.btn-tertiary:hover {
  background-color: var(--slate-a3);
  color: var(--slate-a12);
}

.btn-danger {
  --mdc-theme-primary: var(--red-a11);
}
.btn-danger:hover {
  background-color: var(--red-a12);
}

.btn-ghost {
  background-color: transparent;
  color: var(--text-tertiary);
  gap: 0.25rem;
  padding-inline: 0.5rem;
}
.btn-ghost:hover {
  background-color: var(--gray-hover);
  color: var(--text-primary);
}

.btn-icon-only.btn-xs {
  min-width: 1.5rem;
  max-width: 1.5rem;
  padding-inline: 0;
}
.btn-icon-only.btn-sm {
  min-width: 2rem;
  max-width: 2rem;
  padding-inline: 0;
}
.btn-icon-only.btn-md {
  min-width: 2.5rem;
  max-width: 2.5rem;
  padding-inline: 0;
}
.btn-icon-only.btn-lg {
  min-width: 3rem;
  max-width: 3rem;
  padding-inline: 0;
}

.btn-xs {
  min-height: 1.5rem;
  height: 1.5rem;
  padding-inline: 0.5rem;
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2, 1.333333);
}
.btn-sm {
  min-height: 2rem;
  height: 2rem;
  padding-inline: 0.75rem;
  font-size: 0.8125rem;
  line-height: var(--lh-scale-2, 1.333333);
}
.btn-md {
  min-height: 2.5rem;
  height: 2.5rem;
}
.btn-lg {
  min-height: 3rem;
  height: 3rem;
}
.btn-xl {
  min-height: 3.5rem;
  height: 3.5rem;
}

.btn-full {
  width: 100%;
}

.mdl-button--raised {
  height: 40px;
}

.top-header-button {
  position: absolute;
  top: 5px;
  left: 5px;
}

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: var(--slate-a8);
  background-color: var(--input-bg-color-disabled, var(--slate-a3));
}

.mdl-button--raised {
  background-color: var(--gray-bg);
}
.mdl-button--raised:hover {
  background-color: hsl(var(--gray-30));
}

.mdl-button--fab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-group {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.button-group--full-width {
  display: flex;
  align-items: center;
  column-gap: var(--button-group-gap, 1rem);
}
.button-group--full-width > * {
  flex: 1;
}
.button-group--full-width > * button {
  width: 100%;
}

.button-group--full-width:where(.gap-8) {
  --button-group-gap: 0.5rem;
}

.input-button {
  position: absolute;
  bottom: 6px;
  right: 1rem;
}

.drag-button-wrapper {
  z-index: 1;
  position: relative;
  display: grid;
  place-items: center;
  width: var(--nav-item-height);
  height: var(--nav-item-height);
  cursor: grab;
}

.action-bar {
  display: flex;
  gap: 1rem;
  bottom: 24px;
  right: 0;
  z-index: 5;
  width: fit-content;
  justify-content: flex-end;
  position: fixed;
  bottom: 32px;
  right: 40px;
}

:where(html) {
  --checkbox-size: 1rem;
}
@media (pointer: coarse) {
  :where(html) {
    --checkbox-size: calc(18rem / 16);
  }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  grid-column: 1/-1;
}
@media (pointer: coarse) {
  .checkbox-group {
    row-gap: 1rem;
  }
}

.checkbox-group-horizontal {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;
  grid-column: 1/-1;
}
@media (pointer: coarse) {
  .checkbox-group-horizontal {
    row-gap: 1rem;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
}

.checkbox {
  display: grid;
  place-items: center;
  position: relative;
  appearance: none;
  width: var(--checkbox-size);
  min-width: var(--checkbox-size);
  height: var(--checkbox-size);
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px var(--slate-a12);
  margin-block-start: 1px;
  font-size: var(--scale-2);
  line-height: var(--checkbox-size);
  font-weight: 600;
  color: var(--text-on-color);
  cursor: pointer;
}
.checkbox + label {
  color: var(--text-primary);
  padding-inline-start: 0.5rem;
  cursor: pointer;
}
.checkbox:focus-visible {
  outline: var(--checkbox-focus-outline);
  outline-offset: var(--checkbox-focus-outline-offset);
}
.checkbox:disabled {
  box-shadow: inset 0 0 0 1px var(--text-disabled);
  cursor: not-allowed;
}
.checkbox:disabled + label {
  color: var(--text-disabled);
  cursor: not-allowed;
}
@media (pointer: coarse) {
  .checkbox {
    margin-block-start: 0px;
    font-size: var(--scale-1);
  }
}

.checkbox:checked {
  background-color: var(--slate-a12);
}
.checkbox:checked::after {
  content: "✓";
  position: relative;
}
.checkbox:checked:disabled {
  background-color: var(--text-disabled);
  box-shadow: none;
}

.checkbox + .settings-row--description {
  padding-inline-start: 0.5rem;
}
.checkbox + .settings-row--description > label {
  cursor: pointer;
}

.is-invalid:has(.checkbox-wrapper) .invalid-feedback {
  margin-inline-start: 1.5rem;
}

:where(html) {
  --input-height-xs: 1.75rem;
  --input-height-sm: 2rem;
  --input-height-md: 2.5rem;
  --input-height-lg: 3rem;
  --input-height-xl: 3.5rem;
  --input-width-xxs: calc(64rem / 16);
  --input-width-xs: calc(80rem / 16);
  --input-width-sm: calc(112rem / 16);
  --input-width-md: calc(144rem / 16);
  --input-width-lg: calc(176rem / 16);
  --input-width-xl: calc(208rem / 16);
  --input-width-xxl: calc(298rem / 16);
  --input-width-max-content: max-content;
  --input-focus-outline: 1px solid var(--forge-tertiary-60);
  --input-focus-shadow: 0 0 0 2px var(--forge-tertiary-60-a16);
  --input-focus-outline-offset: -1px;
  --input-box-shadow-disabled: none;
  --radio-focus-outline: 2px solid var(--forge-tertiary-60);
  --radio-focus-shadow: 0 0 0 4px var(--forge-tertiary-60-a16);
  --radio-focus-outline-offset: 1px;
  --checkbox-focus-outline: 2px solid var(--forge-tertiary-60);
  --checkbox-focus-outline-offset: 1px;
  --error-outline: 1px solid var(--ruby-a9);
  --error-shadow: 0 0 0 2px var(--ruby-a3);
  --error-outline-offset: -1px;
  --select-input-icon-size: var(--scale1);
}

legend {
  width: 100%;
}

.input-width--xs {
  max-width: 5rem;
}
.input-width--sm {
  max-width: 7rem;
}
.input-width--md {
  max-width: 298px;
}

.input-wrapper {
  position: relative;
  display: grid;
  row-gap: var(--input-wrapper-gap, var(--spacing));
}
.input-wrapper-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--input-wrapper-gap, var(--spacing));
}

.input-wrapper[data-input-wrapper~=gap-4] {
  --input-wrapper-gap: var(--size-4);
}
.input-wrapper[data-input-wrapper~=gap-8] {
  --input-wrapper-gap: var(--size-8);
}
.input-wrapper[data-input-wrapper~=gap-12] {
  --input-wrapper-gap: var(--size-12);
}
.input-wrapper[data-input-wrapper~=gap-16] {
  --input-wrapper-gap: var(--size-16);
}
.input-wrapper[data-input-wrapper~=width-xs] {
  width: 5rem;
  max-width: 5rem;
}
.input-wrapper[data-input-wrapper~=width-sm] {
  width: 7rem;
  max-width: 7rem;
}
.input-wrapper[data-input-wrapper~=width-md] {
  width: 9rem;
  max-width: 9rem;
}
.input-wrapper[data-input-wrapper~=width-lg] {
  width: 11rem;
  max-width: 11rem;
}
.input-wrapper[data-input-wrapper~=width-xl] {
  width: 13rem;
  max-width: 13rem;
}
.input-wrapper[data-input-wrapper~=width-xxl] {
  width: 18.625rem;
  max-width: 18.625rem;
}

.input-spinner-hidden,
.text-input[type=number] {
  -moz-appearance: textfield;
}
.input-spinner-hidden::-webkit-outer-spin-button, .input-spinner-hidden::-webkit-inner-spin-button,
.text-input[type=number]::-webkit-outer-spin-button,
.text-input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-wrapper-phone-ext {
  display: flex;
  align-items: center;
  flex: none;
  width: 6.5rem;
  position: relative;
}
.input-wrapper-phone-ext::before {
  content: "Ext";
  position: absolute;
  display: inline-block;
  inset-inline-start: 0.5rem;
  color: var(--slate-a11);
  font-family: var(--font-mono);
  font-size: 0.8125rem;
  line-height: var(--lh-scale-1);
}

.phone-ext {
  --input-padding: 2.5rem 1rem;
}

.required-indicator::after {
  content: "*"/"Required";
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  color: var(--ruby-a11);
  translate: calc(-100% - 2px);
  font-size: var(--scale-2);
  font-weight: 500;
  text-align: right;
}

.form-button-datepicker {
  position: absolute;
  right: 11px;
  top: 6px;
}

.input-group {
  display: flex;
  flex-direction: var(--layout-direction, row);
  gap: var(--input-spacing, 0.5rem);
}
.input-group[data-input-group~=block] {
  --layout-direction: column;
  --input-spacing: calc(var(--spacing) * 2);
}
.input-group[data-input-group~=has-button] {
  align-items: flex-end;
}
.input-group[data-input-group~=full] > * {
  flex: 1;
}
.input-group[data-input-group~=xs] {
  --input-spacing: var(--size-4);
}
.input-group[data-input-group~=sm] {
  --input-spacing: var(--size-8);
}
.input-group[data-input-group~=md] {
  --input-spacing: var(--size-16);
}
.input-group[data-input-group~=lg] {
  --input-spacing: var(--size-24);
}
.input-group[data-input-group~=xl] {
  --input-spacing: var(--size-32);
}

.input-group-inline--xs {
  --input-spacing: var(--size-4);
}
.input-group-inline--sm {
  --input-spacing: var(--size-8);
}
.input-group-inline--md {
  --input-spacing: var(--size-16);
}
.input-group-inline--lg {
  --input-spacing: var(--size-24);
}
.input-group-inline--xl {
  --input-spacing: var(--size-32);
}

.input-group-block--xs {
  --input-spacing: var(--size-4);
}
.input-group-block--sm {
  --input-spacing: var(--size-8);
}
.input-group-block--md {
  --input-spacing: var(--size-16);
}
.input-group-block--lg {
  --input-spacing: var(--size-24);
}
.input-group-block--xl {
  --input-spacing: var(--size-32);
}

.input-group-full-width > .mdl-textfield {
  width: 100%;
}

.is-invalid .fr-wrapper {
  border: 1px rgb(213, 0, 0) solid !important;
}

.checkbox-group-horizontal.validation-group .invalid-feedback,
.radio-button-group-horizontal.validation-group .invalid-feedback {
  width: 100%;
  padding-top: 0.5rem;
}

.validation-group.is-invalid .text-input,
.form-group.is-invalid .text-input,
.input-wrapper.is-invalid .text-input,
.radio-button-group-horizontal.is-invalid .text-input,
.checkbox-group-horizontal.is-invalid .text-input {
  outline: var(--error-outline);
  outline-offset: var(--error-outline-offset);
  border-radius: var(--input-border-radius);
  box-shadow: var(--error-shadow);
}
.validation-group.is-invalid .select-input,
.validation-group.is-invalid .slim-select,
.validation-group.is-invalid .slim-select-outlined,
.form-group.is-invalid .select-input,
.form-group.is-invalid .slim-select,
.form-group.is-invalid .slim-select-outlined,
.input-wrapper.is-invalid .select-input,
.input-wrapper.is-invalid .slim-select,
.input-wrapper.is-invalid .slim-select-outlined,
.radio-button-group-horizontal.is-invalid .select-input,
.radio-button-group-horizontal.is-invalid .slim-select,
.radio-button-group-horizontal.is-invalid .slim-select-outlined,
.checkbox-group-horizontal.is-invalid .select-input,
.checkbox-group-horizontal.is-invalid .slim-select,
.checkbox-group-horizontal.is-invalid .slim-select-outlined {
  outline: var(--error-outline);
  outline-offset: var(--error-outline-offset);
  border-radius: var(--input-border-radius);
  box-shadow: var(--error-shadow);
}
.validation-group.is-invalid .textarea,
.form-group.is-invalid .textarea,
.input-wrapper.is-invalid .textarea,
.radio-button-group-horizontal.is-invalid .textarea,
.checkbox-group-horizontal.is-invalid .textarea {
  outline: var(--error-outline);
  outline-offset: var(--error-outline-offset);
  border-radius: var(--input-border-radius);
  box-shadow: var(--error-shadow);
}
.validation-group.is-invalid .checkbox,
.form-group.is-invalid .checkbox,
.input-wrapper.is-invalid .checkbox,
.radio-button-group-horizontal.is-invalid .checkbox,
.checkbox-group-horizontal.is-invalid .checkbox {
  box-shadow: inset 0 0 0 1px var(--ruby-a9), 0 0 0 2px var(--ruby-a3);
}
.validation-group.is-invalid .radio-button,
.form-group.is-invalid .radio-button,
.input-wrapper.is-invalid .radio-button,
.radio-button-group-horizontal.is-invalid .radio-button,
.checkbox-group-horizontal.is-invalid .radio-button {
  box-shadow: inset 0 0 0 1px var(--ruby-a9), inset 0 0 0 4px var(--color-panel-solid), 0 0 0 2px var(--ruby-a3);
}
.validation-group.is-invalid .radio-button--checkmark,
.form-group.is-invalid .radio-button--checkmark,
.input-wrapper.is-invalid .radio-button--checkmark,
.radio-button-group-horizontal.is-invalid .radio-button--checkmark,
.checkbox-group-horizontal.is-invalid .radio-button--checkmark {
  box-shadow: inset 0 0 0 1px var(--ruby-a9), 0 0 0 2px var(--ruby-a3);
}
.validation-group.is-invalid .selectable-card,
.form-group.is-invalid .selectable-card,
.input-wrapper.is-invalid .selectable-card,
.radio-button-group-horizontal.is-invalid .selectable-card,
.checkbox-group-horizontal.is-invalid .selectable-card {
  outline: var(--error-outline);
  outline-offset: var(--error-outline-offset);
  box-shadow: var(--error-shadow);
}
.validation-group.is-invalid .invalid-feedback,
.form-group.is-invalid .invalid-feedback,
.input-wrapper.is-invalid .invalid-feedback,
.radio-button-group-horizontal.is-invalid .invalid-feedback,
.checkbox-group-horizontal.is-invalid .invalid-feedback {
  display: block;
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  color: var(--text-helper);
  font-variant-alternates: var(--small-text);
  color: var(--text-critical);
}

.validation-group.is-invalid .invalid-feedback {
  margin-top: -0.25rem;
}

.input-wrapper.is-invalid .invalid-feedback {
  margin-top: -0.25rem;
}
.input-wrapper:has([data-input-style=width-xs]) .invalid-feedback {
  max-width: var(--input-width-xs);
}
.input-wrapper:has([data-input-style=width-sm]) .invalid-feedback {
  max-width: var(--input-width-sm);
}
.input-wrapper:has([data-input-style=width-md]) .invalid-feedback {
  max-width: var(--input-width-md);
}
.input-wrapper:has([data-input-style=width-lg]) .invalid-feedback {
  max-width: var(--input-width-lg);
}

[class*=input-icon] {
  position: absolute;
  display: grid;
  place-items: center;
  height: var(--input-height, var(--input-height-md));
  pointer-events: none;
}

.input-wrapper:has(.text-input-sm) [class*=input-icon] {
  --input-height: var(--input-height-sm);
}

.input-wrapper:has(.text-input-xs) [class*=input-icon] {
  --input-height: var(--input-height-xs);
}

.input-icon-start {
  left: 0;
  padding-inline-start: var(--input-icon-start-padding, 1rem);
}

.input-icon-start + .text-input {
  --input-padding: 3.125rem 1rem;
}

.input-icon-end {
  right: 0;
  padding-inline-end: var(--input-icon-end-padding, 1rem);
}

.text-input:has(+ .input-icon-end) {
  --input-padding: 1rem 3.125rem;
}
.text-input:has(+ .input-icon-end):where(.text-input-percent) {
  --input-padding: 0.75rem 2.625rem;
  text-align: right;
}

.datepicker {
  display: flex;
}

.datepicker-range {
  display: flex;
  column-gap: var(--size-4);
  align-items: center;
}
@container question-answer (width < 308px) {
  .datepicker-range {
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--size-8);
  }
}

.flatpickr-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.flatpickr-wrapper::after {
  content: "today"/"";
  position: absolute;
  display: inline-block;
  inset-inline-end: 1rem;
  font-family: var(--font-icons);
  font-size: var(--select-input-icon-size, var(--scale1));
  line-height: 1;
  width: var(--select-input-icon-size, var(--scale1));
  pointer-events: none;
}
@container question-answer (width <= 450px) {
  .flatpickr-wrapper {
    width: 100%;
  }
}

.flatpickr-input {
  --input-padding: 1rem calc(2rem + var(--select-input-icon-size));
  font-variant-numeric: tabular-nums;
  text-transform: lowercase;
  width: 100%;
  height: var(--input-height, var(--input-height-md));
  min-height: var(--input-height, var(--input-height-md));
  padding-block: var(--input-padding-block, 0);
  padding-inline: var(--input-padding, 1rem);
  color: var(--text-primary);
  background-color: var(--input-bg-color, var(--slate-a3));
  border: var(--input-border, none);
  border-radius: var(--input-border-radius, 0);
  box-shadow: var(--input-box-shadow, inset 0 -1px 0 var(--slate-a9));
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}
.flatpickr-input::placeholder {
  color: var(--text-placeholder);
}
.flatpickr-input:focus-visible {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-focus-shadow);
}
.flatpickr-input:active:not(:disabled) {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
}
.flatpickr-input:disabled {
  color: var(--text-disabled);
  background-color: var(--input-bg-color-disabled, var(--slate-a3));
  box-shadow: var(--input-box-shadow-disabled, none);
  cursor: not-allowed;
}
.flatpickr-input:read-only {
  --input-box-shadow: inset 0 -1px 0 var(--slate-a9);
  color: var(--text-primary);
}

.flatpickr-input[data-input-style~=outlined],
.datepicker[data-input-style~=outlined] .flatpickr-input {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--slate-a2);
  --input-border-radius: var(--size-2);
  --input-box-shadow: inset 0 0 0 1px var(--slate-a8);
  --input-box-shadow-disabled: inset 0 0 0 1px var(--slate-a7);
}

.flatpickr-months .flatpickr-month {
  height: 40px;
  color: var(--text-primary);
}

.flatpickr-current-month {
  padding: 0;
  height: 40px;
}
.flatpickr-current-month input.cur-year {
  padding: 0;
  border: none;
  height: 40px;
  box-shadow: none;
  background: none;
  font-weight: 400;
  color: var(--text-primary);
}
.flatpickr-current-month input.cur-year[disabled] {
  color: var(--text-tertiary);
}
.flatpickr-current-month .numInputWrapper {
  height: 40px;
  margin-left: 8px;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-secondary);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--text-secondary);
}

.flatpickr-calendar {
  font-size: var(--scale-1);
  background: var(--color-panel-solid);
  box-shadow: 1px 0 0 var(--slate-a4), -1px 0 0 var(--slate-a4), 0 1px 0 var(--slate-a4), 0 -1px 0 var(--slate-a4), 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.arrowTop::before {
  border-bottom-color: var(--slate-a4);
}

.flatpickr-calendar.arrowTop::after {
  border-bottom-color: var(--color-panel-solid);
}

.flatpickr-calendar.arrowBottom::before {
  border-top-color: var(--slate-a4);
}

.flatpickr-calendar.arrowBottom::after {
  border-top-color: var(--color-panel-solid);
}

.flatpickr-months {
  height: 40px;
  align-items: center;
}
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  height: 40px;
  width: 40px;
  color: var(--text-primary);
}
.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
  fill: var(--text-primary);
}
.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
  background: var(--slate-a3);
}
.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: var(--text-primary);
}

.flatpickr-current-month {
  font-size: initial;
}
.flatpickr-current-month span.cur-month {
  font-size: var(--scale0);
  line-height: var(--lh-scale0);
  font-weight: 600;
}

span.flatpickr-weekday {
  color: var(--text-primary);
  font-weight: 600;
}

.flatpickr-day {
  font-feature-settings: "tnum" 1;
  letter-spacing: -0.02em;
  color: var(--text-primary);
}
.flatpickr-day:hover {
  border-radius: 4px;
  background: var(--slate-a3);
  border-color: var(--slate-a3);
}
.flatpickr-day.selected {
  border-radius: 4px;
  background-color: var(--slate-a12);
  border: none;
}
.flatpickr-day.selected:hover {
  background-color: var(--slate-a11);
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: var(--text-tertiary);
}
.flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover {
  background: var(--slate-a3);
  border-color: var(--slate-a3);
}
.flatpickr-day.today {
  border: none;
  color: var(--primary);
  position: relative;
}
.flatpickr-day.today:hover {
  background: var(--primary);
}
.flatpickr-day.today:hover:after {
  background-color: white;
}
.flatpickr-day.today:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 6px;
  width: 4px;
  height: 4px;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--primary);
  border-radius: 50%;
}
.flatpickr-day.today.selected {
  color: #FFFFFF;
}
.flatpickr-day.today.selected:after {
  background-color: #FFFFFF;
}
.flatpickr-day.flatpickr-disabled {
  color: var(--text-disabled);
}
.flatpickr-day.flatpickr-disabled:hover {
  color: var(--text-disabled);
}

.dialog {
  border: none;
  width: var(--dialog-width, 30rem);
  margin-block-start: clamp(5rem, -3.3333rem + 20.8333vw, 10rem);
  margin-inline: auto;
  padding-block: var(--dialog-padding-block, 1rem);
  padding-inline: var(--dialog-padding-inline, 1rem);
  background-color: var(--dialog-bg, var(--color-panel-solid));
  border-radius: var(--dialog-border-radius, 0.5rem);
  box-shadow: var(--dialog-shadow, 0 1rem 4rem var(--black-a7));
  backdrop-filter: var(--dialog-bg-blur);
}
.dialog:focus-visible {
  outline: none;
}

::backdrop {
  background-color: var(--dialog-backdrop);
}

:where(.dialog)[data-dialog~=xs] {
  --dialog-width: 20rem;
}
:where(.dialog)[data-dialog~=sm] {
  --dialog-width: 25rem;
}
:where(.dialog)[data-dialog~=md] {
  --dialog-width: 30rem;
}
:where(.dialog)[data-dialog~=md-lg] {
  --dialog-width: 35rem;
}
:where(.dialog)[data-dialog~=lg] {
  --dialog-width: 40rem;
}
:where(.dialog)[data-dialog~=xl] {
  --dialog-width: 50rem;
}

:where(.dialog)[data-dialog~=translucent] {
  --dialog-bg: var(--color-panel-translucent);
  --dialog-bg-blur: blur(64px) saturate(120%);
  --dialog-bg-saturate: 120%;
}

:where(.dialog)[data-dialog~=wrapper] {
  --dialog-padding-block: 0;
  --dialog-padding-inline: 0;
  --dialog-bg: transparent;
  --dialog-shadow: none;
  overflow: visible;
}

:where(.dialog)[data-dialog~=inner] {
  --dialog-padding-block: 1rem;
  --dialog-padding-inline: 0;
  --dialog-shadow: 0 1rem 4rem var(--black-a7);
  margin-block-start: 0;
}

:where(.dialog)[data-dialog~=no-padding] {
  --dialog-padding-block: 0;
  --dialog-padding-inline: 0;
}

.dialog-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  margin-top: 0.5rem;
}
.dialog-header p {
  color: var(--text-secondary);
}

.dialog-body {
  display: grid;
  row-gap: 1.5rem;
}
.dialog-body .gap-16 {
  row-gap: 1rem;
}

.dialog-body[data-dialog-body~=centered] {
  text-align: center;
  justify-items: center;
}
.dialog-body[data-dialog-body~=success] {
  --dialog-type-bg: var(--jade-a3);
  --dialog-type-color: var(--jade-a11);
}
.dialog-body[data-dialog-body~=warning] {
  --dialog-type-bg: var(--amber-a3);
  --dialog-type-color: var(--amber-a11);
}
.dialog-body[data-dialog-body~=error] {
  --dialog-type-bg: var(--ruby-a3);
  --dialog-type-color: var(--ruby-a11);
}

.dialog-body--icon {
  display: grid;
  place-items: center;
  padding: 0.75rem;
  border-radius: 1.25rem;
  background-color: var(--dialog-type-bg, var(--slate-a3));
  color: var(--dialog-type-color, var(--slate-a11));
  font-family: var(--font-icons);
  font-size: var(--size-48);
  line-height: 1;
  font-weight: 300;
}

.dialog-body--text-wrapper {
  display: grid;
}

.dialog-body--title {
  font-size: var(--scale1);
  line-height: var(--lh-scale1);
  font-weight: var(--heading-weight);
  margin-block-end: calc(var(--lh-scale1) / 2);
}

.dialog-body--text {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}

.dialog-message-card {
  display: grid;
  place-items: center;
  row-gap: 1rem;
  border-radius: 0.25rem;
  padding-block: 1rem 1.25rem;
  padding-inline: 1rem;
  text-align: center;
  background-color: var(--dialog-message-bg, var(--slate-a2));
  color: var(--dialog-message-color, var(--slate-a12));
  border: 1px solid var(--dialog-message-border, var(--slate-a6));
}

.dialog-message-card[data-dialog-message-card~=delete] {
  --dialog-message-bg: var(--ruby-a2);
  --dialog-message-color: var(--ruby-a12);
  --dialog-message-border: var(--ruby-a6);
}

.dialog-message-card[data-dialog-message-card~=error] {
  --dialog-message-bg: var(--ruby-a2);
  --dialog-message-color: var(--ruby-a12);
  --dialog-message-border: var(--ruby-a6);
}

.dialog-message-card[data-dialog-message-card~=warning] {
  --dialog-message-bg: var(--amber-a2);
  --dialog-message-color: var(--amber-a12);
  --dialog-message-border: var(--amber-a6);
}

.dialog-message-card[data-dialog-message-card~=success] {
  --dialog-message-bg: var(--jade-a2);
  --dialog-message-color: var(--jade-a12);
  --dialog-message-border: var(--jade-a6);
}

.dialog-message-card[data-dialog-message-card~=informational] {
  --dialog-message-bg: var(--blue-a2);
  --dialog-message-color: var(--blue-a12);
  --dialog-message-border: var(--blue-a6);
}

.dialog-message-card[data-dialog-message-card~=text-left] {
  place-items: start;
  text-align: left;
}

.dialog-message-card[data-dialog-message-card~=compact] {
  padding-block: 0.75rem 0.875rem;
  padding-inline: 1rem;
}

.dialog-error-id-wrapper {
  display: grid;
  grid-template-columns: [error-code-start] 1fr [error-code-end button-start] auto [button-end];
  align-items: center;
  column-gap: 1rem;
  border-radius: 0.25rem;
  padding-inline: 1rem 0.5rem;
  height: 3rem;
  background-color: var(--dialog-message-bg, var(--slate-a2));
  color: var(--dialog-message-color, var(--slate-a12));
  border: 1px solid var(--dialog-message-border, var(--slate-a6));
}

ux-dialog-overlay {
  background-color: oklch(100% 0 0/0%);
  transition: all 125ms ease-out;
}

ux-dialog-overlay.active:last-of-type {
  background-color: var(--dialog-backdrop);
  transition: all 100ms ease-out;
}

ux-dialog-overlay.active.ux-drawer-overlay:last-of-type {
  background-color: var(--dialog-backdrop);
}

ux-dialog-container {
  transform: scale3d(0.98, 0.98, 0.98);
  transition: all 100ms ease-out !important;
}

ux-dialog-container.active {
  transform: scale3d(1, 1, 1);
  transition: all 125ms 25ms ease-out !important;
}

ux-dialog-container > div {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 1rem !important;
}

div:has(> ux-dialog) {
  width: 100% !important;
  min-width: 15rem !important;
}

.ux-dialog-open ux-dialog {
  position: relative;
  border-radius: 0.5rem;
  border: none;
  background: var(--color-panel-solid);
  width: 100%;
  margin-block-end: clamp(2rem, -28rem + 53.333vb, 4rem);
}

.ux-dialog-margin-top-sm {
  margin-top: 112px;
}

.ux-dialog-margin-top-md {
  margin-block-start: clamp(5rem, -70rem + 133.333vb, 10rem);
}

.dialog-validation-error {
  color: #D50000;
}

.dialog-card-group-body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.dialog-card-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dialog-card-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  background-color: var(--slate-a3);
  border: 1px solid var(--slate-a6);
}
.dialog-card-section .toggle-arrow {
  cursor: pointer;
  transition: transform 50ms ease-out;
}
.dialog-card-section .toggle-arrow-rotate {
  transform: rotate(-90deg);
}
.dialog-card-section .collapsed {
  display: none !important;
}

.toggle-arrow-button {
  display: grid;
  place-items: center;
}

.dialog-card-section-header {
  display: flex;
  gap: 0.5rem;
}
.dialog-card-section-header-info {
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;
  flex: 1;
}

.dialog-card-section-meta {
  font-family: var(--font-mono);
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-feature-settings: "ss04";
  color: var(--text-secondary);
}

.card-list {
  display: flex;
  padding-left: 1.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-4, 0.25rem);
  align-self: stretch;
}

.card-list-item {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 1.5rem;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  padding-block: 1.5rem 1rem;
  padding-inline: 1rem 3rem;
  align-items: center;
}

.modal__title {
  font-size: var(--scale2);
  color: var(--text-primary);
}

.modal__actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  column-gap: 1rem;
}
.modal__actions > * {
  flex: 1;
}

.modal__body {
  padding-block: 1rem 1rem;
  padding-inline: 1rem;
  display: grid;
  row-gap: 1.5rem;
}
.modal__body-container {
  display: grid;
  gap: 1.5rem;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
}
.modal__body-container .material-icons,
.modal__body-container .material-icons-outlined {
  font-size: var(--scale1);
  text-align: center;
}
.modal__body-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  width: 100%;
}

.modal__body--no-padding {
  padding: 0;
}

.ux-dialog-xs {
  max-width: 20rem;
}

.ux-dialog-sm {
  max-width: 25rem;
}

.ux-dialog-md {
  max-width: 30rem;
}

.ux-dialog-md-lg {
  max-width: 35rem;
}

.ux-dialog-lg {
  max-width: 40rem;
}

.ux-dialog-xl {
  max-width: 50rem;
}

.modal-close {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 1;
}

.voicemail-player {
  width: 100%;
}

:where(html) {
  --note-card-bg: var(--slate-a1);
  --note-card-shadow: inset 0 0 0 1px var(--slate-a6);
  --note-card-radius: 0.25rem;
  --note-card-row-gap: 0.75rem;
  --history-meta-avatar-size: 1.25rem;
  --history-meta-avatar-bg: radial-gradient(farthest-side circle at 100% 0%, var(--pink-a5) 0%, var(--violet-a5) 100%);
  --history-meta-avatar-fg: var(--violet-a11);
}

.note-card {
  background-color: var(--note-card-bg);
  box-shadow: var(--note-card-shadow);
  border-radius: var(--note-card-radius, 0.25rem);
  padding-block: 0.75rem 1rem;
  padding-inline: 1rem;
  display: grid;
  row-gap: var(--note-card-row-gap);
}

.note-content {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
}

.history-meta {
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  display: flex;
  align-items: center;
}
.history-meta-avatar {
  width: var(--history-meta-avatar-size);
  height: var(--history-meta-avatar-size);
  border-radius: calc(var(--history-meta-avatar-size) / 2);
  display: grid;
  place-items: center;
  background: var(--history-meta-avatar-bg);
  color: var(--history-meta-avatar-fg);
  font-size: calc(11rem / var(--rem-base));
  line-height: 1;
  font-weight: 450;
  margin-inline-end: 0.5em;
}
.history-meta-avatar:empty {
  display: none;
}
.history-meta-user {
  font-weight: 500;
}
.history-meta-description {
  color: var(--text-tertiary);
  margin-inline-start: 0.35em;
  margin-inline-end: 1em;
}
.history-meta:has(.history-meta-avatar) {
  margin-inline-start: -2px;
}

:where(html) {
  --notification-shadow: 0 36px 14px hsl(218 4% 50% / 2%),
                         0 20px 12px hsl(218 4% 50% / 8%),
                         0 9px 9px hsl(218 4% 50% / 14%),
                         0 2px 5px hsl(218 4% 50% / 16%),
                         0 0 0 hsl(218 4% 50% / 16%);
}

:where(.notification) {
  --color-darken: calc(l - 0.125);
}

.notification {
  position: relative;
  display: flex;
  column-gap: var(--size-12);
  height: auto;
  min-height: 3rem;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  padding: 1rem 1rem 1rem 0.8125rem;
  background-color: var(--notification-bg, var(--slate-2));
  color: var(--notification-text, var(--text-primary));
  border-left: 3px solid var(--notification-border-strong, var(--slate-a10));
}
.notification::before {
  content: "";
  position: absolute;
  inset: 0;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: var(--notification-border, var(--slate-a6));
  pointer-events: none;
  box-sizing: border-box;
}
.notification--text-wrapper {
  display: grid;
  flex: auto;
}
.notification--text-wrapper-inline {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}
.notification--details {
  display: flex;
  column-gap: 1rem;
}
.notification--icon {
  font-family: var(--font-icons);
  font-size: var(--scale1);
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
  color: var(--notification-icon);
}
.notification--title {
  color: var(--notification-title, var(--text-primary));
  font-weight: 500;
}
.notification--subtitle:empty {
  display: none;
}
.notification--caption:empty {
  display: none;
}
.notification--action {
  margin-block-start: 1rem;
}

.notification--caption:where(.dark) {
  color: var(--amber-a12);
}

.notification--caption:where(.small) {
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
}

.notification:has(.notification--caption) .notification--subtitle {
  margin-block-end: calc(var(--lh-scale-1) / 2);
}

[data-notification-type=informational] {
  --notification-bg: var(--blue-2);
  --notification-border: var(--blue-a6);
  --notification-border-strong: var(--blue-a10);
  --notification-icon: oklch(from var(--blue-a11) var(--color-darken) c h);
  --notification-title: var(--blue-a12);
  --notification-text: oklch(from var(--blue-a11) var(--color-darken) c h);
}
[data-notification-type=informational] .btn-secondary {
  background-color: var(--color-surface);
  color: var(--blue-a12);
  box-shadow: inset 0 0 0 1px var(--slate-a7);
}
[data-notification-type=informational] .btn-secondary:hover {
  background-color: var(--white);
  box-shadow: inset 0 0 0 1px var(--slate-a9);
}

[data-notification-type=warning] {
  --notification-bg: var(--amber-2);
  --notification-border: var(--amber-a6);
  --notification-border-strong: var(--amber-a10);
  --notification-icon: oklch(from var(--amber-a11) var(--color-darken) c h);
  --notification-title: var(--amber-a12);
  --notification-text: oklch(from var(--amber-a11) var(--color-darken) c h);
}
[data-notification-type=warning] .btn-secondary {
  background-color: var(--color-surface);
  color: var(--amber-a12);
  box-shadow: inset 0 0 0 1px var(--sand-a7);
}
[data-notification-type=warning] .btn-secondary:hover {
  background-color: var(--white);
  box-shadow: inset 0 0 0 1px var(--sand-a9);
}

[data-notification-type=error] {
  --notification-bg: var(--ruby-2);
  --notification-border: var(--ruby-a6);
  --notification-border-strong: var(--ruby-a10);
  --notification-icon: oklch(from var(--ruby-a11) var(--color-darken) c h);
  --notification-title: var(--ruby-a12);
  --notification-text: oklch(from var(--ruby-a11) var(--color-darken) c h);
}
[data-notification-type=error] .btn-secondary {
  background-color: var(--color-surface);
  color: var(--ruby-a12);
  box-shadow: inset 0 0 0 1px var(--mauve-a7);
}
[data-notification-type=error] .btn-secondary:hover {
  background-color: var(--white);
  box-shadow: inset 0 0 0 1px var(--mauve-a9);
}

.dialog:not(.quick-scan):has(.notification) [data-notification-type=error] {
  --notification-bg: var(--ruby-2);
  margin-block-start: 0.75rem;
  box-shadow: var(--notification-shadow);
}

.disbursements-network-error {
  color: var(--ruby-a11);
}

.number-control {
  height: 32px;
  width: 144px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #A8ADB3;
  border-radius: 4px;
}
.number-control input::-webkit-outer-spin-button, .number-control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.number-control input {
  width: 72px;
  height: 100%;
  box-sizing: border-box;
  padding: 8px 7px;
  margin: 0;
  font-size: 14px;
  background: none;
  border: none;
  -moz-appearance: textfield;
  text-align: center;
}
.number-control button {
  width: 32px;
  height: 100%;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.number-control button i {
  color: #525558;
  font-size: 14px;
}

.radio-button-group {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  grid-column: 1/-1;
}
@media (pointer: coarse) {
  .radio-button-group {
    row-gap: 1rem;
  }
}

.radio-button-group-horizontal {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;
  grid-column: 1/-1;
}
@media (pointer: coarse) {
  .radio-button-group-horizontal {
    row-gap: 1rem;
  }
}

.radio-button-wrapper {
  display: flex;
  align-items: flex-start;
}

.radio-button {
  --radio-button-size: calc(18rem / 16);
  appearance: none;
  width: var(--radio-button-size);
  min-width: var(--radio-button-size);
  height: var(--radio-button-size);
  border-radius: calc(var(--radio-button-size) / 2);
  box-shadow: inset 0 0 0 1px var(--slate-a12), inset 0 0 0 4px var(--color-panel-solid);
  background-color: transparent;
  cursor: pointer;
}
.radio-button:focus-visible {
  outline: var(--radio-focus-outline);
  outline-offset: var(--radio-focus-outline-offset);
}
.radio-button + label {
  color: var(--text-primary);
  padding-inline-start: 0.5rem;
  cursor: pointer;
}
@media (pointer: coarse) {
  .radio-button + label {
    padding-block-start: 0.0625rem;
  }
}
@media (pointer: coarse) {
  .radio-button {
    --radio-button-size: 1.25rem;
  }
}
.radio-button:checked {
  background-color: var(--slate-a12);
}
.radio-button:disabled {
  box-shadow: inset 0 0 0 1px var(--text-disabled), inset 0 0 0 4px var(--color-panel-solid);
  cursor: not-allowed;
}
.radio-button:disabled + label {
  color: var(--text-disabled);
  cursor: not-allowed;
}

:where(.selectable-card) .radio-button::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
}

.radio-button--checkmark {
  --radio-button-size: calc(18rem / 16);
  appearance: none;
  width: var(--radio-button-size);
  min-width: var(--radio-button-size);
  height: var(--radio-button-size);
  border-radius: calc(var(--radio-button-size) / 2);
  box-shadow: inset 0 0 0 1px var(--slate-a12), inset 0 0 0 4px var(--color-panel-solid);
  background-color: transparent;
  cursor: pointer;
}
.radio-button--checkmark:focus-visible {
  outline: var(--radio-focus-outline);
  outline-offset: var(--radio-focus-outline-offset);
}
.radio-button--checkmark + label {
  color: var(--text-primary);
  padding-inline-start: 0.5rem;
  cursor: pointer;
}
@media (pointer: coarse) {
  .radio-button--checkmark + label {
    padding-block-start: 0.0625rem;
  }
}
@media (pointer: coarse) {
  .radio-button--checkmark {
    --radio-button-size: 1.25rem;
  }
}
.radio-button--checkmark:checked {
  box-shadow: inset 0 0 0 1px var(--slate-a12);
  background-color: var(--slate-a12);
}
.radio-button--checkmark:checked::after {
  content: "✓";
  position: relative;
  display: grid;
  place-items: center;
  font-size: var(--scale-2);
  line-height: var(--radio-button-size);
  font-weight: 600;
  color: var(--text-on-color);
}

:where(.selectable-card) .radio-button--checkmark::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
}

:where(input[type=range]) {
  --range-slider--track-height: 2px;
  --range-slider--track-color: hsl(var(--gray-25));
  --range-slider--thumb-dimension: 14px;
  --range-slider--thumb-color: var(--text-primary);
}

input[type=range] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--range-slider--track-color);
  border-radius: 0.5rem;
  height: var(--range-slider--track-height);
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6px; /* Centers thumb on the track */
  background-color: var(--range-slider--thumb-color);
  height: var(--range-slider--thumb-dimension);
  width: var(--range-slider--thumb-dimension);
  border-radius: 0.5rem;
}

/***** Firefox styles *****/
input[type=range]::-moz-range-track {
  background-color: var(--range-slider--track-color);
  border-radius: 0.5rem;
  height: var(--range-slider--track-height);
}

input[type=range]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
  background-color: var(--range-slider--thumb-color);
  height: var(--range-slider--thumb-dimension);
  width: var(--range-slider--thumb-dimension);
}

.segmented-control__container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

.segmented-control__controls {
  display: inline-flex;
  justify-content: space-between;
  background-color: hsl(var(--gray-20));
  border-radius: 8px;
  padding: 2px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 32px;
}
.segmented-control__controls:before {
  content: "";
  background: var(--white);
  border-radius: 6px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0;
  z-index: 0;
  transition: transform 150ms ease-out, width 150ms ease-out;
}

.segmented-control__segment {
  height: 100%;
  position: relative;
  text-align: center;
  z-index: 1;
  flex: 1;
}
.segmented-control__segment .radio-button {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 0;
  width: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.segmented-control__segment label {
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  font-weight: var(--heading-weight);
  font-variant-alternates: var(--small-text);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  color: var(--text-secondary);
  padding: 4px 10px;
  position: relative;
  transition: color 150ms ease-out;
  cursor: pointer;
}
.segmented-control__segment.active label {
  color: var(--text-primary);
}

.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.select-wrapper::after {
  content: "expand_more"/"";
  position: absolute;
  display: inline-block;
  inset-inline-end: 1rem;
  font-family: var(--font-icons);
  font-size: var(--select-input-icon-size, var(--scale1));
  line-height: 1;
  width: var(--select-input-icon-size, var(--scale1));
  pointer-events: none;
}
.select-wrapper:has(.select-input:disabled)::after {
  color: var(--text-disabled);
}
.select-wrapper:has([data-input-style~=quick-action])::after {
  inset-inline-end: 0.25rem;
}
.select-wrapper:has([data-input-style~=calendar])::after {
  inset-inline-end: 0.5rem;
}

.select-input {
  --input-padding: 1rem calc(2rem + var(--select-input-icon-size));
  appearance: none;
  width: 100%;
  height: var(--input-height, var(--input-height-md));
  min-height: var(--input-height, var(--input-height-md));
  padding-block: var(--input-padding-block, 0);
  padding-inline: var(--input-padding, 1rem);
  color: var(--text-primary);
  background-color: var(--input-bg-color, var(--slate-a3));
  border: var(--input-border, none);
  border-radius: var(--input-border-radius, 0);
  box-shadow: var(--input-box-shadow, inset 0 -1px 0 var(--slate-a9));
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}
.select-input::placeholder {
  color: var(--text-placeholder);
}
.select-input:focus-visible {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-focus-shadow);
}
.select-input:active:not(:disabled) {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
}
.select-input:disabled {
  color: var(--text-disabled);
  background-color: var(--input-bg-color-disabled, var(--slate-a3));
  box-shadow: var(--input-box-shadow-disabled, none);
  cursor: not-allowed;
}

label:has(+ .select-wrapper > .select-input:disabled) {
  color: var(--text-disabled);
}

legend:has(+ .input-wrapper-horizontal > .select-wrapper > .select-input:disabled) {
  color: var(--text-disabled);
}

.select-input[data-input-style~=outlined] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--slate-a2);
  --input-border-radius: var(--size-2);
  --input-box-shadow: inset 0 0 0 1px var(--slate-a8);
  --input-box-shadow-disabled: inset 0 0 0 1px var(--slate-a7);
}
.select-input[data-input-style~=layer-01] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--color-surface);
}
.select-input[data-input-style~=layer-02] {
  --input-bg-color: var(--slate-a2);
  --input-bg-color-disabled: var(--slate-a2);
}
.select-input[data-input-style~=layer-03] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--color-surface);
}
.select-input[data-input-style~=quick-action] {
  --input-height: 1.5rem;
  --input-padding: 0.75rem calc(0.75rem + var(--select-input-icon-size));
  --input-bg-color: var(--slate-a3);
  --input-bg-color-disabled: var(--slate-a2);
  --input-border-radius: var(--size-6);
  --input-box-shadow: inset 0 0 0 1px var(--slate-a6);
  --input-box-shadow-disabled: inset 0 0 0 1px var(--slate-a6);
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
  font-variant-alternates: var(--small-text);
}
.select-input[data-input-style~=calendar] {
  --input-height: 2rem;
  --input-padding: 1rem calc(0.75rem + var(--select-input-icon-size));
  --input-bg-color: var(--slate-a3);
  --input-bg-color-disabled: var(--slate-a2);
  --input-border-radius: var(--size-6);
  --input-box-shadow: inset 0 0 0 1px var(--slate-a7);
  --input-box-shadow-disabled: inset 0 0 0 1px var(--slate-a6);
  font-variant-alternates: var(--small-text);
}

.select-input[data-input-style~=xs] {
  --input-height: var(--input-height-xs);
  --input-padding: 0.5rem;
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
}
.select-input[data-input-style~=sm] {
  --input-height: var(--input-height-sm);
  --input-padding: 0.5rem;
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
}
.select-input[data-input-style~=lg] {
  --input-height: var(--input-height-lg);
}

.select-input[data-input-style~=width-xs] {
  width: var(--input-width-xs);
  max-width: var(--input-width-xs);
}
.select-input[data-input-style~=width-sm] {
  width: var(--input-width-sm);
  max-width: var(--input-width-sm);
}
.select-input[data-input-style~=width-md] {
  width: var(--input-width-md);
  max-width: var(--input-width-md);
}
.select-input[data-input-style~=width-lg] {
  width: var(--input-width-lg);
  max-width: var(--input-width-lg);
}
.select-input[data-input-style~=width-xl] {
  width: var(--input-width-xl);
  max-width: var(--input-width-xl);
}
.select-input[data-input-style~=width-xxl] {
  width: var(--input-width-xxl);
  max-width: var(--input-width-xxl);
}
.select-input[data-input-style~=width-max-content] {
  width: var(--input-width-max-content);
  max-width: var(--input-width-max-content);
}

.selectable-card {
  position: relative;
  padding-block: 1rem;
  padding-inline: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--slate-a8);
  transition: box-shadow 150ms ease-out;
}
.selectable-card:not(.selectable-card-checked):hover {
  box-shadow: inset 0 0 0 1px var(--slate-a9);
}

.selectable-card-description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.selectable-card-description {
  color: var(--text-tertiary);
}

.selectable-card-checked {
  box-shadow: inset 0 0 0 1px var(--slate-a12);
}

.radio-button-wrapper:focus-within .selectable-card {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  box-shadow: var(--input-focus-shadow);
}

.selectable-card:has(.radio-button:checked) {
  background-color: var(--slate-a2);
  box-shadow: inset 0 0 0 1px var(--slate-a12);
}

.stat-card-wrapper-container-flex {
  display: flex;
  column-gap: 1rem;
}
.stat-card-wrapper-container-flex > .stat-card-wrapper {
  width: 100%;
}

.stat-card-wrapper {
  display: flex;
  column-gap: 1rem;
  background-color: var(--gray-bg-ui);
  border: 1px solid var(--slate-a6);
  border-radius: 0.25rem;
  padding-inline: 0.75rem;
  padding-block: 0.75rem 0.875rem;
}

.stat-card {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  flex: 1;
}

.pool-stats--main {
  padding: 2rem;
  max-width: 80rem;
  margin-inline: auto;
}

.pool-stats--section {
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  align-items: flex-start;
  column-gap: 2rem;
  row-gap: 1rem;
  margin-block-end: 4rem;
  position: relative;
}
.pool-stats--section[data-column-count="1"] {
  --column-count: 1;
  row-gap: 0;
}
.pool-stats--section[data-column-count="2"] {
  --column-count: 2;
}
.pool-stats--section[data-column-count="3"] {
  --column-count: 3;
}
.pool-stats--section[data-column-count="4"] {
  --column-count: 4;
}
.pool-stats--section[data-column-count="6"] {
  --column-count: 6;
}
.pool-stats--section[data-section-type=details] {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-block-end: 5.25rem;
}
.pool-stats--section[data-section-type=details]:after {
  content: "";
  position: absolute;
  inset: 42px -1rem -1.25rem -1rem;
  background-color: var(--gray-bg-ui);
  border: 1px solid hsl(var(--gray-90)/6%);
  z-index: -1;
  border-radius: 0.25rem;
}

.pool-stats--card-group {
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  column-gap: 2rem;
}
.pool-stats--card-group[data-column-count="1"] {
  --column-count: 1;
}
.pool-stats--card-group[data-column-count="2"] {
  --column-count: 2;
}

.pool-stats--heading {
  grid-column: 1/-1;
  font-size: var(--scale3);
  line-height: var(--lh-scale3);
  font-weight: 500;
  padding-block-end: 0.75rem;
}
.pool-stats--heading[data-heading-type=details] {
  min-width: 100%;
}

.pool-stats--card {
  display: grid;
  row-gap: 0.75rem;
}
.pool-stats--card[data-card-type=details] {
  flex: auto;
}

.pool-stats--row {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding-block: 0.75rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: hsl(var(--gray-40));
  margin-block-start: -1px;
  height: 3rem;
}

.pool-stats--caption {
  display: block;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  flex: auto;
}
.pool-stats--caption[data-caption-type=tertiary] {
  color: var(--text-tertiary);
}

.pool-stats--value {
  --stat-value-size: var(--scale-1);
  --stat-value-lh: var(--lh-scale-1);
  display: block;
  font-size: var(--stat-value-size);
  line-height: var(--stat-value-lh);
  font-variant-numeric: tabular-nums;
  padding-inline-end: 2px;
}
.pool-stats--value[data-value-size=large] {
  --stat-value-size: var(--scale2);
  --stat-value-lh: var(--lh-scale2);
  font-variant-numeric: initial;
  padding-inline-end: 0;
}

.stat-divider {
  height: 1px;
  background-color: hsl(var(--gray-90)/16%);
  align-self: center;
  flex: auto;
}

.grid-span-2 {
  grid-column: span 2;
}

.pool-stats--table {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
  border-collapse: collapse;
  margin-inline: -1rem;
}
.pool-stats--table thead {
  background-color: hsl(var(--gray-20));
}
.pool-stats--table th {
  background-color: hsl(var(--gray-20));
}
.pool-stats--table tr {
  height: 3rem;
}
.pool-stats--table td {
  background-color: var(--gray-bg-ui);
}

.pool-stats--table-cell {
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  padding: 0.9375rem 1rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: hsl(var(--gray-30));
  margin-block-start: -1px;
  vertical-align: baseline;
}
.pool-stats--table-cell-header {
  border-top: 0;
  font-weight: 600;
}

.text-input {
  resize: none;
  width: 100%;
  height: var(--input-height, var(--input-height-md));
  min-height: var(--input-height, var(--input-height-md));
  padding-block: var(--input-padding-block, 0);
  padding-inline: var(--input-padding, 1rem);
  color: var(--text-primary);
  background-color: var(--input-bg-color, var(--slate-a3));
  border: var(--input-border, none);
  border-radius: var(--input-border-radius, 0);
  box-shadow: var(--input-box-shadow, inset 0 -1px 0 var(--slate-a9));
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}
.text-input::placeholder {
  color: var(--text-placeholder);
}
.text-input:focus-visible {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-focus-shadow);
}
.text-input:active:not(:disabled) {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
}
.text-input:disabled {
  color: var(--text-disabled);
  background-color: var(--input-bg-color-disabled, var(--slate-a3));
  box-shadow: var(--input-box-shadow-disabled, none);
  cursor: not-allowed;
}
.text-input:read-only:not(:disabled) {
  --input-padding: 0;
  --input-bg-color: transparent;
  --input-box-shadow: var(--input-box-shadow-disabled);
  font-size: var(--scale1);
  line-height: var(--lh-scale1);
}
.text-input:read-only:is(:focus-visible) {
  --input-focus-outline: 0;
  --input-focus-shadow: none;
}

label:has(+ .text-input:disabled) {
  color: var(--text-disabled);
}

legend:has(+ .input-group-inline-full > .input-wrapper > .text-input:disabled) {
  color: var(--text-disabled);
}

.text-input[data-input-style~=outlined] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--slate-a2);
  --input-border-radius: var(--size-2);
  --input-box-shadow: inset 0 0 0 1px var(--slate-a8);
  --input-box-shadow-disabled: inset 0 0 0 1px var(--slate-a7);
}
.text-input[data-input-style~=layer-01] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--color-surface);
}
.text-input[data-input-style~=layer-02] {
  --input-bg-color: var(--slate-a2);
  --input-bg-color-disabled: var(--slate-a2);
}
.text-input[data-input-style~=layer-03] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--color-surface);
}

.text-input[data-input-style~=xs] {
  --input-height: var(--input-height-xs);
  --input-padding: 0.5rem;
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
}
.text-input[data-input-style~=sm] {
  --input-height: var(--input-height-sm);
  --input-padding: 0.5rem;
  font-size: var(--scale-2);
  line-height: var(--lh-scale-2);
}
.text-input[data-input-style~=lg] {
  --input-height: var(--input-height-lg);
}
.text-input[data-input-style~=xl] {
  --input-height: var(--input-height-xl);
}

.text-input[data-input-style~=width-xxs] {
  width: var(--input-width-xxs);
  max-width: var(--input-width-xxs);
}
.text-input[data-input-style~=width-xs] {
  width: var(--input-width-xs);
  max-width: var(--input-width-xs);
}
.text-input[data-input-style~=width-sm] {
  width: var(--input-width-sm);
  max-width: var(--input-width-sm);
}
.text-input[data-input-style~=width-md] {
  width: var(--input-width-md);
  max-width: var(--input-width-md);
}
.text-input[data-input-style~=width-lg] {
  width: var(--input-width-lg);
  max-width: var(--input-width-lg);
}
.text-input[data-input-style~=width-xl] {
  width: var(--input-width-xl);
  max-width: var(--input-width-xl);
}
.text-input[data-input-style~=width-xxl] {
  width: var(--input-width-xxl);
  max-width: var(--input-width-xxl);
}

.textarea {
  --input-height: 7rem;
  --input-padding-block: 0.6875rem;
  scroll-padding-block-end: var(--input-padding-block);
  resize: block;
  width: 100%;
  height: var(--input-height, var(--input-height-md));
  min-height: var(--input-height, var(--input-height-md));
  padding-block: var(--input-padding-block, 0);
  padding-inline: var(--input-padding, 1rem);
  color: var(--text-primary);
  background-color: var(--input-bg-color, var(--slate-a3));
  border: var(--input-border, none);
  border-radius: var(--input-border-radius, 0);
  box-shadow: var(--input-box-shadow, inset 0 -1px 0 var(--slate-a9));
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}
.textarea::placeholder {
  color: var(--text-placeholder);
}
.textarea:focus-visible {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-focus-shadow);
}
.textarea:active:not(:disabled) {
  outline: var(--input-focus-outline);
  outline-offset: -1px;
  border-radius: var(--input-border-radius);
}
.textarea:disabled {
  color: var(--text-disabled);
  background-color: var(--input-bg-color-disabled, var(--slate-a3));
  box-shadow: var(--input-box-shadow-disabled, none);
  cursor: not-allowed;
}
.textarea:where(.jury-voice-menu) {
  --input-padding: 1rem 6.75rem;
}

label:has(+ .textarea:disabled) {
  color: var(--text-disabled);
}

.textarea[data-input-style~=outlined] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--slate-a2);
  --input-border-radius: var(--size-2);
  --input-box-shadow: inset 0 0 0 1px var(--slate-a8);
  --input-box-shadow-disabled: inset 0 0 0 1px var(--slate-a7);
}

.textarea[data-input-style~=layer-01] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--color-surface);
}

.textarea[data-input-style~=layer-02] {
  --input-bg-color: var(--slate-a2);
  --input-bg-color-disabled: var(--slate-a2);
}

.textarea[data-input-style~=layer-03] {
  --input-bg-color: var(--color-surface);
  --input-bg-color-disabled: var(--color-surface);
}

:where(html) {
  --textarea-line-height: var(--lh-scale-1);
}

.textarea[data-input-style~=xxs] {
  --input-height: var(--input-height-md);
}

.textarea[data-input-style~=xs] {
  --input-height: calc(var(--input-height-md) + var(--textarea-line-height));
}

.textarea[data-input-style~=sm] {
  --input-height: calc(var(--input-height-md) + (var(--textarea-line-height) * 2));
}

.textarea[data-input-style~=md] {
  --input-height: calc(var(--input-height-md) + (var(--textarea-line-height) * 3));
}

.textarea[data-input-style~=lg] {
  --input-height: calc(var(--input-height-md) + (var(--textarea-line-height) * 4));
}

.textarea[data-input-style~=xl] {
  --input-height: calc(var(--input-height-md) + (var(--textarea-line-height) * 5));
}

:where(.toggle-switch) {
  --width-multiplier: 2;
  --track-width-min: calc(30rem / 16);
  --track-padding: calc(3rem / 16);
  --track-height: calc(20rem / 16);
  --track-width: max(var(--track-width-min), calc((var(--thumb-size) * var(--width-multiplier)) + (var(--track-padding) * 2)));
  --track-margin: 0;
  --thumb-size: calc(var(--track-height) - (var(--track-padding) * 2));
  --thumb-position: 0%;
  --track-color-inactive: var(--slate-a9);
  --track-color-active: var(--forge-tertiary-60);
  --thumb-color: oklch(100% 0 0);
  --thumb-color-highlight: var(--slate-a6);
  --toggle-label-font-size: var(--scale-1);
  --toggle-label-line-height: var(--lh-scale-1);
  --toggle-label-margin: 1px;
  --thumb-transition-duration: 150ms;
  --thumb-highlight-duration: 100ms;
}

.toggle-switch {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: var(--toggle-label-font-size);
  line-height: var(--toggle-label-line-height);
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.toggle-switch:has(.toggle:disabled).toggle-switch {
  cursor: not-allowed;
}

.toggle {
  appearance: none;
  border: none;
  inline-size: var(--track-width);
  block-size: var(--track-height);
  padding: var(--track-padding);
  margin-block-start: var(--track-margin);
  background-color: var(--track-color-inactive);
  border-radius: var(--track-width);
  flex-shrink: 0;
  display: grid;
  align-items: center;
  grid: [track] 1fr/[track] 1fr;
  pointer-events: none;
  transition: background-color var(--thumb-transition-duration) ease-out;
}
.toggle:checked {
  --thumb-position: calc((var(--track-width) - 100%) - (var(--track-padding) * 2));
  background-color: var(--track-color-active);
}
.toggle:disabled {
  --thumb-color: transparent;
  --track-active: var(--track-color-active);
  cursor: not-allowed;
}
.toggle:disabled::before {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 2px oklch(100% 0 0);
}
.toggle:indeterminate {
  --thumb-position: calc(((var(--track-width) / 2) - (var(--thumb-size) / 2)) - var(--track-padding));
}

.toggle::before {
  --highlight-size: 0;
  content: "";
  grid-area: track;
  inline-size: var(--thumb-size);
  block-size: var(--thumb-size);
  background-color: var(--thumb-color);
  border-radius: calc(var(--thumb-size) / 2);
  box-shadow: 0 0 0 var(--highlight-size) var(--thumb-color-highlight);
  transform: translateX(var(--thumb-position));
  cursor: pointer;
  pointer-events: auto;
  transition: transform var(--thumb-transition-duration) ease-out, box-shadow var(--thumb-transition-duration) ease-out;
}

.toggle:not(:disabled):hover::before {
  --highlight-size: calc(var(--track-padding) * 2);
}

.toggle-label {
  --toggle-label-font-size: 0.8125rem;
  --toggle-label-line-height: var(--lh-scale-2);
  font-size: var(--toggle-label-font-size);
  line-height: var(--toggle-label-line-height);
  font-weight: 450;
}

.toggle-switch[data-toggle~=xs] {
  --track-height: 1rem;
}
.toggle-switch[data-toggle~=xs] .toggle-label {
  --toggle-label-font-size: var(--scale-2);
}
.toggle-switch[data-toggle~=md] {
  --track-height: 1.5rem;
  --toggle-label-font-size: var(--scale0);
  --toggle-label-line-height: var(--lh-scale0);
}
.toggle-switch[data-toggle~=lg] {
  --track-height: 1.875rem;
  --toggle-label-font-size: var(--scale1);
  --toggle-label-line-height: var(--lh-scale1);
}

.toggle-switch[data-toggle~=setting-row] {
  justify-content: space-between;
  flex: auto;
  column-gap: 1rem;
}
.toggle-switch[data-toggle~=inline] {
  align-self: end;
  margin-block-end: 0.625rem;
}
.toggle-switch[data-toggle="inline xs"] {
  margin-block-end: 0.75rem;
}
.toggle-switch[data-toggle~=stacked] {
  flex-direction: column;
  align-items: flex-start;
}

.toggle-switch-inline {
  align-self: end;
  margin-block-end: 0.75rem;
}

.gj-checkbox-md span {
  cursor: initial !important;
}

.gj-checkbox-md input[type=checkbox] + span {
  height: 1rem !important;
  border-radius: 1px !important;
  border: 1px solid hsl(var(--gray-90)) !important;
  position: relative;
}

.gj-checkbox-md input[type=checkbox]:checked + span {
  background-color: var(--primary);
  border: 1px solid var(--primary) !important;
}

.gj-checkbox-md input[type=checkbox]:checked + span:after {
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  content: "" !important;
  background-color: var(--primary) !important;
  height: 0.5625rem;
  left: 0.25rem !important;
  top: 1px !important;
  transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  width: 0.375rem;
}

ul.gj-list-md li {
  font-family: inherit !important;
}

ul.gj-list-md li [data-role=display] {
  padding: 0 0 0 0.25rem !important;
  color: hsl(var(--gray-70)) !important;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  color: var(--text-secondary);
  font-variant-alternates: var(--small-text);
}

.gj-icon {
  font-size: var(--scale1) !important;
}

:where(html) {
  --header-size: 3.5rem;
  --header-background: var(--color-panel-translucent);
  --court-logo-size: 3rem;
  --menu-toggle-size: 2.5rem;
  --nav-island-size: 3rem;
  --nav-island-background: var(--color-panel-translucent);
  --nav-island-border: 1px solid var(--slate-a4);
  --nav-island-box-shadow: 0 2px 12px oklch(0% 0 0 / 8%);
  --background-blur: 16px;
}

.app-bar {
  position: fixed;
  inset-block-start: 1rem;
  inset-inline: 1rem;
  min-height: var(--header-size);
  border-radius: calc(var(--header-size) / 2);
  z-index: 20;
  transition: background-color 100ms ease-out, inset 100ms ease-out;
  pointer-events: none;
}
.app-bar::before {
  content: "";
  position: absolute;
  inset-inline: -1rem;
  inset-block: -1rem -0.5rem;
  backdrop-filter: blur(16px);
}
@media (width < 800px) {
  .app-bar {
    inset-inline: 0.75rem;
    display: block;
    background-color: var(--header-background);
    box-shadow: var(--nav-island-box-shadow);
    border: var(--nav-island-border);
    backdrop-filter: blur(64px);
    pointer-events: auto;
  }
  .app-bar::before {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: auto;
  min-height: var(--header-size);
  position: relative;
}
@media (width < 800px) {
  .header {
    display: block;
  }
}

.branding {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  column-gap: 0.75rem;
  height: var(--header-size);
  position: absolute;
}
.branding::after {
  --badge-background-color: var(--amber-4);
  --badge-text-color: var(--amber-a12);
  --badge-font-weight: 500;
  --badge-font-size: calc(11rem / 16);
  margin-inline-start: 0.75rem;
  display: flex;
  align-items: center;
  column-gap: var(--badge-gap, 0.25rem);
  height: var(--badge-height, var(--size-24));
  width: fit-content;
  padding-inline: var(--badge-padding, 0.5rem);
  border-radius: var(--badge-border-radius, 0.5rem);
  background-color: var(--badge-background-color, var(--slate-a3));
  color: var(--badge-text-color, var(--slate-a12));
  font-size: var(--badge-font-size, var(--scale-2));
  line-height: var(--badge-line-height, var(--lh-scale-2));
  font-weight: var(--badge-font-weight, 450);
  font-variant-alternates: character-variant(l-tail);
}
.branding::after:has([class*=icon--]) {
  --badge-padding: 0.25rem 0.5rem;
}
@media (width < 800px) {
  .branding {
    position: relative;
  }
}

.court-logo-wrapper {
  width: var(--court-logo-size);
  height: var(--court-logo-size);
  border-radius: calc(var(--court-logo-size) / 2);
  display: grid;
  place-items: center;
  overflow: hidden;
}

.court-name-wrapper > span {
  display: block;
}

.court-logo {
  object-fit: cover;
  height: 100%;
}

.menu-toggle {
  position: absolute;
  top: calc((var(--header-size) - var(--menu-toggle-size)) / 2);
  right: 0.5rem;
  width: var(--menu-toggle-size);
  height: var(--menu-toggle-size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.375rem;
  cursor: pointer;
}
.menu-toggle::before, .menu-toggle::after {
  content: "";
  height: 1px;
  width: 1.5rem;
  background-color: var(--text-primary);
  transform-origin: center center;
  transition: translate 50ms ease-out 100ms, rotate 50ms ease-out;
}
@media (width >= 800px) {
  .menu-toggle {
    display: none;
  }
}

:where(.menu-toggle[aria-expanded=true]).menu-toggle::before {
  translate: 0 3px;
  rotate: 45deg;
  transition: translate 100ms ease-out, rotate 100ms ease-out 100ms;
}

:where(.menu-toggle[aria-expanded=true]).menu-toggle::after {
  translate: 0 -4px;
  rotate: -45deg;
  transition: translate 100ms ease-out, rotate 100ms ease-out 100ms;
}

.primary-nav {
  display: none;
  grid-template-rows: 0fr;
  margin-inline: auto;
  transition: grid-template-rows 125ms ease-out, padding 125ms ease-out;
}
@media (width < 800px) {
  .primary-nav {
    display: grid;
  }
}

.primary-nav[aria-hidden=false] {
  grid-template-rows: 1fr;
  padding-block: 1rem;
  transition: grid-template-rows 200ms ease-out, padding 200ms ease-out;
}

.primary-nav-expandable {
  grid-row: 1/span 2;
  transition: opacity 100ms ease-out;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

:where(.primary-nav[aria-hidden=false]) .primary-nav-expandable {
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms ease-out;
}

.primary-nav-candidate-info {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.candidate-info-id {
  display: flex;
  align-items: center;
  margin-block-start: 0.25rem;
}
.candidate-info-id .divider {
  display: inline-block;
  background-color: var(--text-primary);
  height: 1px;
  width: 1.5em;
  margin-inline: 0.5em;
}

.primary-nav-list {
  height: var(--nav-island-size);
  border-radius: calc(var(--nav-island-size) / 2);
  background-color: var(--nav-island-background);
  padding-inline: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background-clip: padding-box;
  border: var(--nav-island-border);
  box-shadow: var(--nav-island-box-shadow);
  backdrop-filter: blur(var(--background-blur));
  pointer-events: auto;
}
@media (width < 800px) {
  .primary-nav-list {
    --nav-island-size: auto;
    --nav-island-background: transparent;
    --nav-island-border: none;
    --nav-island-box-shadow: none;
    display: block;
    padding-inline: 1.5rem;
    backdrop-filter: none;
  }
}

.primary-nav-list[data-primary-nav-list~=large-screen] {
  margin-inline: auto;
}
@media (width < 800px) {
  .primary-nav-list[data-primary-nav-list~=large-screen] {
    display: none;
  }
}

.ja-nav-item {
  font-size: var(--nav-item-font-size, var(--scale-1));
  line-height: var(--nav-item-line-height, --lh-scale-1);
  font-weight: var(--nav-item-font-weight, 500);
  height: var(--nav-item-height, 2rem);
  color: var(--text-primary);
  display: grid;
  place-items: center;
  padding-inline: 0.75rem;
  border-radius: 1rem;
  position: relative;
}
.ja-nav-item:hover {
  color: var(--text-primary);
}
.ja-nav-item::before {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: 2rem;
  z-index: -1;
  transition: background-color 150ms ease-out;
}
.ja-nav-item:hover::before {
  background-color: var(--slate-a3);
}
@media (width < 800px) {
  .ja-nav-item {
    --nav-item-height: 3rem;
    --nav-item-font-size: var(--scale2);
    --nav-item-line-height: var(--lh-scale2);
    --nav-item-font-weight: 400;
    justify-items: start;
    padding-inline: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--slate-a5);
  }
  .ja-nav-item::before {
    display: none;
  }
}

.logout-wrapper {
  pointer-events: auto;
}

.logout-wrapper[data-logout~=small-screen] {
  display: none;
}
@media (width < 800px) {
  .logout-wrapper[data-logout~=small-screen] {
    display: grid;
    place-items: center;
    justify-items: start;
    height: 4rem;
    padding-inline: 1.5rem;
  }
  .logout-wrapper[data-logout~=small-screen] .primary-nav-logout {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center;
    column-gap: 0.5rem;
    min-height: 2rem;
  }
}

.logout-wrapper[data-logout~=large-screen] {
  position: absolute;
  inset-inline-end: 0;
}
@media (width < 800px) {
  .logout-wrapper[data-logout~=large-screen] {
    display: none;
  }
}

.candidate-exemption-request-modal-wrapper {
  position: fixed;
  inset: 0;
  background-color: var(--color-panel-translucent);
  backdrop-filter: blur(64px) saturate(150%);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
}

.candidate-exemption-request-modal {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 41rem 1fr;
  row-gap: 1.5rem;
  padding-inline: 1rem;
  padding-block: 5rem;
}
@media (width < 672px) {
  .candidate-exemption-request-modal {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-inline: 1rem;
    padding-block: 2rem 4rem;
  }
}

.candidate-exemption-request-modal-header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  grid-column: 2/3;
  padding-block-start: 2rem;
}
.candidate-exemption-request-modal-header span {
  color: var(--text-primary);
}
.candidate-exemption-request-modal-header > p {
  color: var(--text-tertiary);
}
@media only screen and (min-width: 320px) and (max-width: 672px) {
  .candidate-exemption-request-modal-header h1 {
    font-size: var(--scale3);
    line-height: var(--lh-scale3);
    font-weight: 600;
  }
}

.candidate-exemption-request-modal-header--display-none {
  display: none;
}

.candidate-exemption-request-modal:has(.candidate-exemption-request-modal-header--display-none).candidate-exemption-request-modal {
  padding-block-start: 0;
}

.candidate-exemption-request-modal-main {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  grid-column: 2/3;
}

.candidate-exemption-request-section {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.candidate-exemption-request-section-main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.candidate-exemption-request-type-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  gap: var(--spacing);
}
.candidate-exemption-request-type-group > * label {
  height: 100%;
}
.candidate-exemption-request-type-group .invalid-feedback {
  grid-column: 1/2;
}

.candidate-exemption-request-new-service-date {
  margin-top: 1rem;
}
@media only screen and (min-width: 830px) {
  .candidate-exemption-request-new-service-date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing);
  }
}

.candidate-exemption-request-section-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-block-start: var(--spacing);
}
.candidate-exemption-request-section-header > p {
  color: var(--text-tertiary);
}

.candidate-exemption-request-progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing);
  height: 3.5rem;
  grid-column: 1/-1;
  margin-inline: -1rem;
  padding-inline: 1rem;
  border-bottom: 1px solid transparent;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: border 100ms ease-out, background-color 100ms ease-out;
}
@media (width < 672px) {
  .candidate-exemption-request-progress-bar {
    height: 3rem;
  }
}

.progress-bar-border {
  border-bottom: 1px solid var(--slate-a6);
  background-color: var(--slate-2);
  backdrop-filter: blur(24px);
}

.candidate-exemption-request-progress-bar-inner {
  display: flex;
  width: 41rem;
}
.candidate-exemption-request-progress-bar-inner .incomplete-text {
  color: var(--text-tertiary);
}

.candidate-exemption-request-progress-step {
  display: flex;
  align-items: center;
  gap: var(--spacing);
  font-size: var(--scale-1);
  font-weight: 500;
}

@media (width < 600px) {
  .candidate-exemption-request-progress-step-number:not(.status-active) ~ .candidate-exemption-request-progress-step-text {
    display: none;
  }
}

.candidate-exemption-request-progress-step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px dashed var(--text-primary);
  color: var(--text-primary);
  font-size: var(--scale-2);
}

.status-active {
  border: 1px solid var(--text-primary);
  background: var(--text-primary);
  color: var(--slate-1);
}
.status-completed span.candidate-exemption-request-progress-step-number {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
}

.candidate-exemption-step-control {
  grid-column: 2/3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.btn-back {
  margin-inline-end: auto;
}

.btn-next {
  margin-inline-start: auto;
}

.candidate-exemption-request-form-close-btn {
  position: fixed;
  inset: 0.5rem 1rem auto auto;
  border-radius: 1.25rem;
  z-index: 40;
  pointer-events: initial;
}
@media (width < 672px) {
  .candidate-exemption-request-form-close-btn {
    inset-block: auto 0.5rem;
    inset-inline: 0;
    margin-inline: auto;
  }
}

.date-dropdown,
.date-dropdown-profile {
  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(92px, 1fr) minmax(100px, 1fr);
  gap: 0.5rem;
}
@media (width < 380px) {
  .date-dropdown,
  .date-dropdown-profile {
    grid: auto/1fr;
  }
}

@media (width <= 398px) {
  .date-dropdown-profile {
    grid: auto/1fr;
  }
}

:where(.date-dropdown-profile) .select-input[data-input-style~=outlined] {
  --input-bg-color: inherit;
  --input-bg-color-disabled: inherit;
  --input-border-radius: inherit;
  --input-box-shadow: inherit;
  --input-box-shadow-disabled: inherit;
  --input-height: inherit;
}

.floating-nav-bar {
  display: none;
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 3.5rem;
  z-index: 20;
}
@media only screen and (min-width: 672px) {
  .floating-nav-bar {
    display: block;
  }
}
@media (min-width: 672px) and (max-width: 829px) {
  .floating-nav-bar {
    top: 0;
    left: 0;
    right: 0;
    height: 5rem;
    background-color: hsl(var(--gray-05)/90%);
    backdrop-filter: blur(20px);
    padding: 1rem 1rem 0.5rem 1rem;
  }
}

.floating-nav {
  background: white;
  height: 100%;
  width: fit-content;
  border-radius: 28px;
  box-shadow: 0 43px 26px rgba(0, 0, 0, 0.01), 0 19px 19px rgba(0, 0, 0, 0.02), 0 5px 11px rgba(0, 0, 0, 0.02), 0 0 0 rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  padding: 0.25rem 1.5rem 0.25rem 0.25rem;
}
.floating-nav__wrapper {
  display: flex;
  align-items: center;
}
.floating-nav__logout {
  border: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-left: 1.5rem;
  color: hsl(var(--gray-70));
  cursor: pointer;
}
.floating-nav__logout i {
  font-size: var(--scale-1);
}
.floating-nav__logo {
  width: 100%;
  height: 100%;
}
.floating-nav__logo-wrapper {
  height: 3rem;
  width: 3rem;
  border-radius: 30px;
  overflow: hidden;
}
.floating-nav__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.floating-nav__link a, .floating-nav__link button {
  font-size: var(--scale-1);
  font-weight: 500;
  color: hsl(var(--gray-90));
  background: none;
  border: none;
  cursor: pointer;
}

.forge-tooltip {
  --forge-tooltip-theme-background: var(--gray-a12);
  --forge-tooltip-theme-on-background: var(--gray-1);
  --forge-tooltip-font-size: 12px;
  line-height: var(--lh-scale-2);
  font-weight: 450;
  transform: scale(0.95);
  white-space: pre-wrap;
  margin: 4px;
}

.online-panel-confirm-dialog {
  --dialog-padding-block: 1rem 1.5rem;
  --dialog-padding-inline: 1.5rem;
}

.progress-bar.p-100 {
  background: var(--primary) !important;
  transition: 600ms cubic-bezier(0.165, 0.84, 0.44, 1) background-color;
}

.questionnaire-progress {
  display: grid;
  row-gap: 0.25rem;
  position: fixed;
  left: initial;
  bottom: 1.5rem;
  width: 100%;
  z-index: 12;
  max-width: 240px;
}

.progress-bar-container {
  background-clip: padding-box;
  border: 1px solid var(--slate-a5);
  position: relative;
  background: var(--slate-a2);
  width: 240px;
  height: 0.625rem;
  border-radius: 0.625rem;
}
.progress-bar-container::before {
  width: 240px;
  height: 0.625rem;
  border-radius: 0.625rem;
  position: absolute;
  display: block;
  content: "";
}
.progress-bar-container .progress-bar {
  position: relative;
  background: var(--primary);
  width: 240px;
  height: 0.5rem;
  border-radius: 0.5rem;
}
.progress-bar-container .progress-bar, .progress-bar-container .progress-bar::after {
  transition: 600ms cubic-bezier(0.165, 0.84, 0.44, 1) width, 600ms cubic-bezier(0.165, 0.84, 0.44, 1) background-color;
}
.progress-bar-container .progress-bar::after {
  width: 240px;
  height: 0.625rem;
  border-radius: 0.625rem;
  position: absolute;
  display: block;
  content: "";
}
.progress-bar-container .progress-bar.p-0 {
  width: 0px;
}
.progress-bar-container .progress-bar.p-0::after {
  width: 0px;
}
.progress-bar-container .progress-bar.p-1 {
  width: 2.4px;
}
.progress-bar-container .progress-bar.p-1::after {
  width: 2.4px;
}
.progress-bar-container .progress-bar.p-2 {
  width: 4.8px;
}
.progress-bar-container .progress-bar.p-2::after {
  width: 4.8px;
}
.progress-bar-container .progress-bar.p-3 {
  width: 7.2px;
}
.progress-bar-container .progress-bar.p-3::after {
  width: 7.2px;
}
.progress-bar-container .progress-bar.p-4 {
  width: 9.6px;
}
.progress-bar-container .progress-bar.p-4::after {
  width: 9.6px;
}
.progress-bar-container .progress-bar.p-5 {
  width: 12px;
}
.progress-bar-container .progress-bar.p-5::after {
  width: 12px;
}
.progress-bar-container .progress-bar.p-6 {
  width: 14.4px;
}
.progress-bar-container .progress-bar.p-6::after {
  width: 14.4px;
}
.progress-bar-container .progress-bar.p-7 {
  width: 16.8px;
}
.progress-bar-container .progress-bar.p-7::after {
  width: 16.8px;
}
.progress-bar-container .progress-bar.p-8 {
  width: 19.2px;
}
.progress-bar-container .progress-bar.p-8::after {
  width: 19.2px;
}
.progress-bar-container .progress-bar.p-9 {
  width: 21.6px;
}
.progress-bar-container .progress-bar.p-9::after {
  width: 21.6px;
}
.progress-bar-container .progress-bar.p-10 {
  width: 24px;
}
.progress-bar-container .progress-bar.p-10::after {
  width: 24px;
}
.progress-bar-container .progress-bar.p-11 {
  width: 26.4px;
}
.progress-bar-container .progress-bar.p-11::after {
  width: 26.4px;
}
.progress-bar-container .progress-bar.p-12 {
  width: 28.8px;
}
.progress-bar-container .progress-bar.p-12::after {
  width: 28.8px;
}
.progress-bar-container .progress-bar.p-13 {
  width: 31.2px;
}
.progress-bar-container .progress-bar.p-13::after {
  width: 31.2px;
}
.progress-bar-container .progress-bar.p-14 {
  width: 33.6px;
}
.progress-bar-container .progress-bar.p-14::after {
  width: 33.6px;
}
.progress-bar-container .progress-bar.p-15 {
  width: 36px;
}
.progress-bar-container .progress-bar.p-15::after {
  width: 36px;
}
.progress-bar-container .progress-bar.p-16 {
  width: 38.4px;
}
.progress-bar-container .progress-bar.p-16::after {
  width: 38.4px;
}
.progress-bar-container .progress-bar.p-17 {
  width: 40.8px;
}
.progress-bar-container .progress-bar.p-17::after {
  width: 40.8px;
}
.progress-bar-container .progress-bar.p-18 {
  width: 43.2px;
}
.progress-bar-container .progress-bar.p-18::after {
  width: 43.2px;
}
.progress-bar-container .progress-bar.p-19 {
  width: 45.6px;
}
.progress-bar-container .progress-bar.p-19::after {
  width: 45.6px;
}
.progress-bar-container .progress-bar.p-20 {
  width: 48px;
}
.progress-bar-container .progress-bar.p-20::after {
  width: 48px;
}
.progress-bar-container .progress-bar.p-21 {
  width: 50.4px;
}
.progress-bar-container .progress-bar.p-21::after {
  width: 50.4px;
}
.progress-bar-container .progress-bar.p-22 {
  width: 52.8px;
}
.progress-bar-container .progress-bar.p-22::after {
  width: 52.8px;
}
.progress-bar-container .progress-bar.p-23 {
  width: 55.2px;
}
.progress-bar-container .progress-bar.p-23::after {
  width: 55.2px;
}
.progress-bar-container .progress-bar.p-24 {
  width: 57.6px;
}
.progress-bar-container .progress-bar.p-24::after {
  width: 57.6px;
}
.progress-bar-container .progress-bar.p-25 {
  width: 60px;
}
.progress-bar-container .progress-bar.p-25::after {
  width: 60px;
}
.progress-bar-container .progress-bar.p-26 {
  width: 62.4px;
}
.progress-bar-container .progress-bar.p-26::after {
  width: 62.4px;
}
.progress-bar-container .progress-bar.p-27 {
  width: 64.8px;
}
.progress-bar-container .progress-bar.p-27::after {
  width: 64.8px;
}
.progress-bar-container .progress-bar.p-28 {
  width: 67.2px;
}
.progress-bar-container .progress-bar.p-28::after {
  width: 67.2px;
}
.progress-bar-container .progress-bar.p-29 {
  width: 69.6px;
}
.progress-bar-container .progress-bar.p-29::after {
  width: 69.6px;
}
.progress-bar-container .progress-bar.p-30 {
  width: 72px;
}
.progress-bar-container .progress-bar.p-30::after {
  width: 72px;
}
.progress-bar-container .progress-bar.p-31 {
  width: 74.4px;
}
.progress-bar-container .progress-bar.p-31::after {
  width: 74.4px;
}
.progress-bar-container .progress-bar.p-32 {
  width: 76.8px;
}
.progress-bar-container .progress-bar.p-32::after {
  width: 76.8px;
}
.progress-bar-container .progress-bar.p-33 {
  width: 79.2px;
}
.progress-bar-container .progress-bar.p-33::after {
  width: 79.2px;
}
.progress-bar-container .progress-bar.p-34 {
  width: 81.6px;
}
.progress-bar-container .progress-bar.p-34::after {
  width: 81.6px;
}
.progress-bar-container .progress-bar.p-35 {
  width: 84px;
}
.progress-bar-container .progress-bar.p-35::after {
  width: 84px;
}
.progress-bar-container .progress-bar.p-36 {
  width: 86.4px;
}
.progress-bar-container .progress-bar.p-36::after {
  width: 86.4px;
}
.progress-bar-container .progress-bar.p-37 {
  width: 88.8px;
}
.progress-bar-container .progress-bar.p-37::after {
  width: 88.8px;
}
.progress-bar-container .progress-bar.p-38 {
  width: 91.2px;
}
.progress-bar-container .progress-bar.p-38::after {
  width: 91.2px;
}
.progress-bar-container .progress-bar.p-39 {
  width: 93.6px;
}
.progress-bar-container .progress-bar.p-39::after {
  width: 93.6px;
}
.progress-bar-container .progress-bar.p-40 {
  width: 96px;
}
.progress-bar-container .progress-bar.p-40::after {
  width: 96px;
}
.progress-bar-container .progress-bar.p-41 {
  width: 98.4px;
}
.progress-bar-container .progress-bar.p-41::after {
  width: 98.4px;
}
.progress-bar-container .progress-bar.p-42 {
  width: 100.8px;
}
.progress-bar-container .progress-bar.p-42::after {
  width: 100.8px;
}
.progress-bar-container .progress-bar.p-43 {
  width: 103.2px;
}
.progress-bar-container .progress-bar.p-43::after {
  width: 103.2px;
}
.progress-bar-container .progress-bar.p-44 {
  width: 105.6px;
}
.progress-bar-container .progress-bar.p-44::after {
  width: 105.6px;
}
.progress-bar-container .progress-bar.p-45 {
  width: 108px;
}
.progress-bar-container .progress-bar.p-45::after {
  width: 108px;
}
.progress-bar-container .progress-bar.p-46 {
  width: 110.4px;
}
.progress-bar-container .progress-bar.p-46::after {
  width: 110.4px;
}
.progress-bar-container .progress-bar.p-47 {
  width: 112.8px;
}
.progress-bar-container .progress-bar.p-47::after {
  width: 112.8px;
}
.progress-bar-container .progress-bar.p-48 {
  width: 115.2px;
}
.progress-bar-container .progress-bar.p-48::after {
  width: 115.2px;
}
.progress-bar-container .progress-bar.p-49 {
  width: 117.6px;
}
.progress-bar-container .progress-bar.p-49::after {
  width: 117.6px;
}
.progress-bar-container .progress-bar.p-50 {
  width: 120px;
}
.progress-bar-container .progress-bar.p-50::after {
  width: 120px;
}
.progress-bar-container .progress-bar.p-51 {
  width: 122.4px;
}
.progress-bar-container .progress-bar.p-51::after {
  width: 122.4px;
}
.progress-bar-container .progress-bar.p-52 {
  width: 124.8px;
}
.progress-bar-container .progress-bar.p-52::after {
  width: 124.8px;
}
.progress-bar-container .progress-bar.p-53 {
  width: 127.2px;
}
.progress-bar-container .progress-bar.p-53::after {
  width: 127.2px;
}
.progress-bar-container .progress-bar.p-54 {
  width: 129.6px;
}
.progress-bar-container .progress-bar.p-54::after {
  width: 129.6px;
}
.progress-bar-container .progress-bar.p-55 {
  width: 132px;
}
.progress-bar-container .progress-bar.p-55::after {
  width: 132px;
}
.progress-bar-container .progress-bar.p-56 {
  width: 134.4px;
}
.progress-bar-container .progress-bar.p-56::after {
  width: 134.4px;
}
.progress-bar-container .progress-bar.p-57 {
  width: 136.8px;
}
.progress-bar-container .progress-bar.p-57::after {
  width: 136.8px;
}
.progress-bar-container .progress-bar.p-58 {
  width: 139.2px;
}
.progress-bar-container .progress-bar.p-58::after {
  width: 139.2px;
}
.progress-bar-container .progress-bar.p-59 {
  width: 141.6px;
}
.progress-bar-container .progress-bar.p-59::after {
  width: 141.6px;
}
.progress-bar-container .progress-bar.p-60 {
  width: 144px;
}
.progress-bar-container .progress-bar.p-60::after {
  width: 144px;
}
.progress-bar-container .progress-bar.p-61 {
  width: 146.4px;
}
.progress-bar-container .progress-bar.p-61::after {
  width: 146.4px;
}
.progress-bar-container .progress-bar.p-62 {
  width: 148.8px;
}
.progress-bar-container .progress-bar.p-62::after {
  width: 148.8px;
}
.progress-bar-container .progress-bar.p-63 {
  width: 151.2px;
}
.progress-bar-container .progress-bar.p-63::after {
  width: 151.2px;
}
.progress-bar-container .progress-bar.p-64 {
  width: 153.6px;
}
.progress-bar-container .progress-bar.p-64::after {
  width: 153.6px;
}
.progress-bar-container .progress-bar.p-65 {
  width: 156px;
}
.progress-bar-container .progress-bar.p-65::after {
  width: 156px;
}
.progress-bar-container .progress-bar.p-66 {
  width: 158.4px;
}
.progress-bar-container .progress-bar.p-66::after {
  width: 158.4px;
}
.progress-bar-container .progress-bar.p-67 {
  width: 160.8px;
}
.progress-bar-container .progress-bar.p-67::after {
  width: 160.8px;
}
.progress-bar-container .progress-bar.p-68 {
  width: 163.2px;
}
.progress-bar-container .progress-bar.p-68::after {
  width: 163.2px;
}
.progress-bar-container .progress-bar.p-69 {
  width: 165.6px;
}
.progress-bar-container .progress-bar.p-69::after {
  width: 165.6px;
}
.progress-bar-container .progress-bar.p-70 {
  width: 168px;
}
.progress-bar-container .progress-bar.p-70::after {
  width: 168px;
}
.progress-bar-container .progress-bar.p-71 {
  width: 170.4px;
}
.progress-bar-container .progress-bar.p-71::after {
  width: 170.4px;
}
.progress-bar-container .progress-bar.p-72 {
  width: 172.8px;
}
.progress-bar-container .progress-bar.p-72::after {
  width: 172.8px;
}
.progress-bar-container .progress-bar.p-73 {
  width: 175.2px;
}
.progress-bar-container .progress-bar.p-73::after {
  width: 175.2px;
}
.progress-bar-container .progress-bar.p-74 {
  width: 177.6px;
}
.progress-bar-container .progress-bar.p-74::after {
  width: 177.6px;
}
.progress-bar-container .progress-bar.p-75 {
  width: 180px;
}
.progress-bar-container .progress-bar.p-75::after {
  width: 180px;
}
.progress-bar-container .progress-bar.p-76 {
  width: 182.4px;
}
.progress-bar-container .progress-bar.p-76::after {
  width: 182.4px;
}
.progress-bar-container .progress-bar.p-77 {
  width: 184.8px;
}
.progress-bar-container .progress-bar.p-77::after {
  width: 184.8px;
}
.progress-bar-container .progress-bar.p-78 {
  width: 187.2px;
}
.progress-bar-container .progress-bar.p-78::after {
  width: 187.2px;
}
.progress-bar-container .progress-bar.p-79 {
  width: 189.6px;
}
.progress-bar-container .progress-bar.p-79::after {
  width: 189.6px;
}
.progress-bar-container .progress-bar.p-80 {
  width: 192px;
}
.progress-bar-container .progress-bar.p-80::after {
  width: 192px;
}
.progress-bar-container .progress-bar.p-81 {
  width: 194.4px;
}
.progress-bar-container .progress-bar.p-81::after {
  width: 194.4px;
}
.progress-bar-container .progress-bar.p-82 {
  width: 196.8px;
}
.progress-bar-container .progress-bar.p-82::after {
  width: 196.8px;
}
.progress-bar-container .progress-bar.p-83 {
  width: 199.2px;
}
.progress-bar-container .progress-bar.p-83::after {
  width: 199.2px;
}
.progress-bar-container .progress-bar.p-84 {
  width: 201.6px;
}
.progress-bar-container .progress-bar.p-84::after {
  width: 201.6px;
}
.progress-bar-container .progress-bar.p-85 {
  width: 204px;
}
.progress-bar-container .progress-bar.p-85::after {
  width: 204px;
}
.progress-bar-container .progress-bar.p-86 {
  width: 206.4px;
}
.progress-bar-container .progress-bar.p-86::after {
  width: 206.4px;
}
.progress-bar-container .progress-bar.p-87 {
  width: 208.8px;
}
.progress-bar-container .progress-bar.p-87::after {
  width: 208.8px;
}
.progress-bar-container .progress-bar.p-88 {
  width: 211.2px;
}
.progress-bar-container .progress-bar.p-88::after {
  width: 211.2px;
}
.progress-bar-container .progress-bar.p-89 {
  width: 213.6px;
}
.progress-bar-container .progress-bar.p-89::after {
  width: 213.6px;
}
.progress-bar-container .progress-bar.p-90 {
  width: 216px;
}
.progress-bar-container .progress-bar.p-90::after {
  width: 216px;
}
.progress-bar-container .progress-bar.p-91 {
  width: 218.4px;
}
.progress-bar-container .progress-bar.p-91::after {
  width: 218.4px;
}
.progress-bar-container .progress-bar.p-92 {
  width: 220.8px;
}
.progress-bar-container .progress-bar.p-92::after {
  width: 220.8px;
}
.progress-bar-container .progress-bar.p-93 {
  width: 223.2px;
}
.progress-bar-container .progress-bar.p-93::after {
  width: 223.2px;
}
.progress-bar-container .progress-bar.p-94 {
  width: 225.6px;
}
.progress-bar-container .progress-bar.p-94::after {
  width: 225.6px;
}
.progress-bar-container .progress-bar.p-95 {
  width: 228px;
}
.progress-bar-container .progress-bar.p-95::after {
  width: 228px;
}
.progress-bar-container .progress-bar.p-96 {
  width: 230.4px;
}
.progress-bar-container .progress-bar.p-96::after {
  width: 230.4px;
}
.progress-bar-container .progress-bar.p-97 {
  width: 232.8px;
}
.progress-bar-container .progress-bar.p-97::after {
  width: 232.8px;
}
.progress-bar-container .progress-bar.p-98 {
  width: 235.2px;
}
.progress-bar-container .progress-bar.p-98::after {
  width: 235.2px;
}
.progress-bar-container .progress-bar.p-99 {
  width: 237.6px;
}
.progress-bar-container .progress-bar.p-99::after {
  width: 237.6px;
}
.progress-bar-container .progress-bar.p-100 {
  width: 240px;
}
.progress-bar-container .progress-bar.p-100::after {
  width: 240px;
}

.questionnaire-progress-data {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;
}

.questionnaire-progress-text {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  font-variant-alternates: var(--small-text);
}

.questionnaire-progress-numbers, .mono {
  font-family: var(--font-mono);
  font-feature-settings: "lnum" 1, "pnum" 1, "kern" 1, "liga" 1, "ss04" 1;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  letter-spacing: normal;
  text-align: right;
}

.mono {
  text-align: left;
}

.question-number {
  font-family: var(--font-mono);
  font-feature-settings: "lnum" 1, "pnum" 1, "kern" 1, "liga" 1, "ss04" 1;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  letter-spacing: normal;
  color: var(--text-secondary);
}

.question-card {
  background-clip: padding-box;
  background-color: var(--color-panel-solid);
  border: 1px solid var(--slate-a5);
  border-radius: 0.5rem;
  padding: 1rem 3rem 1.5rem 1rem;
  box-shadow: 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 1px 0px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 1px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 2px -1px hsla(0, 0%, 0%, 0.05), 0px 2px 4px -2px hsla(0, 0%, 0%, 0.05), 0px 3px 6px -3px hsla(0, 0%, 0%, 0.05);
}
@media (max-width: 479px) {
  .question-card {
    padding: 1rem 1rem 1.5rem 0.5rem;
  }
}

.question-card--body {
  display: flex;
  column-gap: 1rem;
}

.questionnaire-body-editable {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: calc(var(--spacing) * 3);
}
.questionnaire-body-editable input[type=number] {
  max-width: 12ch;
}

.question__answer {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);
  container-type: inline-size;
  container-name: question-answer;
}

.question-group {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);
}

.questionnaire--question-nested {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);
}
@media only screen and (min-width: 830px) {
  .questionnaire--question-nested {
    max-width: none;
    margin-left: initial;
  }
}

.question-card-with-header {
  background-clip: padding-box;
  background-color: var(--color-panel-solid);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 1px 0px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 1px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 2px -1px hsla(0, 0%, 0%, 0.05), 0px 2px 4px -2px hsla(0, 0%, 0%, 0.05), 0px 3px 6px -3px hsla(0, 0%, 0%, 0.05);
}

.question-card--header {
  position: relative;
  display: flex;
  column-gap: 1rem;
  padding: 1rem 5.875rem 1rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid var(--slate-a6);
  min-height: 3rem;
}
.question-card--header button {
  position: absolute;
  top: 0.5625rem;
  right: 0.5rem;
}
.question-card--header-editing {
  background: var(--blue-a3);
  border: 1px solid var(--blue-a6);
  color: var(--blue-a12);
}

.question-card-with-header--body {
  padding-block: 1rem 1.5rem;
  padding-inline: clamp(1rem, -0.04rem + 4.2667vw, 3rem);
  border: 1px solid var(--slate-a6);
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

:where(.dialog-body) .question-card-with-header {
  background-color: var(--gray-bg-ui);
}
:where(.dialog-body) .question-card-with-header--body {
  padding-inline: 1rem;
}

.question--readonly {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);
}

@media (width < 800px) {
  .sidebar-nav {
    position: fixed;
    inset-inline: 0;
    display: flex;
    align-items: flex-end;
    min-height: var(--sidebar-nav-size-mobile, 120px);
    backdrop-filter: blur(16px);
    z-index: 10;
  }
}

.sidebar--inner {
  position: sticky;
  top: calc(var(--content-grid-margin) + 0.5rem);
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
@media (width < 800px) {
  .sidebar--inner {
    display: none;
  }
}

.sidebar--inner-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-height: 3rem;
  width: 100%;
  padding-inline: clamp(1rem, 0.0344rem + 3.9312vw, 2rem);
}
@media (width >= 800px) {
  .sidebar--inner-mobile {
    display: none;
  }
}

.sidebar--candidate-info {
  padding-inline: 0.5rem;
}

.sidebar--inner .sidebar--candidate-info .heading-06 {
  font-size: clamp(var(--scale2), 2.0100502513vw, var(--scale3));
  letter-spacing: clamp(var(--ls-scale2), -0.0341708543vw, var(--ls-scale3));
  line-height: 1.25;
}

.questionnaire-nav {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  row-gap: 0.25rem;
}

.questionnaire-nav-item {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  min-height: 2.5rem;
  padding-inline: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid transparent;
  color: var(--text-secondary);
  transition: color 150ms ease-out, background-color 150ms ease-out;
}
.questionnaire-nav-item.complete {
  font-weight: 500;
  color: var(--text-tertiary);
}
.questionnaire-nav-item--nest, .questionnaire-nav-item--group {
  flex-direction: column;
  align-items: flex-start;
  padding-block: 0.25rem;
}
.questionnaire-nav-item.active {
  background-color: var(--color-panel-translucent);
  border: 1px solid var(--slate-a5);
  background-clip: padding-box;
}

.sidebar-nav-questionnaire-container {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  min-height: 2rem;
  width: 100%;
}
.sidebar-nav-questionnaire-container.active {
  color: var(--text-primary);
  font-weight: 500;
}
.sidebar-nav-questionnaire-container.inactive {
  color: var(--text-tertiary);
}
.sidebar-nav-questionnaire-container .questionnaire-icon--nav {
  width: 1.25rem;
  fill: hsl(var(--gray-90));
}

.sidebar-mobile {
  padding-block-start: 1.5rem;
  will-change: max-height;
  opacity: 0;
  overflow: hidden;
  transition: max-height 1000ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 1500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 600ms ease-out;
}
@media only screen and (min-width: 830px) {
  .sidebar-mobile {
    display: none;
  }
}

.sidebar-container-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-inline: clamp(1rem, 4.1025641026vw, 1.5rem);
  transition: max-height 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sidebar-nav-state:checked ~ .sidebar-nav .sidebar-mobile {
  opacity: 1;
}

.sidebar-nav-state:checked ~ .sidebar-nav .sidebar--candidate-info {
  animation: slideNav 2000ms cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: 1;
  animation-delay: 25ms;
}
.sidebar-nav-state:checked ~ .sidebar-nav .questionnaire-nav {
  animation: slideNav 3000ms cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: 1;
  animation-delay: 100ms;
}

.progress-stats {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  margin-inline: auto 0.125rem;
  font-weight: 400;
  font-variant-numeric: tabular-nums;
}

.circle-check {
  display: flex;
}

.circle-check.checked span {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
}

@keyframes slideNav {
  0% {
    opacity: 0;
    transform: translateY(-12px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
}
:where(html) {
  color-scheme: light;
}

@media (prefers-color-scheme: dark) {
  :where(html) {
    color-scheme: dark;
    --gray-1: oklch(17.76% 0 0);
    --gray-2: oklch(21.34% 0 0);
    --gray-3: oklch(25.2% 0 0);
    --gray-4: oklch(28.5% 0 0);
    --gray-5: oklch(31.32% 0 0);
    --gray-6: oklch(34.85% 0 0);
    --gray-7: oklch(40.17% 0 0);
    --gray-8: oklch(48.91% 0 0);
    --gray-9: oklch(53.82% 0 0);
    --gray-10: oklch(58.29% 0 0);
    --gray-11: oklch(76.99% 0 0);
    --gray-12: oklch(94.91% 0 0);
    --gray-a1: oklch(0% 0 0 / 0%);
    --gray-a2: oklch(100% 0 0 / 3.53%);
    --gray-a3: oklch(100% 0 0 / 7.06%);
    --gray-a4: oklch(100% 0 0 / 10.59%);
    --gray-a5: oklch(100% 0 0 / 13.33%);
    --gray-a6: oklch(100% 0 0 / 17.25%);
    --gray-a7: oklch(100% 0 0 / 23.14%);
    --gray-a8: oklch(100% 0 0 / 33.33%);
    --gray-a9: oklch(100% 0 0 / 39.22%);
    --gray-a10: oklch(100% 0 0 / 44.71%);
    --gray-a11: oklch(100% 0 0 / 68.63%);
    --gray-a12: oklch(100% 0 0 / 92.94%);
    --slate-1: oklch(17.85% 0.004 285.98);
    --slate-2: oklch(21.32% 0.004 264.48);
    --slate-3: oklch(25.21% 0.006 271.18);
    --slate-4: oklch(28.32% 0.007 248.07);
    --slate-5: oklch(31.18% 0.008 255.56);
    --slate-6: oklch(34.66% 0.01 253.97);
    --slate-7: oklch(39.93% 0.012 252.94);
    --slate-8: oklch(48.93% 0.016 251.69);
    --slate-9: oklch(53.7% 0.015 262.34);
    --slate-10: oklch(58.25% 0.015 266.63);
    --slate-11: oklch(76.86% 0.01 258.34);
    --slate-12: oklch(94.89% 0.003 264.54);
    --slate-a1: oklch(0% 0 0 / 0%);
    --slate-a2: oklch(94.75% 0.029 201.91 / 3.53%);
    --slate-a3: oklch(93.15% 0.024 250.08 / 7.84%);
    --slate-a4: oklch(93.05% 0.031 225.1 / 11.37%);
    --slate-a5: oklch(93.65% 0.031 243.7 / 14.51%);
    --slate-a6: oklch(93.01% 0.033 243.96 / 18.82%);
    --slate-a7: oklch(93.68% 0.032 245.3 / 25.1%);
    --slate-a8: oklch(93.68% 0.032 245.3 / 36.47%);
    --slate-a9: oklch(93.65% 0.028 258.36 / 42.75%);
    --slate-a10: oklch(94.48% 0.023 264.46 / 48.24%);
    --slate-a11: oklch(97.36% 0.011 252.09 / 70.98%);
    --slate-a12: oklch(99.39% 0.003 264.54 / 93.73%);
    --ruby-1: oklch(18.9% 0.014 1.9);
    --ruby-2: oklch(20.8% 0.015 3.6);
    --ruby-3: oklch(25.4% 0.061 6);
    --ruby-4: oklch(29.3% 0.089 6.1);
    --ruby-5: oklch(33.4% 0.1 6.5);
    --ruby-6: oklch(38.2% 0.106 7.1);
    --ruby-7: oklch(44.8% 0.116 9.1);
    --ruby-8: oklch(54.3% 0.145 11.3);
    --ruby-9: oklch(62.8% 0.195 13.2);
    --ruby-10: oklch(66.4% 0.18 13.6);
    --ruby-11: oklch(78.1% 0.129 15.1);
    --ruby-12: oklch(90.6% 0.053 355.7);
    --ruby-a1: oklch(61.7% 0.242 18.1 / 3.53%);
    --ruby-a2: oklch(69.4% 0.2 10.4 / 5.49%);
    --ruby-a3: oklch(64.6% 0.244 14.7 / 17.25%);
    --ruby-a4: oklch(63.9% 0.247 13.5 / 25.88%);
    --ruby-a5: oklch(65.2% 0.239 11 / 32.55%);
    --ruby-a6: oklch(67.3% 0.223 10.1 / 39.61%);
    --ruby-a7: oklch(69.2% 0.204 10.7 / 50.20%);
    --ruby-a8: oklch(69.7% 0.198 12.2 / 68.24%);
    --ruby-a9: oklch(67.7% 0.213 13.4 / 89.41%);
    --ruby-a10: oklch(70.3% 0.192 13.7 / 92.16%);
    --ruby-a11: oklch(78.1% 0.129 15.1);
    --ruby-a12: oklch(90.9% 0.053 355.7);
    --amber-1: oklch(18.5% 0.013 77.8);
    --amber-2: oklch(21.2% 0.018 82);
    --amber-3: oklch(25.8% 0.044 74.5);
    --amber-4: oklch(29.6% 0.063 73.9);
    --amber-5: oklch(33.6% 0.072 72.9);
    --amber-6: oklch(38.6% 0.078 75.3);
    --amber-7: oklch(45.4% 0.082 75);
    --amber-8: oklch(53.6% 0.096 73.4);
    --amber-9: oklch(85.4% 0.157 84.1);
    --amber-10: oklch(88.5% 0.181 94.8);
    --amber-11: oklch(86.2% 0.173 89);
    --amber-12: oklch(93.5% 0.072 86.1);
    --amber-a1: oklch(60.9% 0.213 35.1 / 2.35%);
    --amber-a2: oklch(77.3% 0.172 66.2 / 5.10%);
    --amber-a3: oklch(72.8% 0.18 55 / 13.33%);
    --amber-a4: oklch(73.1% 0.182 54.5 / 19.61%);
    --amber-a5: oklch(74.6% 0.178 58.4 / 25.49%);
    --amber-a6: oklch(77.3% 0.172 66.2 / 31.76%);
    --amber-a7: oklch(80.4% 0.164 71.9 / 40.39%);
    --amber-a8: oklch(81.0% 0.158 71.9 / 52.94%);
    --amber-a9: oklch(85.4% 0.157 84.1);
    --amber-a10: oklch(88.5% 0.181 94.8);
    --amber-a11: oklch(86.2% 0.173 89);
    --amber-a12: oklch(93.5% 0.072 86.1);
    --blue-a4: oklch(59.26% 0.223 258.41 / 34.12%);
    --blue-a12: oklch(90.71% 0.051 238.45);
    --jade-4: oklch(31.62% 0.057 167.62);
    --jade-a4: oklch(88.24% 0.199 160.14 / 17.65%);
    --jade-a12: oklch(94.5% 0.081 166.61 / 93.73%);
    --orange-4: oklch(29.37% 0.071 56.7);
    --orange-a4: oklch(68.03% 0.214 39.8 / 22.35%);
    --orange-a12: oklch(92.47% 0.052 66.17);
    --sky-4: oklch(32.08% 0.07 248.74);
    --sky-a4: oklch(64.84% 0.193 252.75 / 28.63%);
    --sky-a12: oklch(93.4% 0.0526 214.317);
    --mint-4: oklch(31.46% 0.054 190.98);
    --mint-a4: oklch(90.18% 0.156 189.21 / 17.25%);
    --mint-a12: oklch(95.61% 0.059 168.06 / 96.08%);
    --text-primary: oklch(94.48% 0.003 247.95);
    --text-secondary: oklch(94.48% 0.003 247.95 / 75%);
    --text-tertiary: oklch(94.48% 0.003 247.96 / 61%);
    --text-helper: oklch(94.48% 0.003 247.96 / 61%);
    --text-placeholder: oklch(94.48% 0.003 247.96 / 48%);
    --text-disabled: oklch(94.48% 0.003 247.96 / 24%);
    --text-on-color: oklch(5.52% 0.003 247.95);
    --color-surface: oklch(0% 0 0 / 25.1%);
    --color-panel-solid: var(--slate-2);
    --color-panel-translucent: var(--slate-a2);
  }
  :where(html) .text-input[data-input-style~=outlined],
  :where(html) .select-input[data-input-style~=outlined] {
    --input-box-shadow: inset 0 0 0 1px var(--slate-a7);
  }
  :where(html) :where(.date-dropdown-profile) .select-input[data-input-style~=outlined] {
    --input-box-shadow: inherit;
  }
}
.login-page {
  display: grid;
  justify-items: center;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--slate-2);
}
@media (width >= 600px) {
  .login-page {
    place-items: center;
  }
}

.login-page--wrapper {
  display: grid;
  grid: auto min-content/auto;
  row-gap: 4rem;
  max-width: calc(45ch + 1.5rem);
  padding-inline: clamp(1rem, -48.5rem + 200vw, 1.5rem);
  padding-block: 2rem clamp(2rem, -14rem + 53.3333vw, 8rem);
}
@media (width < 600px) {
  .login-page--wrapper {
    row-gap: 2rem;
  }
}

.login-page--header {
  display: grid;
  justify-items: center;
  align-content: start;
  row-gap: 1rem;
}
@media (width < 600px) {
  .login-page--header {
    align-content: center;
  }
}
@media (width < 380px) {
  .login-page--header {
    padding-block-start: 1rem;
  }
}

.login-logo {
  --court-logo-size: 4rem;
}

.login-page--branding {
  text-align: center;
}

.login-page--form {
  display: grid;
  row-gap: 1.5rem;
}

.login-page--fieldset {
  display: grid;
  row-gap: 1.5rem;
}
.login-page--fieldset input[type=number] {
  -moz-appearance: textfield;
}
.login-page--fieldset input[type=number]::-webkit-inner-spin-button,
.login-page--fieldset input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

:where(html) {
  --mobile-nav-size: 3.5rem;
  --sidebar-nav-size-mobile: calc(var(--header-size) + 1rem + var(--sidebar-inner-size-mobile));
  --sidebar-inner-size-mobile: 3rem;
  --content-grid-columns: [sidebar-start] var(--sidebar-width) [sidebar-end content-start] 1fr [content-end];
  --content-grid-rows: ;
  --content-column-gap: clamp(2rem, calc(-3.7143rem + 11.4286vw), 4rem);
  --content-grid-margin: calc(120rem / var(--rem-base));
  --content-grid-padding-inline: clamp(1rem, calc(0.0344rem + 3.9312vw), 2rem);
  --content-grid-width: fit-content;
  --content-width: calc(800rem / var(--rem-base));
  --content-intro-size: clamp(var(--scale3), calc(0.0714rem + 2.8571vw), var(--scale5));
  --content-intro-line-height: clamp(var(--lh-scale3), calc(0.0893rem + 3.5714vw), var(--lh-scale5));
  --content-row-gap: 1rem;
  --content-margin-bottom: 4rem;
  --content-padding-top: calc(var(--sidebar-nav-size-mobile) + 2rem);
}

.app-layout {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--slate-1);
}

.content-intro {
  font-size: var(--content-intro-size);
  line-height: var(--content-intro-line-height);
  font-weight: 450;
  margin-block-end: var(--content-intro-line-height);
}

:where(html) {
  --dashboard-margin-block: calc(var(--header-size) + 2rem);
  --dashboard-content-padding-top: 7rem;
  --home-status-badge-height: calc(1.25rem + var(--lh-scale-1));
}

.dashboard-container {
  container-type: inline-size;
  container-name: dashboard;
}

.dashboard {
  position: relative;
  display: grid;
  grid: auto/[intro-start] 1fr [intro-end reporting-start] 1fr [reporting-end];
  column-gap: 4rem;
  margin-inline: auto;
  margin-block-start: var(--dashboard-margin-block);
  margin-block-end: 6rem;
  padding-inline: clamp(1rem, -0.2456rem + 5.0713vw, 3rem);
  max-width: 1360px;
  scrollbar-gutter: stable;
}
@container dashboard (width < 80ch) {
  .dashboard {
    grid: [intro-start] auto [intro-end reporting-start] auto [reporting-end]/1fr;
    column-gap: 0;
    row-gap: 4rem;
  }
}

.dashboard-intro {
  grid-area: intro;
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 1.5rem;
  padding-block-start: var(--dashboard-content-padding-top);
}
@container dashboard (width < 80ch) {
  .dashboard-intro {
    --dashboard-content-padding-top: 2rem;
  }
}

.sticky {
  position: sticky;
  top: calc(var(--header-size) + 2rem + 7rem);
}

.home-status-badge {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding-inline: 1rem;
  height: var(--home-status-badge-height);
  border-radius: calc(var(--home-status-badge-height) / 2);
  background-color: var(--status-badge-bg-color, var(--slate-a4));
  width: fit-content;
}

.home-status-icon {
  --indicator-size: 0.75rem;
  border-radius: 0.25rem;
  height: var(--indicator-size);
  width: var(--indicator-size);
  background-color: var(--indicator-color, var(--text-primary));
  border: 1px solid var(--indicator-border-color, var(--text-primary));
  position: relative;
}

.home-status-badge[data-candidate-status~=selected] {
  --status-badge-bg-color: var(--plum-a4);
  --status-badge-text-color: var(--plum-a12);
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=selected] {
    --indicator-color: var(--plum-a12);
    --indicator-border-color: var(--plum-a12);
    color: var(--plum-a12);
  }
}

.home-status-badge[data-candidate-status~=summoned] {
  --status-badge-bg-color: var(--violet-a4);
  --status-badge-text-color: var(--violet-a12);
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=summoned] {
    --indicator-color: var(--violet-a12);
    --indicator-border-color: var(--violet-a12);
    color: var(--violet-a12);
  }
}

.home-status-badge[data-candidate-status~=confirmed], .home-status-badge[data-candidate-status~=qualified], .home-status-badge[data-candidate-status~=service-complete] {
  --status-badge-bg-color: var(--jade-a4);
  --status-badge-text-color: var(--jade-a12);
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=confirmed], .home-status-badge[data-candidate-status~=qualified], .home-status-badge[data-candidate-status~=service-complete] {
    --indicator-color: var(--jade-a12);
    --indicator-border-color: var(--jade-a12);
    color: var(--jade-a12);
  }
}

.home-status-badge[data-candidate-status~=excused], .home-status-badge[data-candidate-status~=excusal-pending] {
  --status-badge-bg-color: var(--amber-a4);
  --status-badge-text-color: var(--amber-a12);
  --indicator-color: transparent;
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=excused], .home-status-badge[data-candidate-status~=excusal-pending] {
    --indicator-border-color: var(--amber-a12);
    color: var(--amber-a12);
  }
}

.home-status-badge[data-candidate-status~=disqualified], .home-status-badge[data-candidate-status~=disqualification-pending], .home-status-badge[data-candidate-status~=undeliverable] {
  --status-badge-bg-color: var(--ruby-a4);
  --status-badge-text-color: var(--ruby-a12);
  --indicator-color: transparent;
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=disqualified], .home-status-badge[data-candidate-status~=disqualification-pending], .home-status-badge[data-candidate-status~=undeliverable] {
    --indicator-border-color: var(--ruby-a12);
    color: var(--ruby-a12);
  }
}

.home-status-badge[data-candidate-status~=deferred], .home-status-badge[data-candidate-status~=deferral-pending], .home-status-badge[data-candidate-status~=postponed] {
  --status-badge-bg-color: var(--orange-a4);
  --status-badge-text-color: var(--orange-a12);
  --indicator-color: transparent;
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=deferred], .home-status-badge[data-candidate-status~=deferral-pending], .home-status-badge[data-candidate-status~=postponed] {
    --indicator-border-color: var(--orange-a12);
    color: var(--orange-a12);
  }
}

.home-status-badge[data-candidate-status~=panel] {
  --status-badge-bg-color: var(--sky-a4);
  --status-badge-text-color: var(--sky-a12);
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=panel] {
    --indicator-color: var(--sky-a12);
    --indicator-border-color: var(--sky-a12);
    color: var(--sky-a12);
  }
}

.home-status-badge[data-candidate-status~=juror] {
  --status-badge-bg-color: var(--mint-a4);
  --status-badge-text-color: var(--mint-a12);
}
@media (prefers-color-scheme: dark) {
  .home-status-badge[data-candidate-status~=juror] {
    --indicator-color: var(--mint-a12);
    --indicator-border-color: var(--mint-a12);
    color: var(--mint-a12);
  }
}

.home-summary {
  font-size: var(--scale5);
  line-height: var(--lh-scale5);
  margin-block-end: 1.5rem;
}

.dashboard-exemptions {
  display: grid;
  row-gap: 1.5rem;
}

.dashboard-reporting-info-container {
  grid-area: reporting;
  container-type: inline-size;
  container-name: reporting-info;
}

.dashboard-reporting-info {
  --reporting-info-columns: repeat(2, 1fr);
  display: grid;
  grid-template-columns: var(--reporting-info-columns);
  grid-auto-rows: min-content;
  column-gap: 2rem;
  row-gap: 3rem;
  padding-block-start: calc(var(--dashboard-content-padding-top) + var(--home-status-badge-height) + 1.5rem);
}
@container dashboard (width < 80ch) {
  .dashboard-reporting-info {
    padding-block-start: 0;
  }
}
@container reporting-info (width < 45ch) {
  .dashboard-reporting-info {
    --reporting-info-columns: 1fr;
  }
}

.reporting-info-block {
  display: grid;
  row-gap: 2rem;
  padding-block: 1rem;
  border-block-start: 1px solid var(--slate-a8);
  align-content: space-between;
  grid-column: var(--grid-span);
}
@container reporting-info (width < 45ch) {}

.reporting-info-block[data-reporting-info~=full] {
  --grid-span: 1 / -1;
}

.reporting-info-block[data-reporting-info~="2-col"] {
  grid: auto/1fr 1fr;
  column-gap: 2rem;
}

.reporting-date-time {
  font-size: var(--scale7);
  line-height: var(--lh-scale7);
  font-weight: 500;
  display: flex;
  column-gap: 0.5em;
  flex-wrap: wrap;
  position: relative;
}

.reporting-upcoming-dates {
  --badge-font-size: var(--scale-1);
  --badge-background-color: var(--slate-a4);
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}

.reporting-contact-info {
  display: grid;
  grid-template-columns: 3.5rem max-content;
  column-gap: 1em;
  font-size: var(--scale0);
  line-height: var(--lh-scale0);
  margin-block-end: calc(var(--lh-scale0) / 2);
}

.em-dash {
  font-weight: 300;
}

.grid-parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
}
@media only screen and (min-width: 672px) {
  .grid-parent {
    grid-template-columns: repeat(16, 1fr);
    grid-column-gap: 32px;
  }
}
@media only screen and (min-width: 1056px) {
  .grid-parent {
    grid-template-columns: repeat(16, 1fr);
    grid-column-gap: 32px;
  }
}

.history-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
}
@media only screen and (min-width: 672px) {
  .history-grid {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 32px;
  }
}
@media only screen and (min-width: 1056px) {
  .history-grid {
    grid-template-columns: repeat(16, 1fr);
    grid-column-gap: 32px;
  }
}

.history-body {
  grid-column: 1/-1;
  margin-top: 1rem;
  padding-inline: 1rem;
}
@media only screen and (min-width: 830px) {
  .history-body {
    grid-column: 3/-3;
    padding-top: 1rem;
    padding-bottom: 4rem;
    margin: 0;
  }
}

.history-dashboard-header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
.history-dashboard-header h2 {
  font-variation-settings: "wght" 500;
}

.list-border-top li {
  border-top: 1px solid rgba(115, 119, 122, 0.4);
  margin-bottom: 13px;
  padding-top: 11px;
}

.history-dashboard-hero {
  margin-top: 96px;
}
@media only screen and (min-width: 672px) {
  .history-dashboard-hero {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
}
.history-dashboard-hero .list-border-top {
  width: 100%;
  margin-top: 40px;
  grid-column: 1/-1;
}
@media only screen and (min-width: 1056px) {
  .history-dashboard-hero .list-border-top {
    grid-column: -1/-6;
  }
}
@media only screen and (min-width: 1312px) {
  .history-dashboard-hero .list-border-top {
    grid-column: -1/-5;
  }
}
.history-dashboard-hero .list-border-top li {
  display: flex;
}
.history-dashboard-hero .list-border-top li span {
  flex: 50%;
}

.history-dashboard-instruction {
  grid-column: 1/-1;
}
@media only screen and (min-width: 1056px) {
  .history-dashboard-instruction {
    grid-column: 1/10;
  }
}
.history-dashboard-instruction p {
  margin-top: 0;
  margin-bottom: 0;
}
.history-dashboard-instruction p:last-child {
  color: rgb(115, 119, 122);
}
.history-dashboard-instruction h2 {
  font-variation-settings: "wght" 500;
}

.list-card-header--controls {
  margin-top: 0.5rem;
}
.list-card-header--controls .btn-tertiary {
  border-radius: 6px;
}

:where(html) {
  --container-width: 79rem;
  --min-padding: 2.891566265060241vw;
}

.profile-incomplete-container {
  display: grid;
  grid-template-columns: var(--content-grid-columns);
  column-gap: var(--content-column-gap, 4rem);
  margin-inline: auto;
  padding-block-start: var(--content-grid-margin);
  padding-inline: var(--content-grid-padding-inline);
  max-width: var(--content-grid-width, fit-content);
  min-height: 100vh;
  min-height: 100dvh;
}
@media (width < 800px) {
  .profile-incomplete-container {
    --content-grid-columns: 1fr;
    --content-grid-margin: 0;
  }
}

.profile-container {
  --content-grid-columns: 1fr;
  display: grid;
  grid-template-columns: var(--content-grid-columns);
  column-gap: var(--content-column-gap, 4rem);
  margin-inline: auto;
  padding-block-start: var(--content-grid-margin);
  padding-inline: var(--content-grid-padding-inline);
  max-width: var(--content-grid-width, fit-content);
  min-height: 100vh;
  min-height: 100dvh;
}
@media (width < 800px) {
  .profile-container {
    --content-grid-columns: 1fr;
    --content-grid-margin: 0;
  }
}

.profile-form,
.profile-form-incomplete {
  display: flex;
  flex-direction: column;
  row-gap: var(--content-row-gap, 1rem);
  margin-block-end: var(--content-margin-bottom, 4rem);
  max-width: var(--content-width, 40rem);
}
.profile-header {
  --content-intro-padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-width: var(--content-width, 40rem);
  padding-inline: var(--content-intro-padding);
}
@media (width < 800px) {
  .profile-header {
    --content-intro-padding: 0;
    padding-block-start: var(--content-padding-top);
  }
}

.profile-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 1.5rem 2rem;
  background-color: var(--color-panel-solid);
  border: 1px solid var(--slate-a5);
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  box-shadow: 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 0px 0px 0px hsla(0, 0%, 0%, 0), 0px 1px 0px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 1px -1px hsla(0, 0%, 0%, 0.05), 0px 1px 2px -1px hsla(0, 0%, 0%, 0.05), 0px 2px 4px -2px hsla(0, 0%, 0%, 0.05), 0px 3px 6px -3px hsla(0, 0%, 0%, 0.05);
}
@media (max-width: 480px) {
  .profile-section {
    grid-template-columns: 1fr;
  }
}
.profile-section h3 {
  margin-block-end: 0.5rem;
  font-weight: 500;
}
.profile-section:last-of-type > label {
  width: 80ch;
}

.profile-section-footer {
  display: flex;
  justify-content: flex-end;
  padding-block-start: 0.5rem;
}

.profile-sms-opt-in--label {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.questionnaire-container {
  display: grid;
  grid-template-columns: var(--content-grid-columns);
  column-gap: var(--content-column-gap, 4rem);
  margin-inline: auto;
  padding-block-start: var(--content-grid-margin);
  padding-inline: var(--content-grid-padding-inline);
  max-width: var(--content-grid-width, fit-content);
  min-height: 100vh;
  min-height: 100dvh;
}
@media (width < 800px) {
  .questionnaire-container {
    --content-grid-columns: 1fr;
    --content-grid-margin: 0;
  }
}

.questionnaire-form {
  display: flex;
  flex-direction: column;
  row-gap: var(--content-row-gap, 1rem);
  margin-block-end: var(--content-margin-bottom, 4rem);
  max-width: var(--content-width, 40rem);
}
.questionnaire-header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-width: var(--content-width, 40rem);
  padding-inline: var(--content-intro-padding);
}
@media (width < 800px) {
  .questionnaire-header {
    --content-intro-padding: 0;
    padding-block-start: var(--content-padding-top);
  }
}

.question-group-container {
  margin-inline: -0.5rem;
}
@media only screen and (min-width: 480px) {
  .question-group-container {
    margin-inline: 0;
  }
}

.questionnaire--question-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.questionnaire-question-body--control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionnaire-body .grid-parent--questionnaire {
  height: calc(100vh - 9.375rem);
}

.questionnaire-question-body {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  margin-top: 1rem;
}
@media only screen and (min-width: 1056px) {
  .questionnaire-question-body {
    grid-row: initial;
    margin-top: 0;
    padding: 1rem 0 4rem;
  }
}
.questionnaire-question-body h2 {
  margin-bottom: 0;
}
.questionnaire-question-body h3, .questionnaire-question-body h6 {
  width: 100%;
  margin: 0 0 1rem 0;
}
@media only screen and (min-width: 1312px) {
  .questionnaire-question-body h3, .questionnaire-question-body h6 {
    width: 25%;
    margin-bottom: 0.75rem;
  }
}
.questionnaire-question-body .btn__form {
  align-self: flex-end;
}
.questionnaire-question-body .half-width {
  width: calc(50% - 2rem);
}

:where(html) {
  --spacing: 0.5rem;
  --validation-scroll-padding: calc(var(--header-size) + 2rem);
}

html {
  scroll-padding-block-start: var(--validation-scroll-padding, 5.5rem);
  scroll-padding-block-end: 2rem;
}
@media (width < 800px) {
  html {
    --validation-scroll-padding: calc(var(--sidebar-nav-size-mobile) + 1rem);
  }
}

body {
  overflow: initial;
}

.icon {
  position: relative;
}

.exemption-submit {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-inline: 0.5rem;
}
.exemption-submit p {
  margin-top: 0;
  max-width: 30em;
}
@media only screen and (min-width: 672px) {
  .exemption-submit {
    min-width: 640px;
    max-width: 1140px;
  }
}

.exemption-submit-icon {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  position: relative;
  margin-bottom: 1.5rem;
}
.exemption-submit-icon:before {
  content: "";
  display: block;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.exemption-submit-icon .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.exemption-submit-icon svg {
  fill: #FFFFFF;
  width: 2rem;
  height: 2rem;
}
.exemption-submit-icon--success {
  background: rgba(140, 185, 180, 0.56);
}
.exemption-submit-icon--success:before {
  background: #054A49;
}
.exemption-submit-icon--unavailable {
  background: rgba(255, 209, 153, 0.44);
}
.exemption-submit-icon--unavailable:before {
  background: #FFD199;
}
.exemption-submit-icon--unavailable svg {
  fill: #66543D;
}