@use '../../../../node_modules/citizen/src/styles/abstracts' as *;
@use 'content' as *;

.questionnaire-container {
  @include content-layout-base;
}

.questionnaire-form {
  @include content;
}

.questionnaire-header {
  @include content-header;
}

.question-group-container {
  margin-inline: -0.5rem;

  @include breakpoint(sm) {
    margin-inline: 0;
  }
}

.questionnaire--question-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

// Buttons at bottom of question-list for next/previous/complete
.questionnaire-question-body--control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionnaire-body {
  .grid-parent--questionnaire {
    height: calc(100vh - calc(150rem / 16));
  }
}

.questionnaire-question-body {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  margin-top: 1rem;

  @include breakpoint(lg) {
    grid-row: initial;
    margin-top: 0;
    padding: 1rem 0 4rem;
  }

  h2 {
    margin-bottom: 0;
  }

  h3, h6 {
    width: 100%;
    margin: 0 0 1rem 0;

    @include breakpoint(xl) {
      width: 25%;
      margin-bottom: 0.75rem;
    }
  }
  .btn__form { align-self: flex-end; }
  .half-width { width: calc(50% - 2rem); }
}
