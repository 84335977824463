@use '../../../node_modules/citizen/src/styles/abstracts' as *;
@use '../../../node_modules/citizen/src/styles/base' as *;

// Light theme — Auto
// ————————————————————
:where(html) {
  color-scheme: light;
}


// Dark theme — Auto
// ————————————————————
@media (prefers-color-scheme: dark) {
  :where(html) {
    color-scheme: dark;

    --gray-1: oklch(17.76% 0 0);
    --gray-2: oklch(21.34% 0 0);
    --gray-3: oklch(25.2% 0 0);
    --gray-4: oklch(28.5% 0 0);
    --gray-5: oklch(31.32% 0 0);
    --gray-6: oklch(34.85% 0 0);
    --gray-7: oklch(40.17% 0 0);
    --gray-8: oklch(48.91% 0 0);
    --gray-9: oklch(53.82% 0 0);
    --gray-10: oklch(58.29% 0 0);
    --gray-11: oklch(76.99% 0 0);
    --gray-12: oklch(94.91% 0 0);

    --gray-a1: oklch(0% 0 0 / 0%);
    --gray-a2: oklch(100% 0 0 / 3.53%);
    --gray-a3: oklch(100% 0 0 / 7.06%);
    --gray-a4: oklch(100% 0 0 / 10.59%);
    --gray-a5: oklch(100% 0 0 / 13.33%);
    --gray-a6: oklch(100% 0 0 / 17.25%);
    --gray-a7: oklch(100% 0 0 / 23.14%);
    --gray-a8: oklch(100% 0 0 / 33.33%);
    --gray-a9: oklch(100% 0 0 / 39.22%);
    --gray-a10: oklch(100% 0 0 / 44.71%);
    --gray-a11: oklch(100% 0 0 / 68.63%);
    --gray-a12: oklch(100% 0 0 / 92.94%);

    --slate-1: oklch(17.85% 0.004 285.98);
    --slate-2: oklch(21.32% 0.004 264.48);
    --slate-3: oklch(25.21% 0.006 271.18);
    --slate-4: oklch(28.32% 0.007 248.07);
    --slate-5: oklch(31.18% 0.008 255.56);
    --slate-6: oklch(34.66% 0.01 253.97);
    --slate-7: oklch(39.93% 0.012 252.94);
    --slate-8: oklch(48.93% 0.016 251.69);
    --slate-9: oklch(53.7% 0.015 262.34);
    --slate-10: oklch(58.25% 0.015 266.63);
    --slate-11: oklch(76.86% 0.01 258.34);
    --slate-12: oklch(94.89% 0.003 264.54);

    --slate-a1: oklch(0% 0 0 / 0%);
    --slate-a2: oklch(94.75% 0.029 201.91 / 3.53%);
    --slate-a3: oklch(93.15% 0.024 250.08 / 7.84%);
    --slate-a4: oklch(93.05% 0.031 225.1 / 11.37%);
    --slate-a5: oklch(93.65% 0.031 243.7 / 14.51%);
    --slate-a6: oklch(93.01% 0.033 243.96 / 18.82%);
    --slate-a7: oklch(93.68% 0.032 245.3 / 25.1%);
    --slate-a8: oklch(93.68% 0.032 245.3 / 36.47%);
    --slate-a9: oklch(93.65% 0.028 258.36 / 42.75%);
    --slate-a10: oklch(94.48% 0.023 264.46 / 48.24%);
    --slate-a11: oklch(97.36% 0.011 252.09 / 70.98%);
    --slate-a12: oklch(99.39% 0.003 264.54 / 93.73%);

    --ruby-1: oklch(18.9% 0.014 1.9);
    --ruby-2: oklch(20.8% 0.015 3.6);
    --ruby-3: oklch(25.4% 0.061 6);
    --ruby-4: oklch(29.3% 0.089 6.1);
    --ruby-5: oklch(33.4% 0.1 6.5);
    --ruby-6: oklch(38.2% 0.106 7.1);
    --ruby-7: oklch(44.8% 0.116 9.1);
    --ruby-8: oklch(54.3% 0.145 11.3);
    --ruby-9: oklch(62.8% 0.195 13.2);
    --ruby-10: oklch(66.4% 0.18 13.6);
    --ruby-11: oklch(78.1% 0.129 15.1);
    --ruby-12: oklch(90.6% 0.053 355.7);

    --ruby-a1: oklch(61.7% 0.242 18.1 / 3.53%);
    --ruby-a2: oklch(69.4% 0.2 10.4 / 5.49%);
    --ruby-a3: oklch(64.6% 0.244 14.7 / 17.25%);
    --ruby-a4: oklch(63.9% 0.247 13.5 / 25.88%);
    --ruby-a5: oklch(65.2% 0.239 11 / 32.55%);
    --ruby-a6: oklch(67.3% 0.223 10.1 / 39.61%);
    --ruby-a7: oklch(69.2% 0.204 10.7 / 50.20%);
    --ruby-a8: oklch(69.7% 0.198 12.2 / 68.24%);
    --ruby-a9: oklch(67.7% 0.213 13.4 / 89.41%);
    --ruby-a10: oklch(70.3% 0.192 13.7 / 92.16%);
    --ruby-a11: oklch(78.1% 0.129 15.1);
    --ruby-a12: oklch(90.9% 0.053 355.7);

    --amber-1: oklch(18.5% 0.013 77.8);
    --amber-2: oklch(21.2% 0.018 82);
    --amber-3: oklch(25.8% 0.044 74.5);
    --amber-4: oklch(29.6% 0.063 73.9);
    --amber-5: oklch(33.6% 0.072 72.9);
    --amber-6: oklch(38.6% 0.078 75.3);
    --amber-7: oklch(45.4% 0.082 75);
    --amber-8: oklch(53.6% 0.096 73.4);
    --amber-9: oklch(85.4% 0.157 84.1);
    --amber-10: oklch(88.5% 0.181 94.8);
    --amber-11: oklch(86.2% 0.173 89);
    --amber-12: oklch(93.5% 0.072 86.1);

    --amber-a1: oklch(60.9% 0.213 35.1 / 2.35%);
    --amber-a2: oklch(77.3% 0.172 66.2 / 5.10%);
    --amber-a3: oklch(72.8% 0.18 55 / 13.33%);
    --amber-a4: oklch(73.1% 0.182 54.5 / 19.61%);
    --amber-a5: oklch(74.6% 0.178 58.4 / 25.49%);
    --amber-a6: oklch(77.3% 0.172 66.2 / 31.76%);
    --amber-a7: oklch(80.4% 0.164 71.9 / 40.39%);
    --amber-a8: oklch(81.0% 0.158 71.9 / 52.94%);
    --amber-a9: oklch(85.4% 0.157 84.1);
    --amber-a10: oklch(88.5% 0.181 94.8);
    --amber-a11: oklch(86.2% 0.173 89);
    --amber-a12: oklch(93.5% 0.072 86.1);

    --blue-a4: oklch(59.26% 0.223 258.41 / 34.12%);
    --blue-a12: oklch(90.71% 0.051 238.45);

    --jade-4: oklch(31.62% 0.057 167.62);
    --jade-a4: oklch(88.24% 0.199 160.14 / 17.65%);
    --jade-a12: oklch(94.5% 0.081 166.61 / 93.73%);

    --orange-4: oklch(29.37% 0.071 56.7);
    --orange-a4: oklch(68.03% 0.214 39.8 / 22.35%);
    --orange-a12: oklch(92.47% 0.052 66.17);

    --sky-4: oklch(32.08% 0.07 248.74);
    --sky-a4: oklch(64.84% 0.193 252.75 / 28.63%);
    --sky-a12: oklch(93.4% 0.0526 214.317);

    --mint-4: oklch(31.46% 0.054 190.98);
    --mint-a4: oklch(90.18% 0.156 189.21 / 17.25%);
    --mint-a12: oklch(95.61% 0.059 168.06 / 96.08%);

    --text-primary: oklch(94.48% 0.003 247.95);
    --text-secondary: oklch(94.48% 0.003 247.95 / 75%);
    --text-tertiary: oklch(94.48% 0.003 247.96 / 61%);
    --text-helper: oklch(94.48% 0.003 247.96 / 61%);
    --text-placeholder: oklch(94.48% 0.003 247.96 / 48%);
    --text-disabled: oklch(94.48% 0.003 247.96 / 24%);
    --text-on-color: oklch(5.52% 0.003 247.95);

    --color-surface: oklch(0% 0 0 / 25.1%);
    --color-panel-solid: var(--slate-2);
    --color-panel-translucent: var(--slate-a2);

    .text-input[data-input-style~="outlined"],
    .select-input[data-input-style~="outlined"] {
      --input-box-shadow: inset 0 0 0 1px var(--slate-a7);
    }

    :where(.date-dropdown-profile) .select-input[data-input-style~="outlined"] {
      --input-box-shadow: inherit;
    }
  }
}


// PAGE
// ————————————————————

.login-page {
  display: grid;
  justify-items: center;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--slate-2);
  //overflow-y: scroll;

  @media (width >= 600px) {
    place-items: center;
  }
}

.login-page--wrapper {
  display: grid;
  grid: auto min-content / auto;
  row-gap: 4rem;
  max-width: calc(45ch + 1.5rem);
  padding-inline: clamp(1rem, calc(-48.5rem + 200vw), 1.5rem);
  padding-block: 2rem clamp(2rem, calc(-14rem + 53.3333vw), 8rem);

  @media (width < 600px) {
    row-gap: 2rem;
  }
}

.login-page--header {
  display: grid;
  justify-items: center;
  align-content: start;
  row-gap: 1rem;

  @media (width < 600px) {
    align-content: center;
  }

  @media (width < 380px) {
    padding-block-start: 1rem;
  }
}

.login-logo {
  --court-logo-size: 4rem;
}

.login-page--branding {
  text-align: center;
}

.login-page--form {
  display: grid;
  row-gap: 1.5rem;
  //padding-inline: clamp(1rem, calc(-48.5rem + 200vw), 1.5rem);
  //padding-block-end: 2rem;
}

.login-page--fieldset {
  display: grid;
  row-gap: 1.5rem;

  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
  }
}
