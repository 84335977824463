@use '../../../../node_modules/citizen/src/styles/abstracts' as *;
@use '../../../../node_modules/citizen/src/styles/components/badge' as *;

:where(html) {
  --header-size: 3.5rem;
  --header-background: var(--color-panel-translucent);
  --court-logo-size: 3rem;
  --menu-toggle-size: 2.5rem;
  --nav-island-size: 3rem;
  --nav-island-background: var(--color-panel-translucent);
  --nav-island-border: 1px solid var(--slate-a4);
  --nav-island-box-shadow: 0 2px 12px oklch(0% 0 0 / 8%);
  --background-blur: 16px;
}

// NEW NAV
// ——————————————————————————————
.app-bar {
  position: fixed;
  inset-block-start: 1rem;
  inset-inline: 1rem;
  min-height: var(--header-size);
  border-radius: calc(var(--header-size) / 2);
  z-index: 20;
  transition: background-color 100ms ease-out, inset 100ms ease-out;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    inset-inline: -1rem;
    inset-block: -1rem -0.5rem;
    backdrop-filter: blur(16px);
  }

  @media (width < 800px) {
    inset-inline: 0.75rem;
    display: block;
    background-color: var(--header-background);
    box-shadow: var(--nav-island-box-shadow);
    border: var(--nav-island-border);
    backdrop-filter: blur(64px);
    pointer-events: auto;

    &::before {
      display: none;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: auto;
  min-height: var(--header-size);
  position: relative;

  @media (width < 800px) {
    display: block;
  }
}

.branding {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  column-gap: 0.75rem;
  height: var(--header-size);
  position: absolute;

  &::after {
    --badge-background-color: var(--amber-4);
    --badge-text-color: var(--amber-a12);
    --badge-font-weight: 500;
    --badge-font-size: calc(11rem / 16);
    margin-inline-start: 0.75rem;
    @include badge-base;
  }

  @media (width < 800px) {
    position: relative;
  }
}

.court-logo-wrapper {
  width: var(--court-logo-size);
  height: var(--court-logo-size);
  border-radius: calc(var(--court-logo-size) / 2);
  display: grid;
  place-items: center;
  overflow: hidden;
}

.court-name-wrapper > span {
 display: block;
}

.court-logo {
  object-fit: cover;
  height: 100%;
  // TODO: May need width: 100% here as well, but object-fit: cover should preserve aspect ratio
}


// HAMBURGLAR BUNS
// ————————————————————
.menu-toggle {
  position: absolute;
  top: calc((var(--header-size) - var(--menu-toggle-size)) / 2);
  right: 0.5rem;
  width: var(--menu-toggle-size);
  height: var(--menu-toggle-size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.375rem;
  cursor: pointer;

  &::before, &::after {
    content: "";
    height: 1px;
    width: 1.5rem;
    background-color: var(--text-primary);
    transform-origin: center center;
    transition: translate 50ms ease-out 100ms, rotate 50ms ease-out;
  }

  @media (width >= 800px) {
    display: none;
  }
}


// Animate the buns when menu is opened/closed
// ————————————————————
:where(.menu-toggle[aria-expanded="true"]).menu-toggle::before {
  translate: 0 3px;
  rotate: 45deg;
  transition: translate 100ms ease-out, rotate 100ms ease-out 100ms;
}

:where(.menu-toggle[aria-expanded="true"]).menu-toggle::after {
  translate: 0 -4px;
  rotate: -45deg;
  transition: translate 100ms ease-out, rotate 100ms ease-out 100ms;
}


// Wrapper for the nav + candidate info
// ————————————————————
.primary-nav {
  display: none;
  grid-template-rows: 0fr;
  margin-inline: auto;
  transition: grid-template-rows 125ms ease-out, padding 125ms ease-out;

  @media (width < 800px) {
    display: grid;
  }
}

.primary-nav[aria-hidden="false"] {
  grid-template-rows: 1fr;
  padding-block: 1rem;
  transition: grid-template-rows 200ms ease-out, padding 200ms ease-out;
}

// Extra container required to animate primary nav open/closed with grid rows
// ————————————————————
.primary-nav-expandable {
  grid-row: 1 / span 2;
  transition: opacity 100ms ease-out;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

:where(.primary-nav[aria-hidden="false"]) .primary-nav-expandable {
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms ease-out;
}

.primary-nav-candidate-info {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.candidate-info-id {
  display: flex;
  align-items: center;
  margin-block-start: 0.25rem;

  & .divider {
    display: inline-block;
    background-color: var(--text-primary);
    height: 1px;
    width: 1.5em; // Yes, em is correct. Use font size for harmonious spacing
    margin-inline: 0.5em; // Yes, em is correct. Use font size for harmonious spacing
  }
}

.primary-nav-list {
  height: var(--nav-island-size);
  border-radius: calc(var(--nav-island-size) / 2);
  background-color: var(--nav-island-background);
  padding-inline: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background-clip: padding-box;
  border: var(--nav-island-border);
  box-shadow: var(--nav-island-box-shadow);
  backdrop-filter: blur(var(--background-blur));
  pointer-events: auto;

  @media (width < 800px) {
    --nav-island-size: auto;
    --nav-island-background: transparent;
    --nav-island-border: none;
    --nav-island-box-shadow: none;
    display: block;
    padding-inline: 1.5rem;
    backdrop-filter: none;
  }
}

.primary-nav-list[data-primary-nav-list~="large-screen"] {
  margin-inline: auto;

  @media (width < 800px) {
    display: none;
  }
}

.ja-nav-item {
  font-size: var(--nav-item-font-size, var(--scale-1));
  line-height: var(--nav-item-line-height, (--lh-scale-1));
  font-weight: var(--nav-item-font-weight, 500);
  height: var(--nav-item-height, 2rem);
  color: var(--text-primary);
  display: grid;
  place-items: center;
  padding-inline: 0.75rem;
  border-radius: 1rem;
  position: relative;

  &:hover {
    color: var(--text-primary);
    // TODO: Consider removing global a style from _typography.scss
  }

  &::before {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: 2rem;
    z-index: -1;
    transition: background-color 150ms ease-out;
  }

  &:hover::before {
    background-color: var(--slate-a3);
  }

  @media (width < 800px) {
    --nav-item-height: 3rem;
    --nav-item-font-size: var(--scale2);
    --nav-item-line-height: var(--lh-scale2);
    --nav-item-font-weight: 400;
    justify-items: start;
    padding-inline: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--slate-a5);

    &::before {
      display: none;
    }
  }
}

.logout-wrapper {
  pointer-events: auto;
}

.logout-wrapper[data-logout~="small-screen"] {
  display: none;

  @media (width < 800px) {
    display: grid;
    place-items: center;
    justify-items: start;
    height: 4rem;
    padding-inline: 1.5rem;

    .primary-nav-logout {
      display: grid;
      grid-template-columns: repeat(2, auto);
      place-items: center;
      column-gap: 0.5rem;
      min-height: 2rem;
    }
  }
}

.logout-wrapper[data-logout~="large-screen"] {
  position: absolute;
  inset-inline-end: 0;

  @media (width < 800px) {
    display: none;
  }
}
