@use '../../../../node_modules/citizen/src/styles/abstracts' as *;
@use 'content' as *;

:where(html) {
  --container-width: 79rem;
  --min-padding: 2.891566265060241vw; //24px @ 830px wide viewport
}

.profile-incomplete-container {
  @include content-layout-base;
}

.profile-container {
  --content-grid-columns: 1fr;
  @include content-layout-base;
}

.profile {

}

.profile-form,
.profile-form-incomplete {
  @include content;
}

.profile-header {
  --content-intro-padding: 1rem;
  @include content-header;
}

.profile-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 1.5rem 2rem;
  background-color: var(--color-panel-solid);
  border: 1px solid var(--slate-a5);
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  box-shadow: 0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 1px 0px -1px hsl(0 0% 0% / 0.05),
              0px 1px 1px -1px hsl(0 0% 0% / 0.05),
              0px 1px 2px -1px hsl(0 0% 0% / 0.05),
              0px 2px 4px -2px hsl(0 0% 0% / 0.05),
              0px 3px 6px -3px hsl(0 0% 0% / 0.05);

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  h3 {
    margin-block-end: 0.5rem;
    font-weight: 500;
  }

  &:last-of-type > label {
    width: 80ch;
  }
}

.profile-section-footer {
  display: flex;
  justify-content: flex-end;
  padding-block-start: 0.5rem;
}

.profile-sms-opt-in--label {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
