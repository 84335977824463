@use '../../../node_modules/flatpickr/dist/flatpickr';
@use '../../../node_modules/@tylertech/forge/dist/button/forge-button';
@use '../../../node_modules/@tylertech/forge/dist/tooltip/forge-tooltip';
@use '../../../node_modules/@tylertech/forge/dist/icon-button/forge-icon-button';
@use 'old-styling' as *; // TODO: Inserted after the above imports to override the new styles temporarily. Once JA is ready to refactor entirely, move it above or delete it.
@use '../../../node_modules/citizen/src/styles/abstracts' as *;
@use '../../../node_modules/citizen/src/styles/base' as *;
@use '../../../node_modules/citizen/src/styles/layout' as *;
@use '../../../node_modules/citizen/src/styles/components' as *;
@use 'components' as *;
@use 'layouts' as *;

:where(html) {
  --spacing: 0.5rem;
  --validation-scroll-padding: calc(var(--header-size) + 2rem);
}


// GLOBAL
// ————————————————————

html {
  scroll-padding-block-start: var(--validation-scroll-padding, 5.5rem);
  scroll-padding-block-end: 2rem;

  @media (width < 800px) {
    --validation-scroll-padding: calc(var(--sidebar-nav-size-mobile) + 1rem);
  }
}

body {
  overflow: initial;
  //height: 100vh;
  //height: 100dvh;
}

.icon {
  position: relative;
}


// ————————————————————
// EXEMPTION REQUESTS
// ————————————————————
//.exemption-request {
//  display: flex;
//  flex-direction: column;
//  gap: calc(var(--spacing) * 4);
//
//  .grid {
//    grid-gap: 24px 32px;
//    @include breakpoint(lg) {
//      grid-template-columns: repeat(12, 1fr);
//    }
//  }
//
//  &-section {
//    display: flex;
//    flex-direction: column;
//    gap: calc(var(--spacing) * 4);
//  }
//
//  h2 {
//    margin-bottom: 0;
//  }
//
//  hr.grid-item-full {
//    // grid-row: 1;
//    margin-bottom: 0.75rem;
//  }
//
//  textarea {
//    resize: vertical;
//  }
//}

//.exemption-grid-item--requestor {
//  .card {
//    padding: 0;
//    border: 1px solid $hairlines;
//  }
//  .card-header {
//    justify-content: space-between;
//    .text-short-sm.semibold {
//      flex: 2 1 auto;
//    }
//    .icon {
//      margin-left: 1rem;
//    }
//  }
//  .card-body {
//    p.text-short-md {
//      margin-top: 4px;
//    }
//  }
//}

.exemption-submit {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-inline: 0.5rem;

  p {
    margin-top: 0;
    max-width: calc(1em * 30);
  }

  @include breakpoint(md) {
    min-width: 640px;
    max-width: 1140px;
  }
}

.exemption-submit-icon {
  border-radius: 50%;
  width: calc(96rem / 16);
  height: calc(96rem / 16);
  position: relative;
  margin-bottom: 1.5rem;
  &:before {
    content: "";
    display: block;
    height: calc(80rem / 16) ;
    width: calc(80rem / 16) ;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  svg {
    fill: $white;
    width: 2rem;
    height: 2rem;
  }
  &--success {
    background: rgba($mint-green, .56);
    &:before {
      background: $dark-green;
    }
  }
  &--unavailable {
    background: transparentize($gold, 0.56);
    &:before {
      background: $gold;
    }
    svg {
      fill: $dark-gold;
    }
  }
}

// Alexis Hackity hack hack. James, Please fix
//.exemption-request-question-changed {
//  display: flex;
//  flex-direction: row;
//  align-items: flex-start;
//  padding: 1rem;
//
//  background: rgba(59, 135, 179, 0.2);
//  border: 1px solid rgba(59, 135, 178, 0.2);
//  box-sizing: border-box;
//  border-radius: 0.5rem;
//  svg {
//    margin-right: calc(10rem / 16) ;
//  }
//}
