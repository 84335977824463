@use '../../../../node_modules/citizen/src/styles/abstracts' as *;


// ——————————————————————————————
// PRIMARY CONTAINER
// ——————————————————————————————
.sidebar-nav {

  @media (width < 800px) {
    position: fixed;
    inset-inline: 0;
    display: flex;
    align-items: flex-end;
    min-height: var(--sidebar-nav-size-mobile, 120px);
    backdrop-filter: blur(16px);
    z-index: 10;
  }
}


// ——————————————————————————————
// SIDEBAR INTERIOR —— Desktop
// Formerly known as sidebar-container
// ——————————————————————————————
.sidebar--inner {
  position: sticky;
  top: calc(var(--content-grid-margin) + 0.5rem);
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  @media (width < 800px) {
    display: none;
  }
}

.sidebar--inner-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  min-height: 3rem;
  width: 100%;
  padding-inline: clamp(1rem, calc(0.0344rem + 3.9312vw), 2rem);

  @media (width >= 800px) {
    display: none;
  }
}


// Candidate Info
// ——————————————————————————————
.sidebar--candidate-info {
  padding-inline: 0.5rem;
}

.sidebar--inner .sidebar--candidate-info {
  .heading-06 {
    font-size: clamp(var(--scale2), 2.0100502512562812vw, var(--scale3));
    letter-spacing: clamp(var(--ls-scale2), -0.03417085427135678vw, var(--ls-scale3));
    line-height: 1.25;
  }
}

// Questionnaire nav
// ——————————————————————————————
.questionnaire-nav {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  row-gap: 0.25rem;
}

// Questionnaire list item
.questionnaire-nav-item {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  min-height: 2.5rem;
  padding-inline: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid transparent;
  color: var(--text-secondary);
  transition: color 150ms ease-out, background-color 150ms ease-out;

  &.complete {
    font-weight: 500;
    color: var(--text-tertiary);
  }

  &--nest,
  &--group {
    flex-direction: column;
    align-items: flex-start;
    padding-block: 0.25rem;
  }

  &.active {
    background-color: var(--color-panel-translucent);
    border: 1px solid var(--slate-a5);
    background-clip: padding-box;
  }
}

// Nested questionnaire list item
.sidebar-nav-questionnaire-container {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  min-height: 2rem;
  width: 100%;

  @include breakpoint(md-questionnaire) {
    //margin-top: 0;
  }

  &.active {
    color: var(--text-primary);
    font-weight: 500;
  }

  &.inactive {
    color: var(--text-tertiary);
  }

  .questionnaire-icon--nav {
    width: calc(20rem / 16);
    fill: hsl(var(--gray-90)); // Old style we may no longer need
  }
}


// ——————————————————————————————
// SIDEBAR INTERIOR —— Mobile
// ——————————————————————————————
.sidebar-mobile {
  padding-block-start: 1.5rem;
  will-change: max-height;
  opacity: 0;
  overflow: hidden;
  transition: max-height 1000ms cubic-bezier(.165, .84, .44, 1),
              background-color 1500ms cubic-bezier(.165, .84, .44, 1),
              opacity 600ms ease-out;

  @include breakpoint(md-questionnaire) {
    display: none;
  }
}

.sidebar-container-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-inline: clamp(1rem, 4.102564102564102vw, 1.5rem);
  transition: max-height 600ms cubic-bezier(.165, .84, .44, 1);
}

.sidebar-nav-state:checked ~ .sidebar-nav {
  .sidebar-mobile {
    opacity: 1;
  }
}

// Slide down nav item container
.sidebar-nav-state:checked ~ .sidebar-nav {
  .sidebar--candidate-info {
    animation: slideNav 2000ms cubic-bezier(.165, .84, .44, 1);
    animation-iteration-count: 1;
    animation-delay: 25ms;
  }

  .questionnaire-nav {
    animation: slideNav 3000ms cubic-bezier(.165, .84, .44, 1);
    animation-iteration-count: 1;
    animation-delay: 100ms;
  }
}


// ——————————————————————————————
// UTILITY PIECES
// ——————————————————————————————

.progress-stats {
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  margin-inline: auto 0.125rem;
  font-weight: 400;
  font-variant-numeric: tabular-nums;
}

// Circle check icons
.circle-check {
  display: flex;
}

.circle-check.checked {
  //margin-inline-start: auto;
}

.circle-check span {

}

.circle-check.checked span {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
}


// ——————————————————————————————
// ANIMATION
// ——————————————————————————————

@keyframes slideNav {
  0% {
    opacity: 0;
    transform: translateY(-12px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
}
