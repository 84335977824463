@use "../../../../node_modules/citizen/src/styles/abstracts" as *;
@use "../../../../node_modules/citizen/src/styles/base" as *;


// History
// ————————————————————

.grid-parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;

  @include breakpoint(md) {
    grid-template-columns: repeat(16, 1fr);
    grid-column-gap: 32px;
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(16, 1fr);
    grid-column-gap: 32px;
  }
}

.history-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;

  @include breakpoint(md) {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 32px;
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(16, 1fr);
    grid-column-gap: 32px;
  }
}

.history-body {
  grid-column: 1/-1;
  margin-top: 1rem;
  padding-inline: 1rem;

  @include breakpoint(md-questionnaire) {
    grid-column: 3 / -3;
    padding-top: 1rem;
    padding-bottom: 4rem;
    margin: 0;
  }
}

.history-dashboard-header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  h2 {
    @extend .heading-07;
    font-variation-settings: "wght" 500;
  }
}

.list-border-top {
  li {
    border-top: 1px solid $hairlines;
    margin-bottom: 13px;
    padding-top: 11px
  }
}

.history-dashboard-hero {
  margin-top: 96px;

  @include breakpoint(md) {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  .list-border-top {
    width: 100%;
    margin-top: 40px;
    grid-column: 1 / -1;

    @include breakpoint(lg) {
      grid-column: -1 / -6;
    }
    @include breakpoint(xl) {
      grid-column: -1 / -5;
    }
    li {
      display: flex;
      span {
        flex: 50%;
      }
    }
  }
}

.history-dashboard-instruction {
  grid-column: 1 / -1;
  @include breakpoint(lg) {
    grid-column: 1 / 10;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    &:last-child {
      color: $lighter-grey;
    }
  }

  h2 {
    @extend .heading-07;
    font-variation-settings: "wght" 500;
  }
}

.list-card-header--controls {
  margin-top: 0.5rem;

  .btn-tertiary {
    border-radius: 6px;
  }
}
