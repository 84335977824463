/* This is an old version. Updated one is located in the jury-manager directory. */

/*
Citizen Design System
Version 0.1a

Table of Contents
-----------------------
00 Structure
01 Cards
02 Lists
03 Forms
04 Buttons & Links
05 Interactive
-> 05A Tooltips
06 Utility
07 Modals

** Supports
-----------------------

$breakpoint-xs: 320px;
$breakpoint-sm: 480px;
$breakpoint-md: 672px;
$breakpoint-lg: 1056px;
$breakpoint-xl: 1312px;
$breakpoint-xxl: 1584px;
$breakpoint-xxxl: 1728px;
*/

@use '../../../node_modules/citizen/src/styles/abstracts' as *;
@use '../../../node_modules/citizen/src/styles/base' as *;

// 00 Structure
//.container-login {
//  position: relative;
//  min-height: 100%;
//  max-width: 1728px;
//  box-sizing: content-box;
//  margin: 0 auto;
//  padding-inline: 1rem;
//
//  @include breakpoint(md){
//    background: none;
//  }
//}

//.grid-parent {
//  grid-template-columns: repeat(8, [col-start] 1fr);
//  grid-column-gap: 16px;
//}

//.grid-item {
//  &-full {
//    grid-column: 1 / -1;
//  }
//  &-center {
//    grid-column: 3 / -3;
//  }
//  &-1 {
//    grid-column: 1 / 2;
//  }
//  &-2 {
//    grid-column: 1 / 3;
//  }
//  &-3 {
//    grid-column: 1 / 4;
//  }
//  &-4 {
//    grid-column: 1 / 5;
//  }
//  &-5 {
//    grid-column: 1 / 6;
//  }
//  &-6 {
//    grid-column: 1 / 7;
//  }
//  &-7 {
//    grid-column: 1 / 8;
//  }
//  &-8 {
//    grid-column: 1 / 9;
//  }
//  &-9 {
//    grid-column: 1 / 10;
//  }
//  &-10 {
//    grid-column: 1 / 11;
//  }
//  &-11 {
//    grid-column: 1 / 12;
//  }
//  &-12 {
//    grid-column: 1 / 13;
//  }
//  &-13 {
//    grid-column: 1 / 14;
//  }
//  &-14 {
//    grid-column: 1 / 15;
//  }
//  &-15 {
//    grid-column: 1 / 16;
//  }
//  &-16 {
//    grid-column: 1 / 17;
//  }
//}

//hr {
//  border-top: 1px solid $hairlines;
//  width: 100%;
//}


// 01 Cards
// ————————————————————
.card {
  position: relative;
  background-clip: padding-box;
  background-color: var(--white);
  border: 1px solid hsl(var(--gray-90) / 8%);
  border-radius: 0.5rem;
  padding: 24px;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 1px 0px -1px hsl(0 0% 0% / 0.05),
              0px 1px 1px -1px hsl(0 0% 0% / 0.05),
              0px 1px 2px -1px hsl(0 0% 0% / 0.05),
              0px 2px 4px -2px hsl(0 0% 0% / 0.05),
              0px 3px 6px -3px hsl(0 0% 0% / 0.05);
  transition: $transition all;

  &--field-group {
    background: $off-white;
    width: calc(50% - 36px);
    font-size: 2.2rem;
    margin: 0 32px 32px 0;
    display: inline-block;
    border-radius: 12px;
    padding: 24px;
  }

  &-with-header {
    padding: 0;
    border-radius: 8px;
  }

  &.is-invalid {
    border: 1px solid $error-red;
    transition: 0.4s ease all;
    box-shadow: 0 0 16px -2px rgba(211,66,66,0.2);
  }
}

//.list-card-header--controls {
//  display: flex;
//  gap: 8px;
//  flex-wrap: wrap;
//}


// 02 Lists
// ————————————————————
.list {
  list-style: none;
  padding: 0;
  position: relative;
}


//.list-actions {
//  li {
//    display: inline-block;
//    margin-right: 48px;
//  }
//}

//.list-checklist {
//  .icon {
//    position: relative;
//    z-index: 2;
//  }
//  .icon-circle {
//    width: 18px;
//    height: 18px;
//  }
//}


// 03 Forms
// ————————————————————
//textarea.form-input-field {
//  resize: none;
//  min-height: 120px;
//  display: block;
//  width: 100%;
//}

//.form-input-readonly {
//  background: none;
//}

//.form-input-field {
//    display: block;
//    width: 100%;
//    text-overflow: ellipsis;
//    padding-right: 20px;
//
//  &__half {
//    width: 50%;
//    display: inline-block;
//  }
//  &__third {
//    width: 33%;
//    display: inline-block;
//  }
//  &__fourth {
//    width: 25%;
//    display: inline-block;
//  }
//  &__fifth {
//    width: 20%;
//    min-width: 88px;
//    display: inline-block;
//  }
//  &__sixth {
//    width: 16.6%;
//    min-width: 66px;
//    display: inline-block;
//  }
//}

.form-icon-control {
  position: relative;
}

//.form-select-control {
//  position: relative;
//  display: flex;
//  width: auto;
//  &:after {
//    content: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 5.99999L0 0.999988L0.7 0.299988L5 4.59999L9.3 0.299988L10 0.999988L5 5.99999Z' fill='%23303133'/%3E%3C/svg%3E%0A");
//    display: block;
//    position: absolute;
//    right: 12px;
//    top: 6px;
//    pointer-events: none;
//  }
//}

//.form-select-control__date {
//  flex: 1;
//}

//.form-block {
//  display: flex;
//  justify-content: space-between;
//
//  &__half {
//    width: 49%;
//    display: inline-block;
//  }
//  &__third {
//    width: 32%;
//    display: inline-block;
//  }
//}

//.form-group {
//  width: 100%;
//  margin: 8px 0;
//  &__half {
//    width: 100%;
//    //margin: 6px 0;
//    @include breakpoint(lg) {
//      width: 48%;
//    }
//  }
//  &__third {
//    width: 33%;
//  }
//  &__fourth {
//    width: 25%;
//  }
//  &__inline, &__checkbox {
//    column-count: 2;
//    margin-top: 0;
//    @include breakpoint(md) {
//      column-count: initial;
//      display: flex;
//      flex-direction: row;
//    }
//  }
//
//  .form-select-control {
//    width: 100%;
//    @include breakpoint(lg) {
//      width: 48%;
//    }
//    select {
//      width: 100%;
//    }
//  }
//}

//.form-list-container {
//  display: grid;
//  grid-template-columns: repeat(2, 1fr);
//  gap: 1.5rem 2rem;
//}


// 04 Buttons & Links
// ————————————————————

//.btn, .btn-link, .btn-icon-link {
//  cursor: pointer;
//  .icon {
//    width: 1.2em;
//    position: relative;
//
//    &-arrow__left {
//      margin-right: 10px;
//      top: 2px;
//    }
//  }
//}

//.icon-arrow {
//  fill: $white;
//  transition: $transition transform;
//}

.btn, .btn-link, .btn-icon-link {

  .animate-slide--right,
  .animate-slide--left {
    transition: $transition transform;
  }

  &:hover {
    .animate-slide--right {
      transform: translateX(6px);
    }
    .animate-slide--left {
      transform: translateX(-6px);
    }
  }
}


//.btn-link {
//  color: $primary-grey-70;
//  text-decoration: none;
//  font-weight: 600;
//  transition: $transition all;
//
//  &:hover {
//    color: lighten($primary-grey-70, 30%);
//  }
//}

//.btn-icon-link {
//  color: $secondary-color;
//  font-weight: 500;
//  display: flex;
//  align-items: center;
//
//  .animate-slide--left {
//    margin-right: 6px;
//  }
//
//  .icon {
//    margin: 1px 0 0 6px;
//  }
//
//  span + inline-svg {
//    margin-left: 6px;
//  }
//
//}

.link-breadcrumb {
  font-size: inherit;
}



// 05 Interactive

// 05A Tooltips
// ————————————————————
//.tooltip {
//  display: none;
//  z-index: 1000;
//  background: $white;
//  opacity: 0;
//  position: absolute;
//  top: 50%;
//  transform: translateY(-50%);
//  margin-left: 30px;
//  line-height: 1.5;
//  min-width: 120px;
//  max-width: 260px;
//  width: max-content;
//  padding: 0 18px 18px;
//  color: $dark-grey;
//  text-transform: none;
//  font-size: 1.125em;
//
//  &:before {
//    top: 50%;
//    left: -24px;
//    border: solid transparent;
//    content: " ";
//    height: 0;
//    width: 0;
//    position: absolute;
//    pointer-events: none;
//    border-color: rgba(255, 255, 255, 0);
//    border-right-color: #fff;
//    transform: translateY(-50%);
//    border-width: 12px;
//    margin-left: 0;
//    }
//
//  &__top {
//    top: -24px;
//    transform: translate(-50%, -100%);
//    margin-left: 0;
//    line-height: 1.5;
//    margin: auto;
//    left: 100%;
//
//
//    &:before {
//      top: 99%;
//      left: 50%;
//      border: solid transparent;
//      content: " ";
//      height: 0;
//      width: 0;
//      position: absolute;
//      pointer-events: none;
//      border-color: rgba(255, 255, 255, 0);
//      border-top-color: $white;
//      transform: translate(-50%, 0%);
//      border-width: 12px;
//    }
//  }
//
//  h6 {
//    margin-bottom: 0;
//  }
//
//}

//.tooltip-trigger {
//  display: inline-block;
//  position: relative;
//  line-height: 0;
//  cursor: pointer;
//  transition: $transition all;
//
//  &:hover {
//    color: $midnight-spruce;
//    .tooltip {
//        display: block;
//        opacity: 1;
//    }
//  }
//
//}

// **Support Classes

// *Typography supports
//.t-light-grey {
//  color: $primary-grey-70;
//}
// *Store breakpoints in body:before content.
// *This allows Javascript to access them

body {
  &.noscroll {
    overflow: hidden;
    position: fixed;
  }
  &:before {
    display: none;
    content: "initial";
    @include breakpoint(xs) {
      content: "xs";
    }
    @include breakpoint(sm) {
      content: "sm";
    }
    @include breakpoint(md) {
      content: "md";
    }
    @include breakpoint(lg) {
      content: "lg";
    }
    @include breakpoint(xl) {
      content: "xl";
    }
    @include breakpoint(xxl) {
      content: "xxl";
    }
    @include breakpoint(xxxl) {
      content: "xxxl";
    }
  }
}


// Table
// ————————————————————
.table {
  width: 100%;
  border-collapse: collapse;

  th {
    background: var(--slate-a3);
    text-align: left;
    font-weight: 600;
    display: none;

    @include breakpoint(md) {
      display: table-cell;
    }
  }

  th, td {
    padding: 16px;
    height: 48px;
    box-sizing: border-box;
    font-size: var(--scale-1);
    line-height: var(--lh-scale-1);
  }

  td {
    display: flex;
    align-items: center;
    padding: 0;
    height: auto;
    border-bottom: 1px solid var(--slate-a6);

    &:first-child {
      border-top: 1px solid var(--slate-a6);

      @include breakpoint(md) {
        border: 0;
      }
    }

    &:last-child {
      //border: 0;
      display: flex;
      justify-content: center;

      @include breakpoint(md) {
        display: table-cell;
        justify-content: initial;
      }

      .table-action {
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;

        button {
          width: 100%;
          height: 100%;
        }

        @include breakpoint(md) {
          height: initial;
          display: inline-block;
        }
      }
    }

    @include breakpoint(md) {
      display: table-cell;
      padding: 16px;
      height: 48px;
    }
  }

  tr {
    background: var(--color-panel-solid);
    display: block;
    margin-bottom: 1.5rem;

    @include breakpoint(md) {
      margin-bottom: 0;
      display: table-row;
      border-bottom: 1px solid var(--slate-a6);
    }
  }
}

.table-header--mobile {
  display: inline-block;
  font-weight: 600;
  width: 110px;
  height: 40px;
  box-sizing: border-box;
  padding: 12px;
  background: var(--slate-a3);
  @include breakpoint(md) {
    display: none;
  }
}

.table-data-content {
  width: calc(100% - 110px);
  box-sizing: border-box;
  padding: 0 12px;

  @include breakpoint(md) {
    width: auto;
    padding: 0;
  }
}

.table-action {
  text-align: right;

  button {
    appearance: none;
    border: 0;
    background: none;
    outline: 0;
  }
}

// 06 Utility
//.error {
//  color: $error-red;
//}
