@use '../../../../node_modules/citizen/src/styles/abstracts' as *;

.floating-nav-bar {
  display: none;
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 3.5rem;
  z-index: 20;

  @include breakpoint(md){
    display: block;
  }

  @media (min-width: 672px) and (max-width: 829px) {
    top: 0;
    left: 0;
    right: 0;
    height: 5rem;
    background-color: hsl(var(--gray-05) / 90%);
    backdrop-filter: blur(20px);
    padding: 1rem 1rem 0.5rem 1rem;
  }
}

.floating-nav {
  background: white;
  height: 100%;
  width: fit-content;
  border-radius: 28px;
  box-shadow: 0 43px 26px rgba(0, 0, 0, 0.01), 0 19px 19px rgba(0, 0, 0, 0.02), 0 5px 11px rgba(0, 0, 0, 0.02), 0 0 0 rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  padding: 0.25rem 1.5rem 0.25rem 0.25rem;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__logout {
    border: none;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: 1.5rem;
    color: hsl(var(--gray-70));
    cursor: pointer;

    & i {
      font-size: var(--scale-1);
    }
  }

  &__logo {
    width: 100%;
    height: 100%;

    &-wrapper {
      height: 3rem;
      width: 3rem;
      border-radius: 30px;
      overflow: hidden;
    }
  }

  &__links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }

  &__link {

    & a, button {
      font-size: var(--scale-1);
      font-weight: 500;
      color: hsl(var(--gray-90));
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}
