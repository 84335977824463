@use '../../../node_modules/citizen/src/styles/abstracts' as *;


// GLOBAL BITS
// ——————————————————————————————

.question-number {
  font-family: var(--font-mono);
  font-feature-settings: "lnum" 1,"pnum" 1,"kern" 1,"liga" 1, "ss04" 1;
  font-size: var(--scale-1);
  line-height: var(--lh-scale-1);
  letter-spacing: normal;
  color: var(--text-secondary);
}


// ——————————————————————————————
// COMPLETING QUESTIONNAIRE
// ——————————————————————————————

.question-card {
  background-clip: padding-box;
  background-color: var(--color-panel-solid);
  border: 1px solid var(--slate-a5);
  border-radius: 0.5rem;
  padding: 1rem 3rem 1.5rem 1rem;
  box-shadow: 0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 1px 0px -1px hsl(0 0% 0% / 0.05),
              0px 1px 1px -1px hsl(0 0% 0% / 0.05),
              0px 1px 2px -1px hsl(0 0% 0% / 0.05),
              0px 2px 4px -2px hsl(0 0% 0% / 0.05),
              0px 3px 6px -3px hsl(0 0% 0% / 0.05);

  @media (max-width: 479px) {
    padding: 1rem 1rem 1.5rem 0.5rem;
  }
}

.question-card--body {
  display: flex;
  column-gap: 1rem;
}

.questionnaire-body-editable {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: calc(var(--spacing) * 3);

  input[type=number] {
    max-width: 12ch;
  }
}

.question__answer {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);
  container-type: inline-size;
  container-name: question-answer;
}


// Unsure if this is required at the moment
// This might be covered by the .questionnaire-body-editable class above
.question-group {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);
}

// Nested question that shows up when selecting triggering answer
.questionnaire--question-nested {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 3);

  @include breakpoint(md-questionnaire) {
    max-width: none;
    margin-left: initial;
  }
}


// ——————————————————————————————
// REVIEW QUESTIONNAIRE
// ——————————————————————————————
.question-card-with-header {
  background-clip: padding-box;
  background-color: var(--color-panel-solid);
  //border: 1px solid var(--slate-a6);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 0px 0px 0px hsl(0 0% 0% / 0),
              0px 1px 0px -1px hsl(0 0% 0% / 0.05),
              0px 1px 1px -1px hsl(0 0% 0% / 0.05),
              0px 1px 2px -1px hsl(0 0% 0% / 0.05),
              0px 2px 4px -2px hsl(0 0% 0% / 0.05),
              0px 3px 6px -3px hsl(0 0% 0% / 0.05);
}

.question-card--header {
  position: relative;
  display: flex;
  column-gap: 1rem;
  padding: 1rem 5.875rem 1rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid var(--slate-a6);
  min-height: 3rem;

  button {
    position: absolute;
    top: px-to-rem(9);
    right: 0.5rem;
  }

  &-editing {
    background: var(--blue-a3);
    border: 1px solid var(--blue-a6);
    color: var(--blue-a12);
  }
}

.question-card-with-header--body {
  padding-block: 1rem 1.5rem;
  padding-inline: clamp(1rem, calc(-0.04rem + 4.2667vw), 3rem);
  border: 1px solid var(--slate-a6);
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

:where(.dialog-body) {
  .question-card-with-header {
    background-color: var(--gray-bg-ui);
  }

  .question-card-with-header--body {
    padding-inline: 1rem;
  }
}

.question--readonly {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);
}

