:where(html) {
  --mobile-nav-size: 3.5rem;
  --sidebar-nav-size-mobile: calc(var(--header-size) + 1rem + var(--sidebar-inner-size-mobile));
  --sidebar-inner-size-mobile: 3rem;

  --content-grid-columns: [sidebar-start] var(--sidebar-width) [sidebar-end content-start] 1fr [content-end];
  --content-grid-rows: ;
  --content-column-gap: clamp(2rem, calc(-3.7143rem + 11.4286vw), 4rem);
  --content-grid-margin: calc(120rem / var(--rem-base));
  --content-grid-padding-inline: clamp(1rem, calc(0.0344rem + 3.9312vw), 2rem);
  --content-grid-width: fit-content;

  --content-width: calc(800rem / var(--rem-base));
  --content-intro-size: clamp(var(--scale3), calc(0.0714rem + 2.8571vw), var(--scale5)); // 800px & 1080px
  --content-intro-line-height: clamp(var(--lh-scale3), calc(0.0893rem + 3.5714vw), var(--lh-scale5)); // 800px & 1080px
  --content-row-gap: 1rem;
  --content-margin-bottom: 4rem;
  --content-padding-top: calc(var(--sidebar-nav-size-mobile) + 2rem);
}

.app-layout {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--slate-1);
  //overflow-y: scroll;
}


@mixin content-layout-base {
  display: grid;
  grid-template-columns: var(--content-grid-columns);
  column-gap: var(--content-column-gap, 4rem);
  margin-inline: auto;
  padding-block-start: var(--content-grid-margin);
  padding-inline: var(--content-grid-padding-inline);
  max-width: var(--content-grid-width, fit-content);
  min-height: 100vh; // For browsers that don't support dynamic vh unit
  min-height: 100dvh;

  @media (width < 800px) {
    --content-grid-columns: 1fr;
    --content-grid-margin: 0;
  }
}


@mixin content {
  display: flex;
  flex-direction: column;
  row-gap: var(--content-row-gap, 1rem);
  margin-block-end: var(--content-margin-bottom, 4rem);
  max-width: var(--content-width, 40rem);

  @media (width < 800px) {

  }
}

@mixin content-header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-width: var(--content-width, 40rem);

  padding-inline: var(--content-intro-padding);

  @media (width < 800px) {
    --content-intro-padding: 0;
    padding-block-start: var(--content-padding-top);
  }
}

.content-intro {
  font-size: var(--content-intro-size);
  line-height: var(--content-intro-line-height);
  font-weight: 450;
  margin-block-end: var(--content-intro-line-height);
}
